<template>
    <div v-if="this.userData.privileges.proxyAccess || this.userData.id != this.userData.csrep_userid">
        <v-dialog
        v-model="dialog"
        scrollable
        transition="dialog-bottom-transition"
        max-width="800">
        <template v-slot:activator="{ on, attrs }">
        <v-icon
            large
            color="info"
            v-bind="attrs"
            v-on="on"
        >mdi-account-search</v-icon>

        <v-btn class="ml-6 caption info small" elevation="0"  :href="getCsiLink()" target="_blank" rel="noopener">CSI<v-icon
        right
        dark
        >
            mdi-open-in-new
        </v-icon></v-btn>
        </template>
            <v-card>
            <v-toolbar class="data-table-toolbar" dark flat>
                <h2>Find A User</h2>
            </v-toolbar>
            <v-card-title>
                <v-text-field
                    id="proxySearch"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text class="pa-4" style="height: 430px;">
                <div>
                <v-data-table
                    dense
                    v-on:input="searchForProxyUsers"
                    :headers="headers"
                    :items="getProxyUserList()"
                    :page.sync="page"

                    show-selected
                    @page-count="pageCount = $event"
                    @click:row="rowClicked"
                    :loading="searchLoading"
                    loading-text="Loading... Please wait"
                    :footer-props="{
                        'items-per-page-options': itemsPerPageList
                    }">
                    <template v-slot:item="{ item }">
                        <tr v-if="item.blocked" :class="selectedRow.indexOf(item.userid)>-1?'selected-disabled-blocked-row':'disabled-blocked-row'" @click="rowClicked(item)">
                            <td>{{item.username}} <em>(blocked)</em></td>
                            <td>{{item.userid}}</td>
                            <td>{{item.priv_group}}</td>
                            <td>{{item.title}}</td>
                        </tr>
                        <tr v-else-if="item.disabled" :class="selectedRow.indexOf(item.userid)>-1?'selected-disabled-blocked-row':'disabled-blocked-row'" @click="rowClicked(item)">
                            <td>{{item.username}} <em>(disabled)</em></td>
                            <td>{{item.userid}}</td>
                            <td>{{item.priv_group}}</td>
                            <td>{{item.title}}</td>
                        </tr>
                        <tr v-else :class="selectedRow.indexOf(item.userid)>-1?'selected-row':'normal-row'" @click="rowClicked(item)">
                            <td>{{item.username}}</td>
                            <td>{{item.userid}}</td>
                            <td>{{item.priv_group}}</td>
                            <td>{{item.title}}</td>
                        </tr>
                    </template>

                </v-data-table>
                </div>
                <v-alert :value="true" v-if="proxyErrorMessage != ''" type="error">
                    {{ proxyErrorMessage }}
                </v-alert>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
                <v-spacer></v-spacer>
                <v-btn
                    id="cancelBtn"
                    color="primary"
                    small
                    @click="dialog = false"
                    >Cancel</v-btn>
                <v-btn
                    id="proxyBtn"
                    color="primary"
                    small
                    @click="changeUser"
                    :loading="proxyLoading"
                    :disabled="this.selectedRow.length < 1"
                    >Proxy</v-btn>
            </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { throws } from "assert";
import {mapGetters, mapActions} from "vuex";

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        }
    },
    data () {
        return {
            dialogm1: '',
            search: '',
            dialog: this.isOpen,
            timeout: null,
            page: 1,
            pageCount: 0,
            headers: [
                { text: 'Username', value: 'username', width: "40%" },
                { text: 'User ID', value: 'userid', width: "12%" },
                { text: 'Privilege Group', value: 'priv_group', width: "24%" },
                { text: 'Title', value: 'title', width: "24%" },
            ],
            selectedRow: [],
            users: [],
            searchLoading: false,
            proxyLoading: false,
            itemsPerPageList: [5],
        }
    },
    computed: {
        ...mapGetters("users", [
            "proxyUserList",
            "userData",
            "proxyLookupFinished",
            "proxyErrorMessage",
        ]),
    },
    methods: {
        ...mapActions(
          'users', ['retrieveProxyableUsers',
                                'proxyAsUser',
                                'setProxyLookupFinished',
                                'resetUserData',
                                'retrieveUserData',
                                'setProxyErrorMessage'],
        ),
        ...mapActions('resources', ['retrieveAccountHorizon']),
        changeUser() {
            // need to change user but remember who this is
            // if a user is selected
            if(this.selectedRow.length == 1) {
                // close the dialog box and load the users data
                // call this to change bearer token
                let send_id = this.userData.csrep_userid == null ? this.userData.id : this.userData.csrep_userid
                this.proxyAsUser({userid: this.selectedRow[0], csrep_userid: send_id})
                this.proxyLoading = true
            }
        },
        searchForProxyUsers() {
            if(this.search.length < 4) {
                this.stopSpinny()
                this.users = []
                this.selectedRow = []
            } else {
                this.selectedRow = []
                let send_id = this.userData.csrep_userid == null ? this.userData.id : this.userData.csrep_userid
                this.retrieveProxyableUsers({lookup: this.search, csrep_userid: send_id});
            }
            this.setProxyErrorMessage('')
        },
        rowClicked(row) {
            this.toggleSelection(row.userid);
        },
        toggleSelection(userid) {
            if (this.selectedRow.includes(userid)) {
                this.selectedRow = this.selectedRow.filter(
                    selectedUserid => selectedUserid !== userid
                );
            } else {
                this.selectedRow.push(userid);
            }

            if(this.selectedRow.length > 1) {
                this.selectedRow.shift();
            }
        },
        setProxyUserList() {
            this.users = this.proxyUserList
            this.getItemsPerPage()
            this.searchLoading = false;
        },
        getProxyUserList() {
            return this.users;
        },
        getItemsPerPage() {
            let itemsPerPage = [5]
            if(this.users.length > 5) {
                itemsPerPage.push(10)
            }
            if(this.users.length > 10) {
                itemsPerPage.push(15)
            }
            this.itemsPerPageList = itemsPerPage
        },
        getCsiLink() {
            return process.env.VUE_APP_CSI + "index.php?userid=" + this.userData.id + "&app_state=4";
        },
        closePage() {
            if(this.proxyLookupFinished) {
                this.$router.push('/insights').catch(() => {})
                this.setProxyLookupFinished(false)
                this.proxyLoading = false
                this.dialog = false
            }
        },
        stopSpinny() {
            this.searchLoading = false
            this.proxyLoading = false
        },
    },
    watch: {
        proxyUserList: 'setProxyUserList',
        proxyLookupFinished: 'closePage',
        proxyErrorMessage: 'stopSpinny',
        search: function (val) {
        // wait a half second to reduce the number of requests
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.searchLoading = true;
                this.searchForProxyUsers();
            }, 500);
        },
    }
}

</script>

<style>

    .app-title {
      position: relative;
      border-left: 2px solid rgba(255,255,255,.5);
      top: -.3rem;
    }

    /* suppress anchor tag underlines */
    .v-list-item__action > a,
    .v-list-item__content > a {
        text-decoration: none;
    }

    /* footer links */
    .v-footer {
        background-color: #083a81 !important;
    }

    .v-footer span {
        color: rgba(255, 255, 255, .5);
    }

    .footer-link {
        text-decoration: none;
        color: rgba(255, 255, 255, .5);
    }

    .footer-link:hover {
        text-decoration: underline;
        color: rgba(255, 255, 255, 1);
    }

    /* style guide - corporate background color */
    .cf-background {
        background-color: #083a81 !important;
    }

  #app-loading-message-area {
    font-size: .8em;
  }

  .dim-item {
    opacity: .25;
  }

  .disabled-blocked-row {
    background-color: #FFFFFF;
  }
  .disabled-blocked-row:hover {
    background-color: #eeeeee !important;
    cursor: pointer;
  }
  .disabled-blocked-row em {
    color: #ff4535 !important;
    background-color: #FFFFFF;
  }
  .disabled-blocked-row:hover em {
    color: #ff4535 !important;
    background-color: #eeeeee;
    cursor: pointer;
  }

  .selected-disabled-blocked-row {
    background-color: #BBDEFB !important;
  }
  .selected-disabled-blocked-row:hover {
    background-color: #90CAF9 !important;
    cursor: pointer;
  }
  .selected-disabled-blocked-row em{
    color: #ff4535 !important;
    background-color: #BBDEFB !important;
  }
  .selected-disabled-blocked-row:hover em{
    color: #ff4535 !important;
    background-color: #90CAF9 !important;
    cursor: pointer;
  }


  .selected-row {
    background-color: #BBDEFB !important;
  }

  .selected-row:hover {
    background-color: #90CAF9 !important;
    cursor: pointer;
  }
  .normal-row {
    background-color: #FFFFFF;
  }
  .normal-row:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }

  .data-table-toolbar {
    background-color: #1976d2 !important;
  }

  .data-table-toolbar h2 {
    color: #FFFFFF !important;
  }

</style>
