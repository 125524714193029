<template>
  <v-dialog v-model="showTeamEditModal" persistent width="50vw">
    <v-card class="pa-md-4 mx-lg-auto">
      <v-card-title class="pt-0 mt-0">
        <h1 class="agent-title">Edit Teams</h1>
        <v-spacer></v-spacer>
        <v-btn x-small text icon color="primary" @click="hideModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="teamModalCard">
        <v-alert v-if="this.setTeamError" type="error" class="alert-message">
          {{ "There was an error while saving teams. Please try again later." }}
        </v-alert>
        <v-combobox
          v-model="selectedTeams"
          :items="allTeams"
          item-value="id"
          item-text="team_name"
          chips
          label="Select from list or type a new team name and hit Enter"
          ref="combobox"
          class="small-combobox"
          multiple
          return-object
          hint="Maximum of 30 teams are allowed per profile"
          persistent-hint
          :menu-props="{ maxHeight: '200' }"
          :search-input.sync="teamSearch"
          @change="handleComboChange"
        >
        </v-combobox>
      </v-card-text>
      <div class="modal-footer">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" :disabled="saving" @click="saveTeams"
            >Save</v-btn
          >
          <v-btn small color="primary" :disabled="saving" @click="hideModal"
            >Cancel</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
    <confirm ref="confirm"></confirm>
  </v-dialog>
</template>

<script>
import Confirm from "@/components/Widgets/Confirm.vue";
import SetupTip from "@/components/SetupTip.vue";
import { mapActions, mapGetters } from "vuex";

// 128 is the max length of the team name stored in the db...
const MAX_TEAM_NAME_LENGTH = 128;
const MAX_TEAM_COUNT = 30;

export default {
  name: "TeamEditModal",
  props: {
    showTeamEditModal: {
      type: Boolean,
      default: false
    },
    profileId: {
      type: Number,
      default: null
    },
    profileType: {
      type: Number,
      default: null
    },
    profileTeams: {
      type: Array,
      default: []
    },
    teams: {
      type: Array,
      default: []
    }
  },
  components: {
    Confirm,
    SetupTip
  },
  data() {
    return {
      allTeams: [],
      selectedTeams: [],
      setTeamError: false,
      teamSearch: "",
      saving: false
    };
  },
  methods: {
    ...mapActions("resources", ["saveTeamData", "saveTeam"]),
    handleComboChange() {
      this.teamSearch = "";
    },
    loadTeams() {
      this.allTeams = this.allTeamsSorted;
      this.selectedTeams = this.profileTeamsSorted;
    },
    hideModal() {
      this.hideErrors();
      this.$emit("HIDE_TEAM_EDIT_MODAL", { reload: false });
    },
    hideErrors() {
      this.setTeamError = false;
    },
    async delaySavePromise() {
      await new Promise(resolve => setTimeout(resolve, 10));
    },
    async saveTeams() {
      this.saving = true;
      // this call fixes an issue if you click directly on save without hitting the "enter" key
      await this.delaySavePromise();

      this.hideErrors();

      let newTeamParams = [
        {
          profile_id: this.profileId,
          profile_type: this.profileType
        }
      ];
      let newTeamNames = [];
      let selectedTeamIds = [];
      // compile new/old teams
      this.selectedTeams.forEach(team => {
        if (team.length >= MAX_TEAM_NAME_LENGTH) {
          team = team.substring(0, MAX_TEAM_NAME_LENGTH);
        }
        if (typeof team === "string") {
          newTeamNames.push(team);
        } else {
          selectedTeamIds.push(team.id);
        }
      });

      // save the existing teams first
      let params = {
        profile_id: this.profileId,
        profile_type: this.profileType,
        team_ids: selectedTeamIds
      };
      let success = await this.saveTeamData(params);
      if (!success) {
        this.setTeamError = true;
      } else {
        // add any new teams
        for await (const team of newTeamNames) {
          let success = await this.saveTeam({
            team_name: team,
            profile_json: JSON.stringify(newTeamParams)
          });
          if (!success) {
            this.setTeamError = true;
          }
        }
      }

      if (!this.setTeamError) {
        this.saving = false;
        this.$emit("HIDE_TEAM_EDIT_MODAL", { reload: true });
      }
    },
    getAndCleanNewTeams() {
      let newTeams = [];
      let newTeamsUnique = [];

      this.selectedTeams.forEach((team, index) => {
        if (typeof team === "string") {
          if (team.trim() == "") {
            this.selectedTeams.splice(index, 1);
          }

          // dont push duplicate strings into array...
          let teamTrimmed = team.trim();
          if (!newTeamsUnique.includes(teamTrimmed)) {
            newTeamsUnique.push(teamTrimmed);
          } else {
            //remove duplicate string team in v-model...
            this.selectedTeams.splice(index, 1);
          }
          newTeams.push(teamTrimmed);
        }
      });

      return newTeams;
    },
    handleSelectedTeams() {
      // this function cleans newly selected teams/ compares and handles existing teams

      // get and clean new neams added
      let newStringTeams = this.getAndCleanNewTeams();

      // iterate the selected teams and if the team is not a string,
      // check to see if the newly selected teams contain the string and remove that string from the current list of selected teams
      this.selectedTeams.forEach((team, index) => {
        if (typeof team !== "string") {
          let teamNameTrimmed = team.team_name.trim();
          // does the array of new teams include the current teams team name?
          if (newStringTeams.includes(teamNameTrimmed)) {
            // if so, remove the newly added string team from the current list of selected names (no dupes)
            this.removeNewStringName(teamNameTrimmed);
          }
        } else {
          // if the all team array contains the new team, the new team should be set to the all team array
          this.removeExistingStringName(team.trim(), index);
        }
      });
    },
    removeNewStringName(teamNameTrimmed) {
      // remove newly added string name
      const indexOfObject = this.selectedTeams.findIndex(stringTeamName => {
        if (typeof stringTeamName === "string") {
          return stringTeamName == teamNameTrimmed;
        }
      });

      if (indexOfObject != -1) this.selectedTeams.splice(indexOfObject, 1);
    },
    removeExistingStringName(team, index) {
      // remove existing string name
      if (this.allTeams.some(teams => teams.team_name === team)) {
        const existingObj = this.allTeams.find(allTeamObj => {
          return allTeamObj.team_name == team.trim();
        });
        this.selectedTeams.splice(index, 1, existingObj);
      }
    }
  },
  computed: {
    ...mapGetters("resources", ["teamData", "teamDataLoadingStatus"]),
    allTeamsSorted() {
      return this.teams
        ? [...this.teams].sort((a, b) => a.team_name.localeCompare(b.team_name))
        : null;
    },
    profileTeamsSorted() {
      return this.profileTeams
        ? [...this.profileTeams].sort((a, b) =>
            a.team_name.localeCompare(b.team_name)
          )
        : null;
    }
  },
  watch: {
    showTeamEditModal: "loadTeams",
    selectedTeams: function(teamVal) {
      if (teamVal) {
        if (teamVal.length > MAX_TEAM_COUNT) {
          this.selectedTeams.pop();
        }
        this.handleSelectedTeams(teamVal);
      }
    }
  },
  mounted() {
    this.loadTeams();
  }
};
</script>
