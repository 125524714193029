<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row>
        <v-col
          style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center"
        >
          <Filters
            feature="scorecards-v2"
            @adjustments-filter-updated-reload="filterUpdated"
          ></Filters>
          <scorecard-processing-status
            :type="'dynalabel'"
          ></scorecard-processing-status>
          <loading-status-box
            v-if="
              aggregatedScorecardDynalabelDataLoadingStatus != 'loaded' ||
                groupedScorecardDynalabelDataLoadingStatus != 'loaded' ||
                presentationScorecardsDataLoadingStatus != 'loaded'
            "
            :message="dynalabelMessageType"
            :type="dynalabelLoadingType"
          ></loading-status-box>
          <v-container
            v-else
            v-show="$route.name == 'scorecards-v2-dynalabels'"
            pl-5
            pr-5
            fluid
            style="height: 100vh; max-height: calc(100%);"
          >
          <v-row>
            <v-col class="pl-8">
              <scorecard-breadcrumbs :linkEnabled="true" :scorecardHeader="aggregatedScorecardDynalabelData.scorecardTitle" :level="'dynalabel'" @go-to-page="goBack"></scorecard-breadcrumbs>
            </v-col>

            <v-col class="text-right pr-10">
              <export-menu
                @exportClick="exportClick"
              ></export-menu>
              <scorecard-download
                ref="ScorecardDownload"
                v-if="
                  aggregatedScorecardDynalabelDataLoadingStatus == 'loaded' &&
                  groupedScorecardDynalabelDataLoadingStatus == 'loaded' &&
                  presentationScorecardsDataLoadingStatus == 'loaded'
                "
                class="float-right"
                :scorecardId="Number(this.$route.params.scorecardId)"
                :scorecardUrl="scorecardDynalabelUrl"
                :scorecardTitle="aggregatedScorecardDynalabelData.scorecardTitle + ' Grouping Dynamic Label'"
                :dateRange="getDateRangeString()"
              ></scorecard-download>
              <v-btn fab small dark class="ml-2" color="primary" href="/faq/0" target="_blank" rel="noopener" title="Click for Scorecard Help and FAQs">
                <v-icon dark>mdi-help-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

            <v-data-table
              :headers="aggregatedHeaders"
              :items="aggregatedRowData"
              :hide-default-footer="true"
            >

              <template v-for="header in aggregatedHeaders" v-slot:[`header.${header.value}`]="{ header }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-if="header.text != getFormattedTitleName(header.text)" v-on="on">{{getFormattedTitleName(header.text)}}&hellip;</span>
                    <span v-else v-on="on">{{header.text}}</span>
                  </template>
                  <span>{{header.text}}</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-alert
                  :value="true"
                  color="warning"
                  icon="mdi-message-alert-outline"
                  class="ma-2"
                  dark
                >
                  {{ noDataMessage }}
                </v-alert>
              </template>
              <template v-slot:item.name="{ item }">
                <v-btn
                  @click="loadDynaLabelTeam()"
                  :disabled="dynalabelDisabled(item)"
                  text
                  small
                  color="primary"
                  class="agent-btn"
                  >{{ item.name }}
                </v-btn>
              </template>

              <template v-slot:item.average_handle_time="{ item }">
                <span v-if="item.call_count > 0">{{
                  item.average_handle_time
                }}</span>
              </template>

              <template v-slot:item.average_call_volume="{ item }">
                <span v-if="item.call_count > 0">{{
                  item.average_call_volume
                }}</span>
              </template>

              <template v-slot:item.call_count="{ item }">
                <span v-if="item.call_count > 0">{{ item.call_count }}</span>
              </template>

              <template v-slot:item.average_call_score="{ item }">
                <score-chip
                  v-if="item.call_count > 0"
                  :score="item.average_call_score"
                  :calls_modified="item.calls_modified"
                  :aggregate_cat="false"
                ></score-chip>
              </template>

              <template
                v-for="(slot, i) in columnSlots"
                v-slot:[`item.${slot.id}`]="{ item }"
              >
                <score-chip
                  v-if="item.call_count > 0"
                  :key="i"
                  :score="item[slot.id]"
                  :calls_modified="item[slot.id + '_calls_modified']"
                  :aggregate_cat="true"
                  :nonAppCatHandling="nonAppCatHandling"
                  :num_not_applicable="item[slot.id + '_num_not_applicable']"
                  :total_calls="item[slot.id + '_total_calls']"
                ></score-chip>
              </template>

              <template v-slot:footer> </template>
            </v-data-table>

            <br />
            <h2>Dynamic Labels</h2>
            <br />
            <v-data-table
              :headers="groupedHeaders"
              :items="groupedRowData"
              :custom-sort="customSort"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100]
              }"
            >
              <template v-for="header in groupedHeaders" v-slot:[`header.${header.value}`]="{ header }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-if="header.text != getFormattedTitleName(header.text)" v-on="on">{{getFormattedTitleName(header.text)}}&hellip;</span>
                    <span v-else v-on="on">{{header.text}}</span>
                  </template>
                  <span>{{header.text}}</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-alert
                  :value="true"
                  color="warning"
                  icon="mdi-message-alert-outline"
                  class="ma-2"
                  dark
                >
                  {{ noDataMessage }}
                </v-alert>
              </template>
              <template v-slot:item.name="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="dynalabelDisabled(item)"
                      @click="loadDynaLabelAgents(item.id)"
                      text
                      small
                      color="primary"
                      class="agent-btn"
                      >{{ item.name }}
                    </v-btn>
                  </template>
                  <span
                    >{{ item.name }} ({{ item.id }}) dynamic label&hellip;</span
                  >
                </v-tooltip>
              </template>

              <template v-slot:item.average_handle_time="{ item }">
                <span v-if="item.call_count > 0">{{
                  item.average_handle_time
                }}</span>
              </template>

              <template v-slot:item.average_call_volume="{ item }">
                <span v-if="item.call_count > 0">{{
                  item.average_call_volume
                }}</span>
              </template>

              <template v-slot:item.call_count="{ item }">
                <span v-if="item.call_count > 0">{{ item.call_count }}</span>
              </template>

              <template v-slot:item.average_call_score="{ item }">
                <score-chip
                  v-if="item.call_count > 0"
                  :score="item.average_call_score"
                  :calls_modified="item.calls_modified"
                  :aggregate_cat="false"
                ></score-chip>
              </template>
              <template
                v-for="(slot, i) in groupedColumnSlots"
                v-slot:[`item.${slot.id}`]="{ item }"
              >
                <score-chip
                  v-if="item.call_count > 0"
                  :key="i"
                  :score="item[slot.id]"
                  :calls_modified="item[slot.id + '_calls_modified']"
                  :aggregate_cat="true"
                  :nonAppCatHandling="nonAppCatHandling"
                  :num_not_applicable="item[slot.id + '_num_not_applicable']"
                  :total_calls="item[slot.id + '_total_calls']"
                ></score-chip>
              </template>

              <template v-slot:footer> </template>
            </v-data-table>
            <div class="pb-12">
              <h5 class="footnote">
                <sup class="footnote">*</sup> A call can belong to more than one
                dynamic label. The sum of the dynamic labels may add up to more
                than the "All Dynamic Labels" total.
              </h5>
            </div>
          </v-container>
        </v-col>
      </v-row>
      <ScheduledExportsModal
        :showScheduledExportsModal="showScheduledExportsModal"
        @HIDE_SCHEDULED_EXPORTS_MODAL="hideScheduledExportsModal"
        :isNew="true"
        :insightOrScorecard="'scorecards'"
        :pdfDelivery="false"
        :exportPath="1"
      ></ScheduledExportsModal>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Filters from "@/components/Filters.vue";
import moment from "moment";
import ScoreChip from "@/components/Scorecards/ScoreChip.vue";
import InsightsLoadingStatus from "../../components/Insights/InsightsLoadingStatus.vue";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import PermissionCheck from "./PermissionCheck.vue";
import presentation from "@/mixins/presentation";
import ScorecardProcessingStatus from "../../components/Scorecards/ScorecardProcessingStatus.vue";
import ExportMenu from "@/components/Widgets/ExportMenu.vue";
import ScorecardDownload from "@/components/Controls/ScorecardDownload.vue";
import ScorecardBreadcrumbs from '../../components/Breadcrumbs/ScorecardBreadcrumbs.vue';
import selectPage from "@/mixins/selectPage";
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";

const CATEGORY_REQUEST_COUNT = 3;
const CATEGORY_LOAD_LIMIT = 3;

export default {
  mixins: [presentation, selectPage],
  components: {
    Filters,
    ScoreChip,
    InsightsLoadingStatus,
    LoadingStatusBox,
    PermissionCheck,
    ScorecardProcessingStatus,
    ExportMenu,
    ScorecardDownload,
    ScorecardBreadcrumbs,
    ScheduledExportsModal,
  },
  props: {},
  data: () => ({
    aggregatedDynalabelCatCount: 0,
    groupedDynalabelCatCount: 0,
    noDataMessage: process.env.VUE_APP_NO_CALLS_FOUND,
    showScheduledExportsModal: false,
  }),
  computed: {
    ...mapGetters("notifications", ["notificationsList", "transcriptFileInfo"]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "standardUser",
    ]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("scorecardsV2Aggregates", [
      "aggregatedScorecardDynalabelData",
      "aggregatedScorecardDynalabelDataLoadingStatus",
      "aggregatedScorecardDynalabelDataLoadingError",
      "aggregatedScorecardDynalabelDataLoadingErrorStatus",

      "groupedScorecardDynalabelData",
      "groupedScorecardDynalabelDataLoadingStatus",
      "groupedScorecardDynalabelDataLoadingError",
      "groupedScorecardDynalabelDataLoadingErrorStatus",

      "groupedDynalabelCategoryLoadCount",
      "aggregatedDynalabelLoadCount",

      "displayFormats"
    ]),
    ...mapGetters("presentation", [
      "presentationScorecardsData",
      "presentationScorecardsDataLoadingStatus",
      "presentationScorecardsDataLoadingError",
      "presentationScorecardsDataLoadingErrorStatus"
    ]),
    dynalabelLoadingType() {
      if (
        this.aggregatedScorecardDynalabelDataLoadingErrorStatus ||
        this.groupedScorecardDynalabelDataLoadingErrorStatus ||
        this.presentationScorecardsDataLoadingErrorStatus
      )
        return "error";
      else return "loading";
    },
    dynalabelMessageType() {
      if (
        this.aggregatedScorecardDynalabelDataLoadingErrorStatus ||
        this.groupedScorecardDynalabelDataLoadingErrorStatus ||
        this.presentationScorecardsDataLoadingErrorStatus
      )
        return "There was an error loading dynamic label scorecard data...";
      else return "Dynamic label scorecard data loading, please wait...";
    },
    aggregatedHeaders() {
      var thisThis = this;
      let headers = [
        {
          text: "Dynamic Label",
          value: "name",
          align: "left",
          sortable: true,
          width: "1%"
        },
        {
          text: this.durationValHeaderText,
          value: "average_handle_time",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Calls/ Day",
          value: "average_call_volume",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Total Calls",
          value: "call_count",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Score",
          value: "average_call_score",
          align: "center",
          sortable: true,
          width: "1%"
        }
      ];
      if (this.aggregatedScorecardDynalabelData.hasOwnProperty("categories")) {
        try {
          this.aggregatedScorecardDynalabelData.categories.forEach(function(
            category
          ) {
            let nameFixed = thisThis.decodeHtml(category.title);
            headers.push({
              text: nameFixed,
              value: category.id,
              align: "center",
              sortable: true,
              width: "1%"
            });
          });
        } catch {
          // NoOp
        }
      }
      return headers;
    },
    groupedHeaders() {
      var thisThis = this;
      let headers = [
        {
          text: "Dynamic Label",
          value: "name",
          sortable: true,
          width: "1%"
        },
        {
          text: this.durationValHeaderText,
          value: "average_handle_time",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Calls/ Day",
          value: "average_call_volume",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Total Calls",
          value: "call_count",
          align: "center",
          sortable: true,
          width: "1%"
        },
        {
          text: "Score",
          value: "average_call_score",
          align: "center",
          sortable: true,
          width: "1%"
        }
      ];
      if (this.aggregatedScorecardDynalabelData.hasOwnProperty("categories")) {
        try {
          this.aggregatedScorecardDynalabelData.categories.forEach(function(
            category
          ) {
            let nameFixed = thisThis.decodeHtml(category.title);
            headers.push({
              text: nameFixed,
              value: category.id,
              align: "center",
              sortable: true,
              width: "1%"
            });
          });
        } catch {
          // NoOp
        }
      }
      return headers;
    },
    groupedColumnSlots() {
      let slots = [];
      if (this.groupedScorecardDynalabelData.hasOwnProperty("items")) {
        this.groupedScorecardDynalabelData.items.forEach(function(item) {
          item.metrics.categories.forEach(function(category) {
            slots.push({
              id: category.id,
              calls_modified: category.calls_modified,
              num_not_applicable: category.num_not_applicable,
              total_calls: category.total_calls
            });
          });
        });
      }

      return slots;
    },
    columnSlots() {
      let slots = [];
      //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
      if (
        this.aggregatedScorecardDynalabelData.hasOwnProperty("items") &&
        this.aggregatedScorecardDynalabelData.items.length > 0
      ) {
        if (
          this.aggregatedScorecardDynalabelData.items[0].metrics.hasOwnProperty(
            "categories"
          )
        ) {
          try {
            this.aggregatedScorecardDynalabelData.items[0].metrics.categories.forEach(
              function(category) {
                slots.push({
                  id: category.id,
                  calls_modified: category.calls_modified,
                  num_not_applicable: category.num_not_applicable,
                  total_calls: category.total_calls
                });
              }
            );
          } catch {
            // NoOp
          }
        }
      }
      return slots;
    },
    nonAppCatHandling() {
      return this.aggregatedScorecardDynalabelData.hasOwnProperty(
        "nonAppCatHandling"
      ) && this.aggregatedScorecardDynalabelData.nonAppCatHandling
        ? this.aggregatedScorecardDynalabelData.nonAppCatHandling
        : 0;
    },
    aggregatedRowData() {
      if (
        this.aggregatedScorecardDynalabelDataLoadingStatus != "loaded" ||
        !this.aggregatedScorecardDynalabelData.hasOwnProperty("items") ||
        !this.aggregateItemDisplay(this.aggregatedScorecardDynalabelData.items)
      ) {
        return [];
      } else {
        let row = {};
        if (
          this.aggregatedScorecardDynalabelData.hasOwnProperty("categories")
        ) {
          row = this.scorecardsPresentationFormattedData(
            this.aggregatedScorecardDynalabelData.items[0],
            this.aggregatedScorecardDynalabelData.categories,
            false,
            false
          );
        } else {
          row = this.scorecardsPresentationFormattedData(
            this.aggregatedScorecardDynalabelData.items[0],
            [],
            false,
            false
          );
        }

        return [row];
      }
    },
    groupedRowData() {
      let rtnList = [...this.groupedScorecardDynalabelData.items].sort((a, b) => new Intl.Collator('en').compare(a.dynalabelTitle, b.dynalabelTitle));

      return rtnList.map(obj => {
        let row = {};
        if (obj.metrics.hasOwnProperty("categories")) {
          row = this.scorecardsPresentationFormattedData(
            obj,
            obj.metrics.categories,
            true,
            false
          );
        } else {
          row = this.scorecardsPresentationFormattedData(obj, [], true, false);
        }
        return row;
      });
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    durationValHeaderText() {
      return this.aggregatedScorecardDynalabelData.items[0].metrics
        .timeDisplay == "1"
        ? "Handle Time"
        : "Talk Time";
    },

    scorecardDynalabelUrl() {
      // make the scorecards URL
      let exportUrl = new URL(process.env.VUE_APP_SCORECARDS_INTERNAL + this.$route.params.scorecardId + '/dynalabels/')
      exportUrl.searchParams.append('scorecards_editor',false)
      exportUrl.searchParams.append('start_date',moment(this.start_date).format("YYYY-MM-DD"))
      exportUrl.searchParams.append('end_date',moment(this.end_date).format("YYYY-MM-DD"))
      exportUrl.searchParams.append('call_type',this.$store.getters["filters/scorecardAdjustments"] || "all")
      exportUrl.searchParams.append('loadall','categories')
      return exportUrl.toString()
    }
  },
  methods: {
    ...mapActions({
      retrieveScorecardsAggregatedDynaLabelData:
        "scorecardsV2Aggregates/retrieveScorecardsAggregatedDynaLabelData",
      retrieveScorecardsGroupedDynaLabelData:
        "scorecardsV2Aggregates/retrieveScorecardsGroupedDynaLabelData",
      retrieveAggregatedDynalabelCategories:
        "scorecardsV2Aggregates/retrieveAggregatedDynalabelCategories",
      retrieveGroupedDynalabelCategories:
        "scorecardsV2Aggregates/retrieveGroupedDynalabelCategories",
      setGroupedDynalabelLoadCounter:
        "scorecardsV2Aggregates/setGroupedDynalabelLoadCounter",
      setAggregatedDynalabelLoadCounter:
        "scorecardsV2Aggregates/setAggregatedDynalabelLoadCounter",
      retrievePresentationScorecardsData:
        "presentation/retrievePresentationScorecardsData",
      setGroupedDynalabelLoadingStatus:
        "scorecardsV2Aggregates/setGroupedDynalabelLoadingStatus",
      setAggregateDynalabelLoadingStatus:
        "scorecardsV2Aggregates/setAggregateDynalabelLoadingStatus"
    }),
    hideScheduledExportsModal(hideScheduledExportsModal) {
      this.showScheduledExportsModal = hideScheduledExportsModal;
    },
    getFormattedTitleName(name) {
      // if the name is greater than 24 in length, add the words together until the name is greater than 20
      if(name.length <= 20) {
        return name
      }
      let nameArray = name.split(' ')
      let rtn_name = ""
      nameArray.forEach(element => {
        if(rtn_name.length < 20) {
          rtn_name += element
        }
        if(rtn_name.length < 19) {
          rtn_name += " "
        }
      })
      return rtn_name
    },
    goBack() {
      this.selectScorecardPage('dashboard', false);
    },
    aggregateItemDisplay(items) {
      if (
        items.length < 1 ||
        (items[0].metrics.totalCalls < 1 &&
          items[0].metrics.excludedCallCount < 1)
      ) {
        return false;
      }
      return true;
    },
    dynalabelDisabled(item) {
      if (item.call_count < 1 && item.excluded_calls < 1) {
        return true;
      }
      return false;
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    loadPresentationData() {
      if (this.$route.name == "scorecards-v2-dynalabels") {
        this.setAggregateDynalabelLoadingStatus("loading");
        this.setGroupedDynalabelLoadingStatus("loading");
        this.retrievePresentationScorecardsData({
          scorecard_id: this.$route.params.scorecardId,
          aggregate_type: "dynalabel"
        });
      }
    },
    filterUpdated() {
      this.resetData();
      this.loadPresentationData();
    },
    sortItems(items, xIndex, xIsDesc) {
      items.sort((a, b) => {
        if (xIndex === "name") {
          if (!xIsDesc) {
            return new Intl.Collator('en').compare(a.name, b.name)
          } else {
            return new Intl.Collator('en').compare(b.name, a.name)
          }
        } else if (xIndex == "average_handle_time") {
          if (!xIsDesc) {
            return Number(a["average_duration"]) < Number(b["average_duration"])
              ? -1
              : 1;
          } else {
            return Number(b["average_duration"]) < Number(a["average_duration"])
              ? -1
              : 1;
          }
        } else {
          if (!xIsDesc) {
            return a[xIndex] < b[xIndex] ? -1 : 1;
          } else {
            return b[xIndex] < a[xIndex] ? -1 : 1;
          }
        }
      });
      return items;
    },
    customSort(items, index, isDesc) {
      let xIndex = Array.isArray(index) ? index[0] : index;
      let xIsDesc = Array.isArray(isDesc) ? isDesc[0] : isDesc;
      return xIndex ? this.sortItems(items, xIndex, xIsDesc) : items;
    },
    loadDynaLabelTeam() {
      let scorecard_id = this.$route.params.scorecardId;
      this.$router
        .push({
          path: "/scorecards-v2/" + scorecard_id + "/dynalabels/0/agents/",
          query: this.$route.query
        })
        .catch(err => {});
      setTimeout(function() {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    },
    loadDynaLabelAgents(dynalabel_id) {
      let scorecard_id = this.$route.params.scorecardId;
      this.$router
        .push({
          path:
            "/scorecards-v2/" +
            scorecard_id +
            "/dynalabels/" +
            dynalabel_id +
            "/agents/",
          query: this.$route.query
        })
        .catch(err => {});
      setTimeout(function() {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    loadCategories(params) {
      let parameters = {
        scorecard_id: this.$route.params.scorecardId,
        grouping: this.getUnit,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        categories: params.categories,
        group_by_item: params.group_by_item,
        call_type: this.$store.getters["filters/scorecardAdjustments"] || "all"
      };
      if (params.group_by_item) {
        this.retrieveGroupedDynalabelCategories(parameters);
      } else {
        this.retrieveAggregatedDynalabelCategories(parameters);
      }
    },
    createAggregatedCategoryRequest() {
      let localList = this.aggregatedScorecardDynalabelData.categories;
      for (let x = 0; x < CATEGORY_REQUEST_COUNT; x++) {
        let categories = [];
        for (
          let i = this.aggregatedDynalabelCatCount;
          i < this.aggregatedDynalabelCatCount + CATEGORY_LOAD_LIMIT;
          i++
        ) {
          if (localList[i] != undefined) {
            //this will send one request at a time - could change it to more?
            categories.push(localList[i].id);
          }
        }
        let params = {
          categories: categories,
          group_by_item: false
        };
        this.loadCategories(params);
        this.aggregatedDynalabelCatCount += CATEGORY_LOAD_LIMIT;
      }
    },
    createGroupedCategoryRequest() {
      let localList = this.groupedScorecardDynalabelData.categories;
      for (let x = 0; x < CATEGORY_REQUEST_COUNT; x++) {
        let categories = [];
        for (
          let i = this.groupedDynalabelCatCount;
          i < this.groupedDynalabelCatCount + CATEGORY_LOAD_LIMIT;
          i++
        ) {
          if (localList[i] != undefined) {
            //this will send one request at a time - could change it to more?
            categories.push(localList[i].id);
          }
        }
        let params = {
          categories: categories,
          group_by_item: true
        };
        this.loadCategories(params);
        this.groupedDynalabelCatCount += CATEGORY_LOAD_LIMIT;
      }
    },
    aggregatedCategoryRetrieval() {
      let loadedCount = this.aggregatedScorecardDynalabelData.categories.filter(
        value => !value.loading
      ).length;

      if (
        loadedCount >= this.aggregatedDynalabelCatCount &&
        this.aggregatedScorecardDynalabelDataLoadingStatus == "loaded" &&
        this.aggregatedScorecardDynalabelData.categories.length > 0 &&
        this.aggregatedScorecardDynalabelData.categories.some(
          element => element.loading
        )
      ) {
        this.createAggregatedCategoryRequest();
      }
    },
    groupedCategoryRetrieval() {
      let loadedCount = this.groupedScorecardDynalabelData.categories.filter(
        value => !value.loading
      ).length;

      if (
        loadedCount >= this.groupedDynalabelCatCount &&
        this.groupedScorecardDynalabelDataLoadingStatus == "loaded" &&
        this.groupedScorecardDynalabelData.categories.length > 0 &&
        this.groupedScorecardDynalabelData.categories.some(
          element => element.loading
        )
      ) {
        this.createGroupedCategoryRequest();
      }
    },
    retrieveData() {
      if (this.$route.name == "scorecards-v2-dynalabels") {
        let payload = {
          scorecard_id: this.$route.params.scorecardId,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          grouping: this.getUnit,
          call_type:
            this.$store.getters["filters/scorecardAdjustments"] || "all",
          presentation: this.presentationScorecardsData
        };
        this.retrieveScorecardsAggregatedDynaLabelData(payload);
        this.retrieveScorecardsGroupedDynaLabelData(payload);
      }
    },
    resetData() {
      this.aggregatedDynalabelCatCount = 0;
      this.groupedDynalabelCatCount = 0;
      this.setAggregatedDynalabelLoadCounter(0);
      this.setGroupedDynalabelLoadCounter(0);
    },
    exportClick(item) {
      switch (item) {
        case "downloadExport":
          // do immediate export stuff
          this.$refs.ScorecardDownload.exportScorecard();
          break;
        case "scheduleExport":
          // pop schedule export modal to do schedule stuff
          this.showScheduledExportsModal = true;
          break;
      }
    },
    getDateRangeString() {
      return (
        moment(this.start_date).format("M/D/YYYY") +
        " - " +
        moment(this.end_date).format("M/D/YYYY")
      );
    },
  },
  beforeDestroy() {
    // destroyed - clear variables....
    this.resetData();
  },
  mounted() {
    // confirm there is a fresh var count
    this.resetData();
    if (this.userDataLoadingStatus == "loaded") {
      this.loadPresentationData();
    }
    this.$root.$on("DATE_RANGE_UPDATED", () => {
      this.resetData();
      this.$router
        .push({
          query: Object.assign(
            {},
            this.$route.query,
            {
              start_date: String(
                moment(this.$store.getters["dateRange/start_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            },
            {
              end_date: String(
                moment(this.$store.getters["dateRange/end_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            }
          )
        })
        .catch(err => {
          this.err = err;
        });

      if (
        this.userDataLoadingStatus == "loaded" &&
        this.$route.name == "scorecards-v2-dynalabels"
      ) {
        this.loadPresentationData();
      }
    });
  },
  watch: {
    userDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.loadPresentationData();
      }
    },
    aggregatedScorecardDynalabelDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.aggregatedCategoryRetrieval();
      }
    },
    groupedScorecardDynalabelDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.groupedCategoryRetrieval();
      }
    },
    aggregatedDynalabelLoadCount: function(count) {
      if (count >= CATEGORY_LOAD_LIMIT) {
        this.setAggregatedDynalabelLoadCounter(0);
        this.aggregatedCategoryRetrieval();
      }
    },
    groupedDynalabelCategoryLoadCount: function(count) {
      if (count >= CATEGORY_LOAD_LIMIT) {
        this.setGroupedDynalabelLoadCounter(0);
        this.groupedCategoryRetrieval();
      }
    },
    presentationScorecardsDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.retrieveData();
      }
    }
  }
};
</script>

<style scoped>
::v-deep button.agent-btn span.v-btn__content {
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

</style>
