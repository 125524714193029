<template>
  <v-card :style="cardHeight">
    <!-- component header -->
    <unified-call-component-header
      :component="component"
      :callId="callId"
      :callData="callData"
      :expandIcon="expandIcon"
      :closedTaskExists="closedTaskExists"
      :selfSigned="selfSigned"
      :showTask="showTask"
      @shouldShowAudio="shouldShowAudio"
      @toggleCols="toggleCols"
    ></unified-call-component-header>

    <!-- component body -->
    <unified-call-component-body
      :cardHeight="cardHeight"
      :component="component"
      :callId="callId"
      :callData="callData"
      :playHeadPosition="playHeadPosition"
      :displayedRegions="displayedRegions"
      :pageQueryData="pageQueryData"
      :audioExpanded="audioExpanded"
      :selfSigned="selfSigned"
      :selectedTask="selectedTask"
      :showTask="showTask"
      @update-play-from-position="updatePlayFromPosition"
      @spliceDisplayedRegions="spliceDisplayedRegions"
      @setDisplayedRegions="setDisplayedRegions"
      @resetParent="resetUserInterface"
    ></unified-call-component-body>
  </v-card>
</template>

<script>
import UnifiedCallComponentHeader from "./UnifiedCallComponentHeader.vue";
import UnifiedCallComponentBody from "./UnifiedCallComponentBody.vue";

export default {
  components: {
    UnifiedCallComponentBody,
    UnifiedCallComponentHeader
  },
  data() {
    return {
      debounceTimeout: null,
      showAudio: true,
      cardHeight: 0,
      cardHeightStyle: ""
    };
  },
  props: {
    component: {
      required: true,
      type: String
    },
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    expandIcon: {
      required: false,
      type: String,
      default: "mdi-unfold-more-vertical"
    },
    audioExpanded: {
      required: true,
      type: Boolean
    },
    rootHeight: {
      required: true,
      type: Number
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    pageQueryData: {
      type: Object,
      required: false,
      default: null
    },
    displayedRegions: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    closedTaskExists: {
      required: false,
      default: false,
      type: Boolean
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedTask: {
      type: Object,
      required: false,
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {},
  methods: {
    resetUserInterface(params) {
      this.$emit("resetParent", params);
    },
    toggleCols() {
      this.$emit("toggleCols", this.component);
    },
    shouldShowAudio(showAudio) {
      this.showAudio = showAudio;
    },
    setHeights() {
      this.cardHeight = Math.max(this.rootHeight - 200, 50);
      this.cardHeightStyle = "height: " + this.cardHeight + "px !important; min-height:130px";
    },
    debouncedResize() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setHeights();
      }, 500);
    },
    updatePlayFromPosition(e) {
      this.$emit("update-play-from-position", e);
    },
    spliceDisplayedRegions(index) {
      this.$emit("spliceDisplayedRegions", index);
    },
    setDisplayedRegions(regions) {
      this.$emit("setDisplayedRegions", regions);
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  },
  mounted() {
    this.setHeights();
    window.addEventListener("resize", this.debouncedResize);
  },
  watch: {
    rootHeight: "setHeights"
  }
};
</script>
<style scoped></style>
