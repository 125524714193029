<template>
  <div>
    <v-card-title
      v-if="!noBodyHeader"
      id="my-title"
      class="pa-2"
      style="height: 5rem"
    >
      <!-- call transcript card header -->
      <call-transcript-body-header
        v-if="componentIsTranscript"
        :callId="callId"
        :selfSigned="selfSigned"
        @transcriptViewChange="updateTranscriptView"
        @summaryDataRetrieved="setSummaryData"
      ></call-transcript-body-header>
      <!-- call scorecard header -->
      <call-scorecard-header
        v-else-if="componentIsScorecard && !selfSigned"
        :callData="callData"
        :callId="callId"
        :typeOfExclusion="typeOfExclusion"
        :excludeCall="excludeCall"
        @clearAppliedChanges="clearLiveOverrides"
        @excludedTypeChange="changeExcludedType"
        @excludedCallChange="changeCallExcluded"
        @changeCallOverrides="applyScorecardChanges"
        @deleteAppliedOverrides="removeExistingScChanges"
      >
      </call-scorecard-header>

      <span v-else></span>
    </v-card-title>

    <v-card-text
      :style="scrollHeightStyle"
      :class="cardTextClass"
    >
      <!-- call transcript  -->
      <call-transcript-body
        v-if="componentIsTranscript"
        :callId="callId"
        :callData="callData"
        :playHeadPosition="playHeadPosition"
        :transcriptView="transcriptView"
        :summaryInfo="summaryInfo"
        :phraseChosen="phraseChosen"
        :showTask="showTask"
        :selfSigned="selfSigned"
        @update-play-from-position="updatePlayFromPosition"
        @triggerPhraseSelected="triggerPhraseSelected"
        @triggerPhraseCaught="triggerPhraseCaught"
      ></call-transcript-body>

      <!-- call scorecard -->
      <call-scorecard-body
        v-else-if="componentIsScorecard"
        :callData="callData"
        :callId="callId"
        :displayedRegions="displayedRegions"
        :typeOfExclusion="typeOfExclusion"
        :excludeCall="excludeCall"
        :clearAppliedChanges="clearAppliedChanges"
        :changesApplied="changesApplied"
        :changesRemoved="changesRemoved"
        :pageQueryData="pageQueryData"
        :selfSigned="selfSigned"
        :showTask="showTask"
        @spliceDisplayedRegions="spliceDisplayedRegions"
        @setDisplayedRegions="setDisplayedRegions"
        @clearAppliedChanges="clearLiveOverrides"
        @changeCallOverrides="applyScorecardChanges"
        @deleteAppliedOverrides="removeExistingScChanges"
      >
      </call-scorecard-body>

      <call-task-body
        v-else-if="componentIsTask"
        :selfSigned="selfSigned"
        :scrollHeightStyle="scrollHeightStyle"
        :selectedTask="selectedTask"
        :callId="callId"
        :callData="callData"
        @resetParent="resetUserInterface"
      >
      </call-task-body>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CallTrancript from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptBody.vue";
import CallTranscriptBodyHeader from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptHeader.vue";
import CallScorecardLayout from "@/components/Scorecards/CallScorecardLayout.vue";
import CallScorecardBody from "@/components/UnifiedCall/Component/CallScorecard/CallScorecardBody.vue";
import CallScorecardHeader from "@/components/UnifiedCall/Component/CallScorecard/CallScorecardHeader.vue";
import CallTranscriptBody from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptBody.vue";
import CallTaskBody from "@/components/UnifiedCall/Component/CallTask/CallTaskBody.vue";

export default {
  components: {
    CallTrancript,
    CallTranscriptBodyHeader,
    CallScorecardLayout,
    CallScorecardBody,
    CallScorecardHeader,
    CallTranscriptBody,
    CallTaskBody
  },
  data() {
    return {
      scrollHeight: 0,
      scrollHeightStyle: "",
      titleHeight: 0,
      titleHeightStyle: "",
      debounceTimeout: null,

      //transcript vars
      activeTab: "tab-transcript",
      transcriptView: "transcript",
      summaryInfo: {},
      phraseChosen: null,

      //scorecard vars
      excludeCall: false,
      clearAppliedChanges: false,
      typeOfExclusion: null,
      changesApplied: false,
      changesRemoved: false
    };
  },
  props: {
    cardHeight: {
      type: Number,
      default: 0
    },
    component: {
      required: true,
      type: String
    },
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    displayedRegions: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    pageQueryData: {
      type: Object,
      required: false,
      default: null
    },
    audioExpanded: {
      required: true,
      type: Boolean
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedTask: {
      type: Object,
      required: false
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus"
    ]),
    ...mapGetters("users", [
      "manualScoringAccess",
      "userData",
      "userDataListLoadingStatus"
    ]),
    componentIsTranscript() {
      return this.component == "transcript";
    },
    componentIsScorecard() {
      return this.component == "scorecard";
    },
    componentIsTask() {
      return this.component == "task";
    },
    cardTextClass() {
      if (this.componentIsTranscript) return "unified-card-text scrollable-content";
      else if (this.componentIsTask) return "no-padding";
      else return "scrollable-content";
    },
    noBodyHeader() {
      //does the body have a header?...
      return (
        ((this.componentIsScorecard &&
          this.callScorecardDataV2LoadingStatus == "loaded" &&
          !this.manualScoringAccess) || (this.componentIsScorecard && this.selfSigned)) ||
        this.componentIsTask
      );
    }
  },
  methods: {
    ...mapActions({
      retrieveAgentData: "resources/retrieveAgentData",
      updateTaskInTaskLists: "scorecardTasks/updateTaskInTaskLists"
    }),
    resetUserInterface(params) {
      this.$emit("resetParent", params);
    },
    setHeights() {
      //if the body does not have a header, account for more scroll height
      this.scrollHeight = !this.noBodyHeader
        ? Math.max(this.cardHeight - 100, 25)
        : Math.max(this.cardHeight - 20, 25);
      this.scrollHeightStyle =
        "height: " + this.scrollHeight + "px !important;"
    },
    debouncedResize() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setHeights();
      }, 500);
    },

    //transcript helpers
    updatePlayFromPosition(e) {
      this.$emit("update-play-from-position", e);
    },
    updateTranscriptView(view) {
      this.transcriptView = view;
    },
    setSummaryData(info) {
      this.summaryInfo = info;
    },
    triggerPhraseSelected(phrase) {
      this.phraseChosen = phrase;
    },
    triggerPhraseCaught() {
      this.phraseChosen = null;
    },

    //scorecard helpers
    spliceDisplayedRegions(index) {
      this.$emit("spliceDisplayedRegions", index);
    },
    setDisplayedRegions(regions) {
      this.$emit("setDisplayedRegions", regions);
    },
    clearLiveOverrides(clear) {
      this.clearAppliedChanges = clear;
    },
    changeExcludedType(type) {
      this.typeOfExclusion = type;
    },
    changeCallExcluded(excluded) {
      this.excludeCall = excluded;
    },
    applyScorecardChanges(apply) {
      this.changesApplied = apply;
    },
    removeExistingScChanges(remove) {
      this.changesRemoved = remove;
    },
    initScorecardData() {
      // if scorecard data is reloaded, we need to re-init the root vars based on the data retrieved
      if (
        this.callScorecardDataV2LoadingStatus == "loaded" &&
        this.componentIsScorecard
      ) {
        this.excludeCall = this.callData.exclusionType != null ? true : false;
        this.typeOfExclusion = this.callData.exclusionType;
      }
    }
  },
  destroyed() {
    this.excludeCall = false;
    this.typeOfExclusion = null;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  },
  mounted() {
    if (!this.selfSigned) {
      this.retrieveAgentData();
    }
    this.setHeights();
    window.addEventListener("resize", this.debouncedResize);
    if (this.componentIsScorecard) this.initScorecardData();
  },
  watch: {
    cardHeight: "setHeights",
    callScorecardDataV2LoadingStatus: "initScorecardData"
  }
};
</script>

<style scoped>
.scrollable-content {
  overflow-y: auto !important; /* Enables vertical scrolling */
  padding-right: 16px !important; /* Adds padding for better readability when scrolling */
}

.no-padding {
  padding: 0 !important;
}
</style>
