<template>
  <v-content style="height: 100vh; max-height: calc(100%);" id="my-content">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);" v-if="this.userDataLoadingStatus=='loaded'&&this.hasSetupAccess">
      <v-row style="height: 100vh; max-height: calc(100%);">
        <v-col cols="2" style="padding: 0; height: 100vh; max-height: calc(100%);">
          <v-navigation-drawer
            width="auto"
            style="height: 100vh; max-height: calc(100%); padding: 0 0 20px 10px;"
          >
            <v-list nav dense>
              <v-subheader>SETUP</v-subheader>

              <!-- dyna labels menu list group -->
              <v-list-group id="dynamicLabelsSetupList" v-model="dynaLabelsActive">

                <template v-slot:activator ref="dynaLabels">
                    <v-list-item-title @click="menuHeaderClicked('dynaLabels')">Dynamic Labels</v-list-item-title>
                </template>

                <v-list-item class="pl-4" v-if="dynaLabelsList.length>listLimit">
                  <v-text-field
                      dense
                      v-model="dynaLabelsSearch"
                      placeholder="Find Dynamic Labels..."
                      append-icon="mdi-magnify"
                      clearable
                      @click:clear="dynaLabelsSearch()"
                    ></v-text-field>
                </v-list-item>

                 <v-list-item link class="pl-4 pt-2 pb-2">
                  <v-list-item-content>
                    <v-list-item-title id="addNewDynamicLabel" class="addItem" @click="makeNewDynaLabel">+ Add New Dynamic Label</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-for="item in filteredDynaLabelsList" :key="item.id" :to="{ name: 'setup-dyna-labels', params: { dynaLabelId: item.id }, query: $route.query }" class="pl-4 pr-6" link>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item.draft"><v-icon v-text="'mdi-file-edit-outline'" small></v-icon></v-list-item-icon>
                  <v-list-item-icon v-if="item.processing"><v-icon class='processing' v-text="'mdi-autorenew'" small></v-icon></v-list-item-icon>
                </v-list-item>

              </v-list-group>

              <!-- access roles list group -->
              <v-list-group id="accessRolesSetupList" v-model="accessRolesActive">

                <template v-slot:activator ref="accessRoles">
                  <v-list-item-title @click="menuHeaderClicked('accessRoles')">Access Roles</v-list-item-title>
                </template>

                <v-list-item class="pl-4 pt-2 pb-2" v-if="accessRolesList.length>listLimit">
                  <v-text-field
                    dense
                    v-model="accessRolesSearch"
                    placeholder="Find Access Roles..."
                    append-icon="mdi-magnify"
                    clearable

                  ></v-text-field>
                </v-list-item>

                <v-list-item link class="pl-4 pt-2 pb-2">
                  <v-list-item-content>
                    <v-list-item-title id="addNewAccessRole" class="addItem"  @click="makeNewAccessRole">+ Add New Access Role</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item  v-for="item in filteredAccessRolesList" :key="item.id" :to="{ name: 'setup-access-roles', params: { accessRoleId: item.id }, query: $route.query }" class="pl-4 pr-6" link>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list-group>

              <v-list-group id="insightDefinitionsSetupList" v-model="insightDefinitionsActive">
                <template v-slot:activator ref="insightdefinitions">
                    <v-list-item-title @click="menuHeaderClicked('insightDefinitions')">Insight Definitions</v-list-item-title>
                </template>

                <v-list-item class="pl-4" v-if="insightsDefinitionsList.length>listLimit">
                  <v-text-field
                      dense
                      v-model="insightDefinitionsSearch"
                      placeholder="Find Insight Definitions..."
                      append-icon="mdi-magnify"
                      clearable
                    ></v-text-field>
                </v-list-item>

                <v-list-item link class="pl-4 pt-2 pb-2">
                  <v-list-item-content>
                    <v-list-item-title id="addNewInsightDefinition" class="addItem " v-if="insightsDefinitionsListLoadingStatus=='loaded'" @click="makeNewInsightDefinition">+ Add New Insight Definition</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

                <v-list-item v-for="item in filteredInsightsDefinitionsList" :key="item.id" :to="{ name: 'setup-insight-definitions', params: { insightDefinitionId: item.id }, query: $route.query }" class="pl-4 pr-6" link>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item.processing"><v-icon class='processing' v-text="'mdi-autorenew'" small></v-icon></v-list-item-icon>
                </v-list-item>

              </v-list-group>

              <v-list-group id="scorecardDefinitionsSetupList" v-model="scorecardDefinitionsActive" v-if="canCreateScorecards">
                <template v-slot:activator ref="scorecardDefinitions">
                    <v-list-item-title @click="menuHeaderClicked('scorecardDefinitions')">Scorecard Definitions</v-list-item-title>
                </template>
                <v-list-item class="pl-4" v-if="scorecardDefinitionsList.length>listLimit">
                  <v-text-field
                      dense
                      v-model="scorecardDefinitionsSearch"
                      placeholder="Find Scorecard Definitions..."
                      append-icon="mdi-magnify"
                      clearable
                    ></v-text-field>
                </v-list-item>

                <v-list-item link class="pl-4 pt-2 pb-2">
                  <v-list-item-content>
                    <v-list-item-title id="addNewScorecardDefinition" class="addItem " v-if="scorecardDefinitionsListLoadingStatus=='loaded'" @click="makeNewScorecardDefinition">+ Add New Scorecard Definition</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

                <v-list-item v-for="item in filteredScorecardsDefinitionsList" :disabled="item.processing==2" :key="item.id" :to="{ name: 'setup-scorecard-definitions', params: { scorecardDefinitionId: item.id }, query: $route.query }" class="pl-4 pr-6" link>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"> </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item.draft"><v-icon v-text="'mdi-file-edit-outline'" small></v-icon></v-list-item-icon>
                  <v-list-item-icon v-if="item.processing"><v-icon class='processing' v-text="'mdi-autorenew'" small></v-icon></v-list-item-icon>
                </v-list-item>

              </v-list-group>

              <v-list-group id="coachingDefinitionsSetupList" v-model="coachingDefinitionsActive">
                <template v-slot:activator ref="coachingDefinitions">
                    <v-list-item-title @click="menuHeaderClicked('coachingDefinitions')">Coaching Definitions</v-list-item-title>
                </template>
                <v-list-item class="pl-4" v-if="coachingDefinitionsList.length>listLimit">
                  <v-text-field
                      dense
                      v-model="coachingDefinitionsSearch"
                      placeholder="Find Coaching Definitions..."
                      append-icon="mdi-magnify"
                      clearable
                    ></v-text-field>
                </v-list-item>

                <v-list-item link class="pl-4 pt-2 pb-2">
                  <v-list-item-content>
                    <v-list-item-title id="addNewCoachingDefinition" class="addItem " v-if="coachingDefinitionsListLoadingStatus=='loaded'" @click="makeNewCoachingDefinition">+ Add New Coaching Definition</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

                <v-list-item v-for="item in filteredCoachingDefinitionsList" :key="item.id" :to="{ name: 'setup-coaching-definitions', params: { coachingDefinitionId: item.id }, query: $route.query }" class="pl-4 pr-6" link>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"> </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item.draft"><v-icon v-text="'mdi-file-edit-outline'" small></v-icon></v-list-item-icon>
                  <!-- TODO: implement these when needed -->
                  <!-- <v-list-item-icon v-if="item.processing"><v-icon class='processing' v-text="'mdi-autorenew'" small></v-icon></v-list-item-icon> -->
                </v-list-item>

              </v-list-group>

              <v-list-item ref="agent_link" :inputValue="this.agent_link_active" v-model="agentsActive" @click="menuHeaderClicked('agents')" color="primary" class="py-3">
                <v-list-item-title> Profiles </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="10" style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; overflow-y: scroll;">
          <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
            <v-row style="height: 100vh; max-height: calc(100%);">
              <v-col cols="12" style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; overflow-y: scroll;">

                <default-page
                  :setupMenuItems="setupMenuItems"
                  :setupMenuItemsLists="setupMenuItemsLists"
                  :dynaLabelsList="dynaLabelsList"
                  :accessRolesList="accessRolesList"
                  @makeNew="makeNew"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                  v-if="routeName==''||routeName=='setup'"
                ></default-page>

                <dyna-labels-listing
                  v-if="routeName=='setup-dyna-labels' && !this.$route.params.dynaLabelId"
                  :dynaLabelsList="sortedDynalabelsList"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></dyna-labels-listing>

                <dyna-labels-page
                  v-if="routeName=='setup-dyna-labels' && this.$route.params.dynaLabelId && dynaLabelDataLoadingStatus=='loaded'"
                  :dynaLabelId="String(this.$route.params.dynaLabelId)"
                  :description="setupMenuItems[0].description"
                  :expandMessage="setupMenuItems[0].expandMessage"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></dyna-labels-page>
                <div v-else-if="routeName=='setup-dyna-labels' && this.$route.params.dynaLabelId && dynaLabelDataLoadingStatus!='loaded'">
                  <loading-status-box
                    :message="'Loading dynamic label, please wait...'"
                    :type="dynaLabelMessageType"
                  ></loading-status-box>
                </div>

                <access-roles-listing
                  v-if="routeName=='setup-access-roles' && !this.$route.params.accessRoleId"
                  :accessRolesList="sortedAccessRolesList"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></access-roles-listing>

                <access-roles-page
                  v-if="routeName=='setup-access-roles' && this.$route.params.accessRoleId && accessRolePageDataLoaded"
                  :accessRoleId="String(accessRoleId)"
                  :description="setupMenuItems[1].description"
                  :expandMessage="setupMenuItems[1].expandMessage"
                  :usingModal="false"
                  @openMenu="openMenu"
                  @updateAccessRoleObject="updateAccessRoleObject"
                  @selectTarget="selectTarget"
                ></access-roles-page>

                <agent-setup v-if="routeName=='setup-agents'"></agent-setup>

                <div v-else-if="routeName=='setup-access-roles' && this.$route.params.accessRoleId && !accessRolePageDataLoaded">
                  <loading-status-box
                    :message="'Loading access role, please wait...'"
                    :type="accessRoleMessageType"
                  ></loading-status-box>
                </div>

                <insight-definitions-listing
                  v-if="routeName=='setup-insight-definitions' && !this.$route.params.insightDefinitionId"
                  :insightsDefinitionsList="sortedInsightsDefinitionsList"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></insight-definitions-listing>

                <insight-definitions-page
                  v-if="routeName=='setup-insight-definitions' && this.$route.params.insightDefinitionId && insightPageDataLoaded"
                  :insightDefinitionId="String(insightDefinitionId)"
                  :description="setupMenuItems[2].description"
                  :expandMessage="setupMenuItems[2].expandMessage"
                  @openMenu="openMenu"
                  @updateinsightDefinitionStatus="updateinsightDefinitionReadyStatus"
                  @selectTarget="selectTarget"
                ></insight-definitions-page>
                <div v-else-if="routeName=='setup-insight-definitions' && this.$route.params.insightDefinitionId && !insightPageDataLoaded">
                  <loading-status-box
                    :message="'Loading insight definition, please wait...'"
                    :type="insightMessageType"
                  ></loading-status-box>
                </div>

                <scorecard-definitions-listing
                  v-if="routeName=='setup-scorecard-definitions' && canCreateScorecards && !this.$route.params.scorecardDefinitionId"
                  :scorecardsDefinitionsList="sortedScorecardsDefinitionsList"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></scorecard-definitions-listing>

                <scorecard-definitions-page
                  v-if="routeName=='setup-scorecard-definitions' && canCreateScorecards && this.$route.params.scorecardDefinitionId && scorecardPageDataLoaded"
                  :scorecardDefinitionId="String(scorecardDefinitionId)"
                  :description="setupMenuItems[3].description"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                  @initCheckScorecardProcessing="initCheckScorecardProcessing"
                ></scorecard-definitions-page>
                 <div v-else-if="routeName=='setup-scorecard-definitions' && this.$route.params.scorecardDefinitionId && !scorecardPageDataLoaded ">
                  <loading-status-box
                    :message="'Loading scorecard definition, please wait...'"
                    :type="scorecardMessageType"
                  ></loading-status-box>
                </div>

                <coaching-definitions-listing
                  v-if="routeName=='setup-coaching-definitions' && !this.$route.params.coachingDefinitionId"
                  :coachingDefinitionsList="sortedCoachingDefinitionsList"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></coaching-definitions-listing>
                <coaching-definitions-page
                  v-if="routeName=='setup-coaching-definitions' && canCreateScorecards && this.$route.params.coachingDefinitionId && coachingPageDataLoaded"
                  :coachingDefinitionId="String(coachingDefinitionId)"
                  :description="setupMenuItems[4].description"
                  @openMenu="openMenu"
                  @selectTarget="selectTarget"
                ></coaching-definitions-page>
                 <div v-else-if="routeName=='setup-coaching-definitions' && this.$route.params.coachingDefinitionId && !coachingPageDataLoaded ">
                  <loading-status-box
                    :message="'Loading coaching definition, please wait...'"
                    :type="scorecardMessageType"
                  ></loading-status-box>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>

    import {mapActions, mapGetters} from "vuex"
    import setupMenu from "@/mixins/setupMenu"
    import utils from "@/mixins/utils"
    import defaultPage from "@/pages/Setup/DefaultPage.vue"
    import dynaLabelsPage from "@/pages/Setup/DynaLabelsPage.vue"
    import dynaLabelsListing from "@/pages/Setup/DynaLabelsListing.vue"
    import accessRolesPage from "@/pages/Setup/AccessRolesPage.vue"
    import accessRolesListing from "@/pages/Setup/AccessRolesListing.vue"
    import insightDefinitionsListing from '@/pages/Setup/InsightDefinitionsListing.vue'
    import InsightDefinitionsPage from './Setup/InsightDefinitionsPage.vue'
    import ScorecardDefinitionsListing from './Setup/ScorecardDefinitionsListing.vue'
    import ScorecardDefinitionsPage from './Setup/ScorecardDefinitionsPage.vue'
    import LoadingStatusBox from '@/components/Widgets/LoadingStatusBox.vue'
    import AgentSetup from "./Setup/AgentSetup.vue"
    import CoachingDefinitionsListing from './Setup/CoachingDefinitionsListing.vue'
    import CoachingDefinitionsPage from '@/pages/Setup/CoachingDefinitionsPage.vue'

    export default {
      name: 'Settings',
      mixins: [
        setupMenu,
        utils,
      ],
      components: {
        defaultPage,
        dynaLabelsPage,
        dynaLabelsListing,
        accessRolesPage,
        accessRolesListing,
        insightDefinitionsListing,
        InsightDefinitionsPage,
        ScorecardDefinitionsListing,
        ScorecardDefinitionsPage,
        LoadingStatusBox,
        AgentSetup,
        CoachingDefinitionsListing,
        CoachingDefinitionsPage
      },
      data() {
        return {
          routeName: '',
          dynaLabelId: '',
          insightDefinitionId: '',
          scorecardDefinitionId: '',
          coachingDefinitionId: '',
          accessRoleId: '',
          listLimit: 5,
          dynaLabelsSearch: "",
          insightDefinitionsSearch: "",
          scorecardDefinitionsSearch: "",
          coachingDefinitionsSearch: "",
          accessRolesSearch: "",
          dynaLabelsActive: false,
          insightDefinitionsActive: false,
          scorecardDefinitionsActive: false,
          coachingDefinitionsActive: false,
          accessRolesActive: false,
          agentsActive: false,
          preventProp: false,
          newAccessRoleObject: false,
          insightDefinitionReadyStatus: false,
          preventAccessRoleListRefresh: false,
          preventInsightDefinitionRefresh: false,
          preventScorecardDefinitionRefresh: false,
          polling: null,
          agent_link_active: false,
          // mock data
        }
      },
      computed: {
        ...mapGetters('dynaLabels',
        [ 'autosavePaused',
          'dynaLabelsList',
          'dynaLabelsListLoadingStatus',
          'dynaLabelsListLoadingError',
          'dynaLabelsListLoadingErrorStatus',
          'dynaLabelData',
          'dynaLabelDataLoadingStatus',
          'dynaLabelDataLoadingError',
          'dynaLabelDataLoadingErrorStatus',
          'dynaLabelsEditorList',
          'dynaLabelsEditorListLoadingStatus',
          'dynaLabelsEditorListLoadingError',
          'dynaLabelsEditorListLoadingErrorStatus',
        ]),
        ...mapGetters('accessRoles',
        [ 'accessRolesList',
          'accessRolesListLoadingStatus',
          'accessRolesListLoadingError',
          'accessRolesListLoadingErrorStatus',
          'authorizedUserList',
          'authorizedUserListLoadingStatus',
          'authorizedUserListLoadingError',
          'authorizedUserListLoadingErrorStatus',
          'accessRoleData',
          'accessRoleDataLoadingStatus',
          'accessRoleDataLoadingError',
          'accessRoleDataLoadingErrorStatus',
          'accessRoleNewID',
          'accessRoleNewIDLoadingStatus',
          'accessRoleNewIDLoadingError',
          'accessRoleNewIDLoadingErrorStatus',
        ]),
        ...mapGetters('insights',
        [ 'insightsDefinitionsList',
          'insightsDefinitionsListLoadingStatus',
          'insightsDefinitionsListLoadingError',
          'insightsDefinitionsListLoadingErrorStatus',
          'insightsDefinitionsListItemData',
          'insightsDefinitionsListItemDataLoadingStatus',
          'insightsDefinitionsListItemDataLoadingError',
          'insightsDefinitionsListItemDataLoadingErrorStatus',
          'insightDefinitionNewIdLoadingErrorStatus',
          'insightDefinitionNewIdLoadingError',
          'insightDefinitionNewIdLoadingStatus',
          'insightDefinitionNewId',
        ]),
        ...mapGetters('scorecardDefinitions',
        [ 'scorecardDefinitionsList',
          'scorecardDefinitionsListLoadingStatus',
          'scorecardDefinitionsListLoadingError',
          'scorecardDefinitionsListLoadingErrorStatus',
          'scorecardDefinitionNewIdLoadingErrorStatus',
          'scorecardDefinitionNewIdLoadingError',
          'scorecardDefinitionNewIdLoadingStatus',
          'scorecardDefinitionNewId',
          "scorecardsDefinitionsEditorListItem",
          "scorecardsDefinitionsEditorListItemLoadingStatus",
          "scorecardsDefinitionsEditorListItemLoadingError",
          "scorecardsDefinitionsEditorListItemLoadingErrorStatus",
          "updateProcessFlagsLoadingStatus"
        ]),
        ...mapGetters('coachingDefinitions',
        [ 'coachingDefinitionsList',
          'coachingDefinitionsListLoadingStatus',
          'coachingDefinitionsListLoadingError',
          'coachingDefinitionsListLoadingErrorStatus',
          "coachingDefinitionsListItem",
          "coachingDefinitionsListItemLoadingStatus",
          "coachingDefinitionsListItemLoadingError",
          "coachingDefinitionsListItemLoadingErrorStatus",
        ]),
        ...mapGetters('resources',
          [ 'accountData',
            'agentData',
            'searchData',
          ]),
        ...mapGetters('users',[
          'userData',
          'userDataLoadingStatus',
          'userDataLoadingErrorStatus',
          'cfOnlyUser',
          'administratorUser',
          'standardUser',
          'powerUser',
          'powerPlusUser',
          'hasSetupAccess',
          'canCreateScorecards'
        ]),
        scorecardPageDataLoaded() {
          return (
            this.accessRolesListLoadingStatus == 'loaded' &&
            this.scorecardDefinitionsListLoadingStatus == 'loaded' &&
            this.dynaLabelsEditorListLoadingStatus == 'loaded' &&
            this.scorecardsDefinitionsEditorListItemLoadingStatus == 'loaded'
          )
        },
        coachingPageDataLoaded() {
          return (
            this.scorecardDefinitionsListLoadingStatus == 'loaded' &&
            this.coachingDefinitionsListItemLoadingStatus == 'loaded' &&
            this.coachingDefinitionsListLoadingStatus == 'loaded'
          )
        },
        scorecardMessageType() {
          if(this.accessRolesListLoadingErrorStatus || this.scorecardDefinitionsListLoadingErrorStatus || this.scorecardsDefinitionsEditorListItemLoadingErrorStatus || this.dynaLabelsEditorListLoadingErrorStatus) return 'error'
          else return 'loading'
        },
        insightPageDataLoaded() {
          return (
            this.insightsDefinitionsListLoadingStatus == 'loaded' &&
            this.dynaLabelsEditorListLoadingStatus == 'loaded' &&
            this.insightsDefinitionsListItemDataLoadingStatus == 'loaded'
          )
        },
        insightMessageType() {
          if(this.insightsDefinitionsListLoadingErrorStatus || this.dynaLabelsEditorListLoadingErrorStatus || this.insightsDefinitionsListItemDataLoadingErrorStatus) return 'error'
          else return 'loading'
        },
        accessRolePageDataLoaded() {
          return (
            this.accessRoleDataLoadingStatus == 'loaded' &&
            this.accessRolesListLoadingStatus == 'loaded' &&
            this.authorizedUserListLoadingStatus == 'loaded' &&
            this.userDataLoadingStatus == 'loaded'
          )
        },
        accessRoleMessageType() {
          if(this.accessRolesListLoadingErrorStatus || this.accessRoleDataLoadingErrorStatus || this.authorizedUserListLoadingErrorStatus || this.userDataLoadingErrorStatus) return 'error'
          else return 'loading'
        },

        dynaLabelMessageType() {
          if(this.dynaLabelDataLoadingErrorStatus) return 'error'
          else return 'loading'
        },
        filteredDynaLabelsList() {
          let search = this.dynaLabelsSearch || ''
          let rtnList = [...this.sortedDynalabelsList];

          return rtnList.filter(dynalabel => {
            if (dynalabel.title!=null) {
              return dynalabel.title.toLowerCase().includes(search.toLowerCase());
            }
          });
        },
        sortedDynalabelsList() {
          return [...this.dynaLabelsList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
        },
        filteredInsightsDefinitionsList() {
          let search = this.insightDefinitionsSearch || ''
          let rtnList = [...this.sortedInsightsDefinitionsList]

          return rtnList.filter(insightDefinition => {
            return insightDefinition.title.toLowerCase().includes(search.toLowerCase());
          });
        },
        sortedInsightsDefinitionsList() {
          return [...this.insightsDefinitionsList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
        },
        filteredScorecardsDefinitionsList() {
          let search = this.scorecardDefinitionsSearch || ''
          let rtnList = [...this.sortedScorecardsDefinitionsList]

          return rtnList.filter(scorecardDefinition => {
            return scorecardDefinition.title.toLowerCase().includes(search.toLowerCase());
          });
        },
        filteredCoachingDefinitionsList() {
          let search = this.coachingDefinitionsSearch || ''
          let rtnList = [...this.sortedCoachingDefinitionsList]

          return rtnList.filter(scorecardDefinition => {
            return scorecardDefinition.title.toLowerCase().includes(search.toLowerCase());
          });
        },
        sortedCoachingDefinitionsList() {
          return [...this.coachingDefinitionsList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
        },
        sortedScorecardsDefinitionsList() {
          return [...this.scorecardDefinitionsList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
        },
        filteredAccessRolesList() {
          let search = this.accessRolesSearch || ''
          let rtnList = [...this.sortedAccessRolesList]

          return rtnList.filter(accessRole => {
            return accessRole.title.toLowerCase().includes(search.toLowerCase());
          });
        },
        sortedAccessRolesList() {
          return [...this.accessRolesList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
        },
        setupMenuItems() {
          // get menu item data - TODO: swap out with real computed property later on
          let setupMenuItems = this.getSetupMenuItems() // from setupMenu.js
      
          if(!this.canCreateScorecards) {
            return setupMenuItems.filter(o => { return o.type!='scorecardDefinitions' })
          } else {
            return setupMenuItems
          }
        },
        setupMenuItemsLists() {
          return {
            'dynaLabelsList': this.sortedDynalabelsList,
            'accessRolesList': this.sortedAccessRolesList,
            'insightDefinitionsList': this.sortedInsightsDefinitionsList,
            'scorecardDefinitionsList': this.sortedScorecardsDefinitionsList,
            'coachingDefinitionsList': this.sortedCoachingDefinitionsList,
          }
        },
        scorecardsStillProcessing() {
          return this.scorecardDefinitionsList.length > 0 ? this.scorecardDefinitionsList.some(scorecard => scorecard.processing > 0) : null
        }
      },
      methods: {

        ...mapActions({

          setAutosavePausedStatus: 'dynaLabels/setAutosavePausedStatus',

          retrieveDynaLabelsList: 'dynaLabels/retrieveDynaLabelsList',
          retrieveDynaLabelData: 'dynaLabels/retrieveDynaLabelData',
          createNewDynaLabel: 'dynaLabels/createNewDynaLabel',
          publishDynaLabel: 'dynaLabels/publishDynaLabel',
          deleteDynaLabel: 'dynaLabels/deleteDynaLabel',

          retrieveAccessRolesList: 'accessRoles/retrieveAccessRolesList',
          retrieveAccessRoleData: 'accessRoles/retrieveAccessRoleData',
          createNewAccessRole: 'accessRoles/createNewAccessRole',
          publishAccessRole: 'accessRoles/publishAccessRole',
          deleteAccessRole: 'accessRoles/deleteAccessRole',

          retrieveAccountData: 'resources/retrieveAccountData',
          retrieveAgentData: 'resources/retrieveAgentData',
          retrieveSearchListData: 'resources/retrieveSearchListData',

          retrieveInsightsEditorList: 'insights/retrieveInsightsEditorList',
          retrieveInsightsEditorListItem: 'insights/retrieveInsightsEditorListItem',
          createNewInsightDefinition: 'insights/createNewInsightDefinition',

          setInsightListLoadingStatus: "insights/setInsightListLoadingStatus",
          setAccessRolesListLoadingStatus: "accessRoles/setAccessRolesListLoadingStatus",

          retrieveScorecardDefinitionsEditorList: "scorecardDefinitions/retrieveScorecardDefinitionsEditorList",
          retrieveScorecardEditorListItem: "scorecardDefinitions/retrieveScorecardEditorListItem",

          setScorecardListLoadingStatus: "scorecardDefinitions/setScorecardListLoadingStatus",
          createNewScorecardDefinition: "scorecardDefinitions/createNewScorecardDefinition",
          updateScorecardProcessingFlags: "scorecardDefinitions/updateScorecardProcessingFlags",

          retrieveCoachingDefinitionsList: "coachingDefinitions/retrieveCoachingDefinitionsList",
          retrieveCoachingEditorListItem: "coachingDefinitions/retrieveCoachingEditorListItem",
          createNewCoachingDefinition: "coachingDefinitions/createNewCoachingDefinition",
          setCoachingListLoadingStatus: "coachingDefinitions/setCoachingListLoadingStatus"

        }),
        makeNew(type) {
          this.setAutosavePausedStatus(true)
          switch(type) {
            case 'accessRoles':
              this.makeNewAccessRole()
              break;
            case 'dynaLabels':
              this.makeNewDynaLabel()
              break;
            case 'insightDefinitions':
              this.makeNewInsightDefinition()
              break;
            case 'scorecardDefinitions':
              this.makeNewScorecardDefinition()
              break;
            case 'coachingDefinitions':
              this.makeNewCoachingDefinition()
              break;
          }
        },
        makeNewDynaLabel() {
          this.setAutosavePausedStatus(true)
          // set dynaLabelDataLoadingStatus
          let id = Date.now()
          this.createNewDynaLabel(id)
          this.selectTarget({'type':'dynaLabels','id':id})
        },
        makeNewInsightDefinition(){
          let user = this.userData
          let id = Date.now()
          let params = {
            id: id,
            user_id: user.id
          }
          this.createNewInsightDefinition(params)
          this.insightDefinitionReadyStatus = true;
          this.selectTarget({'type':'insightDefinitions','id':id})
        },
        makeNewScorecardDefinition(){
          let id = Date.now()
          let params = {
            id: id,
            user_id: this.userData.id
          }
          this.createNewScorecardDefinition(params)
          this.selectTarget({'type':'scorecardDefinitions','id':id})
        },
        makeNewCoachingDefinition(){
          let id = Date.now()
          let params = {
            id: id,
            user_id: this.userData.id
          }
          this.createNewCoachingDefinition(params)
          this.selectTarget({'type':'coachingDefinitions','id':id})
        },
        makeNewAccessRole(){
          let user = this.userData
          let id = Date.now()
          let params = {
            id: id,
            user_id: user.id
          }
          this.newAccessRoleObject = true;
          this.createNewAccessRole(params)
          this.selectTarget({'type':'accessRoles','id':id})
        },
        openMenu(type) {
          this[type + 'Active'] = true
        },
        initCheckScorecardProcessing() {
          if (!this.polling) {
            this.polling = setInterval(() => {
                this.updateScorecardProcessingFlags();
            }, 10000);
          }
        },
        selectTarget(obj) {
          this.preventProp = obj.preventProp
          let route = { name: "setup-" + this.kebabCase(obj.type), query: this.$route.query }
          if(obj.id!=='undefined') {
            switch(obj.type) {
              case 'dynaLabels':
                route.params = { dynaLabelId: Number(obj.id) }
                break;
              case 'accessRoles':
                route.params = { accessRoleId: Number(obj.id) }
                break;
              case 'insightDefinitions':
                route.params = { insightDefinitionId: Number(obj.id) }
                break;
              case 'scorecardDefinitions':
                route.params = { scorecardDefinitionId: Number(obj.id) }
                break;
              case 'coachingDefinitions':
                route.params = { coachingDefinitionId: Number(obj.id) }
                break;
            }
          }
          this.$router.push(route).catch(err => {})
          setTimeout(function() {
            if(this.$vuetify) this.$vuetify.goTo(0)
          }, 1500)
        },
        retrieveData() {

          // get resource info
          this.retrieveAccountData()
          this.retrieveAgentData({ email: true })
          this.retrieveSearchListData()
          // get lists
          this.retrieveDynaLabelsList({})

          // because there is no draft mode for access roles
          if (!this.newAccessRoleObject && !this.preventAccessRoleListRefresh) {
            this.setAccessRolesListLoadingStatus("loading")
            this.retrieveAccessRolesList()
            this.newAccessRoleObject = true
          }
          this.preventAccessRoleListRefresh = false
          this.newAccessRoleObject = false

          // because there is no draft mode for insight definitions
          if (!this.insightDefinitionReadyStatus) {
            this.setInsightListLoadingStatus("loading")
            this.retrieveInsightsEditorList()
            this.insightDefinitionReadyStatus = true
          }
          this.preventInsightDefinitionRefresh = false
          this.insightDefinitionReadyStatus = false

          // because there is no draft mode for scorecard definitions
          if (!this.preventScorecardDefinitionRefresh && this.scorecardDefinitionsListLoadingStatus!='loading') {
            this.setScorecardListLoadingStatus("loading")
            this.retrieveScorecardDefinitionsEditorList()
          }
          this.preventScorecardDefinitionRefresh = false

          this.retrieveCoachingDefinitionsList()

          // get page data conditionally
          if(this.dynaLabelId) this.retrieveDynaLabelData(this.dynaLabelId)
          if(this.accessRoleId) this.retrieveAccessRoleData(this.accessRoleId)
          if(this.insightDefinitionId) this.retrieveInsightsEditorListItem(this.insightDefinitionId)
          if(this.scorecardDefinitionId) this.retrieveScorecardEditorListItem(this.scorecardDefinitionId)
          if(this.coachingDefinitionId) this.retrieveCoachingEditorListItem(this.coachingDefinitionId)
        },
        routeChanged() {
          // route has changed, but we don't know what to, yet...
          this.routeName = this.$route.name
          this.dynaLabelId = this.$route.params.dynaLabelId || ''
          this.insightDefinitionId = this.$route.params.insightDefinitionId || ''
          this.scorecardDefinitionId = this.$route.params.scorecardDefinitionId || ''
          this.coachingDefinitionId = this.$route.params.coachingDefinitionId || ''
          this.accessRoleId = this.$route.params.accessRoleId || ''
          if (this.userDataLoadingStatus=='loaded') {
            this.retrieveData()
          }
        },
        updateAccessRoleObject(state) {
          this.newAccessRoleObject = state
        },
        updateinsightDefinitionReadyStatus(state) {
          this.insightDefinitionReadyStatus = state
        },
        activateDynaLabels() {
          // only if prevent propogation is false
          this.agentsActive = false
          if(!this.preventProp) {
            if (this.dynaLabelsActive) {
              this.$router.push({path:'/setup/dyna-labels/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            } else if (this.noMenusActive()) {
              this.$router.push({path:'/setup/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            }
          }
        },
        activateInsightDefinitions() {
          // only if prevent propogation is false
          this.preventInsightDefinitionRefresh = true
          this.agentsActive = false
          if(!this.preventProp) {
            if (this.insightDefinitionsActive) {
              this.$router.push({path:'/setup/insight-definitions/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            } else if (this.noMenusActive()) {
              this.$router.push({path:'/setup/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            }
          }
        },
        activateScorecardDefinitions() {
           // only if prevent propogation is false
          this.preventScorecardDefinitionRefresh = true
          this.agentsActive = false
          if(!this.preventProp) {
            if (this.scorecardDefinitionsActive) {
              this.$router.push({path:'/setup/scorecard-definitions/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            } else if (this.noMenusActive()) {
              this.$router.push({path:'/setup/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            }
          }
        },
        activateCoachingDefinitions() {
           // only if prevent propogation is false
          this.agentsActive = false
          if(!this.preventProp) {
            if (this.coachingDefinitionsActive) {
              this.$router.push({path:'/setup/coaching-definitions/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            } else if (this.noMenusActive()) {
              this.$router.push({path:'/setup/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            }
          }
        },
        activateAgents() {
          this.agentsActive = true
          this.$router.push({path:'/setup/agents/'}).catch(err => {})
          setTimeout(function () {
            if (this.$vuetify) this.$vuetify.goTo(0)
          }, 1500)
        },
        selectNewAccessRole() {
          this.openMenu('accessRoles')
          this.selectTarget({'type':'accessRoles','id':this.accessRoleNewID})
        },
        selectNewInsightDefinition() {
          this.openMenu('insightDefinitions')
          this.selectTarget({'type':'insightDefinitions','id':this.insightDefinitionNewId})
        },
        activateAccessRoles() {
          // only if prevent propogation is false
          this.preventAccessRoleListRefresh = true
          if(!this.preventProp) {
            if (this.accessRolesActive) {
              this.$router.push({path:'/setup/access-roles/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
              // this.newObject = false
            } else if (this.noMenusActive()) {
              this.$router.push({path:'/setup/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            }
          }
        },
        menuHeaderClicked(item) {
          if(item=='dynaLabels') {
            this.preventProp = false;
            this.activateDynaLabels()
          }
          if(item=='accessRoles') {
            this.preventProp = false;
            this.activateAccessRoles()
          }
          if(item=='insightDefinitions') {
            this.preventProp = false;
            this.activateInsightDefinitions()
          }
          if(item=='scorecardDefinitions') {
            this.preventProp = false;
            this.activateScorecardDefinitions()
          }
          if(item=='coachingDefinitions') {
            this.preventProp = false;
            this.activateCoachingDefinitions()
          }
          if(item=='agents') {
            this.preventProp = false;
            // this.$refs['agent_link'].isActive = true
            // this.agent_link_active = true
            this.activateAgents()
          }

        },
        clearPoll() {
          if (this.polling !== null)  {
            clearInterval(this.polling);
            this.polling = null
          }
        },
        checkPollStatus() {
          if (this.scorecardsStillProcessing != null && !this.scorecardsStillProcessing) {
            this.clearPoll()
          }
        },
        noMenusActive() {
          return !this.dynaLabelsActive && !this.accessRolesActive && !this.insightDefinitionsActive && !this.scorecardDefinitionsActive && !this.coachingDefinitionsActive
        },
      },
      beforeDestroy() {
        this.clearPoll()
      },
      mounted() {
        this.routeName = this.$route.name
        this.dynaLabelId = this.$route.params.dynaLabelId
        this.insightDefinitionId = this.$route.params.insightDefinitionId
        this.scorecardDefinitionId = this.$route.params.scorecardDefinitionId
        this.coachingDefinitionId = this.$route.params.coachingDefinitionId
        this.accessRoleId = this.$route.params.accessRoleId
        if (this.userDataLoadingStatus=='loaded') {
          this.retrieveData()
        }
        this.initCheckScorecardProcessing()
      },
      updated() {
        // need to close open menus when an update event occurs, but only if no object is selected
        // check that the params object is empty
        if(this.$route.params && Object.keys(this.$route.params).length === 0) {
          // then check if any menu is open
          if(!(this.dynaLabelsActive && this.accessRolesActive && this.insightDefinitionsActive && this.scorecardDefinitionsActive && this.coachingDefinitionsActive)) {
            // close them
            this.dynaLabelsActive = false
            this.accessRolesActive = false
            this.insightDefinitionsActive = false
            this.scorecardDefinitionsActive = false
            this.coachingDefinitionsActive = false
          }
        }
      },
      watch: {
        $route: "routeChanged",
        dynaLabelsActive: "activateDynaLabels",
        accessRolesActive: "activateAccessRoles",
        insightDefinitionsActive: "activateInsightDefinitions",
        scorecardDefinitionsActive: "activateScorecardDefinitions",
        coachingDefinitionsActive: "activateCoachingDefinitions",
        accessRoleNewID: "selectNewAccessRole",
        insightDefinitionNewId: "selectNewInsightDefinition",
        scorecardsStillProcessing: "checkPollStatus",
        userDataLoadingStatus: function(status) {
          if(status=='loaded') {
            // prevent url twiddling - if no hasSetupAccess redirect to root path
            if(!this.hasSetupAccess) {
              this.$router.replace({path:'/'}).catch(err => {
              })
              setTimeout(function () {
                if (this.$vuetify) this.$vuetify.goTo(0)
              }, 1500)
            } else {
              this.retrieveData()
            }
          }
        },
      },
    }

</script>

<style scoped>

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 20px !important;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 4px 4px !important;
  line-height: 20px !important;
}

.v-list-item__title:hover,
.v-list-item__subtitle:hover {
  text-overflow: inherit;
  white-space: pre-wrap;
}

.v-list-item__icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.addItem {
  color: #1976d2 !important;
}

.v-progress-circular {
margin: 8rem;
}

i.processing {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

</style>
