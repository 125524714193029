<template>
  <v-icon v-if="scorecardId!=undefined && displaySoup" class="float-right" @click="openTab" title="Open Printable Scorecard in New Tab...">mdi-newspaper-variant-multiple-outline</v-icon>
</template>

<script>

  export default {
    props: {
      printable_url: {
        type: String,
        required: true
      },
      scorecardId: {
        required: true
      },
      displaySoup: {
        required: false,
        default: true
      }
    },
    data: () => ({
    }),
    methods: {
      openTab() {
        window.open(this.printable_url, "_blank", "noopener")
      }
    },
    mounted() {
    }
  }

</script>

<style scoped>

  .float-right {
    margin-left: .5rem;
  }

</style>
