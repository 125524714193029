<template>
  <v-app-bar elevation="1" color="primary lighten-2">
    <v-toolbar-title class="white--text">
      {{ reviewType }} Review
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="white--text call-details">
      <span>Agent: {{ agentName }}</span>
      <span> &mdash; </span>
      <span>{{ callDirectionString }} - ({{ callId }})</span>
      <span> &mdash; </span>
      <span>{{ callDT }}</span>
      <span> &mdash; </span>
      <span>Client Call Identifier: {{ clientCallIdentifier }}</span>
    </div>

    <v-spacer></v-spacer>

    <!-- header controls -->
    <unified-call-header-controls
      :callId="callId"
      :callData="callData"
      :selfSigned="selfSigned"
      @update-call-note="updateCallNoteListener"
      @update-call-has-tags="updateCallHasTagsListener"
      @HIDE_MODAL="hideModal"
    ></unified-call-header-controls>
  </v-app-bar>
</template>

<script>
import moment from "moment";
import UnifiedCallHeaderControls from "./UnifiedCallHeaderControls.vue";

export default {
  components: { UnifiedCallHeaderControls },
  data() {
    return {};
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    callId: {
      type: Number,
      required: true
    },
    callData: {
      type: Object,
      required: true
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    agentName() {
      return this.callData.agentName;
    },
    clientCallIdentifier() {
      return this.callData.inasteriskid;
    },
    callDirectionString() {
      let msg = "";
      if (this.callData.direction == "in") {
        // in
        msg = "Inbound call from " + this.formatPhoneNumber(this.callData.ani);
      } else {
        // out
        msg = "Outbound call to " + this.formatPhoneNumber(this.callData.dns);
      }
      return msg;
    },
    callDT() {
      return moment(this.callData.adjustedDateTime).format(
        "dddd, MMMM D, YYYY [at] h:mm A"
      );
    },
    isChat() {
      return this.callData.media_type == "Chat";
    },
    reviewType() {
      return this.isChat ? "Chat" : "Call";
    }
  },
  methods: {
    formatPhoneNumber(number) {
      return number.length == 10
        ? "(" +
            number.slice(0, 3) +
            ") " +
            number.slice(3, 6) +
            "-" +
            number.slice(6)
        : number;
    },
    hideModal() {
      this.$emit("HIDE_MODAL", false);
    },
    updateCallNoteListener(e) {
      this.$emit("update-call-note", e);
    },
    updateCallHasTagsListener(e) {
      this.$emit("update-call-has-tags", e);
    },
  },
  mounted() {}
};
</script>
