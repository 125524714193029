<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn fab small dark color="primary" href="/setup-faq/2" target="_blank" rel="noopener" title="Click for Setup Help and FAQs">
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row v-for="row in chunkedInsightDefinitionItems" :key="row.index" justify-space-between>
      <v-col v-for="insightDefinition in row" :key="insightDefinition.index" :cols="12/colCount" class="d-flex flex-column">
        <v-card class="mr-8 pa-1 setup-card flex d-flex flex-column card-outer" outlined tile>
          <v-card-title>{{insightDefinition.title}}</v-card-title>
          <v-card-text style="min-height: 5vh;">{{insightDefinition.description}}</v-card-text>
          <v-card-actions class="card-actions">
            <p class="insight-definition-info">Created: {{formatDate(insightDefinition.created_at)}}<br/>
            Updated: {{formatDate(insightDefinition.updated_at)}}<br/>
            Insight Definition ID: {{insightDefinition.id}}</p>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="openMenuAndSelectTarget('insightDefinitions', insightDefinition.id)">Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import moment from "moment";

  export default {
    name: 'InsightDefinitionsListing',
    props: {
      insightsDefinitionsList: {
        type: Array,
      },
    },
    data() {
      return {
        colCount: 2,
      }
    },
    components: {
    },
    computed: {
      chunkedInsightDefinitionItems() {
        let chunkedInsightDefinitionItems = [];
        let index = 0;
        while (index < this.insightsDefinitionsList.length) {
          let tmparr = this.insightsDefinitionsList.slice(index, this.colCount + index);
          chunkedInsightDefinitionItems.push(tmparr);
          index += this.colCount;
        }
        return chunkedInsightDefinitionItems;
      },
    },
    methods: {
      openMenuAndSelectTarget(type, id) {
        this.$emit('openMenu', type)
        this.$emit('selectTarget', { 'type':type, 'id':id })
      },
      formatDate(time){
        return moment(time).format('l LT');
      },
    },
    watch: {
    },
    mounted() {
      this.$emit('openMenu', 'insightDefinitions')
    }
  }

</script>

<style scoped>

  p.insight-definition-info {
    font-size: .75rem;
    color: rgba(0,0,0,.33);
  }

</style>
