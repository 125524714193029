<template>
  <div>
    <v-btn
      v-if="hitEnabled"
      icon
      :color="checkedRegions.includes(rowId) ? 'primary' : 'grey lighten-2'"
      @click="updateDisplayedRegions(rowId)"
    >
      <v-icon small>mdi-eye</v-icon>
    </v-btn>

    <v-icon v-else-if="hitDisabled" color="grey lighten-2" small
      >mdi-eye-off-outline</v-icon
    >

    <span v-else></span>
  </div>
</template>
<script>
export default {
  computed: {},
  props: {
    hitEnabled: {
      required: true,
      type: Boolean
    },
    hitDisabled: {
      required: true,
      type: Boolean
    },
    checkedRegions: {
      required: true,
      type: Array
    },
    rowId: {
      required: true,
      type: Number
    }
  },
  methods: {
    updateDisplayedRegions(id) {
      this.$emit("updateDisplayedRegions", id);
    }
  },
  mounted() {
    this.$nextTick();
  }
};
</script>

<style></style>
