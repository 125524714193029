<template>
  <v-container class=" text-center" pa-0 fluid>
    <v-row justify="center" style="padding-bottom:2rem">
      <v-col cols="12">
        <scorecard-loading-status
          v-if="scorecardCallCount < 1 && !isPrintable"
          :message="all_calls_excluded"
          :type="'nodata'"
        >
        </scorecard-loading-status>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead v-if="!isPrintableTeam">
              <!-- header used for team, agent, and printable agent scorecard -->
              <tr v-if="isPrintableAgent" class="header-row top">
                <th colspan="8" class="pl-2" style="font-weight: normal;">
                  <scorecard-breadcrumbs
                    :scorecardHeader="scorecardAgentData.scorecardTitle"
                    :dynaLabelHeader="scorecardAgentData.dynalabelTitle"
                    :singleDynaCount="scorecardAgentData.dynalabelCount < 2"
                    :agentHeader="scorecardAgentData.agentName"
                    :level="'printable-agent'"
                    :linkEnabled="true"
                    @go-to-page="selectPage"
                  ></scorecard-breadcrumbs>
                </th>
              </tr>
              <tr class="header-row top">
                <th :colspan="getColSpan('headerTop')">
                  <h2 v-html="getHead()"></h2>
                </th>
                <th colspan="2">
                  <div v-if="!isPrintable">
                    <download-p-d-f-control
                      class="pt-2"
                      :printable_url="getPrintableURL()"
                      :scorecardId="$route.params.scorecardId"
                    ></download-p-d-f-control>
                    <email-control
                      :agentId="Number(agentId)"
                      :url="getURL()"
                      :printable_url="getPrintableURL()"
                      :feature="'scorecards'"
                      :dialog_title="emailText"
                      :reporttype="emailType + '-scorecard'"
                      :scorecardId="String($route.params.scorecardId)"
                      :start_date="$route.query.start_date"
                      :end_date="$route.query.end_date"
                      :icon_position="'float-right pt-2'"
                      :type="emailType"
                    ></email-control>
                    <div :title="'Schedule Delivery...'">
                      <custom-icon
                        @click.native="
                          $event => exportClick('scheduleExportPDF')
                        "
                        name="schedule-export-pdf"
                        class="pdf-delivery float-right"
                      ></custom-icon>
                    </div>
                  </div>
                  &nbsp;
                </th>
              </tr>
              <tr class="header-row bottom">
                <th :colspan="getColSpan('headerBottom1')">
                  <h2 v-html="getSubhead()"></h2>
                </th>
                <th :colspan="getColSpan('headerBottom2')">
                  <h2 class="header-score">
                    <!-- we need to access 'items' like this since presentation-service looks at an array at the aggregate level -->
                    Score: {{ scorecardScore }}
                  </h2>
                </th>
              </tr>
              <tr class="cols" :class="scorecardTableType">
                <th v-if="showCBC" :class="thStyle + ' ' + categoryHeader">
                  Calls By Category
                </th>
                <th class="text-center" :class="thStyle">
                  <v-icon
                    v-if="!showCBC && canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('cbc')"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    v-else-if="canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('cbc')"
                    >mdi-chevron-right</v-icon
                  >
                </th>
                <th :class="printableColStyle">
                  Category
                </th>
                <th v-if="showHBC" :class="thStyle">
                  Subcategory Results
                </th>
                <th class="text-center" :class="thStyle">
                  <v-icon
                    v-if="!showHBC && canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('hbc')"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    v-else-if="canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('hbc')"
                    >mdi-chevron-right</v-icon
                  >
                </th>
                <th :class="printableColStyle">
                  Subcategory
                </th>
                <th class="text-right" :class="printableColStyle">Score</th>
              </tr>
            </thead>
            <thead v-else>
              <!-- printable TEAM scorecard header -->

              <tr class="header-row top">
                <th colspan="12">
                  <v-row class="printable-header">
                    <v-col cols="6">
                      <v-flex fill-height>
                        <h1>{{ getScorecardName }}</h1>
                        <h2 :class="printableTeamCallRange">
                          {{ getScorecardSubtitle }}
                        </h2>
                        <h3 v-html="getScorecardHeader"></h3>
                      </v-flex>
                    </v-col>
                    <v-col cols="2">
                      <v-flex class="text-center card-container">
                        <p class="caption">Calls/Day</p>
                        <h1 class="numbers pt-4">
                          {{ getTeamACV }} <span class="suffix"></span>
                        </h1>
                      </v-flex>
                    </v-col>
                    <v-col cols="2">
                      <v-flex class="text-center card-container">
                        <p class="caption">Talk Time</p>
                        <h1 class="numbers pt-4">
                          {{ getATTms() }}
                        </h1>
                      </v-flex>
                    </v-col>
                    <v-col cols="2">
                      <v-flex class="text-center card-container">
                        <p class="caption">Score</p>
                        <h1 class="numbers pt-4">{{ getTeamACSLastScore }}%</h1>
                      </v-flex>
                    </v-col>
                  </v-row>
                </th>
              </tr>
              <!-- if the scorecard does not have any excluded calls, show an empty data row on printable scorecard -->
              <tr v-if="scorecardCallCount < 1">
                <scorecard-loading-status
                  :message="all_calls_excluded"
                  :type="'nodata'"
                ></scorecard-loading-status>
              </tr>
              <tr v-else class="cols" :class="scorecardTableType">
                <th v-if="showCBC" :class="thStyle + ' ' + categoryHeader">
                  Calls By Category
                </th>
                <th class="text-center" :class="thStyle">
                  <v-icon
                    v-if="!showCBC && canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('cbc')"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    v-else-if="canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('cbc')"
                    >mdi-chevron-right</v-icon
                  >
                </th>
                <th :class="printableColStyle">
                  Category
                </th>
                <th v-if="showHBC" :class="thStyle">
                  Subcategory Results
                </th>
                <th class="text-center" :class="thStyle">
                  <v-icon
                    v-if="!showHBC && canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('hbc')"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    v-else-if="canExpandCols"
                    small
                    color="info darken-2"
                    @click="toggleColumn('hbc')"
                    >mdi-chevron-right</v-icon
                  >
                </th>
                <th :class="printableColStyle">
                  Subcategory
                </th>
                <th class="text-right" :class="printableColStyle">Score</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="row in scorecardRows">
                <tr class="group-separator">
                  <td v-if="showCBC" class="pr-1">
                    <template v-if="row.primary">
                      <div class="catGaugeContainer">
                        <div
                          class="catGaugeOuter"
                          :style="row.catGaugeOuterStyle"
                        >
                          <div
                            class="catGaugeInner"
                            :style="row.catGaugeInnerStyle"
                          >
                            <span class="catGaugeInfo">{{
                              row.catCallCount
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </td>
                  <td class="pl-1 pr-1 text-center">
                    <template v-if="row.primary && row.app_overrides > 0">
                      <v-tooltip
                        bottom
                        :disabled="isPrintable"
                        color="rgba(1,106,175,1)"
                        open-delay="750"
                        close-delay="500"
                        content-class="cat-stats-overlay"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="help-cursor">
                            <v-icon color="info darken-2"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </div>
                        </template>
                        <p v-if="row.applicable_manual_app > 0">
                          {{ row.applicable_manual_app }}
                          <span v-if="row.applicable_manual_app == 1"
                            >call is</span
                          ><span v-else>calls are</span> adjusted to applicable
                        </p>
                        <p v-if="row.applicable_manual_nonapp > 0">
                          {{ row.applicable_manual_nonapp }}
                          <span v-if="row.applicable_manual_nonapp == 1"
                            >call is</span
                          ><span v-else>calls are</span> adjusted to not
                          applicable
                        </p>
                      </v-tooltip>
                    </template>
                  </td>
                  <td class="category-cell">
                    <template v-if="row.primary">
                      <div>{{ ampersandReplacement(row.catName) }}</div>
                    </template>
                  </td>
                  <td v-if="showHBC" class="pr-1">
                    <div class="catGaugeContainer ">
                      <div
                        class="catGaugeOuter"
                        :style="row.subcatGaugeOuterStyle"
                      >
                        <div
                          class="catGaugeInner"
                          :style="row.subcatGaugeInnerStyle"
                        >
                          <span class="catGaugeInfo">{{
                            row.subcatHitCount
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pl-1 pr-1 text-center">
                    <v-tooltip
                      :disabled="isPrintable"
                      bottom
                      color="rgba(204,153,102,1)"
                      open-delay="750"
                      close-delay="500"
                      content-class="cat-stats-overlay"
                      v-if="row.hit_overrides > 0 || row.fail_overrides > 0"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="help-cursor">
                          <v-icon color="warning darken-2"
                            >mdi-alert-circle-outline</v-icon
                          >
                        </div>
                      </template>
                      <p v-if="row.hit_overrides > 0">
                        {{ row.hit_overrides }}
                        <span v-if="row.hit_overrides == 1">call is</span
                        ><span v-else>calls are</span> adjusted to checked
                      </p>
                      <p v-if="row.fail_overrides > 0">
                        {{ row.fail_overrides }}
                        <span v-if="row.fail_overrides == 1">call is</span
                        ><span v-else>calls are</span> adjusted to unchecked
                      </p>
                    </v-tooltip>
                  </td>
                  <td class="text-left">
                    <div>{{ ampersandReplacement(row.subcatName) }}</div>
                  </td>
                  <td
                    v-if="row.primary"
                    :class="printableCategoryScore + ' align-right'"
                  >
                    <template v-if="row.show_na"
                      >N/A</template
                    >
                    <template v-else>{{
                      formatScore(Number(row.catScore))
                    }}</template>
                  </td>
                  <td v-else>&nbsp;</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <ScheduledExportsModal
      :showScheduledExportsModal="showScheduledExportsModal"
      @HIDE_SCHEDULED_EXPORTS_MODAL="hideScheduledExportsModal"
      :isNew="true"
      :insightOrScorecard="'scorecards'"
      :pdfDelivery="pdfDelivery"
    ></ScheduledExportsModal>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import downloadPDFControl from "@/components/Controls/DownloadPDFControl.vue";
import emailControl from "@/components/Controls/EmailControl.vue";
import ScorecardLoadingStatus from "@/components/Scorecards/ScorecardLoadingStatus.vue";
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";
import ScorecardBreadcrumbs from "../Breadcrumbs/ScorecardBreadcrumbs.vue";
import selectPage from "@/mixins/selectPage";

const ALL_CALLS_EXCLUDED = "No Scorable Calls";

export default {
  mixins: [selectPage],
  components: {
    downloadPDFControl,
    emailControl,
    ScorecardLoadingStatus,
    ScheduledExportsModal,
    ScorecardBreadcrumbs
  },
  props: {
    format: String,
    agentId: Number
  },
  data() {
    return {
      showCBC: false,
      showHBC: false,
      scorecardRows: [],
      scorecardCallCount: 0,
      all_calls_excluded: ALL_CALLS_EXCLUDED,
      showScheduledExportsModal: false,
      pdfDelivery: false
    };
  },
  computed: {
    ...mapGetters("users", ["cfOnlyUser", "administratorUser", "standardUser"]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",

      "scorecardV2TeamData",
      "scorecardV2TeamDataLoadingStatus",
      "scorecardV2TeamDataLoadingError",
      "scorecardV2TeamDataLoadingErrorStatus"
    ]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    emailType() {
      return this.teamScorecard ? "team" : "agent";
    },
    emailText() {
      return this.teamScorecard ? "Scorecard" : "Agent Scorecard";
    },
    isPrintableTeam() {
      return this.format == "printable-team";
    },
    isPrintableAgent() {
      return this.format == "printable-agent";
    },
    isPrintable() {
      return (
        this.format == "printable-agent" || this.format == "printable-team"
      );
    },
    canExpandCols() {
      return !this.isPrintableTeam && !this.isPrintableAgent;
    },
    teamScorecard() {
      return this.agentId == 0;
    },
    scorecardTableType() {
      return this.isPrintableTeam ? "scorecard-table" : "";
    },
    thStyle() {
      return this.isPrintableTeam ? "printable" : "special";
    },
    printableColStyle() {
      return this.isPrintableTeam ? "printable" : "";
    },
    categoryHeader() {
      return this.isPrintableTeam ? "category" : "";
    },
    getScorecardName() {
      return this.teamScorecard
        ? this.scorecardTeamData.metrics.scorecardTitle
        : this.scorecardAgentData.scorecardTitle;
    },
    getTeamACV() {
      return !this.teamScorecard
        ? Math.round(this.scorecardAgentData.metrics.averageCallVolume)
        : Math.round(this.scorecardTeamData.metrics.averageCallVolume);
    },
    getTeamACSLastScore() {
      return !this.teamScorecard
        ? Math.round(this.scorecardAgentData.metrics.callScoreAvg * 100)
        : Math.round(this.scorecardTeamData.metrics.callScoreAvg * 100);
    },
    getScorecardSubtitle() {
      return this.teamScorecard
        ? this.buildScorecardSubtitle(this.scorecardTeamData.metrics.totalCalls)
        : this.buildScorecardSubtitle(
            this.scorecardAgentData.metrics.totalCalls
          );
    },
    getScorecardHeader() {
      return !this.teamScorecard
        ? this.scorecardAgentData.agentName + " &mdash; Agent Scorecard"
        : " Scorecard ";
    },
    scorecardTeamData() {
      return this.isPrintableTeam &&
        this.$route.name != "printable-agent-scorecard-v2"
        ? this.scorecardV2TeamData.items[0]
        : this.scorecardV2Data.items[0];
    },
    scorecardAgentData() {
      return this.scorecardV2Data.items.find(x => x.id == this.agentId);
    },
    printableTeamCallRange() {
      return this.isPrintableTeam ? "printable-team" : "";
    },
    printableCategoryScore() {
      return !this.isPrintable ? "category-score-cell" : "";
    },
    scorecardScore() {
      return !this.teamScorecard
        ? this.formatScore(this.scorecardAgentData.metrics.callScoreAvg)
        : this.formatScore(this.scorecardTeamData.metrics.callScoreAvg);
    }
  },

  methods: {
    selectPage(page) {
      this.selectScorecardPage(page, "scorecard");
    },
    buildScorecardSubtitle: function(callCount) {
      const startDate = this.formatDate(this.start_date);
      const endDate = this.formatDate(this.end_date);
      if (startDate == endDate) {
        return callCount + " calls on " + startDate;
      } else {
        return callCount + " calls from " + startDate + " to " + endDate;
      }
    },
    getURL() {
      let url =
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.$route.params.agentId +
        "/calls/printable-agent-scorecard" +
        "?start_date=" +
        moment(this.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(this.end_date).format("YYYY-MM-DD");
      url += this.$store.getters["filters/scorecardAdjustments"]
        ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
        : "all";
      return url;
    },
    getPrintableURL() {
      let printable_route =
        this.agentId == 0
          ? "printable-team-scorecard"
          : "printable-agent-scorecard";
      let url =
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.$route.params.agentId +
        "/calls/" +
        printable_route +
        "?start_date=" +
        this.$route.query.start_date +
        "&end_date=" +
        this.$route.query.end_date;
      url += this.$store.getters["filters/scorecardAdjustments"]
        ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
        : "all";
      return url;
    },
    getColSpan(col) {
      let cols = 0;
      switch (col) {
        case "headerTop":
          cols = 4;
          if (this.showCBC) cols += 1;
          if (this.showHBC) cols += 1;
          return cols;

        case "headerBottom1":
          cols = 4;
          if (this.showCBC) cols += 1;
          if (this.showHBC) cols += 1;
          return cols;

        case "headerBottom2":
          cols = 1;
          return cols;

        case "headerCBC":
          cols = 1;
          if (this.showCBC) cols += 2;
          return cols;
      }
    },
    toggleColumn(col) {
      switch (col) {
        case "cbc":
          this.showCBC = !this.showCBC;
          break;
        case "hbc":
          this.showHBC = !this.showHBC;
          break;
      }
    },
    getHead() {
      if (!this.teamScorecard) {
        return this.isPrintableTeam
          ? this.scorecardTeamData.agentName + " &mdash; Agent Scorecard"
          : this.scorecardV2Data.scorecardTitle +
              " Agent Scorecard for " +
              this.scorecardTeamData.agentName;
      } else {
        return this.isPrintableTeam
          ? this.scorecardTeamData.agentName + " &mdash; Agent Scorecard"
          : this.scorecardV2Data.scorecardTitle + " Scorecard";
      }
    },
    getSubhead() {
      const startDate = this.formatDate(this.start_date);
      const endDate = this.formatDate(this.end_date);
      //we need to access items like this since presentation-service looks at an array at the aggregate level
      let callCount = !this.teamScorecard
        ? this.scorecardAgentData.metrics.totalCalls
        : this.scorecardTeamData.metrics.totalCalls;

      if (startDate == endDate) {
        return callCount + " calls on " + startDate;
      } else {
        return callCount + " calls from " + startDate + " to " + endDate;
      }
    },
    formatDate(date) {
      return moment(date).format("MMMM D, YYYY");
    },
    formatScore(score) {
      score = score ? score : 0; // prevents NaN
      return Math.round(score * 100) + "%";
    },
    ampersandReplacement(replacement) {
      return replacement.replace(/&amp;/g, "&");
    },
    getCatGaugeOuterStyle() {
      return (
        "width: 5em;" +
        " background-color: rgba(1,106,175,.60);" +
        " border-radius: .25em;"
      );
    },
    getCatGaugeInnerStyle(catCallCount, allCallCount) {
      let width = allCallCount > 0 ? (catCallCount / allCallCount) * 5 : 0;
      return (
        "width: " +
        +Math.round(width * 100) / 100 +
        "em;" +
        " background-color: rgba(1,106,175,1);" +
        " border-radius: .25em;" +
        " padding-left: .25em;"
      );
    },
    getCatOverrideLanguage(app_overrides) {
      if (app_overrides == 1)
        return app_overrides + " call has an applicability override";
      else return app_overrides + " calls have applicability overrides";
    },
    getSubcatGaugeOuterStyle() {
      return (
        "width: 5em;" +
        " background-color: rgba(1,106,175,1);" +
        " border-radius: .25em;"
      );
    },
    getSubcatGaugeInnerStyle(subcatCallCount, catCallCount) {
      let width = catCallCount > 0 ? (subcatCallCount / catCallCount) * 5 : 0;
      return (
        "width: " +
        Math.round(width * 100) / 100 +
        "em;" +
        " background-color: rgba(204,153,102,1);" +
        " border-radius: .25em;" +
        " padding-left: .25em;"
      );
    },
    getATTms() {
      let attVal = !this.teamScorecard
        ? this.scorecardAgentData.metrics.averageDuration
        : this.scorecardTeamData.metrics.averageDuration;
      return this.formatHMS(attVal);
    },
    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    buildScorecard() {
      let lastCategoryName = "";
      let primary = false;
      if (this.scorecardV2Data.hasOwnProperty("items")) {
        let itemData = !this.teamScorecard
          ? this.scorecardAgentData
          : this.scorecardTeamData;
        if (
          itemData &&
          itemData.hasOwnProperty("metrics") &&
          itemData.metrics.hasOwnProperty("categories")
        ) {
          this.scorecardCallCount = itemData.metrics.totalCalls;
          itemData.metrics.categories.forEach(category => {
            category.sub_cats.forEach(subcategory => {
              if (lastCategoryName != category.title) {
                lastCategoryName = category.title;
                primary = true;
              } else {
                primary = false;
              }
              this.scorecardRows.push({
                primary: primary,
                catScore: category.score,
                catName: category.title,
                subcatName: subcategory.title,
                catCallCount: category.num_applicable,
                subcatCallCount: subcategory.call_count,
                subcatHitCount: subcategory.hits_per_category,
                catGaugeOuterStyle: this.getCatGaugeOuterStyle(),
                catGaugeInnerStyle: this.getCatGaugeInnerStyle(
                  category.num_applicable,
                  this.scorecardCallCount
                ),
                subcatGaugeOuterStyle: this.getSubcatGaugeOuterStyle(),
                subcatGaugeInnerStyle: this.getSubcatGaugeInnerStyle(
                  subcategory.hits_per_category,
                  category.num_applicable
                ),
                // category has manual overrides to applicability
                app_overrides: category.calls_modified,
                applicable_manual_app: category.manual_applicable_sum,
                applicable_manual_nonapp: category.manual_nonapplicable_sum,
                // subcategory has manual overrides to hits - we could do fails and hits if we wanted
                hit_overrides: subcategory.manual_pass_count,
                // subcategory has manual overrides to hits - we could do fails and hits if we wanted
                fail_overrides: subcategory.manual_fail_count,
                show_na:
                  Number(category.num_applicable) == 0 &&
                  category.num_not_applicable == category.total_calls
              });
            });
          });
        }
      }
    },
    hideScheduledExportsModal(hideScheduledExportsModal) {
      this.showScheduledExportsModal = hideScheduledExportsModal;
    },
    exportClick(item) {
      switch (item) {
        case "scheduleExportPDF":
          // pop schedule export modal to do schedule stuff
          this.pdfDelivery = true;
          this.showScheduledExportsModal = true;
          break;
      }
    }
  },
  mounted() {
    if (this.isPrintableTeam || this.isPrintableAgent) {
      this.showHBC = true;
      this.showCBC = true;
    }
    this.buildScorecard();
  }
};
</script>

<style scoped>
/* page layout commands */
@page {
  size: landscape;
  margin: 0.2in;
}

::v-deep table {
  page-break-inside: auto !important;
}
::v-deep table thead {
  display: table-header-group !important;
}
::v-deep table tfoot {
  display: table-footer-group !important;
}

::v-deep .v-breadcrumbs__item--disabled {
  color: black !important;
  font-size: 0.9rem !important;
}

/* print specific adjustments */
@media print {
  body {
    zoom: 81%;
  }

  ::v-deep table {
    page-break-inside: auto !important;
  }
  ::v-deep table thead {
    display: table-header-group !important;
  }
  ::v-deep table tfoot {
    display: table-footer-group !important;
  }
}

p {
  font-size: 0.7rem;
  text-align: center;
}

thead tr.cols th {
  font-size: 1rem;
  padding: 8px 16px;
  border-bottom: 1px solid rgb(64, 64, 64) !important;
  background-color: rgba(244, 244, 244, 1) !important;
}

::v-deep thead tr.cols th.special {
  background-color: rgba(234, 234, 234, 1) !important;
}
thead tr.hedr th h2 {
  color: rgba(25, 118, 210, 1);
}

thead tr th span {
  font-weight: 900;
}

thead tr.header-row th {
  vertical-align: top;
}

thead tr.header-row th h2 {
  font-size: 1.3rem;
  color: #1976d2;
}

.breadcrumb-head {
  font-size: 1.3rem;
  color: #1976d2;
}

thead tr.header-row th h2.printable-team {
  font-size: 1.3rem;
  color: rgba(1, 106, 175, 1);
}

thead tr.header-row.top th {
  padding-bottom: 0rem;
}

thead tr.header-row.bottom th {
  padding-top: 0rem;
}

thead tr.header-row th h2.header-score {
  text-align: right;
}

tfoot tr td {
  border-top: 1px solid rgb(64, 64, 64) !important;
  background-color: #eee;
}

th.align-right,
td.align-right {
  text-align: right;
}

.small {
  font-size: 0.8rem;
}

.stats-header {
  text-align: center;
}

.call-limit {
  position: static;
  top: 35.7rem;
  right: 46rem;
}

h5.footnote {
  margin-bottom: 0.5rem;
}
sup.footnote {
  vertical-align: baseline;
  position: relative;
  top: 0rem;
  font-size: 1rem;
  color: rgba(179, 63, 38, 1);
}
h5.footnote sup.footnote {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

div.center-control {
  display: flex;
  justify-content: center;
}

div.catGaugeContainer {
  display: block;
}
div.catGaugeOuter {
  position: relative;
}
div.catGaugeInner {
  position: relative;
}
span.catGaugeInfo {
  color: rgb(255, 255, 255, 0.95);
  mix-blend-mode: luminosity;
}

.help-cursor {
  cursor: help !important;
}

.cat-stats-overlay p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0.5rem !important;
}

td.category-cell {
  text-align: left;
  font-weight: 900;
  color: rgba(102, 102, 102, 0.9);
}

td.category-score-cell {
  font-size: 1.4rem !important;
  font-weight: 900;
  color: rgba(25, 118, 210, 0.9);
}

@media print {
  div.catGaugeOuter {
    -webkit-print-color-adjust: exact;
  }

  div.catGaugeInner {
    -webkit-print-color-adjust: exact;
  }
}

.printable-header {
  border-bottom: 1px dotted rgba(1, 68, 135, 0.33);
  margin-bottom: 8pt;
}
.printable-header div.col {
  padding-bottom: 0;
}

.scorecard-table {
  width: 100%;
  border-collapse: collapse;
}

.scorecard-table th.printable {
  background-color: rgba(3, 35, 102, 0.7) !important;
  color: rgba(255, 255, 255, 0.95);
  padding: 8pt;
  font-weight: 700;
  text-align: left;
  font-size: 11pt;
}
.scorecard-table th.category-score {
  text-align: right;
}

.scorecard-table td {
  padding: 4pt 8pt;
  font-size: 10pt;
  vertical-align: top;
}

.scorecard-table tr.foot td {
  background-color: rgba(3, 35, 102, 0.1);
  color: rgba(3, 35, 102, 0.7);
  padding: 8pt;
}

::v-deep .v-data-table__wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}

::v-deep .v-breadcrumbs__divider {
  padding-top: 0.7rem !important;
}

.pdf-delivery {
  cursor: pointer;
  margin-top: 0.45rem;
}
</style>
