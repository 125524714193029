<template>
  <v-card-text :class="'my-2 py-2 scrollable-content-task ' + injectedClass">
    <label
      v-if="editorLabel"
      :class="labelClass + ' label-font'"
    >
      {{ editorLabel }}
    </label>

    <editor-content
      :class="editorContentClass"
      :editor="editor"
    ></editor-content>

    <transition name="fade">
      <span
        v-if="!isValidFormField"
        class="invalid-form-caption caption-font "
        >{{ invalidFormCaption }}</span
      >
    </transition>
  </v-card-text>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent
  },
  props: {
    text2Edit: {
      type: String,
      required: true,
    },
    isValidFormField: {
      type: Boolean,
      required: false,
      default: true,
    },
    invalidFormCaption: {
      type: String,
      required: false,
      default: "",
    },
    placeholderText: {
      type: String,
      required: false,
      default: "",
    },
    elementFocused: {
      type: Boolean,
      required: false,
      default: false,
    },
    editorLabel: {
      type: String,
      required: false,
      default: null,
    },
    injectedClass: {
      type: String,
      required: false,
      default: "",
    }

  },
  data() {
    return {
      editor: null,
      testFoc: false
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.text2Edit,
      extensions: [
        StarterKit,
        Link,
        Placeholder.configure({
          placeholder: this.placeholderText
        })
      ],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
        // this.$emit('input', this.editor.getJSON())
      },
      onFocus: () => {
        // The editor is focused.
        this.$emit("onFocus", true);
      },
      onBlur: () => {
        // The editor isn’t focused anymore.
        this.$emit("onBlur", false);
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  computed: {
    labelClass() {
      if (
        (this.elementFocused && !this.isValidFormField) ||
        (!this.elementFocused && !this.isValidFormField)
      ) {
        return "label-error";
      } else if (this.elementFocused) {
        return "label-focused";
      } else {
        return "label-blurred";
      }
    },
    editorContentClass() {
      let defaultClass = " editor-form-default ";

      return !this.isValidFormField
        ? defaultClass + " editor-form-error "
        : defaultClass + " editor-form-valid ";
    }
  },
  watch: {
    text2Edit(text2Edit) {
      // HTML
      const isSame = this.editor.getHTML() === text2Edit;
      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(text2Edit)
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(text2Edit, false);
    }
  }
};
</script>

<style scoped>
::v-deep .tiptap {
  padding: 0.5rem !important;
}

::v-deep .invalid-form-field .tiptap {
  outline-color: red;
}

::v-deep .editor-form-valid .tiptap {
  /* vuetify primary color */
  outline-color: #1976d2;
  border: 1px solid #c2c2c2;
}

::v-deep .editor-form-error .tiptap {
  outline-color: #ff5252;
  border: 1px solid #ff5252;
}

::v-deep .editor-form-default .tiptap {
  overflow-y: auto;
  height: 12vh;
  max-height: 12vh;
  border-radius: 0.2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
}

.invalid-form-caption {
  color: #ff5252 !important;
}

.valid-form-caption {
  color: rgba(0, 0, 0, 0.54);
}

.caption-font {
  font-size: 11px !important;
}

/* Placeholder CSS */
::v-deep .ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.25);
  height: 0;
}

.label-error {
  color: #ff5252;
}

.label-focused {
  /* vuetify primary color */
  color: #1976d2;
}

.label-blurred {
  color: rgba(0, 0, 0, 0.54);
}

.label-font {
  font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
</style>
