<template>
  <v-row
    v-if="callScorecardDataV2LoadingStatus == 'loaded' && manualScoringAccess"
    class="pr-4 pl-4"
  >
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-btn
          icon
          @click="manualExclude"
          :disabled="typeOfExclusion == 1"
          :color="getIncludeIconColor(hover)"
        >
          <v-icon small>{{ getIncludeIcon(hover) }}</v-icon>
        </v-btn>
      </template> </v-hover
    >&nbsp;
    <div>
      <span class="caption">{{ getIncludeText }}</span>
    </div>

    <v-spacer></v-spacer>

    <div v-if="!applyOrRemove">
      <v-btn
        @click="changeCallOverrides"
        icon
        color="blue"
        class="ml-1 mr-0 pr-0"
      >
        <v-icon small>mdi-playlist-plus</v-icon> </v-btn
      >&nbsp;<span class="caption ml-0 pl-0 mr-3"
        >Apply Pending ({{ totalUpdates }})</span
      >
    </div>

    <div v-if="!applyOrRemove">
      <v-btn
        icon
        color="orange"
        class="ml-1 mr-0 pr-0"
        @click="cancelOverrides"
      >
        <v-icon small>mdi-playlist-minus</v-icon> </v-btn
      >&nbsp;<span class="caption ml-0 pl-0 mr-3">Remove Pending</span>
    </div>

    <div v-if="countOfCurrentOverrides > 0">
      <v-btn
        @click="deleteAppliedOverrides"
        icon
        color="red"
        class="ml-1 mr-0 pr-0"
      >
        <v-icon small>mdi-playlist-remove</v-icon> </v-btn
      >&nbsp;<span class="caption ml-0 pl-0 mr-3"
        >Remove All ({{ countOfCurrentOverrides }})</span
      >
    </div>

    <confirm ref="confirm"> </confirm>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Confirm from "@/components/Widgets/Confirm.vue";

export default {
  components: { Confirm },
  data() {
    return {
      callIncluded: true,
      buttonsDisabled: false,
      applyChange: false,
      removedOverrides: false
    };
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    excludeCall: {
      required: true,
      type: Boolean
    },
    typeOfExclusion: {
      required: false,
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", ["callScorecardDataV2LoadingStatus"]),
    ...mapGetters("users", ["manualScoringAccess"]),
 
    countOfCurrentOverrides() {
      if (this.callData.excludedCall && this.callData.exclusionType == 0) {
        return (
          this.callData.subcategoryManualOverrides +
          this.callData.categoryManualOverrides +
          1
        );
      }
      return (
        this.callData.subcategoryManualOverrides +
        this.callData.categoryManualOverrides
      );
    },
    totalUpdates() {
      let count = 0;
      this.callData.categories.forEach((category, index) => {
        if (category.updated) {
          count++;
        }
        category.sub_cats.forEach((subcategory, index) => {
          if (subcategory.updated) {
            count++;
          }
        });
      });
      if (
        this.excludeCall != this.callData.excludedCall &&
        this.typeOfExclusion != 1 &&
        this.callData.exclusionType != 1
      ) {
        count++;
      }
      return count;
    },
    allCategoriesNotApplicable() {
      return (
        this.callData.categories.filter(
          category => !this.catApplicable(category)
        ).length == this.callData.categories.length
      );
    },
    applyOrRemove() {
      return (
        (this.totalUpdates < 1 || this.buttonsDisabled) && !this.applyChange
      );
    },
    getIncludeText() {
      return !this.excludeCall ? "Call Included" : "Call Excluded";
    },
  },
  methods: {
    manualExclude() {
      //manual exclusion

      //if call is currently not excluded, it will be excluded in the following statements
      if (this.excludedCall) this.$emit("excludedTypeChange", 0);

      this.$emit("excludedCallChange", !this.excludeCall);
    },
    checkExcludedCallType() {
      //only need to check excluded call type when the overrides were not removed
      if (!this.removedOverrides) {
        if (this.allCategoriesNotApplicable) {
          this.$emit("excludedTypeChange", 1);
          this.$emit("excludedCallChange", true);
        } else {
          this.$emit("excludedTypeChange", null);
          this.$emit("excludedCallChange", false);
        }
      } else {
        this.removedOverrides = false;
        this.buttonsDisabled = false;
      }
    },
    showApplyChange() {
      // if the exclusion type is different than the one currently set in the scorecard
      // and there are some categories applicable OR exclusion is not set by flipping switch or no exclusion is set
      if (
        this.typeOfExclusion != this.callData.exclusionType &&
        (!this.allCategoriesNotApplicable ||
          (this.typeOfExclusion && this.typeOfExclusion != 0))
      ) {
        this.applyChange = true;
      } else {
        this.applyChange = false;
      }
    },
    async cancelOverrides() {
      if (
        await this.$refs.confirm.open(
          "Clear Overrides?",
          "About to clear all overrides for call. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        if (this.excludeCall != this.callData.excludedCall) {
          this.$emit("excludedCallChange", !this.excludeCall);
        }
        if (this.typeOfExclusion != this.callData.exclusionType) {
          this.$emit("excludedTypeChange", this.callData.exclusionType);
        }
        this.removedOverrides = true;
        this.applyChange = false;
        this.clearAppliedChanges();
      }
    },
    async deleteAppliedOverrides() {
      if (
        await this.$refs.confirm.open(
          "Delete Applied Overrides?",
          "About to delete all overrides for call. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        this.$emit("deleteAppliedOverrides", true);
      }
    },
    changeCallOverrides() {
      this.$emit("changeCallOverrides", true);
      this.applyChange = false;
    },
    checkExclusion() {
      //only need to update buttons when overrides werent removed - otherwise set them back to original state
      if (!this.removedOverrides) {
        if (!this.allCategoriesNotApplicable) {
          this.$emit("excludedTypeChange", this.excludeCall ? 0 : null);
        }
        this.buttonsDisabled = this.excludeCall ? true : false;
      } else {
        this.removedOverrides = false;
        this.buttonsDisabled = false;
      }
    },
    exclusionTypeChange() {
      if (this.typeOfExclusion == 0) this.clearAppliedChanges();
      this.showApplyChange();
    },
    clearAppliedChanges() {
      this.$emit("clearAppliedChanges", true);
    },
    getIncludeIcon(hover) {
      if (!this.excludeCall) {
        return hover ? "mdi-minus-box" : "mdi-plus-box";
      } else {
        return hover ? "mdi-plus-box" : "mdi-minus-box";
      }
    },
    getIncludeIconColor(hover) {
      if (!this.excludeCall) {
        return hover ? "red darken-1" : "green lighten-1";
      } else {
        return hover ? "green lighten-1" : "red darken-1";
      }
    },
   
    categoryIsManualApp(category) {
      return (
        category.manual_applicable != undefined &&
        category.manual_applicable != null
      );
    },
    categoryAppHandling(category) {
      if (this.categoryIsManualApp(category)) {
        return category.manual_applicable == 1;
      } else {
        return category.applicable == 1;
      }
    },
    catApplicable(category) {
      if (category.updated) {
        if (this.categoryIsManualApp(category)) {
          return category.applicable == 1 ? true : false;
        } else {
          return category.applicable != 1;
        }
      } else {
        if (this.categoryIsManualApp(category)) {
          return category.manual_applicable == 1;
        } else {
          return category.applicable == 1;
        }
      }
    }
  },

  mounted() {},
  watch: {
    allCategoriesNotApplicable: "checkExcludedCallType",
    excludeCall: "checkExclusion",
    typeOfExclusion: "exclusionTypeChange"
  }
};
</script>

<style scoped></style>
