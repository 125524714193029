import axios from "@/utils/AxiosInstance.js";
import CacheHandler from "@/utils/CacheHandler";

export const scorecardsV2Calls = {
  namespaced: true,
  state: {
    favoriteScorecardTab: 0,
    callScorecardDataUrl: "",
    scorecardContentLength: 0,

    callSearchHitsLoadingErrorStatus: false,
    callSearchHitsLoadingError: "",
    callSearchHitsLoadingStatus: "notLoading",
    callSearchHits: {},

    callSearchHitsTranscriptLoadingErrorStatus: false,
    callSearchHitsTranscriptLoadingError: "",
    callSearchHitsTranscriptLoadingStatus: "notLoading",
    callSearchHitsTranscript: {},

    callScorecardDataV2LoadingErrorStatus: false,
    callScorecardDataV2LoadingError: "",
    callScorecardDataV2LoadingStatus: "notLoading",
    callScorecardDataV2: {},

    allCallScorecardDataV2LoadingErrorStatus: false,
    allCallScorecardDataV2LoadingError: "",
    allCallScorecardDataV2LoadingStatus: "notloading",
    allCallScorecardDataV2: [],

    scorecardV2Data: {},
    scorecardV2DataLoadingStatus: "notLoading",
    scorecardV2DataLoadingError: "",
    scorecardV2DataLoadingErrorStatus: false,

    scorecardCallLogSkeleton: {},
    scorecardCallLogSkeletonLoadingStatus: "notLoading",
    scorecardCallLogSkeletonLoadingError: "",
    scorecardCallLogSkeletonLoadingErrorStatus: false,

    scorecardV2TeamData: {},
    scorecardV2TeamDataLoadingStatus: "notLoading",
    scorecardV2TeamDataLoadingError: "",
    scorecardV2TeamDataLoadingErrorStatus: false,

    scorecardDistributionData: {},
    scorecardDistributionDataLoadingStatus: "notLoading",
    scorecardDistributionDataLoadingError: "",
    scorecardDistributionDataLoadingErrorStatus: false,

    scorecardPrintableDistributionData: {},
    scorecardPrintableDistributionDataLoadingStatus: "notLoading",
    scorecardPrintableDistributionDataLoadingError: "",
    scorecardPrintableDistributionDataLoadingErrorStatus: false,

    scorecardTrendsData: {},
    scorecardTrendsDataLoadingStatus: "notLoading",
    scorecardTrendsDataLoadingError: "",
    scorecardTrendsDataLoadingErrorStatus: false,

    scorecardCallVolumeData: {},
    scorecardCallVolumeDataLoadingStatus: "notLoading",
    scorecardCallVolumeDataLoadingError: "",
    scorecardCallVolumeDataLoadingErrorStatus: false,

    scorecardAllAgentTrendsData: {},
    scorecardAllAgentTrendsDataLoadingStatus: "notLoading",
    scorecardAllAgentTrendsDataLoadingError: "",
    scorecardAllAgentTrendsDataLoadingErrorStatus: false,

    scorecardAllAgentCallVolumeData: {},
    scorecardAllAgentCallVolumeDataLoadingStatus: "notLoading",
    scorecardAllAgentCallVolumeDataLoadingError: "",
    scorecardAllAgentCallVolumeDataLoadingErrorStatus: false,

    scorecardCallContext: {},
    scorecardCallContextLoadingStatus: "notLoading",
    scorecardCallContextLoadingError: "",
    scorecardCallContextLoadingErrorStatus: false,

    callCategoryRetrievalError: "",

    allCallScorecardDataV2ErrorMessage:
      "There was a problem loading this scorecard...",
    callScorecardDataV2ErrorMessage:
      "There was a problem loading this call scorecard...",
    callCategoryRetrievalErrorMessage:
      "There was a problem loading categories for this call..."
  },
  getters: {
    favoriteScorecardTab: state => state.favoriteScorecardTab,
    callScorecardDataUrl: state => state.callScorecardDataUrl,
    scorecardContentLength: state => state.scorecardContentLength,

    callSearchHitsLoadingErrorStatus: state =>
      state.callSearchHitsLoadingErrorStatus,
    callSearchHitsLoadingError: state => state.callSearchHitsLoadingError,
    callSearchHitsLoadingStatus: state => state.callSearchHitsLoadingStatus,
    callSearchHits: state => state.callSearchHits,

    callSearchHitsTranscriptLoadingErrorStatus: state =>
      state.callSearchHitsTranscriptLoadingErrorStatus,
    callSearchHitsTranscriptLoadingError: state => state.callSearchHitsTranscriptLoadingError,
    callSearchHitsTranscriptLoadingStatus: state => state.callSearchHitsTranscriptLoadingStatus,
    callSearchHitsTranscript: state => state.callSearchHitsTranscript,

    scorecardCallLogSkeletonLoadingErrorStatus: state =>
      state.scorecardCallLogSkeletonLoadingErrorStatus,
    scorecardCallLogSkeletonLoadingError: state =>
      state.scorecardCallLogSkeletonLoadingError,
    scorecardCallLogSkeletonLoadingStatus: state =>
      state.scorecardCallLogSkeletonLoadingStatus,
    scorecardCallLogSkeleton: state => state.scorecardCallLogSkeleton,

    callScorecardDataV2LoadingErrorStatus: state =>
      state.callScorecardDataV2LoadingErrorStatus,
    callScorecardDataV2LoadingError: state =>
      state.callScorecardDataV2LoadingError,
    callScorecardDataV2LoadingStatus: state =>
      state.callScorecardDataV2LoadingStatus,
    callScorecardDataV2: state => state.callScorecardDataV2,

    allCallScorecardDataV2ErrorMessage: state =>
      state.allCallScorecardDataV2ErrorMessage,
    allCallScorecardDataV2LoadingErrorStatus: state =>
      state.allCallScorecardDataV2LoadingErrorStatus,
    allCallScorecardDataV2LoadingError: state =>
      state.allCallScorecardDataV2LoadingError,
    allCallScorecardDataV2LoadingStatus: state =>
      state.allCallScorecardDataV2LoadingStatus,
    allCallScorecardDataV2: state => state.allCallScorecardDataV2,

    callScorecardDataV2ErrorMessage: state =>
      state.callScorecardDataV2ErrorMessage,

    scorecardV2Data: state => state.scorecardV2Data,
    scorecardV2DataLoadingStatus: state => state.scorecardV2DataLoadingStatus,
    scorecardV2DataLoadingError: state => state.scorecardV2DataLoadingError,
    scorecardV2DataLoadingErrorStatus: state =>
      state.scorecardV2DataLoadingErrorStatus,

    scorecardV2TeamData: state => state.scorecardV2TeamData,
    scorecardV2TeamDataLoadingStatus: state =>
      state.scorecardV2TeamDataLoadingStatus,
    scorecardV2TeamDataLoadingError: state =>
      state.scorecardV2TeamDataLoadingError,
    scorecardV2TeamDataLoadingErrorStatus: state =>
      state.sscorecardV2TeamDataLoadingErrorStatus,

    scorecardDistributionData: state => state.scorecardDistributionData,
    scorecardDistributionDataLoadingStatus: state =>
      state.scorecardDistributionDataLoadingStatus,
    scorecardDistributionDataLoadingError: state =>
      state.scorecardDistributionDataLoadingError,
    scorecardDistributionDataLoadingErrorStatus: state =>
      state.scorecardDistributionDataLoadingErrorStatus,

    scorecardPrintableDistributionData: state =>
      state.scorecardPrintableDistributionData,
    scorecardPrintableDistributionDataLoadingStatus: state =>
      state.scorecardPrintableDistributionDataLoadingStatus,
    scorecardPrintableDistributionDataLoadingError: state =>
      state.scorecardPrintableDistributionDataLoadingError,
    scorecardPrintableDistributionDataLoadingErrorStatus: state =>
      state.scorecardPrintableDistributionDataLoadingErrorStatus,

    scorecardTrendsData: state => state.scorecardTrendsData,
    scorecardTrendsDataLoadingStatus: state => state.scorecardTrendsDataLoadingStatus,
    scorecardTrendsDataLoadingError: state => state.scorecardTrendsDataLoadingError,
    scorecardTrendsDataLoadingErrorStatus: state => state.scorecardTrendsDataLoadingErrorStatus,

    scorecardCallVolumeData: state => state.scorecardCallVolumeData,
    scorecardCallVolumeDataLoadingStatus: state => state.scorecardCallVolumeDataLoadingStatus,
    scorecardCallVolumeDataLoadingError: state => state.scorecardCallVolumeDataLoadingError,
    scorecardCallVolumeDataLoadingErrorStatus: state => state.scorecardCallVolumeDataLoadingErrorStatus,

    scorecardAllAgentTrendsData: state => state.scorecardAllAgentTrendsData,
    scorecardAllAgentTrendsDataLoadingStatus: state => state.scorecardAllAgentTrendsDataLoadingStatus,
    scorecardAllAgentTrendsDataLoadingError: state => state.scorecardAllAgentTrendsDataLoadingError,
    scorecardAllAgentTrendsDataLoadingErrorStatus: state => state.scorecardAllAgentTrendsDataLoadingErrorStatus,

    scorecardAllAgentCallVolumeData: state => state.scorecardAllAgentCallVolumeData,
    scorecardAllAgentCallVolumeDataLoadingStatus: state => state.scorecardAllAgentCallVolumeDataLoadingStatus,
    scorecardAllAgentCallVolumeDataLoadingError: state => state.scorecardAllAgentCallVolumeDataLoadingError,
    scorecardAllAgentCallVolumeDataLoadingErrorStatus: state => state.scorecardAllAgentCallVolumeDataLoadingErrorStatus,

    scorecardCallContextLoadingErrorStatus: state => state.scorecardCallContextLoadingErrorStatus,
    scorecardCallContextLoadingError: state => state.scorecardCallContextLoadingError,
    scorecardCallContextLoadingStatus: state => state.scorecardCallContextLoadingStatus,
    scorecardCallContext: state => state.scorecardCallContext,

  },
  mutations: {
    SET_FAVORITE_SCORECARD_TAB(state, id) {
      state.favoriteScorecardTab = id;
    },
    SET_CALL_SCORECARD_DATA_URL(state, data) {
      state.callScorecardDataUrl = data;
    },
    SET_SCORECARD_CONTENT_LENGTH(state, length) {
      state.scorecardContentLength = length;
    },

    SET_CALL_SEARCH_HITS_LOADING_ERROR_STATUS(state, status) {
      state.callSearchHitsLoadingErrorStatus = status;
    },
    SET_CALL_SEARCH_HITS_LOADING_ERROR(state, error) {
      state.callSearchHitsLoadingError = error;
    },
    SET_CALL_SEARCH_HITS_LOADING_STATUS(state, status) {
      state.callSearchHitsLoadingStatus = status;
    },
    SET_CALL_SEARCH_HITS(state, callSearchHits) {
      state.callSearchHits[callSearchHits.call_id] = Object.assign(
        {},
        callSearchHits
      );
    },
    SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR_STATUS(state, status) {
      state.callSearchHitsTranscriptLoadingErrorStatus = status;
    },
    SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR(state, error) {
      state.callSearchHitsTranscriptLoadingError = error;
    },
    SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_STATUS(state, status) {
      state.callSearchHitsTranscriptLoadingStatus = status;
    },
    SET_CALL_SEARCH_HITS_TRANSCRIPT(state, callSearchHitsTranscript) {
      state.callSearchHitsTranscript[callSearchHitsTranscript.call_id] = Object.assign(
        {},
        callSearchHitsTranscript
      );
    },

    SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS(state, status) {
      state.callScorecardDataV2LoadingErrorStatus = status;
    },
    SET_CALL_SCORECARD_DATA_LOADING_ERROR(state, error) {
      state.callScorecardDataV2LoadingError = error;
    },
    SET_CALL_SCORECARD_DATA_LOADING_STATUS(state, status) {
      state.callScorecardDataV2LoadingStatus = status;
    },
    SET_CALL_SCORECARD_DATA(state, callScorecardDataV2) {
      state.callScorecardDataV2[callScorecardDataV2.id] = Object.assign(
        {},
        callScorecardDataV2
      ); // callScorecardDataV2
    },

    SET_SCORECARD_V2_DATA(state, scorecardData) {
      state.scorecardV2Data = scorecardData;
    },
    SET_SCORECARD_V2_DATA_LOADING_STATUS(state, status) {
      state.scorecardV2DataLoadingStatus = status;
    },
    SET_SCORECARD_V2_DATA_LOADING_ERROR(state, error) {
      state.scorecardV2DataLoadingError = error;
    },
    SET_SCORECARD_V2_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardV2DataLoadingErrorStatus = status;
    },

    SET_SCORECARD_CALL_LOG_SKELETON(state, skeleton) {
      state.scorecardCallLogSkeleton = skeleton;
    },
    SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS(state, status) {
      state.scorecardCallLogSkeletonLoadingStatus = status;
    },
    SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR(state, error) {
      state.scorecardCallLogSkeletonLoadingError = error;
    },
    SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR_STATUS(state, status) {
      state.scorecardCallLogSkeletonLoadingErrorStatus = status;
    },

    SET_SCORECARD_V2_TEAM_DATA(state, scorecardV2TeamData) {
      state.scorecardV2TeamData = scorecardV2TeamData;
    },
    SET_SCORECARD_V2_TEAM_DATA_LOADING_STATUS(state, status) {
      state.scorecardV2TeamDataLoadingStatus = status;
    },
    SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR(state, error) {
      state.scorecardV2TeamDataLoadingError = error;
    },
    SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardV2TeamDataLoadingErrorStatus = status;
    },

    SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR_STATUS(state, status) {
      state.allCallScorecardDataV2LoadingErrorStatus = status;
    },
    SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR(state, error) {
      state.allCallScorecardDataV2LoadingError = error;
    },
    SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS(state, status) {
      state.allCallScorecardDataV2LoadingStatus = status;
    },
    SET_ALL_CALL_SCORECARD_DATA_V2(state, allCallScorecardDataV2) {
      state.allCallScorecardDataV2 = allCallScorecardDataV2;
    },

    SET_SCORECARD_DISTRIBUTION_DATA(state, scorecardDistributionData) {
      state.scorecardDistributionData = scorecardDistributionData;
    },
    SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS(state, status) {
      state.scorecardDistributionDataLoadingStatus = status;
    },
    SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR(state, error) {
      state.scorecardDistributionDataLoadingError = error;
    },
    SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardDistributionDataLoadingErrorStatus = status;
    },

    SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA(
      state,
      scorecardPrintableDistributionData
    ) {
      state.scorecardPrintableDistributionData = scorecardPrintableDistributionData;
    },
    SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS(state, status) {
      state.scorecardPrintableDistributionDataLoadingStatus = status;
    },
    SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR(state, error) {
      state.scorecardPrintableDistributionDataLoadingError = error;
    },
    SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS(
      state,
      status
    ) {
      state.scorecardPrintableDistributionDataLoadingErrorStatus = status;
    },

    SET_SCORECARD_TRENDS_DATA(state, scorecardTrendsData) {
      state.scorecardTrendsData = scorecardTrendsData;
    },
    SET_SCORECARD_TRENDS_DATA_LOADING_STATUS(state, status) {
      state.scorecardTrendsDataLoadingStatus = status;
    },
    SET_SCORECARD_TRENDS_DATA_LOADING_ERROR(state, error) {
      state.scorecardTrendsDataLoadingError = error;
    },
    SET_SCORECARD_TRENDS_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardTrendsDataLoadingErrorStatus = status;
    },

    SET_SCORECARD_CALL_VOLUME_DATA(state, scorecardCallVolumeData) {
      state.scorecardCallVolumeData = scorecardCallVolumeData;
    },
    SET_SCORECARD_CALL_VOLUME_DATA_LOADING_STATUS(state, status) {
      state.scorecardCallVolumeDataLoadingStatus = status;
    },
    SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR(state, error) {
      state.scorecardCallVolumeDataLoadingError = error;
    },
    SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardCallVolumeDataLoadingErrorStatus = status;
    },

    SET_SCORECARD_ALL_AGENT_TRENDS_DATA(state, scorecardAllAgentTrendsData) {
      state.scorecardAllAgentTrendsData = scorecardAllAgentTrendsData;
    },
    SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_STATUS(state, status) {
      state.scorecardAllAgentTrendsDataLoadingStatus = status;
    },
    SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR(state, error) {
      state.scorecardAllAgentTrendsDataLoadingError = error;
    },
    SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardAllAgentTrendsDataLoadingErrorStatus = status;
    },

    SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA(state, scorecardAllAgentCallVolumeData) {
      state.scorecardAllAgentCallVolumeData = scorecardAllAgentCallVolumeData;
    },
    SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_STATUS(state, status) {
      state.scorecardAllAgentCallVolumeDataLoadingStatus = status;
    },
    SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR(state, error) {
      state.scorecardAllAgentCallVolumeDataLoadingError = error;
    },
    SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR_STATUS(state, status) {
      state.scorecardAllAgentCallVolumeDataLoadingErrorStatus = status;
    },

    SET_SCORECARD_CALL_CONTEXT(state, context) {
      state.scorecardCallContext = context;
    },
    SET_SCORECARD_CALL_CONTEXT_LOADING_STATUS(state, status) {
      state.scorecardCallContextLoadingStatus = status;
    },
    SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR(state, error) {
      state.scorecardCallContextLoadingError = error;
    },
    SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR_STATUS(state, status) {
      state.scorecardCallContextLoadingErrorStatus = status;
    },

  },
  actions: {
    setCallSearchHitsTranscriptLoadingStatus(context, status) {
      context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_STATUS", status);
    },
    retrieveAllCallScorecardDataV2(context, payload) {
      context.commit(
        "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
        "loading"
      );
      context.commit("SET_ALL_CALL_SCORECARD_DATA_V2", []);

      let urlParams = "?";
      urlParams += "scorecards_editor=false";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&call_type=" + payload.call_type;
      if (payload.page) {
        urlParams += "&page=" + payload.page;
      }
      if (payload.page_size) {
        urlParams += "&page_size=" + payload.page_size;
      }

      if (payload.call_ids && Array.isArray(payload.call_ids)) {
          payload.call_ids.forEach(value => {
            urlParams += '&call_id[]=' + JSON.stringify(value)
          })
      }

      if (payload.order_by) {
        urlParams += "&order_by=" + payload.order_by;
        if (payload.cat_sort_id) {
          urlParams += "&cat_sort_id=" + payload.cat_sort_id;
        }
        if (payload.order_direction) {
          urlParams += "&order_direction=" + payload.order_direction;
        }
      }

      if (payload.search_calls) {
        urlParams += "&search_calls=" + payload.search_calls;
      }

      if (payload.get_audit_info) urlParams += '&get_audit_info=true';

      let path = payload.scorecard_id +
        "/dynalabels/" +
        payload.dynalabel_id +
        "/agents/" +
        payload.agent_id +
        "/calls" +
        urlParams;

      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + path)
        .then(response => {
          let callData = response.data.data;
          let userList = payload.users;

          callData.calls.forEach(call => {
            call.categories.forEach(category => {
              userList.forEach(user => {
                if (category.modified_by_user_id == user.userid) {
                  category.modified_by = user.firstname + " " + user.lastname;
                }
              });
            });
            call.categories = call.categories;
          });

          context.commit("SET_ALL_CALL_SCORECARD_DATA_V2", callData);
          context.commit(
            "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR", "");
          context.commit(
            "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
            "loaded"
          );
          context.commit("SET_CALL_SCORECARD_DATA_URL", process.env.VUE_APP_SCORECARDS_INTERNAL + path + "&loadall=categories");
        })
        .catch(function(error) {
          context.commit(
            "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
            "failed"
          );
          context.commit("SET_ALL_CALL_SCORECARD_DATA_V2", {});
          context.commit(
            "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_ERROR",
            context.getters.allCallScorecardDataV2ErrorMessage
          );
          context.commit("SET_CALL_SCORECARD_DATA_URL", "");
        });
    },
    retrieveCallScorecardDataV2(context, payload) {
      context.commit("SET_CALL_SCORECARD_DATA", {id:payload.call_id});
      context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loading");
      let axios_params = {
        headers: {},
        params: {
          scorecards_editor: false,
          start_date: payload.start_date,
          end_date: payload.end_date,
          call_type: payload.call_type,
        }
      }
      let axios_url = process.env.VUE_APP_SCORECARDS_V2 + "/" +
        payload.scorecard_id +
        "/dynalabels/" +
        payload.dynalabel_id +
        "/agents/" +
        payload.agent_id +
        "/calls/" +
        payload.call_id

      if(payload.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.call_id
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
        axios_params['params']['scorecard_id'] = payload.scorecard_id
        axios_params['params']['dynalabel_id'] = payload.dynalabel_id
        axios_params['params']['agent_id'] = payload.agent_id
        axios_params['params']['route'] = "call_scorecards_v2_url"
      }

      axios
        .get(axios_url, axios_params)
        .then(response => {
          let userList = payload.users;
          let callScorecard = response.data.data[0];
          let categoryManualOverrides = 0;
          let subcategoryManualOverrides = 0;

          callScorecard.loading = false;
          callScorecard.media_type = response.data.data.mediaType
          callScorecard.categories.forEach(category => {
            category.updated = false;
            if (category.manual_applicable != null) {
              userList.forEach(user => {
                if (category.modified_by == user.userid) {
                  category.username = user.firstname + " " + user.lastname;
                }
              });
              categoryManualOverrides++;
            }
            let totalSubcatWeight = 0;
            category.sub_cats.forEach(sub_cat => {
              totalSubcatWeight += sub_cat.weight;
              sub_cat.updated = false;
              if (sub_cat.manual_pass > 0 || sub_cat.manual_fail > 0) {
                userList.forEach(user => {
                  if (sub_cat.override_userid == user.userid) {
                    sub_cat.username = user.firstname + " " + user.lastname;
                  }
                });
                subcategoryManualOverrides++;
              }
            });
            category.sub_cats.sort(
              (a, b) => (a.sort_order > b.sort_order && 1) || -1
            );
            category.totalSubcatWeight = totalSubcatWeight;
          });

          callScorecard.categories = callScorecard.categories.sort(
            (a, b) => (a.sort_order > b.sort_order && 1) || -1
          );
          callScorecard.subcategoryManualOverrides = subcategoryManualOverrides;
          callScorecard.categoryManualOverrides = categoryManualOverrides;

          context.commit("SET_CALL_SCORECARD_DATA", callScorecard);
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR", "");
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function(error) {
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "failed");
          context.commit("SET_CALL_SCORECARD_DATA", {});
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SCORECARD_DATA_LOADING_ERROR",
            context.getters.callScorecardDataV2ErrorMessage
          );
        });
    },
    scoreSubCat(context, params) {
      let callScorecard = Object.assign(
        {},
        context.getters.callScorecardDataV2[params.call_id]
      );
      let category = callScorecard.categories.find(
        x => x.id == params.category_id
      );
      let subcategory = category.sub_cats.find(
        x => x.id == params.subcategory_id
      );
      subcategory.updated = !subcategory.updated;

      context.commit("SET_CALL_SCORECARD_DATA", callScorecard);
    },

    scoreCat(context, params) {
      let callScorecard = Object.assign(
        {},
        context.getters.callScorecardDataV2[params.call_id]
      );
      let category = callScorecard.categories.find(
        x => x.id == params.category_id
      );
      category.updated = !category.updated;
      context.commit("SET_CALL_SCORECARD_DATA", callScorecard);
    },

    clearOverrides(context, payload) {
      let callScorecard = Object.assign(
        {},
        context.getters.callScorecardDataV2[payload.call_id]
      );
      callScorecard.categories.forEach(category => {
        category.updated = false;
        category.sub_cats.forEach(sub_cat => {
          sub_cat.updated = false;
        });
      });
      context.commit("SET_CALL_SCORECARD_DATA", callScorecard);
    },

    removeAppliedOverrides(context, payload) {
      let params = {
        scorecard_id: payload.scorecard_id
      };
      context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loading");
      context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loading");
      context.commit(
        "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
        "loading"
      );
      context.commit(
        "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS",
        "loading"
      );
      context.commit("SET_CALL_SCORECARD_DATA", []);
      axios
        .delete(
          process.env.VUE_APP_SCORECARDS_V2_OVERRIDES + "/" + payload.call_id,
          { data: params }
        )
        .then(response => {
          payload.distributions = "team"
          context.dispatch("retrieveCallScorecardDataV2", payload);
          context.dispatch("retrieveAllCallScorecardDataV2", payload);
          context.dispatch("retrieveScorecardDistributions", payload);
          context.dispatch("retrieveCallLogSkeleton", payload);
          context.dispatch("retrieveScorecard", payload);
        })
        .catch(function(error) {
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "failed");
          context.commit("SET_CALL_SCORECARD_DATA", {});
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SCORECARD_DATA_LOADING_ERROR",
            context.getters.callScorecardDataV2ErrorMessage
          );
        });
    },
    updateCallOverrides(context, payload) {
      let callScorecard = Object.assign(
        {},
        context.getters.callScorecardDataV2[payload.call_id]
      );
      let params = {
        call_id: payload.call_id,
        scorecard_id: payload.scorecard_id,
        excluded_call: payload.excluded_call,
        exclusion_type: payload.exclusion_type,
        subcategories: [],
        categories: []
      };
      callScorecard.categories.forEach(category => {
        let override = null;
        if (category.updated) {
          if (category.manual_applicable == null) {
            if (category.applicable == 1) {
              override = 0;
            } else {
              override = 1;
            }
          }

          let cat_obj = {
            id: category.id,
            override: override
          };
          params.categories.push(cat_obj);
        }
        category.sub_cats.forEach(sub_cat => {
          if (sub_cat.updated) {
            let override = null;
            if (sub_cat.manual_pass == 0 && sub_cat.manual_fail == 0) {
              if (sub_cat.hit == 1) {
                override = 0;
              } else if (sub_cat.fail == 1) {
                override = 1;
              }
            }
            let subcat_obj = {
              id: sub_cat.id,
              override: override
            };
            params.subcategories.push(subcat_obj);
          }
        });
      });
      context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loading");
      context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loading");
      context.commit(
        "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
        "loading"
      );
      context.commit(
        "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
        "loading"
      );
      context.commit(
        "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS",
        "loading"
      );

      axios
        .post(process.env.VUE_APP_SCORECARDS_V2_OVERRIDES, params)
        .then(response => {
          payload.distributions = "team"
          context.dispatch("retrieveCallScorecardDataV2", payload);
          context.dispatch("retrieveAllCallScorecardDataV2", payload);
          context.dispatch("retrieveScorecardDistributions", payload);
          context.dispatch("retrieveCallLogSkeleton", payload);
          context.dispatch("retrieveScorecard", payload);
        })
        .catch(function(error) {
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "failed");
          context.commit("SET_CALL_SCORECARD_DATA", {});
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SCORECARD_DATA_LOADING_ERROR",
            context.getters.callScorecardDataV2ErrorMessage
          );
        });
    },
    createExcludedCall(context, payload) {
      let params = {
        scorecard_id: payload.scorecard_id,
        call_id: payload.call_id,
        exclusion_type: payload.exclusion_type
      };
      context.commit(
        "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
        "loading"
      );
      context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loading");
      context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loading");
      axios
        .post(process.env.VUE_APP_SCORECARDS_V2_EXCLUSIONS, params)
        .then(response => {
          context.dispatch("retrieveCallScorecardDataV2", payload);
          context.dispatch("retrieveAllCallScorecardDataV2", payload);
          context.dispatch("retrieveScorecard", payload);
        })
        .catch(function(error) {
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "failed");
          context.commit("SET_CALL_SCORECARD_DATA", {});
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SCORECARD_DATA_LOADING_ERROR",
            context.getters.callScorecardDataV2ErrorMessage
          );
        });
    },
    deleteExcludedCall(context, payload) {
      let params = {
        scorecard_id: payload.scorecard_id
      };
      context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "loading");
      context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loading");
      context.commit(
        "SET_ALL_CALL_SCORECARD_DATA_V2_LOADING_STATUS",
        "loading"
      );
      axios
        .delete(
          process.env.VUE_APP_SCORECARDS_V2_EXCLUSIONS + "/" + payload.call_id,
          { data: params }
        )
        .then(response => {
          context.dispatch("retrieveCallScorecardDataV2", payload);
          context.dispatch("retrieveAllCallScorecardDataV2", payload);
          context.dispatch("retrieveScorecard", payload);
        })
        .catch(function(error) {
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_STATUS", "failed");
          context.commit("SET_CALL_SCORECARD_DATA", {});
          context.commit("SET_CALL_SCORECARD_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SCORECARD_DATA_LOADING_ERROR",
            context.getters.callScorecardDataV2ErrorMessage
          );
        });
    },

    retrieveScorecard(context, payload) {
      let urlParams = "?";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&loadall=scorecard";
      urlParams += "&call_type=" + payload.call_type;

      if (payload.agent_id != 0 || payload.all_agents) {
        urlParams += "&group_by_agent=true";
      }
      context.commit("SET_SCORECARD_CONTENT_LENGTH", 0);

      context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loading");
      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
            "/" +
            payload.scorecard_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            urlParams
        )
        .then(response => {
          context.commit("SET_SCORECARD_CONTENT_LENGTH",response.headers['content-length']);
          let scorecard = response.data.data;
          if (
            scorecard.hasOwnProperty("items") &&
            scorecard.items.length > 0 &&
            scorecard.items[0].metrics.hasOwnProperty("categories")
          ) {
            scorecard.items.forEach(item => {
              item.metrics.categories = item.metrics.categories.sort(
                (a, b) => (a.sort_order > b.sort_order && 1) || -1
              );
              item.metrics.categories.forEach(category => {
                category.sub_cats = category.sub_cats.sort(
                  (a, b) => (a.sort_order > b.sort_order && 1) || -1
                );
              });
            });
          }
          //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
          context.commit("SET_SCORECARD_V2_DATA", scorecard);
          context.commit("SET_SCORECARD_V2_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_V2_DATA_LOADING_ERROR", "");
          context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_V2_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_V2_DATA", {});
          context.commit("SET_SCORECARD_V2_DATA_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_SCORECARD_V2_DATA_LOADING_ERROR",
            "retrieveScorecard()"
          );
        });
    },

    retrieveTeamScorecard(context, payload) {
      let urlParams = "?";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&loadall=scorecard";
      urlParams += "&call_type=" + payload.call_type;

      context.commit("SET_SCORECARD_V2_TEAM_DATA_LOADING_STATUS", "loading");
      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
            "/" +
            payload.scorecard_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            urlParams
        )
        .then(response => {
          let scorecard = response.data.data;
          //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
          if (
            scorecard.hasOwnProperty("items") &&
            scorecard.items.length > 0 &&
            scorecard.items[0].metrics.hasOwnProperty("categories")
          ) {
            scorecard.items.forEach(item => {
              item.metrics.categories = item.metrics.categories.sort(
                (a, b) => (a.sort_order > b.sort_order && 1) || -1
              );
              item.metrics.categories.forEach(category => {
                category.sub_cats = category.sub_cats.sort(
                  (a, b) => (a.sort_order > b.sort_order && 1) || -1
                );
              });
            });
          }
          context.commit("SET_SCORECARD_V2_TEAM_DATA", scorecard);
          context.commit(
            "SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR", "");
          context.commit("SET_SCORECARD_V2_TEAM_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_V2_TEAM_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_V2_TEAM_DATA", {});
          context.commit(
            "SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_SCORECARD_V2_TEAM_DATA_LOADING_ERROR",
            "retrieveTeamScorecard()"
          );
        });
    },

    retrieveScorecardDistributions(context, payload) {
      let urlParams = "?";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&loadall=scorecard";
      urlParams += "&call_type=" + payload.call_type;
      if (payload.distributions) {
        urlParams += "&distributions=" + payload.distributions;
      }

      context.commit(
        "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
        "loading"
      );
      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
            "/" +
            payload.scorecard_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            "/calls/" +
            urlParams
        )
        .then(response => {
          let distribution = response.data;
          context.commit("SET_SCORECARD_DISTRIBUTION_DATA", distribution);
          context.commit(
            "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR", "");
          context.commit(
            "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit(
            "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
            "failed"
          );
          context.commit("SET_SCORECARD_DISTRIBUTION_DATA", []);
          context.commit(
            "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR",
            "retrieveScorecardDistributions()"
          );
        });
    },

    retrievePrintableScorecardDistributions(context, payload) {
      let urlParams = "?";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&loadall=scorecard";
      urlParams += "&call_type=" + payload.call_type;
      if (payload.distributions) {
        urlParams += "&distributions=" + payload.distributions;
      }

      context.commit(
        "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
        "loading"
      );
      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
            "/" +
            payload.scorecard_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            "/calls/" +
            urlParams
        )
        .then(response => {
          let distribution = response.data;
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA",
            distribution
          );
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS",
            false
          );
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR",
            ""
          );
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_STATUS",
            "failed"
          );
          context.commit("SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA", {});
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_PRINTABLE_SCORECARD_DISTRIBUTION_DATA_LOADING_ERROR",
            "retrieveScorecardDistributions()"
          );
        });
    },

    setFavoriteScorecardTab(context, tab) {
      let key = "favorite_scorecard_tab";
      context.commit("SET_FAVORITE_SCORECARD_TAB", tab);
      if (CacheHandler.hasOwnProperty(key)) {
        CacheHandler.removeItem(key);
      }
      CacheHandler.setItem(key, tab);
    },
    retrieveSearchHits(context, payload) {
      //WARNING: when introducing this function in new areas you need to differentiate between demo calls and actual calls in the payload
      if(payload.transcriptHits) {
        context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_STATUS", "loading");
      } else {
        context.commit("SET_CALL_SEARCH_HITS_LOADING_STATUS", "loading");
      }
      let axios_url = process.env.VUE_APP_SEARCH_AUDIO_MARKERS
      let axios_params = {
        headers: {},
        params: {
          call_id: payload.call_id,
          search_id: payload.search_ids
        }
      }
      if(payload.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['headers'] = {"Authorization": payload.axios_params.userid + " " + payload.axios_params.reckey}
        axios_params['params']['route'] = 'searches-callmarkers'
      }
      axios
        .get(axios_url,axios_params)
        .then(response => {
          let offsets = response.data.data;
          if(payload.transcriptHits) {
            context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT", {
              call_id: payload.call_id,
              offsets: offsets,
              loading: false,
              error: false
            });
            context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR_STATUS", false);
            context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR", "");
            context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_STATUS", "loaded");
          } else {
            if(payload.transcriptHits) {
              context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT", {
                call_id: payload.call_id,
                offsets: offsets,
                loading: false,
                error: false
              });
              context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR_STATUS", false);
              context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_ERROR", "");
              context.commit("SET_CALL_SEARCH_HITS_TRANSCRIPT_LOADING_STATUS", "loaded");
            } else {
              context.commit("SET_CALL_SEARCH_HITS", {
                call_id: payload.call_id,
                offsets: offsets,
                loading: false,
                error: false
              });
              context.commit("SET_CALL_SEARCH_HITS_LOADING_ERROR_STATUS", false);
              context.commit("SET_CALL_SEARCH_HITS_LOADING_ERROR", "");
              context.commit("SET_CALL_SEARCH_HITS_LOADING_STATUS", "loaded");
            }
          }

        })
        .catch(function(error) {
          context.commit("SET_CALL_SEARCH_HITS", []);
          context.commit("SET_CALL_SEARCH_HITS_LOADING_ERROR_STATUS", true);
          context.commit(
            "SET_CALL_SEARCH_HITS_LOADING_ERROR",
            " retrieveSearchHits()"
          );
          context.commit("SET_CALL_SEARCH_HITS_LOADING_STATUS", "failed");
        });
    },

    retrieveCallLogSkeleton(context, payload) {
      context.commit(
        "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS",
        "loading"
      );
      let urlParams = "?";
      urlParams += "&start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&skeleton=true";
      urlParams += "&call_type=" + payload.call_type;
      if (payload.search_calls) {
        urlParams += "&search_calls=" + payload.search_calls;
      }
      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
            "/" +
            payload.scorecard_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            "/calls/" +
            urlParams
        )
        .then(response => {
          let skeleton = response.data;
          skeleton.categories = skeleton.categories.sort(
            (a, b) => (a.sort_order > b.sort_order && 1) || -1
          );
          context.commit("SET_SCORECARD_CALL_LOG_SKELETON", skeleton);
          context.commit(
            "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR", "");
          context.commit(
            "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_CALL_LOG_SKELETON", []);
          context.commit(
            "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_ERROR",
            " retrieveCallLogSkeleton()"
          );
          context.commit(
            "SET_SCORECARD_CALL_LOG_SKELETON_LOADING_STATUS",
            "failed"
          );
        });
    },

    retrieveScorecardTrends(context, payload) {

      context.commit(
        "SET_SCORECARD_TRENDS_DATA_LOADING_STATUS",
        "loading"
      );

      payload.grouping = payload.grouping || 'day'
      let urlParams = "?";
      urlParams += "start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&call_type=" + payload.call_type;
      if (payload.agents) {
        urlParams += '&group_by_agent=true'
        let arrayQueryString = payload.agents.map(function(id, idx) {
          return 'agents[' + idx + ']=' + id;
        }).join('&');
        urlParams += "&" + arrayQueryString;
      } else {
        urlParams += '&group_by_agent=false'
      }
      if (payload.category_id) {
        urlParams += '&category_id=' + payload.category_id
      }

      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
          "/" +
          payload.scorecard_id +
          "/dynalabels/" +
          payload.dynalabel_id +
          "/agents/" +
          payload.agent_id +
          "/" + payload.endpoint + "/" +
          urlParams
        )
        .then(response => {
          let trends = response.data;
          context.commit("SET_SCORECARD_TRENDS_DATA", trends);
          context.commit(
            "SET_SCORECARD_TRENDS_DATA_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_SCORECARD_TRENDS_DATA_LOADING_ERROR", "");
          context.commit(
            "SET_SCORECARD_TRENDS_DATA_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit(
            "SET_SCORECARD_TRENDS_DATA_LOADING_STATUS",
            "failed"
          );
          context.commit("SET_SCORECARD_TRENDS_DATA", {});
          context.commit(
            "SET_SCORECARD_TRENDS_DATA_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_SCORECARD_TRENDS_DATA_LOADING_ERROR",
            "retrieveScorecardTrends()"
          );
        });
    },

    retrieveScorecardCallVolume(context, payload) {

      context.commit(
        "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_STATUS",
        "loading"
      );

      payload.grouping = payload.grouping || 'day'
      let urlParams = "?";
      urlParams += "start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&call_type=" + payload.call_type;
      if (payload.agents) {
        urlParams += '&group_by_agent=true'
        let arrayQueryString = payload.agents.map(function(id, idx) {
          return 'agents[' + idx + ']=' + id;
        }).join('&');
        urlParams += "&" + arrayQueryString;
      } else {
        urlParams += '&group_by_agent=false'
      }
      if (payload.category_id) {
        urlParams += '&category_id=' + payload.category_id
      }

      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
          "/" +
          payload.scorecard_id +
          "/dynalabels/" +
          payload.dynalabel_id +
          "/agents/" +
          payload.agent_id +
          "/call-volume/" +
          urlParams
        )
        .then(response => {
          let callVolume = response.data;
          context.commit("SET_SCORECARD_CALL_VOLUME_DATA", callVolume);
          context.commit(
            "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR", "");
          context.commit(
            "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit(
            "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_STATUS",
            "failed"
          );
          context.commit("SET_SCORECARD_CALL_VOLUME_DATA", {});
          context.commit(
            "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_SCORECARD_CALL_VOLUME_DATA_LOADING_ERROR",
            "retrieveScorecardCallVolume()"
          );
        });
    },

    retrieveScorecardAllAgentTrends(context, payload) {

      context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_STATUS", "loading");

      payload.grouping = payload.grouping || 'day'
      let urlParams = "?";
      urlParams += "start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&call_type=" + payload.call_type;
      urlParams += '&group_by_agent=false'
      if (payload.category_id) {
        urlParams += '&category_id=' + payload.category_id
      }

      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
          "/" +
          payload.scorecard_id +
          "/dynalabels/" +
          payload.dynalabel_id +
          "/agents/0/" + payload.endpoint + "/" +
          urlParams
        )
        .then(response => {
          let trends = response.data;
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA", trends);
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR", "");
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA", {});
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR_STATUS", true);
          context.commit("SET_SCORECARD_ALL_AGENT_TRENDS_DATA_LOADING_ERROR", "retrieveScorecardAllAgentTrends()");
        });
    },

    retrieveScorecardAllAgentCallVolume(context, payload) {

      context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_STATUS", "loading");

      payload.grouping = payload.grouping || 'day'
      let urlParams = "?";
      urlParams += "start_date=" + payload.start_date;
      urlParams += "&end_date=" + payload.end_date;
      urlParams += "&grouping=" + payload.grouping;
      urlParams += "&call_type=" + payload.call_type;
      urlParams += '&group_by_agent=false'

      axios
        .get(
          process.env.VUE_APP_SCORECARDS_V2 +
          "/" +
          payload.scorecard_id +
          "/dynalabels/" +
          payload.dynalabel_id +
          "/agents/0/call-volume/" +
          urlParams
        )
        .then(response => {
          let allCallVolume = response.data;
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA", allCallVolume);
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR", "");
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA", {});
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR_STATUS", true);
          context.commit("SET_SCORECARD_ALL_AGENT_CALL_VOLUME_DATA_LOADING_ERROR", "retrieveScorecardAllAgentCallVolume()");
        });
    },

    retrieveScorecardCallContext(context, payload) {
      context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_STATUS","loading");

      let axios_url = process.env.VUE_APP_SCORECARDS_V2 + "/" +
        payload.params.scorecard_id +
        "/dynalabels/" +
        payload.params.dynalabel_id +
        "/agents/" +
        payload.params.agent_id +
        "/calls/" +
        payload.params.call_id

      if(payload.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.params.call_id
      }
      axios
        .get(
          axios_url, payload
        )
        .then(response => {
          let data = response.data.context;
          context.commit("SET_SCORECARD_CALL_CONTEXT", data);
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR_STATUS",false);
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR", "");
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_STATUS","loaded");
        })
        .catch(function(error) {
          context.commit("SET_SCORECARD_CALL_CONTEXT", {});
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR_STATUS",true);
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_ERROR"," retrieveScorecardCallContext()");
          context.commit("SET_SCORECARD_CALL_CONTEXT_LOADING_STATUS","failed");
        });
    }

  }
};
