<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn fab small dark color="primary" href="/setup-faq/3" target="_blank" rel="noopener" title="Click for Setup Help and FAQs">
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row v-for="row in chunkedScorecardDefinitionItems" :key="row.index" justify-space-between>
      <v-col v-for="scorecardDefinition in row" :key="scorecardDefinition.index" :cols="12/colCount" class="d-flex flex-column">
        <v-card class="mr-8 pa-1 setup-card flex d-flex flex-column card-outer" outlined tile>
          <v-card-title>{{scorecardDefinition.title}}<v-spacer></v-spacer>
          <v-tooltip v-if="scorecardDefinition.processing>0" max-width="30rem" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon x-small v-bind="attrs" v-on="on">
                <v-icon
                  class="processing"
                  v-text="'mdi-autorenew'"
                  small
                ></v-icon>
              </v-btn>
            </template>
            <span>{{scorecardProcessingMessage}}</span>
          </v-tooltip>
          </v-card-title>
          <v-card-subtitle style="min-height: 5vh;">{{scorecardDefinition.description}}</v-card-subtitle>
          <v-card-text style="min-height: 5vh;" class="text--primary">
            <div class="list-container">
          <div class="grey--text ms-4">
            Talk time minimum: <span v-if="scorecardDefinition.min_talktime_length!=null">{{formatHMS(scorecardDefinition.min_talktime_length)}}</span><span v-else>None</span>
          </div>
          <div class="grey--text ms-4">
            Talk time maximum:  <span v-if="scorecardDefinition.max_talktime_length!=null">{{formatHMS(scorecardDefinition.max_talktime_length)}}</span><span v-else>None</span>
          </div>
          <div class="grey--text ms-4">
            Dynamic labels ({{scorecardDefinition.dynalabels.length}} selected)
          </div>

          <div v-for="category in scorecardDefinition.categories" class="grey--text ms-4" :key="category.id">
            {{category.category_title}} ({{category.dynalabels.length}} dynamic labels selected<span v-if="category.manual_scoring==1">, Manual scoring selected</span>)
            <div v-for="sub_category in category.sub_categories" class="grey--text ms-4" :key="sub_category.id">
              {{sub_category.sub_category_title}} ({{sub_category.searches.length}} searches selected)
            </div>
          </div>
            </div>

          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="card-actions">
            <p class="scorecard-definition-info">Created: {{formatDate(scorecardDefinition.created_at)}}<br/>
            Updated: {{formatDate(scorecardDefinition.updated_at)}}<br/>
            Scorecard Definition ID: {{scorecardDefinition.id}}</p>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="openMenuAndSelectTarget('scorecardDefinitions', scorecardDefinition.id)" :disabled="scorecardDefinition.processing==2">Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import moment from "moment";

  export default {
    name: 'ScorecardDefinitionsListing',
    props: {
      scorecardsDefinitionsList: {
        type: Array,
      },
    },
    data() {
      return {
        colCount: 2,
        scorecardProcessingMessage: process.env.VUE_APP_SCORECARD_PROCESSING_MESSAGE
      }
    },
    components: {
    },
    computed: {
      chunkedScorecardDefinitionItems() {
        let chunkedScorecardDefinitionItems = [];
        let index = 0;
        while (index < this.scorecardsDefinitionsList.length) {
          let tmparr = this.scorecardsDefinitionsList.slice(index, this.colCount + index);
          chunkedScorecardDefinitionItems.push(tmparr);
          index += this.colCount;
        }
        return chunkedScorecardDefinitionItems;
      },
    },
    methods: {
      openMenuAndSelectTarget(type, id) {
        this.$emit('openMenu', type)
        this.$emit('selectTarget', { 'type':type, 'id':id })
      },
      formatHMS(seconds) {
        return moment("1900-01-01 00:00:00")
          .add(seconds, "seconds")
          .format("HH:mm:ss")
          .replace(/^0(?:0:0?)?/, "");
      },
      formatDate(time){
        return moment(time).format('l LT');
      },
    },
    watch: {
    },
    mounted() {
      this.$emit('openMenu', 'scorecardDefinitions')
    }
  }

</script>
<style scoped>

  p.scorecard-definition-info {
    font-size: .75rem;
    color: rgba(0,0,0,.33);
  }

  .list-container {
    overflow-y: scroll;
    max-height:10rem;
    padding: 8px;
    margin: 8px;
  }

</style>
