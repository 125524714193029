<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-col
        cols="11"
        class="blue--text text--darken-4 py-0 ml-1 mr-3"
        align-self="end"
      >
        {{ description }}
      </v-col>
      <v-col class="pa-0" style="text-align: right;">
        <v-btn
          fab
          small
          dark
          color="primary"
          href="/setup-faq/4"
          target="_blank" rel="noopener"
          title="Click for Setup Help and FAQs"
        >
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <span v-if="isExpanded">
        <v-col class="hintText py-0 ml-1 mr-3">
          {{ expandMessage }}
        </v-col>
      </span>
    </v-row>

    <v-row v-if="okToRender">
      <v-col :cols="this.userData.features.chat ? 4 : 6">
        <v-text-field
          id="coachingNameText"
          ref="titleField"
          v-model="coachingDefinitionInfo.title"
          outlined
          counter
          :disabled="textFieldsDisabled"
          :maxlength="maxNameLength"
          hint="Coaching hint"
          label="Coaching Definition Name"
          placeholder="Enter your coaching definition name"
          :rules="titleRules"
        ></v-text-field>
      </v-col>
      <v-col :cols="this.userData.features.chat ? 5 : 6">
        <v-text-field
          id="coachingDescriptionTxt"
          v-model="coachingDefinitionInfo.description"
          outlined
          counter
          :disabled="textFieldsDisabled"
          :maxlength="maxDescriptionLength"
          hint="(Optional) Enter a brief description to represent your coaching definition."
          label="Description"
          placeholder="Describe your coaching definition"
        ></v-text-field>
      </v-col>
      <!-- TODO: implement in future phases of coaching -->
      <!-- <v-col cols="2">
        <v-switch
          inset
          class="d-flex justify-center"
          v-model="activeStatus"
          :label="activeStatus ? 'Active' : 'Off'"
          @change="updateActiveStatus()"
        ></v-switch>
      </v-col> -->

      <v-col
        cols="2"
        class="d-flex justify-center"
        v-if="this.userData.features.chat"
      >
        <v-btn-toggle
          @change="mediaTypeChange($event)"
          v-model="coachingDefinitionInfo.media_type"
          mandatory
          :sync="true"
        >
          <v-btn value="Call Recording" :disabled="this.isPublished"
            >Call Recording</v-btn
          >
          <v-btn value="Chat" :disabled="this.isPublished">Chat</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- coaching definition -->
    <v-row v-if="okToRender">
      <v-col cols="12" class="d-flex flex-column">
        <coaching-editor-definition
          v-if="listsLoaded"
          :coachingDefinitionId="coachingDefinitionId"
          :cancelEdits="cancelAll"
          :selectedScorecards="scorecards"
          :mediaType="coachingDefinitionInfo.media_type"
          @updateScorecardSelections="updateScorecardSelections"
          @updateInfo="updateDefinition"
          @updatesCancelled="updatesCancelled"
          @setInfo="setInfo"
        >
        </coaching-editor-definition>
      </v-col>
    </v-row>

    <!-- buttons for saving, deleting, etc. - the visual layout is guided by UI/UX eye-tracking research -->
    <v-row v-if="okToRender">
      <v-col cols="12" class="d-flex">
        <v-btn
          id="publishBtn"
          color="primary"
          class="ma-2"
          :disabled="!canPublish"
          @click="publishDefinition"
          >Publish</v-btn
        >
        <v-btn
          id="cloneBtn"
          color="blue-grey"
          class="ma-2 white--text"
          :disabled="canClone"
          @click="cloneDefinition"
          >Clone</v-btn
        >
        <v-btn
          id="cancelBtn"
          color="normal"
          class="ma-2"
          :disabled="canCancel"
          @click="cancelEdits"
          >Cancel Edits</v-btn
        >
        <div class="d-inline caption mt-4 ml-4">
          Created: {{ formatDate(coachingDefinitionInfo.created_at) }} &mdash;
          Updated:
          {{ formatDate(coachingDefinitionInfo.updated_at) }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          id="deleteBtn"
          color="error"
          class="ma-2"
          :disabled="canDelete"
          @click="deleteDefinition"
          >Delete</v-btn
        >
      </v-col>
    </v-row>
    <confirm id="confirmBtn" ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import confirm from "@/components/Widgets/Confirm.vue";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import moment from "moment";
import CoachingEditorDefinition from "@/components/CoachingDefinitions/CoachingEditorDefinition.vue";

export default {
  name: "CoachingDefinitionsPage",
  props: {
    coachingDefinitionId: String,
    description: String,
    expandMessage: String
  },
  computed: {
    listsLoaded() {
      return this.coachingDefinitionInfo.scorecards != undefined;
    },
    textFieldsDisabled() {
      return (
        this.coachingDefinitionsListLoadingStatus != "loaded" ||
        this.coachingDefinitionsListItemLoadingStatus != "loaded"
      );
    },
    ...mapGetters("coachingDefinitions", [
      "coachingDefinitionsList",
      "coachingDefinitionsListLoadingStatus",
      "coachingDefinitionsListLoadingError",
      "coachingDefinitionsListLoadingErrorStatus",

      "coachingDefinitionsListItem",
      "coachingDefinitionsListItemLoadingStatus",
      "coachingDefinitionsListItemLoadingError",
      "coachingDefinitionsListItemLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userData"]),
    isDraft() {
      return (
        Boolean(
          typeof this.coachingDefinitionInfo === "object" &&
            this.coachingDefinitionInfo.hasOwnProperty("draft") &&
            this.coachingDefinitionInfo.draft
        ) || false
      );
    },
    titleFieldIsValid() {
      return this.$refs.titleField ? !this.$refs.titleField.hasError : false
    },
    okToRender() {
      return Boolean(
        typeof this.coachingDefinitionInfo === "object" &&
          this.coachingDefinitionInfo !== null
      );
    },
    canCancel() {
      return !this.isDraft;
    },
    canPublish() {
      return Boolean(
        this.isDraft &&
          this.titleFieldIsValid &&
          this.scorecardsSelected.length > 0
      );
    },
    canClone() {
      return this.isDraft;
    },
    canDelete() {
      return !this.coachingDefinitionInfo.published;
    },
    isPublished() {
      return (
        Boolean(
          typeof this.coachingDefinitionInfo === "object" &&
            this.coachingDefinitionInfo.hasOwnProperty("published") &&
            this.coachingDefinitionInfo.published
        ) || false
      );
    }
  },
  data() {
    return {
      activeStatus: true,
      isExpanded: false,
      coachingDefinitionInfo: {},
      coachingDefInfoOrig: {},
      maxNameLength: 120,
      maxDescriptionLength: 255,
      scorecardsSelected: [],
      scorecards: [],
      cancelAll: false,
      publishing: false,
      titleRules: [
        value => !!value || "A unique name is required.",
        value => {
          if (
            typeof this.coachingDefinitionInfo === "object" &&
            this.coachingDefinitionInfo.title != undefined
          ) {
            return (
              !this.coachingDefinitionsList.some(item => {
                return (
                  item.id != this.coachingDefinitionId &&
                  item.title.trim().toUpperCase() == value.trim().toUpperCase()
                );
              }) || "Names must be unique."
            );
          } else return "";
        }
      ],
      isMounted: false
    };
  },
  components: {
    confirm,
    LoadingStatusBox,
    CoachingEditorDefinition
  },
  methods: {
    ...mapActions({
      retrieveCoachingDefinitionsList:
        "coachingDefinitions/retrieveCoachingDefinitionsList",
      deleteCoachingDefinition: "coachingDefinitions/deleteCoachingDefinition",

      publishCoachingDefinition:
        "coachingDefinitions/publishCoachingDefinition",
      cloneCoachingDefinition: "coachingDefinitions/cloneCoachingDefinition",
      updateCoachingDefinition: "coachingDefinitions/updateCoachingDefinition",
      setCoachingListLoadingStatus:
        "coachingDefinitions/setCoachingListLoadingStatus",
      cancelCoachingDefinitionEdits:
        "coachingDefinitions/cancelCoachingDefinitionEdits"
    }),
    updateActiveStatus() {
      this.coachingDefinitionInfo.status = this.activeStatus;
    },
    updateScorecardSelections(scorecards) {
      this.scorecardsSelected = scorecards.map(scorecard => ({
        scorecard_id: scorecard.id
      }));

      let updatedScorecards = this.scorecardsSelected
        .map(scorecard => scorecard.scorecard_id)
        .sort();
      let currentScorecards = this.coachingDefinitionInfo.scorecards
        .map(a => a.scorecard_id)
        .sort();

      let scorecardCheck = this.checkDifference(
        updatedScorecards,
        currentScorecards
      );

      if (!scorecardCheck) {
        this.coachingDefinitionInfo.scorecards = this.scorecardsSelected;
        this.updateDefinition();
      }
    },
    checkDifference(selected, current) {
      return (
        selected.length === current.length &&
        selected.every((v, i) => v === current[i])
      );
    },
    toggleHints() {
      this.isExpanded = !this.isExpanded;
    },
    formatDate(time) {
      return moment(time).format("l LT");
    },
    setScorecards() {
      this.setInfo();
      this.scorecards = (this.coachingDefinitionInfo && this.coachingDefinitionInfo.hasOwnProperty("scorecards")) ? this.coachingDefinitionInfo.scorecards : [];
    },
    setInfo() {
      
      this.coachingDefinitionInfo = this.coachingDefinitionsList.find(
        coachingDefinition => {
          return (
            String(coachingDefinition.id) == String(this.coachingDefinitionId)
          );
        }
      );
      this.activeStatus = (this.coachingDefinitionInfo && this.coachingDefinitionInfo.hasOwnProperty("status")) ? Boolean(this.coachingDefinitionInfo.status) : 1;
    },
    updatesCancelled() {
      this.cancelAll = false;
    },
    publishDefinition() {
      let params = this.coachingDefinitionInfo;
      //TODO: remove in future phases of coaching
      params.status = 1
      params.scorecards = this.scorecardsSelected;

      this.publishCoachingDefinition(params);
      this.setCoachingListLoadingStatus("loading");
      this.publishing = true;
    },
    cloneDefinition() {
      let id = Date.now();
      let params = {
        id: id,
        user_id: this.userData.id,
        definition: this.coachingDefinitionInfo
      };
      this.cloneCoachingDefinition(params);
      this.$emit("selectTarget", { type: "coachingDefinitions", id: id });
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel edits and/or remove draft. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        // yes
        this.cancelAll = true;
        let published = this.coachingDefinitionInfo.published;
        let id = this.coachingDefinitionInfo.id;
        this.cancelCoachingDefinitionEdits(id);
        if (!published) {
          this.$router
            .push({ path: "/setup/coaching-definitions/" })
            .catch(err => {});
          setTimeout(function() {
            if (this.$vuetify) this.$vuetify.goTo(0);
          }, 1500);
        } else {
          this.publishing = false;
          this.retrieveCoachingDefinitionsList();
          this.$emit("openMenu", "coachingDefinitions");
          this.$emit("selectTarget", { type: "coachingDefinitions", id: id });
        }
      }
    },
    updateDefinition() {
      if (this.coachingDefinitionInfo) {
      this.updateCoachingDefinition(this.coachingDefinitionInfo);
      }
    },
    checkForChanges() {
      if (!this.publishing) {
        if (
          !(
            JSON.stringify(this.coachingDefinitionInfo) ==
            JSON.stringify(this.coachingDefInfoOrig)
          )
        ) {
          this.updateDefinition();
        }
      }
    },
    checkForChangesInData() {
      if (this.coachingDefinitionInfo.title != undefined) {
        if (
          this.coachingDefinitionInfo.title !=
            this.coachingDefinitionsListItem.title ||
          this.coachingDefinitionInfo.description !=
            this.coachingDefinitionsListItem.description
        ) {
          this.coachingDefinitionInfo = Object.assign(
            {},
            this.coachingDefinitionsListItem
          );
        }
      }
    },
    async deleteDefinition() {
      if (
        await this.$refs.confirm.open(
          "Delete this coaching definition",
          "This action cannot be undone. Are you sure you want to delete?",
          { color: "red" }
        )
      ) {
        this.deleteCoachingDefinition(this.coachingDefinitionInfo.id);
        this.$router
          .push({ path: "/setup/coaching-definitions" })
          .catch(err => {});
        setTimeout(function() {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      }
    },
    async mediaTypeChange(event) {
      if (this.scorecards.length > 0) {
        if (
          await this.$refs.confirm.open(
            "Change Media Type?",
            "You are about to change media type. This will deselect all scorecards. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          this.scorecards = [];
        } else {
          this.coachingDefinitionInfo.media_type =
            event == "Chat" ? "Call Recording" : "Chat";
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    coachingDefinitionsList: "setInfo",
    coachingDefinitionId: "setScorecards"
  },
  beforeUpdate() {
    this.checkForChanges();
  },
  updated() {
    this.publishing = false;
    this.setInfo();
  },
  mounted() {
    this.isMounted = true;
    this.checkForChangesInData();
    this.setInfo();
    if (typeof this.coachingDefinitionInfo === "object") {
      this.coachingDefInfoOrig = JSON.parse(
        JSON.stringify(this.coachingDefinitionInfo)
      );
    }

    this.setScorecards();
    this.$emit("openMenu", "coachingDefinitions");
    this.$emit("selectTarget", {
      type: "coachingDefinitions",
      id: this.coachingDefinitionId,
      preventProp: true
    });
  }
};
</script>

<style scoped>
sup.footnote {
  vertical-align: baseline;
  position: relative;
  top: 0rem;
  font-size: 1rem;
  color: rgba(179, 63, 38, 1);
}

h5.footnote sup.footnote {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
}
.hintText {
  color: rgb(25, 118, 210);
  font-size: 0.875rem;
  line-height: 1.5rem !important;
  padding-left: 1.3rem !important;
}
</style>
