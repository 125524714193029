<template>
  <div>
    <v-app-bar dense dark color="primary" class="header-radius">
      <v-toolbar-title class="component-title white--text pl-0">
        <v-btn v-if="componentIsAudio" dark icon @click="shouldShowAudio()">
          <v-icon v-show="showAudio" class="pr-0 mr-0">mdi-chevron-down</v-icon>
          <v-icon v-show="!showAudio" class="pr-0 mr-0"
            >mdi-chevron-right</v-icon
          >
        </v-btn>
        <v-icon v-else small>{{ headerIcon }}</v-icon>
        {{ componentHeader }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div
        v-if="component == componentTypes.scorecard"
        class="component-title score-title white--text pl-0"
      >
        {{ formatScore(callData.callScore) }}
      </div>

      <v-spacer></v-spacer>

      <v-btn v-if="!componentIsAudio" dark icon @click="toggleCols()">
        <v-icon class="pr-0 mr-0">{{ expandIcon }}</v-icon>
      </v-btn>

      <v-btn dark icon v-if="actionMenuExists">
        <v-menu
          v-model="componentMenu"
          dense
          open-on-hover
          bottom
          offset-y
          nudge-right="0"
          class="compact-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" dark>
              <v-icon class="px-0 mx-0">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item v-if="canEmailComponent" @click="emailComponent">
              <v-list-item-icon>
                <email-control
                  ref="emailControl"
                  :agentId="callData.agentId"
                  :url="emailUrl"
                  :printable_url="printableCallScorecardUrl"
                  :feature="emailFeature"
                  :dialog_title="emailDialog"
                  :reporttype="emailReportType"
                  :scorecardId="String($route.params.scorecardId)"
                  :callId="String(callId)"
                  :start_date="$route.query.start_date"
                  :end_date="$route.query.end_date"
                  :icon_position="''"
                  :type="emailType"
                  :displaySoup="true"
                  :showEmail="emailWindow"
                  :selfSignedTranscript="selfSigned"
                  :customReplaceUrl="emailSignedUrl"
                  @hideEmail="hideEmail"
                ></email-control>
              </v-list-item-icon>
              <v-list-item-title>Email {{ controlText }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="canDownloadComponent"
              @click="downloadComponent"
              :disabled="downloadDisabled"
              :title="downloadTitle"
            >
              <v-list-item-icon>
                <v-icon :disabled="downloadDisabled">{{ downloadIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Download {{ controlText }}</v-list-item-title>
            </v-list-item>

            <!-- special snapshot handling... -->
            <div v-if="showTask">
              <a
                v-if="closedTaskExists && taskSnapshot.snapshot_url"
                :href="taskSnapshot.snapshot_url"
              >
                <v-list-item :title="'Download Snapshot...'">
                  <v-list-item-icon>
                    <v-icon>{{ downloadIcon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Download Task Snapshot</v-list-item-title>
                </v-list-item>
              </a>
              <v-list-item
                v-else-if="closedTaskExists && !taskSnapshot.snapshot_url"
                disabled
              >
                <v-list-item-icon>
                  <v-icon disabled>{{ downloadIcon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Task Snapshot Generating</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-btn>
    </v-app-bar>

    <transcript-download
      ref="transcriptDownload"
      :callId="String(determineDemoCallId)"
      :usingInsights="hasInsightAccess"
      :accountName="callData.accountName"
      :agent="callData.agentName"
      :callerNumber="callData.dns"
      :accountNumber="callData.accountId"
      :callDirection="callData.direction"
      :callDate="callData.adjustedDateTime"
      :displaySoup="true"
      :showIcon="false"
      @transcriptDownloadSetter="transcriptDownloadSetter"
    >
    </transcript-download>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DemoHandler from "@/utils/DemoHandler";
import EmailControl from "@/components/Controls/EmailControl.vue";
import TranscriptDownload from "@/components/Controls/TranscriptDownload.vue";
import callScorecardHelpers from "@/mixins/callScorecardHelpers";
import moment from "moment";

export default {
  mixins: [callScorecardHelpers],
  components: { TranscriptDownload, EmailControl },
  data() {
    return {
      componentMenu: false,
      showAudio: true,
      downloadProcessing: false,
      emailWindow: false,
      downloadType: "",
      componentTypes: {
        transcript: "transcript",
        scorecard: "scorecard",
        task: "task",
        audio: "audio recording"
      }
    };
  },
  props: {
    component: {
      required: true,
      type: String
    },
    callData: {
      required: true,
      type: Object
    },
    callId: {
      required: true,
      type: Number
    },
    expandIcon: {
      required: false,
      type: String,
      default: "mdi-unfold-more-vertical"
    },
    audioExpanded: {
      type: Boolean,
      default: true
    },
    closedTaskExists: {
      required: false,
      default: false,
      type: Boolean
    },
    selfSigned: {
      required: false,
      default: false,
      type: Boolean
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    ...mapGetters("transcript", [
      "callTranscript",
      "callTranscriptLoadingStatus",
      "callTranscriptLoadingError",
      "callTranscriptLoadingErrorStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "taskSnapshot",
      "taskSnapshotLoadingStatus"
    ]),
    ...mapGetters("reckeys", [
      "callReckey",
      "callReckeyLoadingStatus",
      "callReckeyLoadingError",
      "callReckeyLoadingErrorStatus",

      // will eventually be used in the task email url
      "agentReckey",
      "agentReckeyLoadingStatus",
      "agentReckeyLoadingError",
      "agentReckeyLoadingErrorStatus"
    ]),
    actionMenuExists() {
      return (
        (this.componentHasActions && !this.selfSigned) ||
        (this.componentHasActions && this.selfSigned && this.closedTaskExists)
      );
    },
    hasInsightAccess() {
      return this.userData.features.insights;
    },
    downloadTitle() {
      return this.downloadDisabled
        ? "Already in queue..."
        : "Add to export queue...";
    },
    controlText() {
      let headerText = this.component.split(" ").reverse()[0];
      return headerText.charAt(0).toUpperCase() + headerText.slice(1);
    },
    componentHeader() {
      return this.component.charAt(0).toUpperCase() + this.component.slice(1);
    },
    headerIcon() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return "mdi-message-text";
        case this.componentTypes.scorecard:
          return "mdi-order-bool-ascending-variant";
        default:
          return "mdi-clipboard-outline";
      }
    },
    componentIsAudio() {
      return this.component == this.componentTypes.audio;
    },
    transcriptExists() {
      return (
        this.callTranscript.analyses && this.callTranscript.analyses.length > 0
      );
    },
    downloadIcon() {
      return "mdi-tray-arrow-down";
    },
    downloadDisabled() {
      return (
        this.downloadProcessing &&
        (this.downloadType == this.component ||
          (this.downloadType == this.componentTypes.transcript &&
            this.component == this.componentTypes.audio))
      );
    },
    emailFeature() {
      //email control features takes feature with "s" on the end...
      return this.component + "s";
    },
    emailDialog() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return "Transcript Link";
        case this.componentTypes.scorecard:
          return "Call Scorecard";
        case this.componentTypes.audio:
          return "Audio Recording";
        default:
          return "Transcript Link";
      }
    },
    emailReportType() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return "transcript-audio";
        case this.componentTypes.scorecard:
          return "call-scorecard";
        default:
          return "transcript-audio";
      }
    },
    emailType() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return "transcript";
        case this.componentTypes.scorecard:
          return "call";
        default:
          return "transcript";
      }
    },
    emailUrl() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return this.transcriptUrl;
        case this.componentTypes.scorecard:
          return this.callScorecardUrl;
        default:
          return this.transcriptUrl;
      }
    },
    emailSignedUrl() {
      switch (this.component) {
        case this.componentTypes.transcript:
          return this.transcriptUrlSigned;
        case this.componentTypes.scorecard:
          return this.callScorecardUrlSigned;
        default:
          return this.transcriptUrlSigned;
      }
    },
    determineDemoCallId() {
      return DemoHandler.determineDemoCall(this.callId);
    },
    //TODO: move string urls to mixin...
    callScorecardUrlSigned() {
      let filterString = this.$store.getters["filters/scorecardAdjustments"]
        ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
        : "all";

      return this.callReckeyLoadingStatus == "loaded"
        ? "/scorecards-v2-signed/" +
            this.$route.params.scorecardId +
            "/dynalabels/" +
            this.$route.params.dynalabelId +
            "/agents/" +
            this.$route.params.agentId +
            "/calls/" +
            this.callId +
            "/printable-call-scorecard/" +
            this.callReckey +
            "/" +
            this.userData.id +
            "?start_date=" +
            moment(this.$route.query.start_date).format("YYYY-MM-DD") +
            "&end_date=" +
            moment(this.$route.query.end_date).format("YYYY-MM-DD") +
            filterString
        : null;
    },
    printableCallScorecardUrl() {
      let filterString = this.$store.getters["filters/scorecardAdjustments"]
        ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
        : "all";

      return (
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.callData.agentId +
        "/calls/" +
        this.callId +
        "/printable-call-scorecard?start_date=" +
        this.$route.query.start_date +
        "&end_date=" +
        this.$route.query.end_date +
        "#call-scorecard-" +
        this.callId +
        "?start_date=" +
        moment(this.$route.query.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(this.$route.query.end_date).format("YYYY-MM-DD") +
        filterString
      );
    },
    callScorecardUrl() {
      return (
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.callData.agentId +
        "/calls/?start_date=" +
        this.$route.query.start_date +
        "&end_date=" +
        this.$route.query.end_date +
        "#call-scorecard-" +
        this.callId +
        "?start_date=" +
        moment(this.$route.query.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(this.$route.query.end_date).format("YYYY-MM-DD")
      );
    },
    transcriptUrlContext() {
      return (
        "?context=scorecards&context_dynalabel_id=" +
        this.$route.params.dynalabelId +
        "&context_agent_id=" +
        this.$route.params.agentId +
        "&context_id=" +
        this.$route.params.scorecardId +
        "&start_date=" +
        this.$route.query.start_date +
        "&end_date=" +
        this.$route.query.end_date
      );
    },
    transcriptUrlSigned() {
      return this.callReckeyLoadingStatus == "loaded"
        ? "/transcripts-signed/" +
            this.callId +
            "/" +
            this.callReckey +
            "/" +
            this.userData.id +
            this.transcriptUrlContext
        : null;
    },
    transcriptUrl() {
      return "/transcripts/" + this.callId + this.transcriptUrlContext;
    },
    componentHasActions() {
      //task does not have email/download buttons yet...

      //if transcript does not exist, should not be able to download/email it...
      if (
        this.component == this.componentTypes.transcript &&
        !this.transcriptExists
      )
        return false;

      return this.component != this.componentTypes.task;
    },
    canEmailComponent() {
      return (
        !this.selfSigned &&
        (this.component == this.componentTypes.scorecard ||
          this.component == this.componentTypes.transcript ||
          this.component == this.componentTypes.audio)
      );
    },
    canDownloadComponent() {
      if (this.selfSigned) return false;
      //can currently download all components except task and scorecard...
      if (
        this.component == this.componentTypes.transcript ||
        this.component == this.componentTypes.audio
      ) {
        return (
          this.transcriptExists &&
          this.userDataLoadingStatus == "loaded" &&
          this.userData.settings.transcriptExports
        );
      }
      return (
        this.component != this.componentTypes.task &&
        this.component != this.componentTypes.scorecard
      );
    }
  },
  methods: {
    ...mapActions({}),
    toggleCols() {
      this.$emit("toggleCols", this.component);
    },
    emailComponent() {
      //allows for opening a v-dialog inside of a v-menu...
      setTimeout(() => (this.emailWindow = true));
    },
    downloadComponent() {
      switch (this.component) {
        case this.componentTypes.audio:
        case this.componentTypes.transcript:
          this.$refs.transcriptDownload.exportTranscript();
          break;
      }
    },
    shouldShowAudio() {
      this.showAudio = !this.showAudio;
      this.$emit("shouldShowAudio", this.showAudio);
    },
    transcriptDownloadSetter(processing) {
      this.downloadProcessing = processing;
      this.downloadType = this.componentTypes.transcript;
    },
    hideEmail(shouldHide) {
      this.emailWindow = shouldHide;
    },
    checkExpandFromRoot() {
      //if the user trigger "playFromPosition" at the transcript phrase level, we need to make sure the icons here are set correctly when the audio is automatically opened...
      if (this.audioExpanded && !this.showAudio) {
        this.showAudio = this.audioExpanded;
      }
    }
  },
  mounted() {},
  watch: {
    audioExpanded: "checkExpandFromRoot"
  }
};
</script>
<style scoped>
.component-title {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2.667;
  letter-spacing: 0.1666666667em;
  text-transform: uppercase;
}

.score-title {
  font-size: 1.5rem;
  letter-spacing: .05rem;
  font-weight: 900;
}

.header-radius {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

a {
  text-decoration: none;
}
</style>
