<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn fab small dark color="primary" href="/setup-faq/0" target="_blank" rel="noopener" title="Click for Setup Help and FAQs">
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row v-for="row in chunkedDynaLabelItems" :key="row.index" justify-space-between>
      <v-col v-for="dynaLabel in row" :key="dynaLabel.index" :cols="12/colCount" class="d-flex flex-column">
        <v-card class="mr-8 pa-1 setup-card flex d-flex flex-column card-outer" outlined tile>
          <v-card-title>{{dynaLabel.title}}</v-card-title>
          <v-card-text style="min-height: 5vh;">{{dynaLabel.description}}</v-card-text>
          <v-card-actions class="card-actions">
            <p class="dyna-label-info">Created: {{formatDate(dynaLabel.created_at)}}<br/>
            Updated: {{formatDate(dynaLabel.updated_at)}}<br/>
            Dynamic Label ID: {{dynaLabel.id}}</p>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="openMenuAndSelectTarget('dynaLabels', dynaLabel.id)">Edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

  import moment from "moment";

  export default {
    name: 'DynaLabelsListing',
    props: {
      dynaLabelsList: {
        type: Array,
      },
    },
    data() {
      return {
        colCount: 2,
      }
    },
    components: {
    },
    computed: {
      chunkedDynaLabelItems() {
        let chunkedDynaLabelItems = [];
        let index = 0;
        while (index < this.dynaLabelsList.length) {
          let tmparr = this.dynaLabelsList.slice(index, this.colCount + index);
          chunkedDynaLabelItems.push(tmparr);
          index += this.colCount;
        }
        return chunkedDynaLabelItems;
      },
    },
    methods: {
      formatDate(time){
        return moment(time).format('l LT');
      },
      openMenuAndSelectTarget(type, id) {
        this.$emit('openMenu', type)
        this.$emit('selectTarget', { 'type':type, 'id':id })
      }
    },
    watch: {
    },
    mounted() {
      this.$emit('openMenu', 'dynaLabels')
    }
  }

</script>

<style scoped>

  p.dyna-label-info {
    font-size: .75rem;
    color: rgba(0,0,0,.33);
  }

</style>
