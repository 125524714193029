<template>
  <fieldset v-if="isMounted">
    <v-form v-model="validTitle">
      <v-text-field
        class="pr-4 pl-4 pt-4 textarea-font-size"
        counter
        dense
        v-model="editorTitle"
        :maxlength="250"
        :rules="titleRules"
        label="Session Title"
        hint="Type Session Title"
        placeholder="Enter your session title..."
      ></v-text-field>
    </v-form>
    <text-editor
      :text2Edit="editorNote"
      :isValidFormField="editorNoteIsValid()"
      :invalidFormCaption="'A note is required'"
      :placeholderText="'Type a session note...'"
      :elementFocused="noteFocused"
      :editorLabel="'Session Note'"
      @input="updateNote"
      @onFocus="updateNoteFocus"
      @onBlur="updateNoteFocus"
    ></text-editor>
    <text-editor
      :text2Edit="editorTodos ? editorTodos : ''"
      :placeholderText="'Type session to dos...'"
      :elementFocused="todosFocused"
      :editorLabel="'Session To Dos'"
      @input="updateTodos"
      @onFocus="updateTodoFocus"
      @onBlur="updateTodoFocus"
    ></text-editor>
  </fieldset>
</template>

<script>
import { mapActions } from "vuex";
import TextEditor from "../../Tasks/TextEditor.vue";
export default {
  mixins: [],
  components: { TextEditor },
  props: {
    title: {
      type: String,
      required: true
    },
    note: {
      type: String,
      default: ""
    },
    todos: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    editorTitle: "",
    editorNote: "",
    editorTodos: "",
    validTitle: false,
    validNote: false,
    titleRules: [value => !!value || "A title is required."],
    noteRules: [value => !!value || "A note is required."],
    noteFocused: false,
    todosFocused: false,
    isMounted: false
  }),
  computed: {},
  methods: {
    ...mapActions({
      updateGuidanceSession: "guidance/updateGuidanceSession"
    }),
    sessionValid() {
      this.$emit("sessionValid", this.validTitle && this.editorNoteIsValid());
    },
    updateSession() {
      this.updateGuidanceSession({
        id: this.id,
        title: this.editorTitle.trim(),
        note: this.editorNote.trim(),
        //replace empty p tags
        to_dos: this.editorTodos
          ? this.editorTodos.trim().replace(/<p>\s*<\/p>/gi, "")
          : this.editorTodos
      });
    },
    updateNote(noteInput) {
      this.editorNote = noteInput;
      this.updateSession();
    },
    updateTodos(todoInput) {
      this.editorTodos = todoInput;
      this.updateSession();
    },
    removeHtmlTags(htmlString) {
      var html = htmlString;
      var div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    },
    editorNoteIsValid() {
      return this.removeHtmlTags(this.editorNote).trim().length > 0;
    },
    updateNoteFocus(focused) {
      this.noteFocused = focused;
    },
    updateTodoFocus(focused) {
      this.todosFocused = focused;
    }
  },
  mounted() {
    this.editorTitle = this.title;
    this.editorNote = this.note;
    this.editorTodos = this.todos;
    this.isMounted = true;
  },
  watch: {
    editorTitle: "updateSession",
    validTitle: "sessionValid",
    editorNote: "sessionValid"
  }
};
</script>

<style scoped>
.textarea-font-size {
  font-size: 0.8rem;
}
</style>
