import axios from "@/utils/AxiosInstance.js";
import { store } from "@/store/index.js";

export const resources = {
  namespaced: true,
  state: {

    accountData: [],
    accountDataLoadingStatus: 'notLoading',
    accountDataLoadingError: '',
    accountDataLoadingErrorStatus: false,

    accountHorizon: process.env.VUE_APP_DEFAULT_HORIZON,
    accountHorizonLoadingStatus: 'notLoading',
    accountHorizonLoadingError: '',
    accountHorizonLoadingErrorStatus: false,

    agentData: [],
    agentDataLoadingStatus: 'notLoading',
    agentDataLoadingError: '',
    agentDataLoadingErrorStatus: false,

    searchData: [],
    searchDataLoadingStatus: 'notLoading',
    searchDataLoadingError: '',
    searchDataLoadingErrorStatus: false,

    agentsOnTeamData: [],
    agentsOnTeamDataLoadingStatus: 'notLoading',
    agentsOnTeamDataLoadingError: '',
    agentsOnTeamDataLoadingErrorStatus: false,

    teamData: [],
    teamDataLoadingStatus: 'notLoading',
    teamDataLoadingError: '',
    teamDataLoadingErrorStatus: false,

    updateAgentEmailError: '',
    updateAgentEmailErrorStatus: false,

  },
  getters: {

    accountData: state => state.accountData,
    accountDataLoadingStatus: state => state.accountDataLoadingStatus,
    accountDataLoadingError: state => state.accountDataLoadingError,
    accountDataLoadingErrorStatus: state => state.accountDataLoadingErrorStatus,

    accountHorizon: state => state.accountHorizon,
    accountHorizonLoadingStatus: state => state.accountHorizonLoadingStatus,
    accountHorizonLoadingError: state => state.accountHorizonLoadingError,
    accountHorizonLoadingErrorStatus: state => state.accountHorizonLoadingErrorStatus,

    agentData: state => state.agentData,
    agentDataLoadingStatus: state => state.agentDataLoadingStatus,
    agentDataLoadingError: state => state.agentDataLoadingError,
    agentDataLoadingErrorStatus: state => state.agentDataLoadingErrorStatus,

    searchData: state => state.searchData,
    searchDataLoadingStatus: state => state.searchDataLoadingStatus,
    searchDataLoadingError: state => state.searchDataLoadingError,
    searchDataLoadingErrorStatus: state => state.searchDataLoadingErrorStatus,

    teamData: state => state.teamData,
    teamDataLoadingStatus: state => state.teamDataLoadingStatus,
    teamDataLoadingError: state => state.teamDataLoadingError,
    teamDataLoadingErrorStatus: state => state.teamDataLoadingErrorStatus,

    agentsOnTeamData: state => state.agentsOnTeamData,
    agentsOnTeamDataLoadingStatus: state => state.agentsOnTeamDataLoadingStatus,
    agentsOnTeamDataLoadingError: state => state.agentsOnTeamDataLoadingError,
    agentsOnTeamDataLoadingErrorStatus: state => state.agentsOnTeamDataLoadingErrorStatus,

    updateAgentEmailError: state => state.updateAgentEmailError,
    updateAgentEmailErrorStatus: state => state.updateAgentEmailErrorStatus,


  },
  mutations: {

    SET_ACCOUNT_DATA(state, accountData) {
      state.accountData = accountData
    },
    SET_ACCOUNT_DATA_LOADING_STATUS(state, status) {
      state.accountDataLoadingStatus = status
    },
    SET_ACCOUNT_DATA_LOADING_ERROR(state, error) {
      state.accountDataLoadingError = error
    },
    SET_ACCOUNT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.accountDataLoadingErrorStatus = status
    },

    SET_ACCOUNT_HORIZON(state, accountHorizon) {
      state.accountHorizon = accountHorizon
    },
    SET_ACCOUNT_HORIZON_LOADING_STATUS(state, status) {
      state.accountHorizonLoadingStatus = status
    },
    SET_ACCOUNT_HORIZON_LOADING_ERROR(state, error) {
      state.accountHorizonLoadingError = error
    },
    SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS(state, status) {
      state.accountHorizonLoadingErrorStatus = status
    },

    SET_AGENT_DATA(state, agentData) {
      state.agentData = agentData
    },
    SET_AGENT_DATA_LOADING_STATUS(state, status) {
      state.agentDataLoadingStatus = status
    },
    SET_AGENT_DATA_LOADING_ERROR(state, error) {
      state.agentDataLoadingError = error
    },
    SET_AGENT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.agentDataLoadingErrorStatus = status
    },

    SET_SEARCH_DATA(state, searchData) {
      state.searchData = searchData
    },
    SET_SEARCH_DATA_LOADING_STATUS(state, status) {
      state.searchDataLoadingStatus = status
    },
    SET_SEARCH_DATA_LOADING_ERROR(state, error) {
      state.searchDataLoadingError = error
    },
    SET_SEARCH_DATA_LOADING_ERROR_STATUS(state, status) {
      state.searchDataLoadingErrorStatus = status
    },

    SET_TEAM_DATA(state, teamData) {
      state.teamData = teamData
    },
    SET_TEAM_DATA_LOADING_STATUS(state, status) {
      state.teamDataLoadingStatus = status
    },
    SET_TEAM_DATA_LOADING_ERROR(state, error) {
      state.teamDataLoadingError = error
    },
    SET_TEAM_DATA_LOADING_ERROR_STATUS(state, status) {
      state.teamDataLoadingErrorStatus = status
    },

    SET_AGENTS_ON_TEAM_DATA(state, agentsOnTeamData) {
      state.agentsOnTeamData = agentsOnTeamData
    },
    SET_AGENTS_ON_TEAM_DATA_LOADING_STATUS(state, status) {
      state.agentsOnTeamDataLoadingStatus = status
    },
    SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR(state, error) {
      state.agentsOnTeamDataLoadingError = error
    },
    SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR_STATUS(state, status) {
      state.agentsOnTeamDataLoadingErrorStatus = status
    },

    SET_UPDATE_AGENT_EMAIL_ERROR(state, error) {
      state.updateAgentEmailError = error
    },
    SET_UPDATE_AGENT_EMAIL_ERROR_STATUS(state, status) {
      state.updateAgentEmailErrorStatus = status
    },

  },
  actions: {

    retrieveAccountData(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_AUTH_ACCOUNTS, { params: { paginate: false } })
        .then(response => {
          context.commit("SET_ACCOUNT_DATA", response.data.data);
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR", '');
          context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_ACCOUNT_DATA", {});
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR",  'retrieveAccountData(): ' + error_message);
        });

    },

    retrieveAccountHorizon(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_AUTH_HORIZON)
        .then(response => {
          localStorage.setItem("accountHorizon", response.data.horizon);
          context.commit("SET_ACCOUNT_HORIZON", response.data.horizon);
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS", false);
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR", '');
          context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", "failed");
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR",  'retrieveAccountHorizon(): ' + error_message);
        });

    },

    retrieveAgentData(context, payload) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      let params = { params: { paginate: false } }

      let agent_url = process.env.VUE_APP_AUTH_AGENTS

      if(payload && payload.hasOwnProperty('selfSigned') && payload.selfSigned) {
        agent_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        agent_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        params['params']['route'] = 'agent_profiles'

        if (payload.agent_id && !payload.callTask) {
          params['params']['agent_ids_for_reckey'] = [payload.agent_id]
        } else {
          params['params']['call_ids'] = [payload.call_id]
        }
        params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
      }

      context.commit("SET_AGENT_DATA_LOADING_STATUS", 'loading');
      axios
        .get(agent_url, params)
        .then(response => {
          context.commit("SET_AGENT_DATA", response.data);
          context.commit("SET_AGENT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_AGENT_DATA_LOADING_ERROR", '');
          context.commit("SET_AGENT_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_AGENT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_AGENT_DATA", {});
          context.commit("SET_AGENT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_AGENT_DATA_LOADING_ERROR",  'retrieveAgentData(): ' + error_message);
        });

    },

    retrieveSearchListData(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_SEARCH_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_SEARCH_LIST)
        .then(response => {
          context.commit("SET_SEARCH_DATA", response.data.data);
          context.commit("SET_SEARCH_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_SEARCH_DATA_LOADING_ERROR", '');
          context.commit("SET_SEARCH_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_SEARCH_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SEARCH_DATA", {});
          context.commit("SET_SEARCH_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_SEARCH_DATA_LOADING_ERROR",  'retrieveSearchData(): ' + error_message);
        });

    },

    retrieveTeamData(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_TEAM_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_TEAMS)
        .then(response => {
          let teamData = response.data
          let agentsOnTeam = []

          for (let i = 0; i < teamData.length; i++) {
            if (teamData[i].associations) {
              //get all team members
              let teammates = teamData[i].associations.map(item => item.profile_id)

              //does the team have the requesting user on the team?
              if (teammates.includes(store.getters["users/userData"].id)) {
                teamData[i].associations.forEach(
                  association => {
                    //does the team have the requesting user on it?..
                      if (association.profile_type == 0 && !agentsOnTeam.includes(association.profile_id)) {
                        agentsOnTeam.push(association.profile_id)
                      }
                    }
                );
              }
            }
          }

          context.commit("SET_TEAM_DATA", teamData);
          context.commit("SET_TEAM_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_TEAM_DATA_LOADING_ERROR", '');
          context.commit("SET_TEAM_DATA_LOADING_STATUS", "loaded");

          context.commit("SET_AGENTS_ON_TEAM_DATA", agentsOnTeam);
          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR", '');
          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_STATUS", "loaded");

        })
        .catch(function (error) {
          context.commit("SET_TEAM_DATA_LOADING_STATUS", "failed");
          context.commit("SET_TEAM_DATA", {});
          context.commit("SET_TEAM_DATA_LOADING_ERROR_STATUS", true);

          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_STATUS", "failed");
          context.commit("SET_AGENTS_ON_TEAM_DATA", []);
          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR_STATUS", true);

          let error_message = JSON.stringify(error)
          context.commit("SET_TEAM_DATA_LOADING_ERROR",  'retrieveTeamData(): ' + error_message);
          context.commit("SET_AGENTS_ON_TEAM_DATA_LOADING_ERROR",  'retrieveTeamData(): ' + error_message);
        });

    },

    async saveTeam(context, params) {
      let urlParams = '?team_name=' + encodeURIComponent(params['team_name']) + '&profiles=' + params['profile_json']

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_TEAM_DATA_LOADING_STATUS", 'loading');
      let rtn = true
      await axios
        .post(process.env.VUE_APP_TEAMS + urlParams)
        .then(response => {
          rtn = true
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          context.commit("SET_TEAM_DATA_LOADING_ERROR", 'saveTeam(): ' + error_message)
          rtn = false
        });
        return rtn
    },

    async saveTeamData(context, params) {
      let urlParams = params.profile_id + '?'
      urlParams += 'profile_type=' + params.profile_type;
      params.team_ids.forEach(team_id => {
        urlParams += '&team_ids[]=' + team_id
      })

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_TEAM_DATA_LOADING_STATUS", 'loading');
      let rtn = true
      await axios
        .put(process.env.VUE_APP_AUTH_AGENTS + urlParams)
        .then(response => {
          rtn = true
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          context.commit("SET_TEAM_DATA_LOADING_ERROR", 'saveTeamData(): ' + error_message)
          rtn = false
        });
      return rtn
    },

    updateAgentEmail(context, params) {

      axios
        .post(process.env.VUE_APP_AUTH_AGENTS_EMAIL, { 'id':params.id, 'email':params.email })
        .then(response => {
          if(response.status == 200) {
            context.dispatch('retrieveAgentData', {email: true})
          }
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          context.commit("SET_UPDATE_AGENT_EMAIL_ERROR_STATUS", true);
          context.commit("SET_UPDATE_AGENT_EMAIL_ERROR",  'updateAgentEmail(): ' + error_message);
        });

    }

  }
}
