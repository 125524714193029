<template>
    <v-app id="callfinder">
  
      <v-app-bar app dark class="cf-background nav-element" height="128">
  
        <v-toolbar-title class="nav-element">
          <router-link :to="{ name: 'signin'}">
            <img src="@/assets/2021-CallFinder-Logo-WEB-White-Alt.svg" height="130" alt="">
          </router-link>
        </v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <div style="width:40%;">If you have any questions or problems regarding your account please email <a href="mailto:customerservice@mycallfinder.com" style="color:#fff;">customerservice@mycallfinder.com</a> or call 800-639-1700.</div>
  
      </v-app-bar>
  
      <v-content>
        
        <v-row fluid class="fill-height" align="center" justify="center">
            
              <!-- multi factor authentication setup -->
              <v-col v-if="this.mfaStatus == 'setup'" cols="4" class=" pl-12 pt-0 pr-12 pb-0">
                <img id="mfaPicture" class="qr-img" src="img.png" style="display:none;" />
              <h1>Multi-Factor Authentication Setup</h1>
              <p>Download the Google Authenticator or Microsoft Authenticator app. Scan the QR code and enter the authentication code.</p>
              <form @submit.prevent
                id="mfa_form"
                @submit="this.checkMfa"
              >
              <v-text-field
                outlined
                v-model="mfaCode"
                label="Multi-Factor Authentication Code"
                placeholder="XXXXXX"
                required
              ></v-text-field>

              <v-alert v-if="mfaErrorMessage" type="error">
                  {{ mfaErrorMessage }}
              </v-alert>

              <v-layout align-center justify-center>
                <v-btn width="200" type="submit" color="accent darken-3" :loading="this.mfaLoading" :disabled="this.mfaLoading">Verify Code</v-btn>
              </v-layout>

              </form>
            </v-col>

  
          <!-- multi factor authentication form -->
          <v-col v-else cols="4" class="pl-12 pt-0 pr-12 pb-0">
            <img  id="mfaPicture" class="qr-img" src="img.png" style="display:none;" />
            <h1>Multi-Factor Authentication</h1>
            <p>Open the multi-factor authenticator (MFA) app on your mobile device to view your authentication code</p>
            <form @submit.prevent
              id="mfa_form"
              @submit="this.checkMfa"
            >
              <v-text-field
                outlined
                v-model="mfaCode"
                label="Multi-Factor Authentication Code"
                placeholder="XXXXXX"
                required
              >
              </v-text-field>

              <v-alert v-if="mfaErrorMessage" type="error">
                  {{ mfaErrorMessage }}
              </v-alert>

              <p class="caption">
                Contact your account representative if you cannot access your MFA device
              </p>

              <v-layout align-center justify-center>
                <v-btn width="200" type="submit" color="accent darken-3" :loading="this.mfaLoading" :disabled="this.mfaLoading">Verify Code</v-btn>
              </v-layout>
              
            </form>
              
          </v-col>
  
          <v-col cols="8" class="pa-0">
  
            <div class="d-flex fill-height" :style="getBackgroundImage()"></div>
  
          </v-col>
  
        </v-row>
        
      </v-content>
  
  
      <v-footer app class="nav-element" id="appFooter">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters align="center" justify="center">
              <v-btn class="mx-2 no-text-transform" color="white" small text rounded
                     title="CallFinder® Home Page"
                     href="https://www.mycallfinder.com">Copyright &copy; {{copyrightYear}}, CallFinder® 1-800-639-1700
              </v-btn>
              <v-btn class="mx-2" color="white" small text rounded
                     href="/privacy-policy">Privacy Policy
              </v-btn>
              <v-btn class="mx-2" color="white" small text rounded
                     href="mailto:customerservice@mycallfinder.com">Email Customer Service
              </v-btn>
              <v-btn class="mx-2" color="white" small text rounded
                     href="https://findmycalls.com/software_license.html">Software License Agreement
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-footer>
  
    </v-app>
  </template>
  
  <script>
import {mapGetters, mapActions} from "vuex"
  export default {
    name: "mfapage",
    data: function() {
      return {
        username: "",
        password: "",
        errormessage: "",
        showPW: false,
        backgroundImageHeight: '100 vh',
        backgroundImages: [
          'iMac_Desk_Scorecard_Row.jpg',
          'Macbook_Transparent_Scorecard_Row.jpg',
          'Office_Group_Laptop_Foreground_Agent_Insights.jpg',
          'Office_Group_Laptop_Foreground_Dynamic_Labels_Home_Screen.jpg',
          'Office_Group_Laptop_Foreground_Scorecard_Row.jpg',
          'Office_Group_Laptop_Foreground_Scorecard_Tiles.jpg',
          'Two_Women_Macbook_Agent_Insights.jpg',
          'Two_Women_Macbook_Scorecard_Tiles.jpg',
          'Woman_Analyzing_Data_Scorecard_Row.jpg',
        ],
        mfaLoading: false,
        csrfToken: "",
        prevPath: "",
        mfaDisplay: false,
        mfaStatus: "",
        mfaImageStore: "",
        csrfToken: "",
        mfaCode: "",
      };
    },
    computed: {
      ...mapGetters("authentication", [
        "authenticationErrorMessage",
        "mfaImage",
        "mfaCodePassed",
        "toCSRFInvalid",
        "newCSRFToken",
        "updateCSRF",
        "mfaErrorMessage",
        "mfaSigninComplete",
      ]),
      ...mapGetters('users', [
        'userData',
        'userDataLoadingStatus',
        'userDataLoadingError',
        'userDataLoadingErrorStatus',
      ]),
      getPage() {
        return this.$route.query.page || ''
      },
      copyrightYear() {
        return new Date().getFullYear()
      },
    },
    methods: {
      ...mapActions("authentication", [
        "setMfaCodePassed",
        "setAuthenticationErrorMessage",
        "setToCSRFInvalid",
        "getMfaImage",
        "verifyMfaCode",
        "signIn",
        "setMfaSigninComplete",
        "setUpdateCSRF",
        "setMfaErrorMessage",
      ]),
      ...mapActions("users", [
        "retrieveUserData",
        "retrieveAuthorizedUserListData",
        "resetUserData",
      ]),

      goToCSRFInvalid() {
        // bad csrf go here
        if(this.toCSRFInvalid) {
          this.setToCSRFInvalid(false)
          //token timed out
          this.$router.push("/signin?page=CSRFTokenInvalid").catch(() => {})
          this.stopSpinny()
        }
      },
      pushRoute() {
        // need to deal with prevpath here somewhere
        let routeTo = '/'
        // logic for sending the user back to the page they were on
      
        if(this.mfaSigninComplete) {
          if(typeof this.prevPath == "undefined" || String(this.prevPath).substring(0,7) == "/signin"){
            routeTo = "/insights"
          } else if(String(this.prevPath).substring(0,1) == "\\") {
            routeTo = "/" + String(this.prevPath).substring(1)
          } else if(String(this.prevPath).substring(0,1) != "/")  {
            routeTo = "/" + this.prevPath
          } else {
            routeTo = this.prevPath
          }
          this.setMfaSigninComplete(false)
          this.retrieveUserData()
          this.resetTimer()
          this.csrfToken = ""
          // make sure there is a slash in the route
          if(routeTo.substring(0,1) != "/" && routeTo.substring(0,1) != "\\" ) {
            routeTo = "/" + routeTo;
          }
          this.$router.push(routeTo).catch(() => {})
          this.stopSpinny();
          this.mfaLoading = false
        }
      },
      getBackgroundImage() {
        let d = new Date()
        let index = Math.floor(d.getSeconds()/(60/this.backgroundImages.length))
        return {
          "background-image": `url(${require(`@/assets/img/backgrounds/${this.backgroundImages[index]}`)})`,
          "background-repeat": "no-repeat",
          "background-size": "cover",
          "height": this.backgroundImageHeight,
          "overflow": "hidden"
        }
      },
      setBackgroundImageHeight() {
        this.backgroundImageHeight = (window.innerHeight - 190) + 'px'
      },
      checkMfa() {
        this.setMfaErrorMessage("")
        this.mfaLoading = true
        this.verifyMfaCode({code: this.mfaCode, username: this.username, password: this.password, csrfToken: this.newCSRFToken});
        this.setMfaCodePassed("in progress")
      },
      displayMfaImage() {
        this.mfaDisplay = true;
        document.getElementById('mfaPicture').src = this.mfaImageStore;
        document.getElementById('mfaPicture').style.display = "";
      },
      handleMfaResponse() {
        if(this.mfaCodePassed == "Not Verified") {
            this.setMfaErrorMessage("Mfa code was incorrect. Please try again.")
            this.mfaLoading = false
            this.stopSpinny()
        }
      },
      addUserData(data) {
        this.username = data.username
        this.password = data.password
        this.prevPath = data.prevPath
        this.csrfToken = data.csrfToken
        this.mfaStatus = data.mfaStatus
        this.mfaImageStore = data.mfaImage
        if(this.mfaImageStore != "" && this.mfaStatus == "setup") {
            this.displayMfaImage()
        }
      },
      stopSpinny() {
        this.mfaLoading = false;
      },
      setErrorMessage() {
        this.setMfaErrorMessage(this.authenticationErrorMessage);
        this.stopSpinny()
      },

    },

    mounted() {
      let data = this.$route.query.data;
      // if the data isnt clear then send them to the signin page. this would be if they try to go to mfa without signing in first
      if(typeof data.username == "undefined") {
        this.$router.push("/signin").catch(() => {})
      }
      this.addUserData(data);

      window.addEventListener('resize', () =>
        this.setBackgroundImageHeight()
      )
      this.setBackgroundImageHeight();
    },
    watch: {
      mfaImage: 'displayMfaImage',
      mfaCodePassed: 'handleMfaResponse',
      toCSRFInvalid: 'goToCSRFInvalid',
      authenticationErrorMessage: 'setErrorMessage',
      mfaSigninComplete: 'pushRoute',
    }
  };
  </script>
  
  <style scoped>
  
    .text-right {
      text-align: right;
    }
  
    .form-label {
      display: block;
      margin-bottom: 1em;
    }
  
    .form-label > .form-control {
      margin-top: 0.5em;
    }
  
    .form-control {
      display: block;
      width: 100%;
      padding: 0.5em 1em;
      line-height: 1.5;
      border: 1px solid #ddd;
    }
  
    .nav-element {
  
    }
  
    .v-btn.no-text-transform {
      text-transform:none !important;
    }
  
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

    .qr-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        margin-top: 25px;
        
        border: 1px solid #083a81;
        border-radius: 10px;
        padding: 1px;

        margin-bottom: 10%;
    }
  </style>
  