import axios from "@/utils/AxiosInstance.js"

export const guidance = {
  namespaced: true,
  state: {
    guidanceSessionListLoadingErrorStatus: false,
    guidanceSessionListLoadingError: "",
    guidanceSessionListLoadingStatus: "notLoading",
    guidanceSessionList: [],
  },
  mutations: {
    SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS(state, status) {
      state.guidanceSessionListLoadingErrorStatus = status
    },
    SET_GUIDANCE_SESSION_LIST_LOADING_ERROR(state, error) {
      state.guidanceSessionListLoadingError = error
    },
    SET_GUIDANCE_SESSION_LIST_LOADING_STATUS(state, status) {
      state.guidanceSessionListLoadingStatus = status
    },
    SET_GUIDANCE_SESSION_LIST(state, guidanceSessionList) {
      state.guidanceSessionList = guidanceSessionList
    },
  },
  getters: {
    guidanceSessionListLoadingErrorStatus: state => state.guidanceSessionListLoadingErrorStatus,
    guidanceSessionListLoadingError: state => state.guidanceSessionListLoadingError,
    guidanceSessionListLoadingStatus: state => state.guidanceSessionListLoadingStatus,
    guidanceSessionList: state => state.guidanceSessionList,
  },
  actions: {

    retrieveGuidanceSessionList(context, payload) {
      let axios_params = {params:{}}
      let session_url = process.env.VUE_APP_SESSION_NOTES + "?agent_id=" + payload.agent_id

    //   TODO: implement once we have self signed pages for guidance sessions
    //   if(payload.selfSigned) {
    //     session_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.task_id
    //     axios_params['params']['route'] = 'tasks'
    //     if (payload.callTask) {
    //       axios_params['params']['call_id'] = payload.call_id
    //     } else {
    //       axios_params['params']['agent_id_for_reckey'] = payload.agent_id
    //     }
    //     axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}

    //     // agent reckey authorization parameters
    //     axios_params['params']['agent_task_id'] = payload.agent_task_id
    //   }

      context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "loading");
      axios
        .get(session_url, axios_params)
        .then(response => {

          let sessionList = response.data.data
          sessionList = sessionList.map(obj => ({...obj, editing: false, loading: false, new: false, editableNote: obj.note, editableTitle: obj.title, editableTodos: obj.todos}))
          sessionList.forEach((session, index) => {
                if (session.todos) {
                    let to_dos = session.todos.split('<p>').filter(Boolean).map(p => p.replace('</p>', ''));
                    to_dos.forEach((todo, todo_index) => {
                        let todoObj = {}
                        todoObj.text = todo
                        // .split(/<[^>]*>/).join('')
                        todoObj.id = todo_index + 1
                        to_dos[todo_index] = todoObj
                    });
                    session.to_do_arr = to_dos
                } else {
                    session.to_do_arr = []
                }
            });
            
          context.commit("SET_GUIDANCE_SESSION_LIST", sessionList);
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "loaded");
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_GUIDANCE_SESSION_LIST", []);
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "failed");
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR", 'retrieveguidanceSessionList(): ' + error_message)
        });
    },
    setGuidanceEditState(context, params) {
        let guidanceSessionList = context.getters.guidanceSessionList
        let session = guidanceSessionList.find(ses => (ses.id == params.id ));

        //if guidance session is new, just remove it from the list, otherwise update the "is_editing" state
        if (session.new) {
        
            let indexOfObject = guidanceSessionList.findIndex(object => {
              return object.id == params.id;
            });
      
            if (indexOfObject > -1) {
                guidanceSessionList.splice(indexOfObject, 1);
            }
      
            context.commit("SET_GUIDANCE_SESSION_LIST", guidanceSessionList)
        } else {
            session.editableNote = session.note
            session.editableTodos = session.todos
            session.editableTitle = session.title
            session.editing = params.is_editing
            context.commit("SET_GUIDANCE_SESSION_LIST", guidanceSessionList);
        }
    },
    updateGuidanceSession(context, params) {
        let guidanceSessionList = context.getters.guidanceSessionList
        let session = guidanceSessionList.find(ses => (ses.id == params.id ));
        session.editableTitle = params.title
        session.editableNote = params.note
        session.editableTodos = params.to_dos
        context.commit("SET_GUIDANCE_SESSION_LIST", guidanceSessionList);
    },
    createNewGuidanceSession(context) {
        let guidanceSessionList = context.getters.guidanceSessionList
        //gets the root state of the search data which is loaded when the setup tab is loaded
        let did = Date.now()
        let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
        let dt = ISO()
  
        let title = "New Guidance Session"
        let note = "Session Note"
        let guidanceSession = {
          id: did,
          title: title,
          note: note,
          todos: "",
          to_do_arr: [],
          editing: true,
          loading: false,
          new: true,
          editableTitle: title,
          editableNote: note,
          editableTodos: "",
          created_at: dt[0] + ' ' + dt[1],
          updated_at: dt[0] + ' ' + dt[1],
  
        }
        guidanceSessionList.push(guidanceSession)
        guidanceSessionList.sort((a, b) => a.created_at > b.created_at && 1 || -1)
        context.commit("SET_GUIDANCE_SESSION_LIST", guidanceSessionList);
    },
    saveGuidanceSession(context, params) {

        context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "loading");

        let session_params = {
            title: params.editableTitle,
            note: params.editableNote,
            todos: params.editableTodos,
            agent_id: params.agent_id
        }

        if (params.new) {
            //POST
            
            axios
            .post(process.env.VUE_APP_SESSION_NOTES, session_params)
            .then(response => {
                context.dispatch("retrieveGuidanceSessionList", session_params);
             
            }).catch(function (error) {
                context.commit("SET_GUIDANCE_SESSION_LIST", []);
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "failed");
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS", true);
                let error_message = JSON.stringify(error)
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR", 'saveGuidanceSession(): ' + error_message)
            });
        } else {
          
            //PUT
            axios
            .put(process.env.VUE_APP_SESSION_NOTES + params.id, session_params)
            .then(response => {
                context.dispatch("retrieveGuidanceSessionList", session_params);
             
            }).catch(function (error) {
                context.commit("SET_GUIDANCE_SESSION_LIST", []);
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "failed");
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS", true);
                let error_message = JSON.stringify(error)
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
                context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR", 'saveGuidanceSession(): ' + error_message)
            });
        }
       
    },
    deleteGuidanceSession(context, params) {

        context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "loading");

        axios
        .delete(process.env.VUE_APP_SESSION_NOTES + params.id + '?agent_id=' + params.agent_id)
        .then(response => {
            context.dispatch("retrieveGuidanceSessionList", {
                title: params.editableTitle,
                note: params.editableNote,
                todos: params.editableTodos,
                agent_id: params.agent_id
            });
            
        }).catch(function (error) {
            context.commit("SET_GUIDANCE_SESSION_LIST", []);
            context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_STATUS", "failed");
            context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
            context.commit("SET_GUIDANCE_SESSION_LIST_LOADING_ERROR", 'deleteGuidanceSession(): ' + error_message)
        });
       
    }
  }
};
