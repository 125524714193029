<template>
  <div>
    <loading-status-box
      v-if="callScorecardDataV2LoadingStatus != 'loaded'"
      :message="scorecardLoadingMessage"
      :type="scorecardLoadingType"
      :messageStyle="loadingStyle"
    >
    </loading-status-box>

    <call-scorecard-body-table
      v-else
      :callData="callData"
      :callId="callId"
      :format="'callScorecard'"
      :displayedRegions="displayedRegions"
      :clearAppliedChanges="clearAppliedChanges"
      :excludeCall="excludeCall"
      :typeOfExclusion="typeOfExclusion"
      :changesApplied="changesApplied"
      :changesRemoved="changesRemoved"
      :pageQueryData="pageQueryData"
      :selfSigned="selfSigned"
      :showTask="showTask"
      @spliceDisplayedRegions="spliceDisplayedRegions"
      @setDisplayedRegions="setDisplayedRegions"
      @clearAppliedChanges="clearScorecardChanges"
      @changeCallOverrides="applyScorecardChanges"
      @deleteAppliedOverrides="removeExistingScChanges"
    ></call-scorecard-body-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import CallScorecardBodyTable from "./CallScorecardBodyTable.vue";

export default {
  components: {
    LoadingStatusBox,
    CallScorecardBodyTable
  },
  data() {
    return {};
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    displayedRegions: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    clearAppliedChanges: {
      required: true,
      type: Boolean
    },
    excludeCall: {
      required: true,
      type: Boolean
    },
    typeOfExclusion: {
      required: false,
      type: Number,
      default: null
    },
    changesApplied: {
      required: true,
      type: Boolean
    },
    changesRemoved: {
      required: true,
      type: Boolean
    },
    pageQueryData: {
      type: Object,
      required: false,
      default: null
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus"
    ]),
    loadingStyle() {
      return "text-wrap: nowrap; font-size:14px";
    },
    scorecardLoadingType() {
      if (this.callScorecardDataV2LoadingError) return "error";
      else return "loading";
    },
    scorecardLoadingMessage() {
      if (this.callScorecardDataV2LoadingError)
        return "There was an error loading this scorecard...";
      else return "Scorecard loading, please wait...";
    }
  },
  methods: {
    spliceDisplayedRegions(index) {
      this.$emit("spliceDisplayedRegions", index);
    },
    setDisplayedRegions(regions) {
      this.$emit("setDisplayedRegions", regions);
    },
    clearScorecardChanges(clear) {
      this.$emit("clearAppliedChanges", clear);
    },
    applyScorecardChanges(apply) {
      this.$emit("changeCallOverrides", apply);
    },
    removeExistingScChanges(remove) {
      this.$emit("deleteAppliedOverrides", remove);
    },
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped></style>
