<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row>
        <v-col style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center">
          <Filters ref="myInsightsFilters" :channels="this.channelCount" feature="insights" @filters-loaded="filtersLoaded" @agents-filter-updated-reload="filterUpdated" @subjectiveSet-filter-updated-reload="filterUpdated" @accounts-filter-updated-reload="filterUpdated"></Filters>

          <v-row v-show="(this.myRouteName=='insights-dynalabels-agents')">
            <v-col class="pl-12 pb-0">
              <template v-if="loadAggregateInfo">
                <insight-breadcrumbs :linkEnabled="true" :insightHeader="insightHeader" :dynaLabelHeader="dynaLabelHeader" :singleDynaCount="singleDynaCount" :level="'agent'" :searchFieldIsEmpty="searchFieldIsEmpty" @go-to-page="goBack" @re-apply-search-phrase="reApplySearchPhrase"></insight-breadcrumbs>
              </template>
            </v-col>
            <v-col class="text-right pr-10">
              <export-menu @exportClick="exportClick"
                :disableDeliveries="insightsDynaLabelsAgentsTeamDataLoadingStatus!='loaded'"
                :disableExports="insightsDynaLabelsAgentsTeamDataLoadingStatus!='loaded'"
              ></export-menu>
              <insights-download ref="InsightsDownloadAggregate" v-if="this.myRouteName=='insights-dynalabels-agents'" class="float-right"
                :insightsId="Number(uriInsightId)"
                :insightsUrl="insightAgentUrl"
                :insightsTitle="insightHeader + ' Grouping Agent'"
                :dateRange="getDateRangeString()"
              ></insights-download>
              <v-btn fab small dark class="ml-2" color="primary" href="/faq/1" target="_blank" rel="noopener" title="Click for Insights Help and FAQs">
                <v-icon dark>mdi-help-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <insight-processing-status :type="'agent'"></insight-processing-status>

          <v-row v-show="(this.myRouteName=='insights-dynalabel-agent-calls'||this.myRouteName=='insights-dynalabel-team-calls')">
            <v-col class="pl-12 pb-0">
              <template v-if="loadAggregateInfo">
                <insight-breadcrumbs :linkEnabled="true" :insightHeader="insightHeader" :dynaLabelHeader="dynaLabelHeader" :singleDynaCount="singleDynaCount" :level="'call'" :searchFieldIsEmpty="searchFieldIsEmpty" :agentHeader="getAgentText(agentInfo)" @go-to-page="goBack" @re-apply-search-phrase="reApplySearchPhrase"></insight-breadcrumbs>
              </template>
            </v-col>
            <v-col class="text-right pr-10">
              <export-menu @exportClick="exportClick"
                :disableDeliveries="!loadCallInfo"
                :disableExports="!loadCallInfo"
              ></export-menu>
                <insights-download ref="InsightsDownload" v-if="loadCallInfo" class="float-right"
                  :insightsId="Number(uriInsightId)"
                  :insightsUrl="insightsAgentCallDataUrl"
                  :insightsTitle="insightHeader"
                  :dateRange="getDateRangeString()"
                ></insights-download>
                <v-btn fab small dark class="ml-2" color="primary" href="/faq/1" target="_blank" rel="noopener" title="Click for Insights Help and FAQs">
                  <v-icon dark>mdi-help-circle-outline</v-icon>
                </v-btn>
            </v-col>
          </v-row>

          <v-row >
            <v-col cols="8"  offset="3">
              <ad-hoc-search ref="adHocSearch" :channels="this.channelCount" @searchChanged="searchChanged" />
            </v-col>
            <v-col cols="1" class="pr-10 text-right">
            </v-col>

          </v-row>
          <v-container v-show="(this.myRouteName=='insights-dynalabels-agents')" pl-5 pr-5 fluid style="height: 100vh; max-height: calc(100%);">
            <v-row>
              <v-col class="pl-6">
                <v-card flat v-if="loadAggregateInfo" class="mb-12 pb-12">
                  <v-data-table
                      :headers="filteredAggregateHeaders"
                      :items="teamRowData"
                      :hide-default-footer="true"
                      class="pl-4 pr-8 pb-2"

                  >
                    <template v-slot:no-data>
                      <v-alert :value="true" color="warning" icon="mdi-message-alert-outline" class="ma-2" dark>
                        {{ noDataMessage }}
                      </v-alert>
                    </template>

                    <template v-slot:item.name="{ item }">
                      <v-tooltip top v-if="item.callCount>0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="loadTeamCalls()"
                              text
                              small
                              class="agent-btn"
                              color="primary"
                          >{{ item.name }}
                          </v-btn>
                        </template>
                        <span>{{item.name}} ({{item.id}}) call log&hellip;</span>
                      </v-tooltip>

                    </template>

                    <template v-slot:item.team_sentiment="{ item }" >
                      <template v-if="item.channelCount==2">
                        <subjective-distribution-widget
                            :scale="50"
                            :info="item.sentimentCounts"
                            :display="'Customer'"
                            :type="'sentiment'"
                        ></subjective-distribution-widget>

                      </template>
                      <template v-else-if="item.channelCount==1">
                        <subjective-distribution-widget
                            :scale="50"
                            :info="item.sentimentCounts"
                            :display="'Speaker 1/Speaker 2'"
                            :type="'sentiment'"
                        ></subjective-distribution-widget>
                      </template>
                      <template v-else>
                        <subjective-distribution-widget
                            :scale="50"
                            :info="item.sentimentCounts"
                            :display="'Both Channels'"
                            :type="'sentiment'"
                        ></subjective-distribution-widget>
                      </template>
                    </template>


                    <template v-slot:item.team_emotion="{ item }" >
                      <template v-if="!isChat">
                        <template v-if="item.channelCount==2">
                          <subjective-distribution-widget
                              :scale="50"
                              :info="item.emotionCounts"
                              :display="'Customer'"
                              :type="'emotion'"
                          ></subjective-distribution-widget>
                        </template>
                        <template v-else-if="item.channelCount==1">
                          <subjective-distribution-widget
                              :scale="50"
                              :info="item.emotionCounts"
                              :display="'Speaker 1/Speaker 2'"
                              :type="'emotion'"
                          ></subjective-distribution-widget>
                        </template>
                        <template v-else>
                          <subjective-distribution-widget
                              :scale="50"
                              :info="item.emotionCounts"
                              :display="'Both Channels'"
                              :type="'emotion'"
                          ></subjective-distribution-widget>
                        </template>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>

                    <template v-slot:item.averageDuration_formatted="{ item }"><span class="tData" title="Talk time (the average length of all the audio recordings)">{{ item.averageDuration_formatted }}</span></template>

                    <template v-slot:item.silenceAveragePercent="{ item }">
                      <template v-if="!isChat">
                        <span class="tData" :title="item.silenceAverageSeconds + ' (Average measured silence per call)'">{{ item.silenceAveragePercent }}</span>
                        </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>
                    <template v-slot:item.silenceExtra="{ item }">
                      <template v-if="!isChat">
                        <span v-if="item.channelCount>1" class="dim" :title="'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer">{{item.silenceRatio}}</span><span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                        </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>

                    <template v-slot:item.talkTimeAverageSeconds="{ item }">
                      <template v-if="!isChat">
                        <span class="tData" :title="item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'">{{ item.talkTimeAveragePercent}}</span>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>
                    <template v-slot:item.talkTimeExtra="{ item }">
                      <template v-if="!isChat">
                        <span v-if="item.channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' +item.talkTimePercentCustomer">{{ item.talktimeRatio }}</span>
                        <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>

                    <template v-slot:item.overtalkAveragePercent="{ item }">
                      <template v-if="!isChat">
                        <span v-if="item.channelCount>1" class="tData" :title="item.overtalkAverageSeconds + ' (Average measured overtalk per call)'">{{ item.overtalkAveragePercent }}</span><span v-else class="tData" title="Not available from single-channel audio">&mdash;</span>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>
                    <template v-slot:item.overtalkExtra="{ item }">
                      <template v-if="!isChat">
                        <span v-if="item.channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer">{{item.overtalkRatio}}</span><span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </template>

                    <template v-slot:item.callCount="{ item }"><span class="tData">{{ getCallCountDisplay(item) }}</span>
                      <template>
                        <v-icon v-if="item.channelCount==3" color="warning" class="pl-10 mixed-channels">mdi-alert-box</v-icon>
                      </template>
                    </template>

                  </v-data-table>

                  <v-card-title>
                    <h1 class="main">Agent Insights
                      <span class="dim">
                          (of the
                          <template v-if="filteredAgentList.length>0">{{numberGrammar(filteredAgentList.length)}} agent<template v-if="filteredAgentList.length!==1">s</template> selected in filters</template><template v-else>{{numberGrammar(agentsInScope.length)}} available agent<template v-if="agentsInScope.length!==1">s</template></template>,
                          {{numberGrammar(insightsDynaLabelsAgentsAgentData.length)}} agent<template v-if="insightsDynaLabelsAgentsAgentData.length!==1">s</template> with insights <template v-if="insightsDynaLabelsAgentsAgentData.length!==1">were</template><template v-else>was</template> found)</span>
                    </h1>
                    <v-text-field
                        dense
                        v-model="agentSearch"
                        append-icon="mdi-magnify"
                        placeholder="Find Agents"
                        single-line
                        clearable
                        @click:clear="clearAgentSearch()"
                        class="pl-0 pr-4"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                      :sort-by.sync="agentSortBy"
                      :sort-desc.sync="agentSortDesc"
                      :search="agentSearch"
                      :headers="filteredGroupedHeaders"
                      :items="agentRowData"
                      :items-per-page="5"
                      :footer-props="{
                        itemsPerPageOptions: [10, 25, 50, 100]
                      }"
                      class="pl-4 pr-8 pb-2"
                      :custom-sort="customSort"
                  >

                    <template v-slot:no-data>
                      <v-alert :value="true" color="warning" icon="mdi-message-alert-outline" class="ma-2" dark>
                        {{ noDataMessage }}
                      </v-alert>
                    </template>

                    <template v-slot:item.name="{ item }" :class="getCallCountStyle(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="loadAgentCalls(item.id)"
                              :disabled="item.callCount==0"
                              text
                              small
                              color="primary"
                              class="agent-btn"
                          >{{ item.name }}
                          </v-btn>
                        </template>
                        <span v-if="item.callCount>0">{{item.name}} ({{item.id}}) call log&hellip;</span>
                      </v-tooltip>

                    </template>

                    <template v-slot:item.averageDuration_formatted="{ item }">
                      <template v-if="item.callCount>0"><span class="tData" :class="getCallCountStyle(item)" title="Talk Time (the average length of all the audio recordings)">{{ item.averageDuration_formatted }}</span></template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.agent_sentiment="{ item }" >
                      <template v-if="item.callCount>0">
                        <template v-if="item.channelCount==2">
                          <subjective-distribution-widget
                              :scale="50"
                              :info="item.sentimentCounts"
                              :display="'Customer'"
                              :type="'sentiment'"
                              :class="getCallCountStyle(item)"
                          ></subjective-distribution-widget>

                        </template>
                        <template v-else-if="item.channelCount==1">
                          <subjective-distribution-widget
                              :scale="50"
                              :info="item.sentimentCounts"
                              :display="'Speaker 1/Speaker 2'"
                              :type="'sentiment'"
                              :class="getCallCountStyle(item)"
                          ></subjective-distribution-widget>
                        </template>
                        <template v-else>
                          <subjective-distribution-widget

                              :scale="50"
                              :info="item.sentimentCounts"
                              :display="'Both Channels'"
                              :type="'sentiment'"
                              :class="getCallCountStyle(item)"
                          ></subjective-distribution-widget>
                        </template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.agent_emotion="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <template v-if="item.channelCount==2">
                            <subjective-distribution-widget
                                :scale="50"
                                :info="item.emotionCounts"
                                :display="'Customer'"
                                :type="'emotion'"
                            ></subjective-distribution-widget>
                          </template>
                          <template v-else-if="item.channelCount==1">
                            <subjective-distribution-widget
                                :scale="50"
                                :info="item.emotionCounts"
                                :display="'Speaker 1/Speaker 2'"
                                :type="'emotion'"
                            ></subjective-distribution-widget>
                          </template>
                          <template v-else>
                            <subjective-distribution-widget
                                :scale="50"
                                :info="item.emotionCounts"
                                :display="'Both Channels'"
                                :type="'emotion'"
                            ></subjective-distribution-widget>
                          </template>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.silenceAveragePercent="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span class="tData" title="Average measured silence per call">{{ item.silenceAveragePercent }}</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.silenceExtra="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span v-if="item.channelCount>1" class="dim" :title="'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer">{{item.silenceRatio}}</span>
                          <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.talkTimeAveragePercent="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span class="tData" :title="item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'">{{ item.talkTimeAveragePercent }}</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>
                    <template v-slot:item.talkTimeExtra="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span v-if="item.channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' + item.talkTimePercentCustomer">{{item.talktimeRatio}}</span>
                          <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.overtalkAveragePercent="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span v-if="item.channelCount>1" class="tData" :title="item.overtalkAverageSeconds + ' (Average measured overtalk per call)'">{{ item.overtalkAveragePercent }}</span>
                          <span v-else class="tData" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.overtalkExtra="{ item }">
                      <template v-if="item.callCount>0">
                        <template v-if="!isChat">
                          <span v-if="item.channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer">{{item.overtalkRatio}}</span>
                          <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                        </template>
                        <template v-else><span>&mdash;</span></template>
                      </template>
                      <template v-else><span class="no-data">&mdash;</span></template>
                    </template>

                    <template v-slot:item.callCount="{ item }"><span class="tData">{{ getCallCountDisplay(item) }}</span>
                      <template>
                        <v-icon v-if="item.channelCount==3" color="warning" class="pl-10 mixed-channels">mdi-alert-box</v-icon>
                      </template>
                    </template>
                  </v-data-table>

                  <h5 class="footnote" v-if="showMixAlert"><v-icon color="warning" class="mixed-channels">mdi-alert-box</v-icon> Reported data contain a mix of single and dual-channel call recordings &mdash; agent/cust details apply only to the dual-channel recordings.</h5>

                </v-card>

                <v-card flat v-if="insightsDynaLabelsAgentsTeamDataLoadingErrorStatus===true">
                  <v-card-text>{{ insightsDynaLabelsAgentsTeamDataLoadingError }}</v-card-text>
                </v-card>

                <v-card flat v-if="insightsDynaLabelsAgentsTeamDataLoadingStatus!='loaded'">
                  <v-row align="center" justify="center">
                    <v-col style="text-align: center;">
                      <v-progress-circular
                          class="align-self-center mb-4"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                      <div class="progress">retrieving list loading</div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="main-container" v-show="(this.myRouteName=='insights-dynalabel-agent-calls'||this.myRouteName=='insights-dynalabel-team-calls')" pl-5 pr-10 fluid style="height: 100vh; max-height: calc(100%);">

            <v-container :class="(showStats) ? 'stats-container' : 'stats-container-collapsed'">

              <div :class="(showStats) ? 'expand-button' : 'expand-button-collapsed'">
                <v-btn icon dark color="blue-grey">
                  <v-icon small @click="toggleExpansion">{{expansionIcon}}</v-icon>
                </v-btn>
              </div>

              <v-card flat v-show="showStats && loadAgentInfo">
                <v-row class="pa-4 pl-6">
                      <h2 class="blue-grey--text mt-0">Agent Statistics</h2>

                  <h3 class = "float-child" >{{headerPrefix}} Sentiment</h3>
                  <h3 class = "float-child">{{headerPrefix}} Emotion</h3>
                  <template class = "float-container">
                    <template v-if="agentInfo&&agentInfo.channelCount==2">
                      <subjective-distribution-widget class = "float-child"
                                                      :scale="50"
                                                      :info="agentInfo.sentimentCounts"
                                                      :display="'Customer'"
                                                      :type="'sentiment'"
                      ></subjective-distribution-widget>
                      <subjective-distribution-widget class = "float-child"
                                                      :scale="50"
                                                      :info="agentInfo.emotionCounts"
                                                      :display="'Customer'"
                                                      :type="'emotion'"
                      ></subjective-distribution-widget>
                    </template>
                    <template v-else-if="agentInfo&&agentInfo.channelCount==1">
                      <subjective-distribution-widget class = "float-child"
                                                      :scale="50"
                                                      :info="agentInfo.sentimentCounts"
                                                      :display="'Speaker 1/Speaker 2'"
                                                      :type="'sentiment'"
                      ></subjective-distribution-widget>
                      <subjective-distribution-widget class = "float-child"
                                                      :scale="50"
                                                      :info="agentInfo.emotionCounts"
                                                      :display="'Speaker 1/Speaker 2'"
                                                      :type="'emotion'"
                      ></subjective-distribution-widget>
                    </template>
                    <template v-else-if="agentInfo&&agentInfo.sentimentCounts != undefined && agentInfo.emotionCounts != undefined">
                      <subjective-distribution-widget class = "float-child"

                                                      :scale="50"
                                                      :info="agentInfo.sentimentCounts"
                                                      :display="'Both Channels'"
                                                      :type="'sentiment'"
                      ></subjective-distribution-widget>
                      <subjective-distribution-widget class = "float-child"
                                                      :scale="50"
                                                      :info="agentInfo.emotionCounts"
                                                      :display="'Both Channels'"
                                                      :type="'emotion'"
                      ></subjective-distribution-widget>
                    </template>
                  </template>

                  <h3>Silence <span class="dim">(agent/cust)</span></h3>
                  <h4>
                    <span class="large"  v-if="agentInfo" v-html="agentInfo.silenceAveragePercent" :title="agentInfo.silenceAverageSeconds +' (Average measured silence per call)'"></span>
                    <template><span v-if="agentInfo&&agentInfo.channelCount>1" class="dim" :title="'Silence: Agent owns ' + agentInfo.silencePercentAgent + ' / Customer owns ' + agentInfo.silencePercentCustomer">{{agentInfo.silenceRatio}}</span><span v-else class="dim" title="Not available from single-channel audio">&mdash;</span></template>
                  </h4>

                  <h3>Overtalk <span class="dim">(agent/cust)</span></h3>
                  <h4>
                    <span v-if="agentInfo&&agentInfo.channelCount>1" class="large" v-html="agentInfo.overtalkAveragePercent" :title="agentInfo.overtalkAverageSeconds + ' (Average measured overtalk per call)'"></span>
                    <span class="medium" v-else title="Not available from single-channel audio">&mdash;</span>
                    <template><span v-if="agentInfo&&agentInfo.channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + agentInfo.overtalkPercentAgent + ' / Customer owns ' + agentInfo.overtalkPercentCustomer">{{agentInfo.overtalkRatio}}</span><span v-else class="dim" title="Not available from single-channel audio">&mdash;</span></template>
                  </h4>

                  <h3>Speaking Time <span class="dim">(agent/cust)</span></h3>
                  <h4>
                    <span class="large"  v-if="agentInfo"  v-html="agentInfo.talkTimeAveragePercent" :title="agentInfo.talkTimeAverageSeconds + ' (Average speaking time)'"></span>
                    <template>
                          <span v-if="agentInfo&&agentInfo.channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + agentInfo.talkTimePercentAgent + ' / Customer owns ' + agentInfo.talkTimePercentCustomer">{{agentInfo.talktimeRatio}}
                            <template v-if="(agentInfo.talkTimePercentCustomer+agentInfo.talkTimePercentAgent)>1"><sup class="footnote">*</sup></template>
                          </span>
                      <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                    </template>
                  </h4>

                  <div v-if="showMixAlert" class="mix-alert">
                    <p>Silence and Speaking Time apply to all calls.</p>
                    <p>Overtalk and all agent/cust break-downs apply to the <strong>{{dualChannelCallCount}}</strong> call<template v-if="dualChannelCallCount!=1">s</template> that <template v-if="dualChannelCallCount!=1">are</template><template v-else >is a</template> dual-channel recording<template v-if="dualChannelCallCount!=1">s</template>.</p>
                  </div>

                </v-row>
              </v-card>

              <v-card flat v-show="showStats && insightsDynaLabelsAgentsTeamDataLoadingErrorStatus===true">
                <v-card-text>{{ insightsDynaLabelsAgentsTeamDataLoadingError }}</v-card-text>
              </v-card>

              <v-card flat v-show="showStats && !loadAgentInfo">
                <v-row align="center" justify="center">
                  <v-col style="text-align: center;">
                    <v-progress-circular
                        class="align-self-center mb-4"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    <div class="progress">retrieving agent stats</div>
                  </v-col>
                </v-row>
              </v-card>
              <p v-show="!showStats" class="blue-grey--text collapsed-text">Agent&nbsp;Statistics</p>
            </v-container>
            <div class="table-container">
              <v-card flat class="mb-12 pb-12">
                <v-card-title>
                  <v-text-field
                      v-model="callSearch"
                      class="mt-0 mb-0 mr-2 ml-2"
                      placeholder="Find Calls"
                      dense
                      hide-details
                      variant="solo"
                      :page.sync="pageNum"
                      :clearable="!searchDisabled"
                      :disabled="!loadCallInfo"
                      @keydown.enter.prevent="searchCalls"
                      @click:clear="clearCallSearch"
                  ></v-text-field>
                  <v-btn
                      :disabled="searchDisabled"
                      :dark="!searchDisabled"
                      small
                      color="primary"
                      @click="searchCalls"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table
                    v-if="userDataLoadingStatus == 'loaded'"
                    class="pb-8"
                    dense
                    :loading="!loadCallInfo"
                    :headers="filteredCallHeaders"
                    :items="getAgentCallData"
                    :items-per-page="pageSize"
                    :server-items-length="itemRowCount"
                    :page.sync="pageNum"
                    loading-text="Insight calls loading, please wait..."
                    @update:options="loadItems"
                    :fixed-header="true"
                    :height="'70vh'"
                    :footer-props="{
                        itemsPerPageOptions: [10, 25, 50, 100],
                        disableItemsPerPage: !loadCallInfo,
                        disablePagination: !loadCallInfo
                      }"
                >
                  <template v-slot:no-data>
                    <v-alert :value="true" color="warning" icon="mdi-message-alert-outline" class="ma-2" dark>
                      {{ noDataMessage }}
                    </v-alert>
                  </template>

                  <template v-slot:item.adjusted_datetime="{ item }">{{ formatDateTime(item.adjusted_datetime) }}</template>

                  <template v-slot:item.customer_sentiment="{ item }">
                        <span v-if="item.channel_count>1">
                        <subjective-glyph
                            :info="item.sentimentCustomer"
                            :scale="50"
                            :type="'sentiment'"
                        >
                        </subjective-glyph>
                      </span>
                  </template>

                  <template v-slot:item.agent_sentiment="{ item }">
                        <span v-if="item.channel_count>1">
                        <subjective-glyph
                            :info="item.sentimentAgent"
                            :scale="50"
                            :type="'sentiment'"
                        >
                        </subjective-glyph>
                        </span>
                  </template>

                  <template v-slot:item.overall_sentiment="{ item }">
                    <subjective-glyph
                        :info="item.sentimentOverall"
                        :scale="50"
                        :type="'sentiment'"
                    >
                    </subjective-glyph>
                  </template>


                  <template v-slot:item.customer_emotion="{ item }">
                    <span v-if="item.channel_count>1">
                      <template v-if="!isChat">
                        <subjective-glyph
                          :info="item.emotionCustomer"
                          :scale="50"
                          :type="'emotion'"
                        >
                        </subjective-glyph>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </span>
                  </template>

                  <template v-slot:item.agent_emotion="{ item }">
                    <span v-if="item.channel_count>1">
                      <template v-if="!isChat">
                        <subjective-glyph
                          :info="item.emotionAgent"
                          :scale="50"
                          :type="'emotion'"
                        >
                        </subjective-glyph>
                      </template>
                      <template v-else><span>&mdash;</span></template>
                    </span>
                  </template>

                  <template v-slot:item.overall_emotion="{ item }">
                    <template v-if="!isChat">
                      <subjective-glyph
                        :info="item.emotionOverall"
                        :scale="50"
                        :type="'emotion'"
                      >
                      </subjective-glyph>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>

                  <template v-slot:item.talk_time="{ item }"><span class="tData" title="Talk Time (the total length of the audio recording)">{{ item.talk_time }}</span></template>

                  <template v-slot:item.silence_total_percent="{ item }">
                    <template v-if="!isChat">
                      <span class="tData" :title="formatHMS(getSilenceSeconds(item.callDuration, item.talkTimeTotalSeconds)) + ' (Total measured silence)'">{{ formatPercent(item.silence_total_percent, true) }}</span>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>
                  <template v-slot:item.silence_extra="{ item }">
                    <template v-if="!isChat">
                      <span v-if="item.channel_count>1" class="dim" :title="'Silence: Agent owns ' + Math.round(item.silence.percent_agent*100) + '% / Customer owns ' + Math.round(item.silence.percent_customer*100) + '%'">({{Math.round(item.silence.percent_agent*100)}}/{{Math.round(item.silence.percent_customer*100)}})</span><span v-else class="tData" title="Not available from single-channel audio">&mdash;</span>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>

                  <template v-slot:item.speaking_time_total_percent="{ item }">
                    <template v-if="!isChat">
                      <span class="tData" :title="(formatHMS(item.talkTimeTotalSeconds)) + ' (Speaking Time: the total conversation time within the audio recording)'">{{ formatPercent(item.speaking_time_total_percent, false) }}</span>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>
                  <template v-slot:item.speaking_time_extra="{ item }">
                    <template v-if="!isChat">
                      <div class="dont-wrap">
                        <span v-if="item.channel_count>1" class="dim" :title="'Speaking Time: Agent owns ' + Math.round(item.talktime.percent_agent*100) + '% / Customer owns ' + Math.round(item.talktime.percent_customer*100) + '%'">({{Math.round(item.talktime.percent_agent*100)}}/{{Math.round(item.talktime.percent_customer*100)}})</span>
                        <span v-else class="tData" title="Not available from single-channel audio">&mdash;</span>
                      </div>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>

                  <template v-slot:item.overtalk_total_percent="{ item }">
                    <template v-if="!isChat">
                      <span v-if="item.channel_count>1" class="tData" :title="formatHMS(item.overtalkTimeSeconds) + ' (Total measured overtalk)'">{{ formatPercent(item.overtalk_total_percent, true) }}</span><span v-else class="tData" title="Not available from single-channel audio">&mdash;</span>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>
                  <template v-slot:item.overtalk_extra="{ item }">
                    <template v-if="!isChat">
                      <span v-if="item.channel_count>1" class="dim" :title="'Overtalk: Agent owns ' + Math.round(item.overtalk.percent_agent*100) + '% / Customer owns ' + Math.round(item.overtalk.percent_customer*100) + '%'">({{Math.round(item.overtalk.percent_agent*100)}}/{{Math.round(item.overtalk.percent_customer*100)}})</span><span v-else class="tData" title="Not available from single-channel audio">&mdash;</span>
                    </template>
                    <template v-else><span>&mdash;</span></template>
                  </template>

                  <template v-slot:item.note_id="{ item }">
                    <v-layout justify-center><note-edit-control v-if="userDataLoadingStatus==='loaded'" @update-call-note="updateCallNote" :notePriv="userData.privileges.notes" :callId="Number(item.id)" :noteId="(item.note_id == null) ? 0 : item.note_id" :noteIsBlank="item.note_is_blank"></note-edit-control></v-layout>
                  </template>

                  <template v-slot:item.has_tags="{ item }">
                    <tags-edit-control v-if="userDataLoadingStatus==='loaded'" @update-call-has-tags="updateCallHasTags" :tagPriv="userData.privileges.tags" :callId="Number(item.id)" :hasTags="Boolean(item.has_tags)"></tags-edit-control>
                  </template>

                  <template v-slot:item.direction="{ item }">{{ normalizeDirection(item.direction) }}</template>

                  <template v-slot:item.agent_name="{ item }">{{ item.agent_name }}</template>
                  <template v-slot:item.caller_number="{ item }">{{ formatPhoneNumber(item.caller_number) }}</template>

                  <template v-slot:item.speakingTime_formatted="{ item }">{{ item.speakingTime_formatted }}</template>

                  <template v-slot:item.transcript="{ item }">
                    <v-btn
                      :disabled="!loadAggregateInfo"
                        text
                        small
                        color="primary"
                        @click="loadModal(item.id)"
                        title="view transcript & play audio"
                    >{{ item.id }}</v-btn>
                  </template>

                  <template v-slot:item.customMetadata="{ item }">
                    <custom-metadata-modal :callId="item.id"></custom-metadata-modal>
                  </template>

                  <template v-slot:item.audit="{ item }">
                    <v-layout justify-center
                      ><audit-icon v-if="userDataLoadingStatus == 'loaded'"
                        :callId="Number(item.id)"
                        :auditedBefore="Boolean(item.has_been_audited)"
                        :isScorecards="false"
                      ></audit-icon
                    ></v-layout>
                  </template>
                </v-data-table>

                <h5 v-if="this.myRouteName=='insights-dynalabel-agent-calls' && getAgentCallCount()>=getTotalCalls()" class="footnote" ><sup class="footnote" >&dagger;</sup> {{getMaxCallMessage()}}</h5>
                <h5 v-if="this.myRouteName=='insights-dynalabel-team-calls' && getTeamCallCount()>=getTotalCalls()" class="footnote" ><sup class="footnote" >&dagger;</sup> {{getMaxCallMessage()}}</h5>
              </v-card>

              <v-card flat v-if="insightsAgentCallDataLoadingErrorStatus===true">
                <v-card-text>{{ insightsAgentCallDataLoadingError }}</v-card-text>
              </v-card>
              <v-card flat v-if="presentationInsightsDataLoadingErrorStatus==true">
                <v-card-text>{{ presentationInsightsDataLoadingError }}</v-card-text>
              </v-card>

            </div>

            </v-container>
        </v-col>
      </v-row>
    </v-container>

    <TranscriptModal
        v-if="loadAggregateInfo"
        :showModal="showModal"
        :callId="String(this.callId)"
        :key="String(this.callId)"
        :context="'insights'"
        :contextId="parseInt(this.uriInsightId)"
        :contextDynalabelId="parseInt(this.uriDynaLabelId)"
        :crumbDefHeader="insightHeader"
        :crumbDynaHeader="dynaLabelHeader"
        :crumbSingleDyna="singleDynaCount"
        :crumbSearchIsEmpty="searchFieldIsEmpty"
        :crumbAgentHeader="getAgentText(agentInfo)"
        @HIDE_MODAL="hideModal"
        @update-call-note="updateCallNote"
        @update-call-has-tags="updateCallHasTags"
    ></TranscriptModal>
    <ScheduledExportsModal
        :showScheduledExportsModal="showScheduledExportsModal"
        :insightOrScorecard="'insights'"
        @HIDE_SCHEDULED_EXPORTS_MODAL="hideScheduledExportsModal"
        :exportPath="exportPath"
    ></ScheduledExportsModal>
  </v-content>
</template>

<script>
import Filters from "@/components/Filters.vue";
import SubjectiveDistributionWidget from "@/components/Widgets/SubjectiveDistributionWidget.vue"
import NoteEditControl from "@/components/Controls/NoteEditControl";
import TagsEditControl from "@/components/Controls/TagsEditControl";
import TranscriptModal from "@/components/Transcripts/Modal";
import AdHocSearch from "@/components/Controls/AdHocSearch.vue";
import ExportMenu from "@/components/Widgets/ExportMenu.vue";
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue"
import InsightsDownload from "@/components/Controls/InsightsDownload.vue";
import {mapGetters, mapActions} from "vuex";
import emotion from "@/mixins/emotion";
import subjective from "@/mixins/subjective";
import sort from "@/mixins/sort";
import moment from "moment";
import presentation from "@/mixins/presentation";
import selectPage from "@/mixins/selectPage";

import SubjectiveGlyph from '@/components/Widgets/SubjectiveGlyph.vue';
import CustomMetadataModal from '../../components/Widgets/CustomMetadataModal.vue';
import InsightProcessingStatus from '../../components/Insights/InsightProcessingStatus.vue';
import InsightBreadcrumbs from '../../components/Breadcrumbs/InsightBreadcrumbs.vue';

import AuditIcon from "@/components/Widgets/AuditIcon.vue";

const MAX_CALLS = process.env.VUE_APP_MAX_CALLS_INSIGHTS
const MAX_CALL_MESSAGE = process.env.VUE_APP_MAX_CALL_MESSAGE_INSIGHTS;

export default {
  mixins: [emotion, subjective, sort, presentation, selectPage],
  components: {
    TranscriptModal,
    TagsEditControl,
    NoteEditControl,
    SubjectiveDistributionWidget,
    Filters,
    SubjectiveGlyph,
    CustomMetadataModal,
    AdHocSearch,
    ExportMenu,
    ScheduledExportsModal,
    InsightsDownload,
    InsightProcessingStatus,
    InsightBreadcrumbs,
    AuditIcon,
  },
  data() {
    return {
      agentSortBy: 'name',
      agentSortDesc: false,
      showModal: false,
      showScheduledExportsModal: false,
      agentSearch: '',
      callSearch: '',
      emotionType: '',
      agentId: 0,
      callId: "0",
      showFootnote: false,
      showMixAlert: false,
      singleChannelCallCount: 0,
      dualChannelCallCount: 0,
      agentInfo: {},
      agentsInScope: [],
      accountsInScope: [],
      agentCallCount: 0,
      teamCallCount: 0,
      channelCount: 0,
      insightsUrl: '',
      noDataMessage: process.env.VUE_APP_NO_CALLS_FOUND,
      pageNum: 1,
      pageSize: 10,
      orderDirection: "desc",
      orderBy: null,
      showStats: true,
      prevRoute: null,
      exportPath: 3,
    }
  },
  computed: {
    ...mapGetters("presentation", [
      "presentationInsightsData",
      "presentationInsightsDataLoadingStatus",
      "presentationInsightsDataLoadingError",
      "presentationInsightsDataLoadingErrorStatus",

      "presentationInsightsCallData",
      "presentationInsightsCallDataLoadingStatus",
      "presentationInsightsCallDataLoadingError",
      "presentationInsightsCallDataLoadingErrorStatus",
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "standardUser",
    ]),
    ...mapGetters('dateRange', [
      'start_date',
      'end_date',
      'date_range_english',
    ]),
    ...mapGetters("insights", [
      "insightsAdHocSearchPath",
      "insightsDynaLabelsAgentsTeamData",
      "insightsDynaLabelsAgentsTeamDataLoadingStatus",
      "insightsDynaLabelsAgentsTeamDataLoadingErrorStatus",
      "insightsDynaLabelsAgentsTeamDataLoadingError",
      "insightsDynaLabelsAgentsAgentData",
      "insightsDynaLabelsAgentsAgentDataLoadingStatus",
      "insightsDynaLabelsAgentsAgentDataLoadingErrorStatus",

      "insightsAgentCallData",
      "insightsAgentCallDataUrl",
      "insightsAgentCallDataLoadingStatus",
      "insightsAgentCallDataLoadingError",
      "insightsAgentCallDataLoadingErrorStatus",

      "insightCallLogSkeleton",
      "insightCallLogSkeletonLoadingStatus",
      "insightCallLogSkeletonLoadingError",
      "insightCallLogSkeletonLoadingErrorStatus",

      "agentDynalabelData",

      "displayFormats"
    ]),
    isProxying() {
      return (this.userData.id != this.userData.csrep_userid || false)
    },
    canManageSearches() {
      return (this.userData.privileges.callFinderManageSearches || false)
    },
    expansionIcon() {
      return (this.showStats) ? "mdi-arrow-collapse-left" : "mdi-arrow-expand-right"
    },
    searchFieldIsEmpty() {
      return this.$refs.hasOwnProperty('adHocSearch')
          && this.$refs.adHocSearch.hasOwnProperty('search')
          && (typeof this.$refs.adHocSearch.search == 'undefined'
              || this.$refs.adHocSearch.search == null
              || (typeof this.$refs.adHocSearch.search == 'string'
                  && this.$refs.adHocSearch.search == '')) || false
    },
    fixHeight() {
      return (window.innerHeight>=1080 || this.itemRowCount<=10) ? '100%' : "90vh"
    },
    loadAgentInfo() {
      return (this.insightsDynaLabelsAgentsAgentDataLoadingStatus==='loaded' &&
          this.presentationInsightsDataLoadingStatus=='loaded' &&
          this.presentationInsightsCallDataLoadingStatus=='loaded' &&
          this.insightsDynaLabelsAgentsTeamDataLoadingStatus==='loaded' &&
          this.insightsDynaLabelsAgentsAgentDataLoadingErrorStatus===false &&
          this.insightsDynaLabelsAgentsAgentData.length>0 &&
          this.agentInfo !== undefined)
    },
    loadAgentStats() {
      return this.insightsDynaLabelsAgentsAgentDataLoadingStatus==='loaded' &&
          this.presentationInsightsDataLoadingStatus=='loaded' &&
          this.insightsDynaLabelsAgentsAgentDataLoadingErrorStatus===false&&
          this.insightsDynaLabelsAgentsAgentData.length>0
    },
    loadCallInfo() {
      return this.insightsAgentCallDataLoadingStatus=='loaded'&&
          this.presentationInsightsCallDataLoadingStatus=='loaded' &&
          this.insightsAgentCallDataLoadingErrorStatus===false &&
          this.presentationInsightsDataLoadingErrorStatus==false &&
          this.userDataLoadingStatus=='loaded'
    },
    loadAggregateInfo() {
      return this.insightsDynaLabelsAgentsTeamDataLoadingStatus==='loaded' &&
          this.insightsDynaLabelsAgentsTeamDataLoadingErrorStatus==false &&
          this.presentationInsightsDataLoadingStatus=='loaded'
    },
    itemRowCount() {
      return this.insightCallLogSkeleton.hasOwnProperty("call_count")
          ? this.insightCallLogSkeleton.call_count
          : 0;
    },
    searchDisabled() {
      let validSearch = this.callSearch && this.callSearch.trim() != "" ? true : false;
      return !this.loadCallInfo || !validSearch;
    },
    filteredAgentList() {
      if(!this.$store.getters["filters/agents"]) return []
      else return this.$store.getters["filters/agents"].agents || []
    },
    filteredAccountList() {
      if(!this.$store.getters["filters/accounts"]) return []
      else return this.$store.getters["filters/accounts"].accounts || []
    },
    uriDynaLabelId() {
      return this.$route.params.dynalabelId;
    },
    uriInsightId() {
      return this.$route.params.id;
    },
    getAgentCallData() {
      return this.insightsAgentCallData.map(call => ({
        ...call,
        "adjusted_datetime": call.adjustedDateTime,
        "caller_number": call.caller_number,
        "silence_total_percent": call.silence.total_percent,
        "overtalk_total_percent": (call.channel_count > 1 && typeof call.overtalk !== 'undefined') ? call.overtalk.total_percent : 0,
        "talkTimeTotalSeconds": call.talktime.total_seconds,
        "sentimentCustomer": this.getEmotionIndex(call.customer_sentiment.sentiment_first_half, call.customer_sentiment.sentiment_second_half),
        "sentimentAgent": this.getEmotionIndex(call.agent_sentiment.sentiment_first_half, call.agent_sentiment.sentiment_second_half),
        "sentimentOverall": this.getEmotionIndex(call.overall_sentiment.sentiment_first_half, call.overall_sentiment.sentiment_second_half),
        "emotionCustomer": this.getEmotionIndex(call.customer_emotion.emotion_first_half, call.customer_emotion.emotion_second_half),
        "emotionAgent": this.getEmotionIndex(call.agent_emotion.emotion_first_half, call.agent_emotion.emotion_second_half),
        "emotionOverall": this.getEmotionIndex(call.overall_emotion.emotion_first_half, call.overall_emotion.emotion_second_half),
        "agent_name": call.agent_name,
        "agentName": call.agent_name,
        "media_type": this.agentDynalabelData.mediaType,
        "callDuration": call.duration,
        "talk_time": this.formatHMS(call.duration),
        "silencePercentAgent": Math.round(call.silence.percent_agent*100),
        "silencePercentCustomer": Math.round(call.silence.percent_customer*100),
        "overtalkPercentAgent": Math.round(call.overtalk.percent_agent*100),
        "overtalkPercentCustomer": Math.round(call.overtalk.percent_customer*100),
        "overtalkTimeSeconds": call.overtalk.total_seconds,
        "talkTimePercentAgent": Math.round(call.talktime.percent_agent*100),
        "talkTimePercentCustomer": Math.round(call.talktime.percent_customer*100),
        "speaking_time_total_percent": call.talktime.total_percent,
        "callId": call.id
      }))
    },
    insightHeader() {
      return this.agentDynalabelData.insightTitle
    },
    dynaLabelHeader() {
      return this.agentDynalabelData.dynalabelTitle
    },
    dynalabelCount() {
      return this.agentDynalabelData.dynalabelCount
    },
    multiDynaCount() {
      return this.dynalabelCount > 1
    },
    singleDynaCount() {
      return this.dynalabelCount < 2
    },
    teamRowData() {
      let myThis = this
      return this.insightsDynaLabelsAgentsTeamData.map(
          (obj) => {
            let channelCount = 0;
            if(obj.single_channel_call_count>0&&obj.dual_channel_call_count>0) {
              myThis.showMixAlert = true;
              channelCount = 3;
            } else {
              channelCount = (obj.dual_channel_call_count>0) ? 2 : 1;
            }

            myThis.channelCount = channelCount
            myThis.teamCallCount = obj.callCount
            if ((obj.talktime.percent_customer + obj.talktime.percent_agent) > 1) myThis.showFootnote = true
            return myThis.insightsPresentationFormattedData(obj, false, channelCount, true)
          }
      )
    },
    agentRowData() {
      let myThis = this
      return this.insightsDynaLabelsAgentsAgentData.map(
          (obj) => {
            let channelCount = 0;
            if(obj.single_channel_call_count>0&&obj.dual_channel_call_count>0) {
              myThis.showMixAlert = true;
              channelCount = 3;
            } else {
              channelCount = (obj.dual_channel_call_count>0) ? 2 : 1;
            }
            myThis.teamCallCount = obj.callCount
            if ((obj.talktime.percent_customer + obj.talktime.percent_agent) > 1) myThis.showFootnote = true
            return myThis.insightsPresentationFormattedData(obj, true, channelCount, true)
          }
      )
    },
    filteredAggregateHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationInsightsData.map(p => p.source_key);
      return this.teamHeaders
          .filter(function(o) {
            return sourceKeys.includes(o.key);
          })
          .sort(
              ({ key: r }, { key: t }) =>
                  sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
          );
    },
    filteredGroupedHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationInsightsData.map(p => p.source_key);
      return this.agentHeaders
          .filter(function(o) {
            return sourceKeys.includes(o.key);
          })
          .sort(
              ({ key: r }, { key: t }) =>
                  sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
          );
    },
    teamHeaders() {
      return [
        {text: "Agent", value: "name", sortable: false, width: "1%", key: 'agent_insights[].agent.name'},
        {text: "Talk Time", value: "averageDuration_formatted", align: "center", sortable: false, width: "1%", key: 'agent_insights[].average_duration'},
        {text: this.headerPrefix + "Sentiment", value: "team_sentiment", align: "center", sortable: false, width: "1%", key: 'agent_insights[].sentiment_counts.agent_sentiment_counts||agent_insights[].sentiment_counts.customer_sentiment_counts||agent_insights[].sentiment_counts.overall_sentiment_counts'},
        {text: this.headerPrefix + "Emotion", value: "team_emotion", align: "center", sortable: false, width: "1%", key: 'agent_insights[].emotion_counts.agent_emotion_counts||agent_insights[].emotion_counts.customer_emotion_counts||agent_insights[].emotion_counts.overall_emotion_counts'} ,
        {text: "Silence", value: "silenceAveragePercent", align: "center", sortable: false, width: "1%", key: 'agent_insights[].silence.average_percent'},
        {text: "agent/cust", value: "silenceExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'agent_insights[].silence.percent_agent/agent_insights[].silence.percent_customer'},
        {text: "Overtalk", value: "overtalkAveragePercent", align: "center", sortable: false, width: "1%", key:'agent_insights[].overtalk.average_percent'},
        {text: "agent/cust", value: "overtalkExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'agent_insights[].overtalk.percent_agent/agent_insights[].overtalk.percent_customer'},
        {text: "Speaking Time", value: "talkTimeAverageSeconds", align: "center", sortable: false, width: "1%", key: 'agent_insights[].overtalk.percent_agent/agent_insights[].overtalk.percent_customer'},
        {text: "agent/cust", value: "talkTimeExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'agent_insights[].talktime.percent_agent/agent_insights[].talktime.percent_customer'},
        {text: "Calls", value: "callCount", align: "center", sortable: false, width: "1%", key: 'agent_insights[].call_count'},
      ]
    },
    agentHeaders() {
      return [
        {text: "Agent", value: "name", class: "headerAgentName", width: "1%", key: 'agent_insights[].agent.name'},
        {text: "Talk Time", value: "averageDuration_formatted", align: "center", sortable: true, width: "1%", key: 'agent_insights[].average_duration'},
        {text: this.headerPrefix + "Sentiment", value: "agent_sentiment", align: "center", sortable: true , width: "1%", key: 'agent_insights[].sentiment_counts.agent_sentiment_counts||agent_insights[].sentiment_counts.customer_sentiment_counts||agent_insights[].sentiment_counts.overall_sentiment_counts'},
        {text: this.headerPrefix + "Emotion", value: "agent_emotion", align: "center", sortable: true, width: "1%", key: 'agent_insights[].emotion_counts.agent_emotion_counts||agent_insights[].emotion_counts.customer_emotion_counts||agent_insights[].emotion_counts.overall_emotion_counts'},
        {text: "Silence", value: "silenceAveragePercent", align: "center", width: "1%", key: 'agent_insights[].silence.average_percent'},
        {text: "agent/cust", value: "silenceExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'agent_insights[].silence.percent_agent/agent_insights[].silence.percent_customer'},
        {text: "Overtalk", value: "overtalkAveragePercent", align: "center", width: "1%", key:'agent_insights[].overtalk.average_percent'},
        {text: "agent/cust", value: "overtalkExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'agent_insights[].overtalk.percent_agent/agent_insights[].overtalk.percent_customer'},
        {text: "Speaking Time", value: "talkTimeAveragePercent", align: "center", width: "1%", key: 'agent_insights[].overtalk.percent_agent/agent_insights[].overtalk.percent_customer'},
        {text: "agent/cust", value: "talkTimeExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'agent_insights[].talktime.percent_agent/agent_insights[].talktime.percent_customer'},
        {text: "Calls", value: "callCount", align: "center", width: "1%", key: 'agent_insights[].call_count'},
      ]
    },
    callHeaders() {
      if (this.channelCount>1) {
        return [
          {text: "Date/Time", value: "adjusted_datetime", key: "call_insights[].adjustedDateTime", align: "center", sortable: this.loadCallInfo},
          {text: "Customer", value: "caller_number", key: "call_insights[].ani||call_insights[].pots", align: "center", sortable: this.loadCallInfo},
          {text: "Talk Time", value: "talk_time", key: "call_insights[].duration", align: "center", sortable: this.loadCallInfo},
          {text: "Customer Sentiment", value: "customer_sentiment", key: "call_insights[].customer_sentiment.sentiment_first_half&call_insights[].customer_sentiment.sentiment_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Agent Sentiment", value: "agent_sentiment", key: "call_insights[].agent_sentiment.sentiment_first_half&call_insights[].agent_sentiment.sentiment_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Overall Sentiment", value: "overall_sentiment", key: "call_insights[].overall_sentiment.sentiment_first_half&call_insights[].overall_sentiment.sentiment_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Customer Emotion", value: "customer_emotion", key: "call_insights[].customer_emotion.emotion_first_half&call_insights[].customer_emotion.emotion_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Agent Emotion", value: "agent_emotion", key: "call_insights[].agent_emotion.emotion_first_half&call_insights[].agent_emotion.emotion_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Overall Emotion", value: "overall_emotion", key: "call_insights[].overall_emotion.emotion_first_half&call_insights[].overall_emotion.emotion_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Silence", value: "silence_total_percent", key: "call_insights[].silence.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "silence_extra", key: "call_insights[].silence.percent_agent/call_insights[].silence.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Overtalk", value: "overtalk_total_percent", key: "call_insights[].overtalk.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "overtalk_extra", key: "call_insights[].overtalk.percent_agent/call_insights[].overtalk.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Speaking Time", value: "speaking_time_total_percent", key: "call_insights[].talktime.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "speaking_time_extra", key: "call_insights[].talktime.percent_agent/call_insights[].talktime.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Agent", value: "agent_name", class: "headerAgentName", key: "call_insights[].agentName", align: "center", sortable: this.loadCallInfo},
          {text: "Notes", value: "note_id", key: "call_insights[].note_id", align: "center", sortable: this.loadCallInfo},
          {text: "Tags", value: "has_tags", key: "call_insights[].has_tags", align: "center", sortable: this.loadCallInfo},
          {text: "Call ID", value: "transcript", key: "call_insights[].id", align: "center", sortable: this.loadCallInfo},
          {text: "Call_ID", value: "callId", key: "call_insights[].id", align: " d-none", sortable: this.loadCallInfo},
          {text: "Custom Metadata", value: "customMetadata", key: "customMetadata", align: "center", sortable: false},
          {text: "Client Call Identifier", value: "in_asterisk_id", key:"call_insights[].in_asterisk_id", sortable: this.loadCallInfo},
        ]
      } else  {
        return [
          {text: "Date/Time", value: "adjusted_datetime", key: "call_insights[].adjustedDateTime", align: "center", sortable: this.loadCallInfo},
          {text: "Customer", value: "caller_number", key: "call_insights[].ani||call_insights[].pots", align: "center", sortable: this.loadCallInfo},
          {text: "Talk Time", value: "talk_time", key: "call_insights[].duration", align: "center", sortable: this.loadCallInfo},
          {text: "Overall Sentiment", value: "overall_sentiment", key: "call_insights[].overall_sentiment.sentiment_first_half&call_insights[].overall_sentiment.sentiment_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Overall Emotion", value: "overall_emotion", key: "call_insights[].overall_emotion.emotion_first_half&call_insights[].overall_emotion.emotion_second_half", align: "center", sortable: this.loadCallInfo},
          {text: "Silence", value: "silence_total_percent", key: "call_insights[].silence.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "silence_extra", key: "call_insights[].silence.percent_agent/call_insights[].silence.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Overtalk", value: "overtalk_total_percent", key: "call_insights[].overtalk.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "overtalk_extra", key: "call_insights[].overtalk.percent_agent/call_insights[].overtalk.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Speaking Time", value: "speaking_time_total_percent", key: "call_insights[].talktime.total_percent", align: "center", sortable: this.loadCallInfo},
          {text: "agent/cust", value: "speaking_time_extra", key: "call_insights[].talktime.percent_agent/call_insights[].talktime.percent_customer", align: "center", sortable: this.loadCallInfo, class: "vdatatableheaderdim"},
          {text: "Agent", value: "agent_name", class: "headerAgentName", key: "call_insights[].agentName", align: "center", sortable: this.loadCallInfo},
          {text: "Notes", value: "note_id", key: "call_insights[].note_id", align: "center", sortable: this.loadCallInfo},
          {text: "Tags", value: "has_tags", key: "call_insights[].has_tags", align: "center", sortable: this.loadCallInfo},
          {text: "Call ID", value: "transcript", key: "call_insights[].id", align: "center", sortable: this.loadCallInfo},
          {text: "Call_ID", value: "callId", key: "call_insights[].id", align: " d-none", sortable: this.loadCallInfo},
          {text: "Custom Metadata", value: "customMetadata", key: "customMetadata", align: "center", sortable: false},
          {text: "Client Call Identifier", value: "in_asterisk_id", key:"call_insights[].in_asterisk_id", sortable: this.loadCallInfo},
        ]
      }
    },
    filteredCallHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationInsightsCallData.map(p => p.source_key)

      let rtn = this.callHeaders
          .filter(function (o) {
            return sourceKeys.includes(o.key)
          })
          .sort(
              ({ key: r }, { key: t }) =>
                  sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
          );

      if(this.isProxying && this.canManageSearches && rtn.length != 0) {
        rtn.push({
          text: "Audit Call",
          value: "audit",
          sortable: false,
          key: "calls[].in_asterisk_id",
        })
      }
      return rtn
    },
    headerPrefix() { return (this.channelCount==2) ? "Customer " : "Overall " },
    myRouteName() {
      return this.$route.name
    },
    isChat() {
      return this.agentDynalabelData && this.agentDynalabelData.mediaType == 'Chat';
    },
    insightAgentUrl() {
      // make the scorecards URL insightDynalabelUrl
      let exportUrl = new URL(process.env.VUE_APP_INSIGHTS_INTERNAL + this.$route.params.id + '/dynalabels/' + this.$route.params.dynalabelId + '/agents/')
      let subjectiveFilterObject = this.$store.getters["filters/subjectiveSet"] || {subjectiveSet:[]}
      exportUrl.searchParams.append('start_date',moment(this.start_date).format("YYYY-MM-DD"))
      exportUrl.searchParams.append('end_date',moment(this.end_date).format("YYYY-MM-DD"))

      if(JSON.stringify(subjectiveFilterObject.subjectiveSet) !== '[]') {
        subjectiveFilterObject.subjectiveSet.forEach(value => {
          exportUrl.searchParams.append('subjective',JSON.stringify(value))
        })
      }

      if(this.$route.query.speaker !== undefined) exportUrl.searchParams.append('speaker',this.$route.query.speaker)
      if(this.$route.query.phrase !== undefined) exportUrl.searchParams.append('search_phrase',this.$route.query.phrase)

      exportUrl.searchParams.append('grouping',this.getUnit)

      return exportUrl.toString()
    },
  },
  methods: {
    ...mapActions("presentation", [
      "retrievePresentationInsightsData",
    ]),
    ...mapActions("insights", [
      "setInsightsAdHocSearchPath",
      "updateCallNote",
      "updateCallHasTags",
      "retrieveInsightsAgentsTeamData",
      "retrieveInsightsAgentsAgentData",
      "retrieveInsightsAgentCallData",
    ]),
    ...mapActions({
      setAggregatedAgentsLoadingStatus: "insights/setAggregatedAgentsLoadingStatus",
      retrieveCallLogSkeleton: "insights/retrieveCallLogSkeleton"
    }),
    toggleExpansion() {
      this.showStats = !this.showStats
    },
    searchChanged(status) {
      switch(status) {
        case 'on':
            this.setInsightsAdHocSearchPath(this.$route.path)
            break;
        case 'off':
        default:
            this.setInsightsAdHocSearchPath('')
            break;
      }
    },
    reApplySearchPhrase() {
      if(this.$route.query.hasOwnProperty('phrase')&&this.$route.query.phrase!='') {
        this.$refs.adHocSearch.search = this.$route.query.phrase
      }
    },
    loadItems({ sortDesc, page, itemsPerPage, sortBy }) {
      this.pageNum = page;
      this.pageSize = itemsPerPage;
      let parameters = this.getDataParameters();
      if (sortBy.length > 0) {
        //paginate/sort
        this.orderBy = sortBy[0];
        this.categorySortId = null;
        this.orderDirection =
            sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      } else {
        this.orderBy = null;
        this.orderDirection = "desc";
      }
      this.retrieveInsightsAgentCallData(parameters);
    },
    searchCalls() {
      this.pageNum = 1
      let params = this.getDataParameters();
      this.retrieveInsightsAgentCallData(params);
      this.retrieveCallLogSkeleton(params);
    },
    clearCallSearch() {
      this.callSearch = "";
      let params = this.getDataParameters();
      this.retrieveInsightsAgentCallData(params);
      this.retrieveCallLogSkeleton(params);
    },
    exportClick(item) {
      switch (item) {
        case 'downloadExport':
          if(this.myRouteName=='insights-dynalabels-agents') {
            this.$refs.InsightsDownloadAggregate.exportInsights(null,null)
          } else {
            this.$refs.InsightsDownload.exportInsights(this.orderBy,this.orderDirection)
          }
          // do immediate export stuff
          break
        case 'scheduleExport':
          // pop schedule export modal to do schedule stuff
          this.exportPath = (this.myRouteName=='insights-dynalabels-agents') ? 2 : 3;
          this.showScheduledExportsModal = true
          break
      }
    },
    getAgentText(agentInfo) {
      let name = (agentInfo) ? agentInfo.name: ''
      return (this.$route.params.agentId==0) ? "All Agents" : name
    },
    numberGrammar(num) {
      return (num>10) ? num : this.numberToWord(num)
    },
    getAgentHeader(agentName) {
      return (this.$route.params.agentId==0) ? "All Agents" : agentName
    },
    numberToWord(num) {
      return ['zero','one','two','three','four','five','six','seven','eight','nine','ten'][num]
    },
    getTotalCalls() {
      return MAX_CALLS;
    },
    filtersLoaded(f) {
      this.agentsInScope = (f.find(x=> x.value=='agents')) ? f.find(x=> x.value=='agents').options : []
      this.accountsInScope = (f.find(x=> x.value=='accounts')) ? f.find(x=> x.value=='accounts').options : []
    },
    setMixAlert() {
      if(this.insightsAgentCallDataLoadingStatus==='loaded') {
          this.setAgentInfo()
          if (typeof this.agentInfo !== 'undefined') {
            if(this.agentInfo.channelCount==3) {this.showMixAlert = true}
          }
        }
    },
    getSilenceSeconds(callDuration, talkTime) {
      return callDuration - talkTime
    },
    getAgentCallCount (){
      return this.agentCallCount
    },
    getTeamCallCount (){
      return this.teamCallCount
    },
    setAgentInfo() {
      if(this.insightsDynaLabelsAgentsTeamDataLoadingStatus==='loaded') {
        switch(this.myRouteName) {
          case 'insights-dynalabels-agents':
            this.agentInfo = {}
            break;
          case 'insights-dynalabel-agent-calls':
            if (this.agentId==0) {
              this.agentInfo = this.teamRowData[0]
            } else {
              this.agentInfo = this.agentRowData.filter(agent => { return this.agentId==agent.id})[0]
            }
            break;
        }
        if(this.agentInfo) {
          this.singleChannelCallCount = this.agentInfo.singleChannelCallCount
          this.dualChannelCallCount = this.agentInfo.dualChannelCallCount
        }
      }
    },
    loadModal(callid) {
      this.callId = callid
      this.showModal = true;
    },
    hideModal(hideModal) {
      this.showModal = hideModal;
    },
    hideScheduledExportsModal(hideScheduledExportsModal) {
      this.showScheduledExportsModal = hideScheduledExportsModal;
    },
    formatPhoneNumber(number) {
      if(!number) return ''
      return number.length == 10
          ? "(" +
          number.slice(0, 3) +
          ") " +
          number.slice(3, 6) +
          "-" +
          number.slice(6)
          : number;
    },
    formatDate(date) {
      return moment(date).format("MMMM D, YYYY");
    },
    formatDateTime(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
    },
    formatDecimalMinutes(seconds) {
      return Math.round((seconds / 60) * 10) / 10;
    },
    normalizeDirection(s) {
      if(s.length>1) return s.charAt(0).toUpperCase() + s.substring(1);
      else {
        let c = s.charAt(0).toUpperCase()
        return (c=='I') ? 'In' : 'Out'
      }
    },
    getMaxCallMessage() {
      return MAX_CALL_MESSAGE
    },
    getMaxCallCount() {
      return MAX_CALLS;
    },
    getTagsIcon(i) {
      return this.tagsIcons[i];
    },
    loadAgentCalls(agent_id) {
      let insight_id = this.$route.params.id
      this.$router.push({ path: '/insights/' + insight_id + '/dynalabels/' + this.$route.params.dynalabelId + '/agents/' + agent_id + '/calls', query: this.$route.query }).catch(err => {})
      setTimeout(function() {
        if(this.$vuetify) this.$vuetify.goTo(0)
      }, 1500)
    },
    loadTeamCalls() {
      let insight_id = this.$route.params.id
      this.$router.push({ path: '/insights/' + insight_id + '/dynalabels/' + this.$route.params.dynalabelId + '/agents/0/calls', query: this.$route.query }).catch(err => {})
      setTimeout(function() {
        if(this.$vuetify) this.$vuetify.goTo(0)
      }, 1500)
    },
    formatPercent(val, limit) {
      let percent = String(Math.round(val * 100)) ;
      if (limit) {
        if (Number(percent) > 100) {
          return "100%"
        } else {
          return percent + "%"
        }
      } else {
        return percent + "%"
      }
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
          .add(seconds, "seconds")
          .format("HH:mm:ss")
          .replace(/^0(?:0:0?)?/, "");
    },
    clearAgentSearch() {
      this.agentSearch = ''
    },
    resetSearch() {
      let resetQuery = Object.assign({},this.$route.query)
      delete resetQuery.phrase
      delete resetQuery.speaker
      this.$refs.adHocSearch.search = ''
      this.$router.replace({ path: this.$route.path, query: resetQuery})
    },
    goBack(location) {
      this.agentId = 0;
      this.selectInsightPage(location, false, this.$refs)
    },
    filterUpdated() {
      this.loadPresentationData()
      if (this.$route.name == 'insights-dynalabel-agent-calls' || this.$route.name == 'insights-dynalabel-team-calls') {
        this.loadAgentCallData()
      }
    },
    getDataParameters() {
      let insight_id = this.$route.params.id
      let dynalabel_id = this.$route.params.dynalabelId
      let agent_id = this.$route.params.agentId
      let accountsFilterObject = this.$store.getters["filters/accounts"] || {accounts:[]}
      let agentFilterObject = this.$store.getters["filters/agents"] || {agents:[]}
      let subjectiveFilterObject = this.$store.getters["filters/subjectiveSet"] || {subjectiveSet:[]}
      let paramObj = {
        starts_at: moment(this.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.end_date).format("YYYY-MM-DD"),
        insight_id: insight_id,
        dynalabel_id: dynalabel_id,
        agent_id: agent_id,
        agent_ids: agentFilterObject.agents,
        account_ids: accountsFilterObject.accounts,
        subjective: subjectiveFilterObject.subjectiveSet,
        grouping: this.getUnit,
        speaker: this.$route.query.speaker,
        phrase: this.$route.query.phrase,
        page: this.pageNum,
        page_size: this.pageSize,
        order_by: this.orderBy,
        order_direction: this.orderDirection,
        aggregate_type: 'call',
        presentation: this.presentationInsightsData,
        search_calls: this.callSearch ? this.callSearch.trim() : this.callSearch,
      }
      // include audit info if user is proxying and can manage searches
      if(this.isProxying && this.canManageSearches) {
        paramObj.get_audit_info = true
      }
      return paramObj
    },
    loadAgentData() {
      let parameters = this.getDataParameters()
      this.retrieveInsightsAgentsTeamData(parameters)
      this.retrieveInsightsAgentsAgentData(parameters)
    },
    loadAgentCallData() {
      this.showMixAlert = false
      let parameters = this.getDataParameters()
      this.retrieveInsightsAgentCallData(parameters)
      this.retrieveCallLogSkeleton(parameters)
    },
    loadPresentationData() {
      let insight_id = this.$route.params.id
      if (this.$route.name == 'insights-dynalabels-agents') {
        this.retrievePresentationInsightsData({
          insight_id: insight_id,
          aggregate_type: "agent",
          display_type: 'D',
        });
      } else if(this.$route.name == 'insights-dynalabel-agent-calls')  {
        this.retrievePresentationInsightsData({
          insight_id: insight_id,
          aggregate_type: "call",
          display_type: 'D',
        });
        this.retrievePresentationInsightsData({
          insight_id: insight_id,
          aggregate_type: "agent",
          display_type: 'D',
        });
      }
    },
    routeChanged() {
      if (this.$route.name == 'insights-dynalabel-agent-calls') {
        this.agentId = this.$route.params.agentId
        this.loadPresentationData()
        this.loadAgentCallData()
      } else if (this.$route.name == 'insights-dynalabel-team-calls') {
        this.loadAgentCallData()
      } else {
        this.agentId = 0
        this.loadPresentationData()
      }
    },
    getCallCountDisplay(item) {
      return (this.$route.query.phrase) ? (item.callCount + '/' + item.overallCount) : item.callCount;
    },
    getCallCountStyle(item) {
      return (item.callCount == 0) ? 'no-data' : '';
    },
    getDateRangeString() {
      return moment(this.start_date).format("M/D/YYYY") + ' - ' +
          moment(this.end_date).format("M/D/YYYY");
    },
    resetData() {
      this.pageNum = 1;
      this.pageSize = 10;
    },
    checkUserData() {
      if(this.userDataLoadingStatus=="loaded") {
        this.doMounted()
      }
    },
    doMounted() {
      this.routeChanged()
      this.loadPresentationData()
      this.$root.$on('DATE_RANGE_UPDATED', () => {
        this.$router.push(
          { query:
              Object.assign(
                {},
                this.$route.query,
                { start_date: String(moment(this.$store.getters["dateRange/start_date"]).format("YYYY-MM-DD")) },
                { end_date: String(moment(this.$store.getters["dateRange/end_date"]).format("YYYY-MM-DD")) },
              )
          }
        ).catch(err => {})
      })
    }
  },
  created() {
    this.setAggregatedAgentsLoadingStatus("loading")
  },
  mounted() {
    this.checkUserData()
  },
  watch: {
    $route: "routeChanged",
    insightsDynaLabelsAgentsTeamDataLoadingStatus: "setAgentInfo",
    insightsDynaLabelsAgentsAgentDataLoadingStatus: "setAgentInfo",
    insightsAgentCallDataLoadingStatus: "setMixAlert",
    presentationInsightsDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.loadAgentData()
      }
    },
    userDataLoadingStatus: "checkUserData",
  },
};
</script>


<style>
.vdatatableheaderdim {
  color: rgba(0,0,0,.26) !important;
}

.vdatatableheaderdim:hover {
  color: rgba(0,0,0,.4) !important;
  cursor: pointer;
}
</style>

<style scoped>
h1, h2, h3, h4 { width: 100%; }
th span {
  font-size: .8rem;
  color: rgba(0,0,0,.75);
}

th.dim span {
  font-size: .7rem;
}
td > span.name {
  font-size: 1.1rem;
  cursor: pointer;
}
td > span.tData {
  font-size: 1rem;
  cursor: help;
  text-align: center;
}
td span.dim {
  font-size: .9rem;
  cursor: help;
}
h1 {
  line-height: 1.2rem !important;
  font-size: 1.2rem !important;
}

h1.main {
  line-height: 1.5rem !important;
  font-size: 1.5rem !important;
}
h1.main > span.dim {
  font-size: 1.2rem !important;
  color: rgba(0,0,0,.4);
}
h2 {
  margin-top: .75rem;
  line-height: 1.2rem !important;
  font-size: 1.1rem !important;
  color: rgba(0,0,0,.9) !important;
}
h3 {
  margin-top: 1rem;
  position: relative;
  line-height: 1.2rem !important;
  font-size: .9rem !important;
  color: rgba(0,0,0,.9) !important;
}
h3 > span.dim {
  font-size: .75rem;
  color:black;
  display: inline-block;
  position: absolute;
  left: 7rem;
}
h4 {
  position: relative;
  margin-top: .75rem;
}
h4 > span.large {
  font-size: 2rem;
  color: rgba(0,0,0,.8) !important;
  cursor: help;
}
h4 > span.medium {
  font-size: 1.5rem;
  color: rgba(0,0,0,.8) !important;
  cursor: help;
}
h4 span.dim {
  padding-left: .75rem;
  font-size: .9rem;
  color: black;
  display: inline-block;
  position: absolute;
  left: 6.25rem;
  cursor: help;
}
.progress {
  font-size: .9rem;
  color: rgba(0, 0, 0, .5);
}
h5.footnote {
  margin-bottom: .5rem;
}
sup.footnote {
  vertical-align: baseline;
  position: relative;
  top: 0rem;
  font-size: 1rem;
  color: rgba(179, 63, 38, 1);
}
h5.footnote sup.footnote {
  margin-left: .5rem;
  margin-right: .5rem;
}
div.mix-alert {
  margin-top: 2rem;
  padding: 0 2rem 0 0;
}
div.mix-alert p {
  font-size: .9rem;
}
.float-subjective {
  width: 50%;
  float: left;
}
.float-child {
  width: 50%;
  float: left;
}

.no-data {
  opacity: 50%;
  color: #C0C0C0;
}

::v-deep button.agent-btn span.v-btn__content {
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 100%;
}
.stats-container {
  flex-shrink: 1;
  margin-left: 1rem;
  padding-top: 0;
  min-width: 200px;
  max-width: 220px;
}
.stats-container-collapsed {
  flex-shrink: 1;
  margin-left: 1rem;
  padding-top: 0;
  width: 70px;
}
.table-container {
  flex-grow: 1;
  padding-left: 1rem;
  margin-right: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.expand-button {
  text-align: left;
  margin-left: 3rem;
}
.expand-button-collapsed {
  text-align: center;
  margin-bottom: 3rem;
  margin-left: .25rem;
}
.collapsed-text {
  transform: rotate(90deg);
  font-weight: 700;
}

</style>
