<template>
  <div @click="openCallModal">
    <the-task-icon :task="computedTask" v-show="scorecardTaskListLoadingStatus=='loaded'"></the-task-icon>
  </div>
</template>

<script>
import taskIcon from "@/mixins/tasks/taskIcon";
import theTaskIcon from "@/components/Widgets/Tasks/TheTaskIcon.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import CacheHandler from "@/utils/CacheHandler";

export default {
  mixins: [taskIcon],
  components: { theTaskIcon },
  props: {
    callId: {
      required: true,
      default: null
    },
    callData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    task: null
  }),
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataList",
      "userDataLoadingStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "scorecardTaskList",
      "scorecardTaskListLoadingStatus",
      "scorecardTaskListLoadingError",
      "scorecardTaskListLoadingErrorStatus"
    ]),
    taskListTask() {
      return this.scorecardTaskList.find(task => task.call_id == this.callId)
    },
    computedTask() {
      if(this.taskListTask) {
        return this.taskListTask
      } else {
        return {
          task_id: -1,
          coaching_definition_id: null,
          task_agent: this.callData.agentId,
          scorecard_id: this.$route.params.scorecardId,
          call_id: this.callId,
          is_open: 1,
          task_user: this.userData.id,
          agent_can_ack_or_dispute: 1,
          notified_agent: (CacheHandler.hasOwnProperty("email_agent")) ? Number(CacheHandler.getItem("email_agent")) : 1,
          task_title: "",
          due_date: moment()
            .add(7, "days")
            .format("YYYY-MM-DD"),
          task_message: "",
          subcategories: [],
          transcript_segments: [],
          attachments: [],
          created_at: moment().toISOString(),
          updated_at: moment().toISOString(),
          new_task: true,
          general_task: false,
          new_task_from: 'icon',
          replies: [],
        }
      }
    }
  },
  methods: {
    openCallModal() {
      this.$emit("openCallModal", {
        callId: this.callId,
        callData: this.callData,
        clickedFrom: "task",
        selectedTask: this.computedTask
      });
    },
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped></style>
