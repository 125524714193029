<template>
  <v-container :class="paddingClass" fluid>
    <v-row>
      <v-col class="text-center justify-center">
        <v-alert v-if="message" :type="alertType" prominent>
          <v-row class="d-flex">
            <v-col class="d-flex">
              <span :style="messageStyle" > {{ message }}</span>
            </v-col>

            <v-col class="d-flex justify-end">
              <v-progress-circular
                size="24"
                indeterminate
                v-if="type == 'loading'"
              ></v-progress-circular>
              <v-icon v-if="allowClose" class="pl-4 mr-0" @click="hideModal"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    message: String,
    type: String,
    allowClose: {
      required: false,
      type: Boolean,
      default: false
    },
    messageStyle: {
      required: false,
      type: String,
      default: ''
    },
    padding: {
      type: Number,
      default: 8,
    },
  },
  data: () => ({}),
  methods: {
    hideModal() {
      this.$emit("HIDE_MODAL", false);
    }
  },
  computed: {
    alertType() {
      switch (this.type) {
        case "error":
          return "error";
        case "nodata":
          return "warning";
        case "loading":
        default:
          return "info";
      }
    },
    paddingClass() {
      return `px-${this.padding}`;
    },
  }
};
</script>

<style>
.big-type {
  font-size: 5rem !important;
}
</style>
