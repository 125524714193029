import { mapGetters } from "vuex";

const DISPUTED = 4;
const IN_PROGRESS = 2;
const NEW = 1;
const DESC = "desc";

export default {
  computed: {
    ...mapGetters("scorecardTasks", ["scorecardTaskList"])
  },
  methods: {
    getCallsSortedByTask(direction, agentId) {
      let localTasks = (agentId==0) ? this.scorecardTaskList : this.scorecardTaskList.filter(task => task.task_agent == agentId);

      //filter by agent id if set so that we do not send back the whole task list
      let callsSortedByTask = []
      let descending = direction.toLowerCase().trim() == DESC;

      if (!descending) {
        // ascending : disputed → overdue → new task → in progress → closed / acknowledged

        localTasks.sort((taskA, taskB) => {
          let taskAOverdue = taskA.overdue
          let taskBOverdue = taskB.overdue

          // need to handle closed tasks first...
          if (taskA.is_open && !taskB.is_open) {
            return -1
          } else if (!taskA.is_open && taskB.is_open) {
            return 1
          } if (taskA.last_task_status==DISPUTED && taskB.last_task_status!=DISPUTED && !taskAOverdue && !taskBOverdue ) {
            return -1
          } else if (taskA.last_task_status!=DISPUTED && taskB.last_task_status==DISPUTED && !taskBOverdue && !taskAOverdue) {
            return 1
          } else if (!taskAOverdue && taskBOverdue && taskA.last_task_status==DISPUTED ) {
            return -1
          } else if (taskAOverdue && !taskBOverdue && taskB.last_task_status==DISPUTED) {
            return 1
          } else if (taskAOverdue && !taskBOverdue ) {
            return -1
          } else if (!taskAOverdue && taskBOverdue) {
            return 1
          } else if (taskA.last_task_status==NEW && taskB.last_task_status!=NEW) {
            return -1
          } else if (taskA.last_task_status!=NEW && taskB.last_task_status==NEW) {
            return 1
          } else if (taskA.last_task_status==IN_PROGRESS && taskB.last_task_status!=IN_PROGRESS) {
            return -1
          } else if (taskA.last_task_status!=IN_PROGRESS && taskB.last_task_status==IN_PROGRESS ) {
            return 1
          }
        });

        callsSortedByTask = localTasks.map(task => task.call_id)

      } else {
        // descending: closed → in progress → new task → overdue → disputed

        localTasks.sort((taskA, taskB) => {
          let taskAOverdue = taskA.overdue
          let taskBOverdue = taskB.overdue

           if (!taskA.is_open && taskB.is_open) {
            return -1
          } else if (taskA.is_open && !taskB.is_open) {
            return 1
          } else if (taskA.last_task_status==IN_PROGRESS && taskB.last_task_status!=IN_PROGRESS && !taskAOverdue && !taskBOverdue) {
            return -1
          } else if (taskA.last_task_status!=IN_PROGRESS && taskB.last_task_status==IN_PROGRESS && !taskBOverdue && !taskAOverdue) {
            return 1
          } else if (!taskAOverdue && taskBOverdue && taskA.last_task_status==IN_PROGRESS) {
            return -1
          } else if (taskAOverdue && !taskBOverdue && taskB.last_task_status==IN_PROGRESS) {
            return 1
          } else if (taskA.last_task_status==NEW && taskB.last_task_status!=NEW && !taskAOverdue && !taskBOverdue) {
            return -1
          } else if (taskA.last_task_status!=NEW && taskB.last_task_status==NEW && !taskAOverdue && !taskBOverdue) {
            return 1
          } else if (!taskAOverdue && taskBOverdue && taskA.last_task_status==NEW) {
            return -1
          } else if (taskAOverdue && !taskBOverdue && taskB.last_task_status==NEW) {
            return 1
          } else if (taskAOverdue && !taskBOverdue) {
            return -1
          } else if (!taskAOverdue && taskBOverdue) {
            return 1
          } else if (taskA.last_task_status==DISPUTED && taskB.last_task_status!=DISPUTED) {
            return -1
          } else if (taskA.last_task_status!=DISPUTED && taskB.last_task_status==DISPUTED) {
            return 1
          } 

        });

        callsSortedByTask = localTasks.map(task => task.call_id)
      }
      return callsSortedByTask
    }
  }
};
