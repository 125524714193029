<template>
  <div>
    <unified-call-component-header
      ref="audioComponentHeader"
      :component="component"
      :callId="callId"
      :callData="callData"
      :audioExpanded="audioExpanded"
      :selfSigned="selfSigned"
      @shouldShowAudio="shouldShowAudio"
    ></unified-call-component-header>

    <audio-waveform
      v-show="showAudio"
      ref="waveform"
      :callId="String(callId)"
      :displayedRegions="displayedRegions"
      :customStyle="waveformStyle"
      :playFromPosition="playFromPosition"
      :modalView="true"
      :selfSignedTranscript="selfSigned"
      @update-play-head-position="updatePlayHeadPosition"
      @audioHasError="setAudioError"
    >
    </audio-waveform>
  </div>
</template>

<script>
import AudioWaveform from "@/components/AudioWaveform.vue";
import UnifiedCallComponentHeader from "./UnifiedCallComponentHeader.vue";

export default {
  components: { UnifiedCallComponentHeader, AudioWaveform },
  data() {
    return {
      showAudio: true,
      waveformWidth: "99.5%"
    };
  },
  props: {
    component: {
      type: String,
      required: true
    },
    callId: {
      type: Number,
      required: true
    },
    callData: {
      type: Object,
      required: true
    },
    displayedRegions: {
      type: Array,
      required: false,
      default: []
    },
    playFromPosition: {
      type: Number,
      default: 0
    },
    audioExpanded: {
      type: Boolean,
      default: true
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    waveformStyle() {
      return (
        "margin-top: -10px; margin-left: 4px; margin-right: 4px; width:" +
        this.waveformWidth
      );
    }
  },
  methods: {
    shouldShowAudio(showAudio) {
      this.showAudio = showAudio;
      this.$emit("audioExpandTrigger", showAudio);
    },
    updatePlayHeadPosition(e) {
      this.$emit("update-play-head-position", e);
    },
    setAudioError(error) {
      this.$emit("audioHasError", error);
    },
    checkExpandFromRoot() {
      //if the user trigger "playFromPosition" at the transcript phrase level, we need to make sure the icons here are set correctly when the audio is automatically opened...
      if (this.audioExpanded && !this.showAudio) {
        this.showAudio = this.audioExpanded;
      }

      //if the audio player is no longer expanded and the audio is playing, pause the audio
      if (!this.audioExpanded && !this.$refs.waveform.paused) {
        this.$refs.waveform.playpause();
      }
    }
  },
  destroyed() {
    //since audio is expanded by default, when the component is destroyed, reset the root component
    this.$emit("audioExpandTrigger", true);
  },
  mounted() {
    this.waveformWidth = this.$refs.audioComponentHeader.$el.offsetWidth;
  },
  watch: {
    audioExpanded: "checkExpandFromRoot"
  }
};
</script>

<style scoped></style>
