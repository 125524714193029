<template>
  <v-tooltip v-if="!actionsDisabled && manualScoringAccess && !formatIsPrintable" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :class="classType" text v-bind="attrs" v-on="on">
        <v-icon :size="iconSize" :color="iconColor" @click="updateIconState">{{
          iconType
        }}</v-icon>
      </v-btn>
    </template>
    <span v-html="titleText"></span>
  </v-tooltip>
  <v-tooltip v-else-if="!actionsDisabled && !manualScoringAccess && !formatIsPrintable" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :size="iconSize" v-bind="attrs" v-on="on" :color="iconColor">{{
        iconType
      }}</v-icon>
    </template>
    <span v-html="titleText"></span>
  </v-tooltip>
  <v-icon v-else :size="iconSize" :color="iconColor">{{ iconType }}</v-icon>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("scorecardsV2Calls", ["callScorecardDataV2"]),
    ...mapGetters("users", ["manualScoringAccess"]),
    formatIsPrintable() {
      return this.format == "printable";
    }
  },
  props: {
    callId: String,
    type: String,
    iconColor: String,
    iconType: String,
    classType: String,
    row: Object,
    titleText: String,
    applicable: Boolean,
    actionsDisabled: Boolean,
    format: String,
    iconSize: {
      required: false,
      default: '',
      type: String
    }
  },
  methods: {
    ...mapActions({
      scoreSubCat: "scorecardsV2Calls/scoreSubCat",
      scoreCat: "scorecardsV2Calls/scoreCat"
    }),
    updateIconState() {
      if (this.type == "category") {
        this.updateCatStateAndIcon();
      } else if (this.type == "subcategory") {
        this.updateSubcatStateAndIcon();
      }
      this.$emit("updateCallData");
    },
    updateSubcatStateAndIcon() {
      if (this.applicable) {
        let parameters = {
          call_id: Number(this.callId),
          category_id: this.row.catId,
          subcategory_id: this.row.subcatId
        };
        this.$emit("updateSubcat", this.row.subcatId);
        this.scoreSubCat(parameters);
      }
    },
    updateCatStateAndIcon() {
      let parameters = {
        call_id: Number(this.callId),
        category_id: this.row.catId
      };
      this.$emit("updateCat", this.row.catId);
      this.scoreCat(parameters);
    }
  }
};
</script>

<style></style>
