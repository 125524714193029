var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticStyle:{"height":"100vh","max-height":"calc(100%)"}},[_c('v-container',{staticStyle:{"height":"100vh","max-height":"calc(100%)"},attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{staticStyle:{"height":"100vh","max-height":"calc(100%)","padding":"0","position":"relative","align":"center"}},[_c('Filters',{attrs:{"feature":"scorecards-v2"},on:{"adjustments-filter-updated-reload":_vm.filterUpdated}}),_c('scorecard-processing-status',{attrs:{"type":'agent'}}),(
            _vm.aggregatedScorecardAgentDataLoadingStatus != 'loaded' ||
              _vm.groupedScorecardAgentDataLoadingStatus != 'loaded' ||
              _vm.presentationScorecardsDataLoadingStatus != 'loaded'
          )?_c('loading-status-box',{attrs:{"message":_vm.agentMessageType,"type":_vm.agentLoadingType}}):_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'scorecards-v2-agents'),expression:"$route.name == 'scorecards-v2-agents'"}],staticStyle:{"height":"100vh","max-height":"calc(100%)"},attrs:{"pl-8":"","pr-8":"","fluid":""}},[_c('v-row',[_c('v-col',[_c('scorecard-breadcrumbs',{attrs:{"linkEnabled":true,"scorecardHeader":_vm.scorecardHeader,"dynaLabelHeader":_vm.dynaLabelHeader,"level":'agent',"singleDynaCount":_vm.singleDynaCount},on:{"go-to-page":_vm.goBack}})],1),_c('v-col',{staticClass:"text-right pr-10"},[_c('export-menu',{on:{"exportClick":_vm.exportClick}}),(
                _vm.aggregatedScorecardAgentDataLoadingStatus == 'loaded' &&
                _vm.groupedScorecardAgentDataLoadingStatus == 'loaded' &&
                _vm.presentationScorecardsDataLoadingStatus == 'loaded'
              )?_c('scorecard-download',{ref:"ScorecardDownload",staticClass:"float-right",attrs:{"scorecardId":Number(this.$route.params.scorecardId),"scorecardUrl":_vm.scorecardAgentUrl,"scorecardTitle":_vm.scorecardHeader + ' Grouping Agent',"dateRange":_vm.getDateRangeString()}}):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","small":"","dark":"","color":"primary","href":"/faq/0","target":"_blank","rel":"noopener","title":"Click for Scorecard Help and FAQs"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-help-circle-outline")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.filteredAggregateHeaders,"items":_vm.aggregatedRowData,"hide-default-footer":true},scopedSlots:_vm._u([_vm._l((_vm.filteredAggregateHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
              var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(header.text != _vm.getFormattedTitleName(header.text))?_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getFormattedTitleName(header.text))+"…")]):_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.text))])])]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-2",attrs:{"value":true,"color":"warning","icon":"mdi-message-alert-outline","dark":""}},[_vm._v("\n                "+_vm._s(_vm.noDataMessage)+"\n              ")])]},proxy:true},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"agent-btn",attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.loadAgentTeam()}}},[_vm._v("\n                "+_vm._s(item.name)+"\n              ")])]}},{key:"item.average_handle_time",fn:function(ref){
              var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.average_handle_time))]):_vm._e()]}},{key:"item.average_call_volume",fn:function(ref){
              var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.average_call_volume))]):_vm._e()]}},{key:"item.average_call_score",fn:function(ref){
              var item = ref.item;
return [(item.call_count > 0)?_c('score-chip',{attrs:{"score":item.average_call_score,"calls_modified":item.calls_modified,"aggregate_cat":false}}):_vm._e()]}},{key:"item.call_count",fn:function(ref){
              var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.call_count))]):_vm._e()]}},_vm._l((_vm.columnSlots),function(slot,i){return {key:("item." + (slot.id)),fn:function(ref){
              var item = ref.item;
return [(item.call_count > 0)?_c('score-chip',{key:i,attrs:{"score":item[slot.id],"calls_modified":item[slot.id + '_calls_modified'],"aggregate_cat":true,"nonAppCatHandling":_vm.nonAppCatHandling,"num_not_applicable":item[slot.id + '_num_not_applicable'],"total_calls":item[slot.id + '_total_calls']}}):_vm._e()]}}}),{key:"footer",fn:function(){return undefined},proxy:true}],null,true)}),_c('br'),_c('h2',[_vm._v("Agents")]),_c('br'),_c('v-data-table',{staticClass:"mb-12",attrs:{"headers":_vm.filteredGroupedHeaders,"items":_vm.groupedRowData,"custom-sort":_vm.customSort,"footer-props":{
              itemsPerPageOptions: [10, 25, 50, 100]
            }},scopedSlots:_vm._u([_vm._l((_vm.filteredGroupedHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
            var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(header.text != _vm.getFormattedTitleName(header.text))?_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getFormattedTitleName(header.text))+"…")]):_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.text))])])]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-2",attrs:{"value":true,"color":"warning","icon":"mdi-message-alert-outline","dark":""}},[_vm._v("\n                "+_vm._s(_vm.noDataMessage)+"\n              ")])]},proxy:true},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"agent-btn",attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.loadAgent(item.id)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.name)+"\n                  ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.id)+") call log…")])])]}},{key:"item.average_handle_time",fn:function(ref){
            var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.average_handle_time))]):_vm._e()]}},{key:"item.average_call_volume",fn:function(ref){
            var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.average_call_volume))]):_vm._e()]}},{key:"item.call_count",fn:function(ref){
            var item = ref.item;
return [(item.call_count > 0)?_c('span',[_vm._v(_vm._s(item.call_count))]):_vm._e()]}},{key:"item.average_call_score",fn:function(ref){
            var item = ref.item;
return [(item.call_count > 0)?_c('score-chip',{attrs:{"score":item.average_call_score,"calls_modified":item.calls_modified,"aggregate_cat":false}}):_vm._e()]}},_vm._l((_vm.groupedColumnSlots),function(slot,i){return {key:("item." + (slot.id)),fn:function(ref){
            var item = ref.item;
return [(item.call_count > 0)?_c('score-chip',{key:i,attrs:{"score":item[slot.id],"calls_modified":item[slot.id + '_calls_modified'],"aggregate_cat":true,"nonAppCatHandling":_vm.nonAppCatHandling,"num_not_applicable":item[slot.id + '_num_not_applicable'],"total_calls":item[slot.id + '_total_calls']}}):_vm._e()]}}}),{key:"footer",fn:function(){return undefined},proxy:true}],null,true)}),_c('br')],1)],1)],1),_c('ScheduledExportsModal',{attrs:{"showScheduledExportsModal":_vm.showScheduledExportsModal,"isNew":true,"insightOrScorecard":'scorecards',"pdfDelivery":false,"exportPath":2},on:{"HIDE_SCHEDULED_EXPORTS_MODAL":_vm.hideScheduledExportsModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }