<template>
  <div>
    <loading-status-box
      v-if="callTranscriptLoadingStatus != 'loaded' || !transcriptExists"
      :message="transcriptLoadingMessage"
      :type="transcriptLoadingType"
      :messageStyle="transcriptLoadingMessageStyle"
    >
    </loading-status-box>

    <!-- transcript timeline -->
    <call-transcript-timeline
      v-show="showTranscriptTimeline"
      :key="'transcript'"
      :callId="callId"
      :callData="callData"
      :playHeadPosition="playHeadPosition"
      :transcriptView="transcriptView"
      :phraseChosen="phraseChosen"
      :showTask="showTask"
      :selfSigned="selfSigned"
      @update-play-from-position="updatePlayFromPosition"
      @triggerPhraseSelected="triggerPhraseSelected"
      @triggerPhraseCaught="triggerPhraseCaught"
    ></call-transcript-timeline>

    <!-- transcript summary -->
    <transcript-summary
      v-if="showTranscriptSummary"
      :summaryData="summaryInfo.data"
      :summaryIsLoading="summaryInfo.loading"
      :summaryIsReady="summaryInfo.ready"
      :containerClass="''"
      :bodyTextClass="'body-2'"
      :headerTextClass="'body-1'"
    ></transcript-summary>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import CallTranscriptBodyPhrase from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptBodyPhrase.vue";
import TranscriptSummary from "@/components/Transcripts/TranscriptSummary.vue";
import CallTranscriptTimeline from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptTimeline.vue";

export default {
  components: {
    LoadingStatusBox,
    CallTranscriptBodyPhrase,
    TranscriptSummary,
    CallTranscriptTimeline
  },
  data() {
    return {};
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    transcriptView: {
      type: String,
      default: "transcript"
    },
    summaryInfo: {
      type: Object
    },
    phraseChosen: {
      default: null,
      type: String
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    },
    selfSigned: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("transcript", [
      "callTranscript",
      "callTranscriptLoadingStatus",
      "callTranscriptLoadingError",
      "callTranscriptLoadingErrorStatus"
    ]),
    transcriptExists() {
      return (
        this.callTranscript.analyses && this.callTranscript.analyses.length > 0
      );
    },
    transcriptLoadingType() {
      if (this.callTranscriptLoadingError) return "error";
      else if (this.callTranscriptLoadingStatus != "loaded") return "loading";
      else if (
        this.callTranscriptLoadingStatus == "loaded" &&
        !this.transcriptExists
      )
        return "nodata";
      else return "loading";
    },
    transcriptLoadingMessage() {
      if (this.callTranscriptLoadingError)
        return "There was an error loading this call transcript...";
      else if (this.callTranscriptLoadingStatus != "loaded")
        return "Call transcript loading...";
      else if (
        this.callTranscriptLoadingStatus == "loaded" &&
        !this.transcriptExists
      )
        return "Transcript is not available for this call";
      else return "Call transcript loading...";
    },
    transcriptLoadingMessageStyle() {
      return "text-wrap: nowrap; font-size:14px";
    },
    transcript() {
      return this.callTranscript ? this.callTranscript : [];
    },
    showTranscriptTimeline() {
      return (
        this.transcriptView == "transcript" &&
        this.transcript.analyses &&
        this.transcriptExists &&
        this.callTranscriptLoadingStatus == "loaded"
      );
    },
    showTranscriptSummary() {
      return (
        this.transcriptView == "summary" &&
        this.transcriptExists &&
        this.callTranscriptLoadingStatus == "loaded"
      );
    }
  },
  methods: {
    updatePlayFromPosition(e) {
      this.$emit("update-play-from-position", e);
    },
    triggerPhraseSelected(phrase) {
      this.$emit("triggerPhraseSelected", phrase);
    },
    triggerPhraseCaught() {
      this.$emit("triggerPhraseCaught");
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped></style>
