<template>
  <task-framework
    v-if="userDataLoadingStatus == 'loaded'"
    :showModal="true"
    :taskId="Number($route.params.taskid)"
    :pageQueryData="{ size: 1, num: 10, search: '' }"
    :selfSigned="selfSigned"
  ></task-framework>
</template>

<script>
import { mapGetters } from "vuex";
import TaskFramework from "@/components/Tasks/TaskFramework.vue";

export default {
  name: "Tasks",
  components: { TaskFramework },
  data() {
    return {};
  },
  props: {
    selfSigned: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingErrorStatus",
      "userDataListLoadingStatus"
    ])
  },
  methods: {},
  mounted() {},
  watch: {}
};
</script>
<style scoped></style>
