import moment from "moment";

const EXCLUDED_DYNA_FIELDS = [
  "Customer",
  "Acctnmbr",
  "Agent Id",
  "Adjusteddatetime",
  "Cust Id",
  "Call Id",
  "Average Talk Time",
  "Talk Time",
  "Client Call Identifier"
];

const DESIGNATED_TIME_FIELDS = ["Hold Time", "Handle Time External"];

export default {
  methods: {
    formatDynaField(key) {
      if (!key.includes("_")) {
        return key[0].toUpperCase() + key.substr(1);
      } else {
        let keySplit = key.split("_");
        for (var i = 0; i < keySplit.length; i++) {
          keySplit[i] =
            keySplit[i].charAt(0).toUpperCase() + keySplit[i].slice(1);
        }
        let capitalized = keySplit.join("_");
        capitalized = capitalized.replaceAll("_", " ");
        return capitalized;
      }
    },
    formatDynalabelInfo(fields) {
      let itemsFormatted = [];
      fields.forEach((item, index) => {
        item.key = this.formatDynaField(item.key);
        let notNull = item.value != null;
        if (DESIGNATED_TIME_FIELDS.includes(item.key) && notNull) {
          if (item.key == DESIGNATED_TIME_FIELDS[1]) {
            item.key = "Handle Time (External)";
          }
          item.value = this.formatHMS(item.value);
        }
        if (notNull && !EXCLUDED_DYNA_FIELDS.includes(item.key)) {
          itemsFormatted.push(item);
        }
      });

      return itemsFormatted

    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    }
  }
};
