<template>
  <v-btn
    v-if="showTask && !selfSigned"
    icon
    :color="getCoachable(subcatId)"
    class="ml-6 mb-1"
    @click="makeObservation"
  >
    <v-icon
      v-show="hoveredRow == index || hasSubcategoryObservation"
      small
      >mdi-creation</v-icon
    >
  </v-btn>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    index: {
      required: true,
      type: Number
    },
    hoveredRow: {
      required: false,
      default: null
    },
    subcatId: {
      type: Number,
      required: true,
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    callId: {
      required: true,
      type: Number
    },
  },
  computed: {
    ...mapGetters('observations', [
      "savedSubcategoryObservationData",
      "pendingSubcategoryObservationData",
    ]),
    ...mapGetters("scorecardTasks", ["scorecardTaskList"]),
    ...mapGetters("users", [
      "userData",
    ]),
    scTask() {
      return this.scorecardTaskList.find(task => task.call_id == this.callId)
    },
    hasSubcategoryObservation() {
      let hasPendingSubcat = this.pendingSubcategoryObservationData.some((item) => item.subcategory_id === this.subcatId && item.call_id == this.callId)
      return (this.scTask) 
        ? (this.savedSubcategoryObservationData.some((item) => item.subcategory_id === this.subcatId && item.task_id == this.scTask.task_id) || hasPendingSubcat)
        : hasPendingSubcat
    },
  },
  methods: {
    ...mapActions({
      appendSubcategoryObservation: "observations/appendSubcategoryObservation",
    }),
    makeObservation() {
      const index = this.index
      const subcategory_id = this.subcatId
      const comment = ""
      const user_id = this.userData.id
      this.appendSubcategoryObservation([{
        "index": index,
        "subcategory_id": subcategory_id,
        "comment": comment,
        "user_id": user_id,
        "call_id": this.callId
      }])
    },
    getCoachable(subcatId) {
    
      if (this.scTask && this.savedSubcategoryObservationData.some((item) => item.subcategory_id === this.subcatId && item.task_id == this.scTask.task_id)) {
        return "orange darken-1"
      }
      return (this.pendingSubcategoryObservationData.find(item => item.subcategory_id === subcatId && item.call_id == this.callId)) 
        ? "orange darken-1" 
        : "blue-grey darken-1"

    },
  },
};
</script>

<style scoped></style>
