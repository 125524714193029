<template>
  <v-btn
    icon
    :color="getTaskIconColor(task)"
    :title="getTaskTitle(task)"
    @click="doClick"
  >
    <v-badge inline :color="getTaskIconColor(task)">
      <span
        v-if="getTaskCoachRead(task)"
        slot="badge"
        class="custom-v-badge"
      ></span>
      <svg
        v-if="taskIsNew(task)"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
      >
        <path
          d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3ZM12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4C13 4.26522 12.8946 4.51957 12.7071 4.70711C12.5196 4.89464 12.2652 5 12 5C11.7348 5 11.4804 4.89464 11.2929 4.70711C11.1054 4.51957 11 4.26522 11 4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3ZM7 7H17V5H19V19H5V5H7V7Z"
        />
        <path
          d="M17 11.8105L13.405 11.4895L12 8L10.595 11.4895L7 11.8105L9.725 14.3L8.91 18L12 16.0368L15.09 18L14.27 14.3L17 11.8105Z"
        />
      </svg>

      <v-icon v-else>
        {{ getTaskIcon(task) }}
      </v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import taskIcon from "@/mixins/tasks/taskIcon";

export default {
  name: "TheTaskIcon",
  mixins: [taskIcon],
  props: {
    task: {
      required: true,
      default: null
    },
    isOpen: {
      required: false,
      default: null
    },
    emitClick: {
      type: String,
      default: "" // pass in a function name as a string and the icon will invoke it on click passing back the task_id
    }
  },
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {
    doClick() {
      if (this.emitClick !== "") {
        this.$emit(this.emitClick, this.task);
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep .v-badge__badge {
  height: 12px !important;
  min-width: 12px !important;
  border-radius: 10px !important;
  text-align: center !important;
  top: 6px !important;
}
</style>
