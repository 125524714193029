<template>
    <v-container fluid mt-0 pt-4 pr-12 pb-8 pl-8>
          <v-row>
            <v-col class="px-8">
              <template>
                <v-row>
                  <v-col>
                    <h1 class="ml-4">Profiles</h1>
                  </v-col>
                </v-row>
              </template>

              <v-card width="100%" class="ma-4 mb-0" elevation="0" v-if="this.showFilters">
                <v-card-title class="pt-0 pb-0">
                  Filter By &hellip;
                </v-card-title>
                <v-card-text>
                  <template>
                    <v-row align="center" justify="center">
                      <v-col>
                        <v-select
                          ref="sel_profile_type"
                          v-model="profileTypeModel"
                          :items="columnFeatures['profile_type']"
                          :menu-props="{ maxHeight: '400' }"
                          :label="filterLabels['profile_type']"
                          multiple
                          hint="Filter profiles by selected profile types"
                          persistent-hint
                          clearable
                          dense
                          @input="updateFilteredProfiles"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          ref="sel_items"
                          v-model="teamModel"
                          :items="columnFeatures['teams']"
                          item-text="team_name"
                          item-value="id"
                          :menu-props="{ maxHeight: '400' }"
                          :label="filterLabels['teams']"
                          multiple
                          hint="Filter profiles by selected teams"
                          persistent-hint
                          clearable
                          dense
                          return-object
                          @input="updateFilteredProfiles"
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-btn color="primary" small @click="clearFilters">Clear Filters</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>

              <v-card class="mt-0" elevation="0">
                <v-card-title class="pt-0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  v-model="selectedProfiles"
                  :search="search"
                  :headers="agentHeaders"
                  :items="profileItems"
                  :loading="this.agentDataLoadingStatus != 'loaded'"
                  :show-select="false"
                  :custom-sort="customSort"
                  class="elevation-1"
                >
                <template v-for="agentHeader in agentHeaders" v-slot:[`header.${agentHeader.value}`]="{ header }">
                  <v-tooltip v-if="agentHeader.value=='login_count'" bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{agentHeader.text}}</span>
                    </template>
                    <span>Count of Logins in last 90 days</span>
                  </v-tooltip>
                  <span v-else>{{agentHeader.text}}</span>
                </template>

                  <template v-slot:item.profile_type="{ item }">
                    <v-icon :title="getProfileTitle(item.profile_type, item.groupid)">{{ getProfileClass(item.profile_type, item.groupid) }}</v-icon>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <span :title="item.id">{{ item.name }}</span>
                  </template>
                  <template v-slot:item.email="{ item }">
                    <inline-email-editor v-if="item.profile_type == 0" :origEmail="item.email" :id="item.id" @update-agent="updateProfile($event)" class="editable" title="Click to edit email"></inline-email-editor>
                    <span v-else>{{item.email}}</span>
                  </template>
                  <template v-slot:item.last_login_date="{ item }">
                    <span :title="item.last_login_date">{{formatDate(item.last_login_date)}}</span>
                  </template>
                  <template v-slot:item.teamButton="{ item }" class="text-right">
                    <v-btn icon x-small><v-icon @click="editTeam(item)">mdi-account-multiple</v-icon></v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <div>
          <team-edit-modal
            :showTeamEditModal="showTeamEditModal"
            :profileId="selectedProfileId"
            :profileType="selectedProfileType"
            :profileTeams="profileTeams"
            :teams="teamData"
            :isNew="false"
            @HIDE_TEAM_EDIT_MODAL="hideModal"
          ></team-edit-modal>
        </div>
    </v-container>
</template>
<script>
    import {mapActions, mapGetters} from "vuex"
    import InlineEmailEditor from "@/components/Widgets/InlineEmailEditor.vue";
    import TeamEditModal from "@/components/Widgets/TeamEditModal.vue";
    import moment from "moment";

    const ADMIN_USER_GROUP_IDS = [35, 41];

    export default {
    name: "AgentSetup",
    props: {
    },
    components: {
      InlineEmailEditor,
      TeamEditModal
    },

    data() {
      return {
        agentHeaders: [
          { text: 'Type', value: 'profile_type', 'width': '7%' },
          { text: 'Agent/User', value: 'name', 'width': '20%' },
          { text: 'Email', value: 'email', 'width': '26%'},
          { text: 'Name', value: 'full_name', 'width': '20%'},
          { text: 'Last Login', value: 'last_login_date', 'width': '10%'},
          { text: 'Login Count', value: 'login_count', 'width': '10%'},
          { text: 'Teams', value: 'teamButton', 'width': '8%', sortable: false},
        ],
        profileItems: [],
        selectedProfiles: [],
        selectedProfileId: null,
        selectedProfileType: null,
        filteredProfileItems: [],
        search: '',
        showFilters: false,
        profileTypeModel: [],
        teamModel: [],
        showTeamEditModal: false,
        profileTeams: null,
        columnFeatures: {
          profile_type: ['Agent', 'User'],
          teams: []
        },
        filterLabels: {
          profile_type: 'User Type',
          teams: 'Team'
        },
      }
    },
    methods: {
      ...mapActions("resources", [
        "retrieveAgentData",
        "retrieveTeamData",
        "updateAgentEmail"
      ]),
      customSort(items, index, isDesc) {
        let xIndex = (Array.isArray(index)) ? index[0] : index
        let xIsDesc = (Array.isArray(isDesc)) ? isDesc[0] : isDesc

        items.sort((a, b) => {
          if (xIndex === "login_count" || xIndex === "last_login_date") {
            if (!xIsDesc) {
              if (!a[xIndex] && a[xIndex]!=0) return -1
              else return a[xIndex] < b[xIndex] ? -1 : 1
            } else {
              if (!b[xIndex] && b[xIndex]!=0) return -1
              else return b[xIndex] < a[xIndex] ? -1 : 1
            }
          } else if (xIndex === "profile_type") {
            if (!xIsDesc) {
              if (a[xIndex] == 0 && b[xIndex] != 0) return -1
              else if (!ADMIN_USER_GROUP_IDS.includes(a['groupid']) && ADMIN_USER_GROUP_IDS.includes(b['groupid'])) return -1
              else return 1
            } else {
              if (b[xIndex] == 0 && a[xIndex] != 0) return -1
              else if (!ADMIN_USER_GROUP_IDS.includes(b['groupid']) && ADMIN_USER_GROUP_IDS.includes(a['groupid'])) return -1
              else return 1
            }
          } else {
            if (!xIsDesc) {
              return a[xIndex] < b[xIndex] ? -1 : 1;
            } else {
              return b[xIndex] < a[xIndex] ? -1 : 1;
            }
          }
        });
        return items;
      },
      formatDate(time) {
        let date = moment(time)
        return (date.isValid() ? date.format("M/D/YY") : '')
      },
      updateProfile(payload) {
        this.updateAgentEmail(payload)
      },
      getProfileClass(profile_type, groupid) {
        return (profile_type == 0 ? 'mdi-headset' :
          ADMIN_USER_GROUP_IDS.includes(groupid) ? 'mdi-account-star' : 'mdi-account')
      },
      getProfileTitle(profile_type, groupid) {
        return (profile_type == 0 ? 'Agent' :
          ADMIN_USER_GROUP_IDS.includes(groupid) ? 'Admin User' : 'Standard User')
      },
      editTeam(item) {
        this.$nextTick(() => {
          this.selectedProfileId = item.id
          this.selectedProfileType = item.profile_type
          this.profileTeams = item.teams
          this.showTeamEditModal = true
        })
      },
      loadData() {
        this.retrieveAgentData({})
        if (this.teamDataLoadingStatus != 'loaded') {
          this.retrieveTeamData()
        }
        this.buildTeamFilter()
      },
      loadPage() {
        if (this.agentDataLoadingStatus == 'loaded') {
          // establish data table and enable filtering
          this.profileItems = this.sortedProfileItems
          this.showFilters = true
          this.updateFilteredProfiles()
        }
      },
      buildTeamFilter() {
        if (this.teamDataLoadingStatus == 'loaded') {
          this.columnFeatures.teams = this.sortedTeams
        }
      },
      clearFilters() {
        this.profileTypeModel = []
        this.teamModel = []
        this.updateFilteredProfiles()
      },
      updateFilteredProfiles() {
        this.filteredProfileItems = []
        this.sortedProfileItems.forEach((profile) => {
          // loop through profiles and match on profile and/or team
          let typeMatch = false
          let teamMatch = false
          if (this.profileTypeModel.length > 0) {
            typeMatch = (this.profileTypeModel.includes('Agent') && profile.profile_type == 0) || (this.profileTypeModel.includes('User') && profile.profile_type == 1)
          } else {
            // no type(s) selected
            typeMatch = true
          }
          if (this.teamModel.length > 0) {
            let profile_team_ids = profile.teams.map(team => team.id)
            let selected_team_ids = this.teamModel.map(team => team.id)
            teamMatch = profile_team_ids.some(id => selected_team_ids.includes(id))
          } else {
            // no team(s) selected
            teamMatch = true
          }
          if (typeMatch && teamMatch) this.filteredProfileItems.push(profile)
        })
        this.profileItems = (this.profileTypeModel.length > 0 || this.teamModel.length > 0) ? this.filteredProfileItems : this.sortedProfileItems
      },
      hideModal(payload) {
        this.showTeamEditModal = false
        if (payload.reload) {
          this.loadData()
        }
      }
    },
    computed: {
      ...mapGetters("resources", [
        'agentData',
        'agentDataLoadingStatus',
        'agentDataLoadingError',
        'agentDataLoadingErrorStatus',

        'teamData',
        'teamDataLoadingStatus',
        'teamDataLoadingError',
        'teamDataLoadingErrorStatus',
      ]),
      sortedProfileItems() {
        return [...this.agentData].sort((a, b) => a.name.localeCompare(b.name))
      },
      sortedTeams() {
        return [...this.teamData].sort((a, b) => a.team_name.localeCompare(b.team_name))
      },
    },
    watch: {
      agentDataLoadingStatus: 'loadPage',
      teamDataLoadingStatus: 'buildTeamFilter'
    },
    mounted() {
      this.loadData()
    },
  };
  </script>

<style scoped>
  .editable { cursor: pointer; }
</style>
