<template>
    <v-card-text class="task-header">
      <v-row v-show="userDataListLoadingStatus=='loaded'">
        <v-col cols="8" class="dr-flex justify-start">
          <p class="mb-0 pl-0">
            <span class="text-caption">Created By: <b>{{getUsersName(task.task_user)}}</b> on {{ formatDateTime(task.task_created_at) }}</span>
            <br/>
            <span v-if="task.task_created_at!=task.task_updated_at && task.last_status_user" class="text-caption">Updated By: <b>{{getUsersName(task.last_status_user)}}</b> on {{ formatDateTime(task.task_updated_at) }}</span>
          </p>
        </v-col>
        <v-col cols="4" class="dr-flex justify-end">
          <p class="mb-0 pr-0">
              <span class="text-caption">For: <b><span :title="'Agent ID: ' + agentInfo.id">{{ agentInfo.full_name }}</span></b></span>
            <br/>
            <span v-if="task.call_id" class="text-caption">Call ID: <b>{{task.call_id}}</b></span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import taskIcon from "@/mixins/tasks/taskIcon";
import moment from 'moment';

export default {
  components: {
  },
  mixins: [taskIcon],
  props: {
    task: {
      type: Object,
      required: true,
    },
    agentInfo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapActions({}),
    formatDate(date) {
      return moment.utc(date).local().format('MMM D, YYYY')
    },
    formatDateTime(date) {
      return moment.utc(date).local().format('MMM D, YYYY h:mm A')
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) : text;
    },
    getUsersName(userid) {
      let user = this.userDataList.find(user => user.userid === userid)
      return (user) ? user.firstname + ' ' + user.lastname : userid
    },
  },
  computed: {
    ...mapGetters("users", [
      "userDataList",
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "userDataListLoadingStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "guidanceTaskList",
      "guidanceTaskListLoadingStatus",
      "guidanceTaskListLoadingError",
      "guidanceTaskListLoadingErrorStatus",
    ]),
  },
}

</script>

<style scoped>

.task-header {
  background-color: rgba(1, 106, 175, 0.05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.dr-flex {
  display: flex;
  flex-direction: row;
}

</style>
