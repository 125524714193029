<template>
  <v-btn icon>
    <v-menu
      v-model="collapseMenu"
      dense
      open-on-hover
      bottom
      offset-y
      nudge-right="0"
      class="compact-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon class="px-0 mx-0">mdi-unfold-more-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, i) in scCollapseOptions"
          :key="i"
          @click="expandCollapse(item.target)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      collapseMenu: null
    };
  },
  props: {
    scCollapseOptions: {
      required: true,
      type: Array
    }
  },
  methods: {
    expandCollapse(target) {
      this.$emit("expandCollapse", target);
    }
  },
};
</script>

<style scoped></style>
