<template>
  <div>
    <v-menu
      v-model="pageMenu"
      open-on-hover
      bottom
      offset-y
      nudge-right="0"
      class="compact-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" dark>
          <v-icon class="px-0 mx-0">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="metaDataWindow = !metaDataWindow">
          <v-list-item-icon>
            <v-icon color="orange accent-4"
              >mdi-folder-information-outline</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Custom Metadata</v-list-item-title>
        </v-list-item>

        <v-list-item @click="insightsWindow = !insightsWindow">
          <v-list-item-icon>
            <v-icon>mdi-lock-pattern</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Insights</v-list-item-title>
        </v-list-item>

        <!-- notes edit control that will be phased out -->
        <v-list-item @click="openNotesDialog">
          <v-list-item-icon>
            <note-edit-control
              v-if="userDataLoadingStatus == 'loaded'"
              :showNote="notesWindow"
              :notePriv="userData.privileges.notes"
              :callId="callId"
              :noteId="callData.note_id == null ? 0 : callData.note_id"
              :noteIsBlank="callData.note_is_blank"
              :selfSignedTranscript="selfSigned"
              @hideNote="hideNote"
              @update-call-note="updateCallNoteListener"
            ></note-edit-control
          ></v-list-item-icon>
          <v-list-item-title>Notes</v-list-item-title>
        </v-list-item>

        <!-- tags control that will be phased out -->
        <v-list-item @click="openTagsDialog">
          <v-list-item-icon>
            <tags-edit-control
              v-if="userDataLoadingStatus == 'loaded'"
              :showTag="tagsWindow"
              :tagPriv="userData.privileges.tags"
              :callId="Number(callData.id)"
              :hasTags="Boolean(callData.has_tags)"
              :selfSignedTranscript="selfSigned"
              @hideTag="hideTag"
              @update-call-has-tags="updateCallHasTagsListener"
            ></tags-edit-control>
          </v-list-item-icon>
          <v-list-item-title>Tags</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn dark icon v-if="!selfSigned">
      <v-icon class="pr-0 mr-0" @click="hideModal">mdi-close</v-icon>
    </v-btn>

    <!-- sticky dialog component windows -->
    <div class="sticky-dialog">
      <!-- custom metadata window -->
      <custom-metadata-window
        v-if="metaDataWindow"
        :callId="callId"
        :metaDataWindow="metaDataWindow"
        :selfSignedTranscript="selfSigned"
        @closeWindow="metaDataWindow = false"
      ></custom-metadata-window>

      <!-- insights window -->
      <insights-window
        v-if="insightsWindow"
        id="insights-window"
        :callId="callId"
        :insightsWindow="insightsWindow"
        :mediaType="callData.media_type"
        :selfSignedTranscript="selfSigned"
        @closeWindow="insightsWindow = false"
      ></insights-window>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoteEditControl from "../../Controls/NoteEditControl.vue";
import TagsEditControl from "../../Controls/TagsEditControl.vue";
import CustomMetadataWindow from "../../Widgets/CustomMetadataWindow.vue";
import InsightsWindow from "../../Widgets/InsightsWindow.vue";

export default {
  components: {
    CustomMetadataWindow,
    InsightsWindow,
    NoteEditControl,
    TagsEditControl
  },
  data() {
    return {
      pageMenu: false,
      metaDataWindow: false,
      insightsWindow: false,
      notesWindow: false,
      tagsWindow: false,
      metadataZIndex: 400
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    callId: {
      type: Number,
      required: true
    },
    callData: {
      type: Object,
      required: true
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("users", ["userData", "userDataLoadingStatus"]),
    emailAllFilesDisabled() {
      return true;
    },
    downloadAllFilesDisabled() {
      return true;
    }
  },
  methods: {
    openNotesDialog() {
      //allows for opening a v-dialog inside of a v-menu...
      setTimeout(() => (this.notesWindow = true));
    },
    openTagsDialog() {
      //allows for opening a v-dialog inside of a v-menu...
      setTimeout(() => (this.tagsWindow = true));
    },
    hideModal() {
      this.$emit("HIDE_MODAL", false);
    },
    hideNote(shouldHide) {
      this.notesWindow = shouldHide;
    },
    hideTag(shouldHide) {
      this.tagsWindow = shouldHide;
    },
    updateCallNoteListener(e) {
      this.callData.note_id = e.noteId;
      this.callData.note_is_blank = Number(e.note_is_blank);
      this.$emit("update-call-note", e);
    },
    updateCallHasTagsListener(e) {
      this.callData.has_tags = e.has_tags;
      this.$emit("update-call-has-tags", e);
    },
    emailAllFiles() {},
    downloadAllFiles() {}
  },
  mounted() {}
};
</script>

<style scoped>
.sticky-dialog {
  z-index: 500 !important;
}
</style>
