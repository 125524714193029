import Vue from "vue";
import VueRouter from "vue-router";
import { useRouter } from "vue-router";
import routes from "./routes";
import { store } from "@/store/index";
import moment from "moment";
import validateDate from "./validateDate";
import CookieHelper from "@/utils/CookieHelper.js";
import CacheHandler from "../utils/CacheHandler";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return {}
  }
});

router.beforeEach((to, from, next) => {
  // make sure vuex has the most up-to-date dates from the url
  if(validateDate(to.query.start_date)&&validateDate(to.query.end_date)) {
    const userData = store.getters['users/userData']
    var startDateObj = moment(to.query.start_date).startOf('day')
    var endDateObj = moment(to.query.end_date).endOf('day')
    if (!isProxying(userData) || !userData.privileges.callFinderManageSearches) {
      // if start date before horizon, set it to the horizon
      var horizon = process.env.VUE_APP_DEFAULT_HORIZON
      if (!to.meta.signedPage) {
        horizon = getHorizon()
      }
      var horizonDateObj = moment().subtract(horizon, 'days');

      if (startDateObj.diff(horizonDateObj, 'day') < 0 || endDateObj.diff(startDateObj, 'day') > 31) {
        // if start/end date out of bounds, default both to yesterday
        startDateObj = endDateObj = moment().subtract(1, 'days');
        to.query.start_date = startDateObj.format('YYYY-MM-DD');
        to.query.end_date = endDateObj.format('YYYY-MM-DD');
      }
    }

    store.commit("dateRange/setStartDate", startDateObj.format())
    store.commit("dateRange/setEndDate", endDateObj.format())
  }

  if (to.matched.some(record => record.meta.requiresAuth) && (!localStorage.getItem("bearer")) && to.meta.requiresAuth) {
    // send to signin page
    next({ path: "/signin", query: to.query });
  }

  // add hits to vuex if passed on query string
  if (to.query.hasOwnProperty("hit")) {
    store.commit("transcript/change", JSON.parse(to.query.hit));
  }
  if (to.query.hasOwnProperty("hits")) {
    store.commit("transcript/change", JSON.parse(to.query.hits));
  }

  let cookieBearer = getCookieBearerToken();
  if (cookieBearer) {
    if(localStorage.getItem("bearer") != cookieBearer) {
      // if jwt on query param -- coming for elsewhere
      localStorage.setItem("bearer", cookieBearer);
      store.dispatch("authentication/setIsAuthenticated", true);
    }
    clearCookieBearerToken();
  }


  if (to.path != '/signin' && to.path != '/mfapage' && to.path != '/transcripts-signed' && to.path !== '/printable-call-scorecard-v2-signed' && to.path !== '/unified-call-scorecard-v2-signed' && to.path !== '/tasks-signed' && (!to.query.start_date||!to.query.end_date)) {
    to.query.start_date = String(moment(store.getters["dateRange/start_date"]).format("YYYY-MM-DD"))
    to.query.end_date = String(moment(store.getters["dateRange/end_date"]).format("YYYY-MM-DD"))

    var phrase = pullQueryItem('phrase', to.fullPath)
    if (phrase)
      to.query.phrase = phrase
    next({ path: to.path, query: to.query, hash: to.hash });
  }

  next();

});

function pullQueryItem(item, path) {
  var regex = new RegExp(item + "(%3D|=)(.*)");
  var itemMatch = regex.exec(path);
  if (itemMatch) {
    var pulledItem = decodeURI(itemMatch[2]);
    if (pulledItem != '')
      return pulledItem;
  }
  return '';
}

function getCookieBearerToken() {
  return CookieHelper.getCookie(process.env.VUE_APP_R2_AUTH_COOKIE);
}

function clearCookieBearerToken() {
  CookieHelper.clearCookie(process.env.VUE_APP_R2_AUTH_COOKIE);
}

function getHorizon() {
  if (!localStorage.getItem("accountHorizon") )
    store.dispatch("resources/retrieveAccountHorizon");
  return localStorage.getItem("accountHorizon") || process.env.VUE_APP_DEFAULT_HORIZON;
}

function isProxying(userData) {
  return userData && userData.id != userData.csrep_userid || false;
}

function getRoute() {
  return to
}

export default router;
