<template>
  <div>
    <v-dialog
      v-if="insightsWindow"
      id="test-dialog"
      v-model="windowOpen"
      persistent
      hide-overlay
      content-class="elevation-0"
    >
      <v-card
        ref="vCardHeight"
        color="white"
        v-show="insightsWindow"
        class="draggable-card"
        :style="insightWindowStyles"
        elevation="4"
      >
        <!-- TODO: add mouseout???  @mouseout="stopDragging" -->
        <v-card-title
          draggable="true"
          @mousedown="startDragging"
          @mouseup="stopDragging"
          class="draggable-handle primary lighten-2 white--text"
        >
          <div class="row">
            <div class="col">Insights</div>
            <div align="right" class="col">
              <span class="dim">(Call ID: {{ callId }})</span>
            </div>
          </div>
          <v-btn dark icon>
            <v-icon class="pr-0 mr-0" @click="closeWindow">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <v-flex style="overflow: auto">
              <v-data-table
                :headers="dynaLabelHeaders"
                :items="insightData"
                :hide-default-footer="true"
                :hide-default-header="true"
                disable-pagination
                class="dl-dt pt-4"
                :height="tableHeight"
                :loading="insightsCallDataLoadingStatus == 'loading'"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.key`]="{ item }">
                  <span
                    v-if="agentCustField(item.key)"
                    class="dim data-table-font"
                    >{{ formatAgentCustField(item.key) }}</span
                  ><span v-else>{{ formatCamelCase(item.key) }}</span>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <!-- sentiment fields always appear even for chats -->
                  <template v-if="sentimentField(item.original_key)">
                    <subjective-glyph
                      v-if="showAgentCustSubjective(item, 'sentiment')"
                      :info="item.value"
                      :scale="50"
                      :type="'sentiment'"
                    ></subjective-glyph>

                    <subjective-glyph
                      v-if="item.original_key == 'overall_sentiment'"
                      :info="item.value"
                      :scale="50"
                      :type="'sentiment'"
                    ></subjective-glyph>
                  </template>

                  <!-- chat handling -->
                  <template v-else>
                    <!-- if is chat, just display an mdash for the insight -->
                    <template v-if="isChat"
                      >&mdash;</template
                    >
                    <template v-else>
                      <subjective-glyph
                        v-if="showAgentCustSubjective(item, 'emotion')"
                        :info="item.value"
                        :scale="50"
                        :type="'emotion'"
                      >
                      </subjective-glyph>

                      <subjective-glyph
                        v-if="item.original_key == 'overall_emotion'"
                        :info="item.value"
                        :scale="50"
                        :type="'emotion'"
                      >
                      </subjective-glyph>

                      <span v-if="item.original_key == 'silence'" v-html="getItemPercent(item.value)"></span>

                      <span
                        v-if="item.original_key == 'agent/cust_silence'"
                        class="dim data-table-font"
                      >
                        <span
                          v-if="channelCount > 1"
                          class="dim data-table-font"
                          :title="ownershipTitle('silence', item)"
                          >{{ ownershipValue(item) }}
                          <template v-if="agentCustMoreThanOne(item)"
                            ><sup class="footnote">*</sup></template
                          >
                        </span>
                        <span
                          v-else
                          class="dim data-table-font"
                          title="Not available from single-channel audio"
                          >&mdash;</span
                        >
                      </span>

                      <span v-if="item.original_key == 'overtalk'" v-html="getItemPercent(item.value)"></span>

                      <span
                        v-if="item.original_key == 'agent/cust_overtalk'"
                        class="dim data-table-font"
                      >
                        <span
                          v-if="channelCount > 1"
                          class="dim data-table-font"
                          :title="ownershipTitle('overtalk', item)"
                          >{{ ownershipValue(item) }}
                          <template v-if="agentCustMoreThanOne(item)"
                            ><sup class="footnote">*</sup></template
                          >
                        </span>
                        <span
                          v-else
                          class="dim data-table-font"
                          title="Not available from single-channel audio"
                          >&mdash;</span
                        >
                      </span>

                      <span v-if="item.original_key == 'talktime'" v-html="getItemPercent(item.value)"></span>

                      <span
                        v-if="item.original_key == 'agent/cust_talktime'"
                        class="dim data-table-font"
                      >
                        <span
                          v-if="channelCount > 1"
                          class="dim data-table-font"
                          :title="ownershipTitle('Talk Time', item)"
                          >{{ ownershipValue(item) }}
                          <template v-if="agentCustMoreThanOne(item)"
                            ><sup class="footnote">*</sup></template
                          >
                        </span>
                        <span
                          v-else
                          class="dim data-table-font"
                          title="Not available from single-channel audio"
                          >&mdash;</span
                        >
                      </span>
                    </template>
                  </template>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="theme--dark primary" small @click="closeWindow"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SubjectiveGlyph from "./SubjectiveGlyph.vue";
import emotion from "@/mixins/emotion";

const SENTIMENT_FIELDS = [
  "customer_sentiment",
  "agent_sentiment",
  "overall_sentiment"
];

const EMOTION_FIELDS = ["customer_emotion", "agent_emotion", "overall_emotion"];

const EXCLUDED_CALL_FIEDS = [
  "accountid",
  "agentid",
  "inasteriskid",
  "callernumber",
  "agentname",
  "direction",
  "id",
  "duration",
  "adjusteddatetime",
  "channelcount",
  "agentchannel",
  "customerchannel",
  "sentiment"
];

const AGENT_CUST_FIELDS = [
  "agent/cust_silence",
  "agent/cust_talktime",
  "agent/cust_overtalk"
];
export default {
  components: { SubjectiveGlyph },
  mixins: [emotion],
  data() {
    return {
      sentimentFields: SENTIMENT_FIELDS,
      insightWindowStyles: {
        top: "100px",
        left: "100px",
        width: "500px",
        height: "700px"
      },
      dragging: false,
      startX: "",
      startY: "",
      windowOpen: true,
      dynaLabelHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "key"
        },
        {
          text: "Value",
          align: "start",
          sortable: false,
          value: "value"
        }
      ],
      tableHeight: "60vh"
    };
  },
  props: {
    insightsWindow: {
      type: Boolean,
      required: true
    },
    callId: {
      type: Number,
      required: true
    },
    mediaType: {
      type: String,
      required: true
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("insights", [
      "insightsCallData",
      "insightsCallDataLoadingStatus",
      "insightsCallDataLoadingError",
      "insightsCallDataLoadingErrorStatus"
    ]),
    isChat() {
      return this.mediaType.toLowerCase() == "chat";
    },
    insightData() {
      let itemsFormatted = [];
      if (this.insightsCallDataLoadingStatus == "loaded") {
        let result = Object.keys(this.insightsCallData[this.callId])
          .map(key => [key, this.insightsCallData[this.callId][key]])
          .reverse();

        result.forEach((item, index) => {
          let newItem = {};
          let additionalVal = null;
          let originalKey = item[0].toLowerCase();

          if (!EXCLUDED_CALL_FIEDS.includes(originalKey)) {
            let key = this.formatDataKey(item[0]);
            let itemValue = item[1];
            newItem.key = key;
            newItem.original_key = originalKey;

            if (SENTIMENT_FIELDS.includes(originalKey)) {
              newItem.value = this.getEmotionIndex(
                itemValue.sentiment_first_half,
                itemValue.sentiment_second_half
              );
            } else if (EMOTION_FIELDS.includes(originalKey)) {
              newItem.value = this.getEmotionIndex(
                itemValue.emotion_first_half,
                itemValue.emotion_second_half
              );
            } else if (
              originalKey == "silence" ||
              originalKey == "overtalk" ||
              originalKey == "talktime"
            ) {
              additionalVal = {};
              additionalVal.key = "agent/cust_" + originalKey;
              additionalVal.original_key = "agent/cust_" + originalKey;

              additionalVal.agentPercent = itemValue.agentPercent;
              additionalVal.customerPercent = itemValue.customerPercent;

              newItem.value = itemValue.totalPercent;
            } else {
              newItem.value = itemValue;
            }

            itemsFormatted.push(newItem);
            if (additionalVal) {
              itemsFormatted.push(additionalVal);
            }
          }
        });
        return itemsFormatted;
      }
      return itemsFormatted;
    },
    channelCount() {
      return this.insightsCallData[this.callId].channelCount;
    }
  },
  methods: {
    ...mapActions({
      retrieveInsightsCallData: "insights/retrieveInsightsCallData"
    }),
    ownershipValue(item) {
      return (
        "(" +
        this.roundValue(item.agentPercent) +
        "/" +
        this.roundValue(item.customerPercent) +
        ")"
      );
    },
    ownershipTitle(insightType, item) {
      return (
        insightType.charAt(0).toUpperCase() +
        insightType.slice(1) +
        ": Agent owns " +
        this.roundValue(item.agentPercent) +
        "% / Customer owns " +
        this.roundValue(item.customerPercent) +
        "%"
      );
    },
    showAgentCustSubjective(item, subjective) {
      return (
        (this.channelCount == 2 &&
          item.original_key == "customer_" + subjective) ||
        item.original_key == "agent_" + subjective
      );
    },
    roundValue(value) {
      return Math.round(value * 100);
    },
    agentCustMoreThanOne(item) {
      return item.agentPercent + item.customerPercent > 1;
    },
    agentCustField(field) {
      return AGENT_CUST_FIELDS.includes(field);
    },
    sentimentField(field) {
      return SENTIMENT_FIELDS.includes(field);
    },
    formatAgentCustField(field) {
      return field.split("_")[0];
    },
    formatCamelCase(field) {
      return field.replace(/([A-Z])/g, " $1").trim();
    },
    formatPercent(val) {
      let percent = String(this.roundValue(val));
      if (Number(percent) > 100) {
        return "100%";
      } else {
        return percent + "%";
      }
    },
    getItemPercent(value) {
      return value !== null ? this.formatPercent(value) : "&mdash;";
    },
    formatDataKey(key) {
      if (!key.includes("_")) {
        return key[0].toUpperCase() + key.substr(1);
      } else {
        let keySplit = key.split("_");
        for (var i = 0; i < keySplit.length; i++) {
          keySplit[i] =
            keySplit[i].charAt(0).toUpperCase() + keySplit[i].slice(1);
        }
        let capitalized = keySplit.join("_");
        capitalized = capitalized.replaceAll("_", " ");
        return capitalized;
      }
    },
    startDragging(event) {
      this.dragging = true;

      this.startX = event.clientX - parseInt(this.insightWindowStyles.left);
      this.startY = event.clientY - parseInt(this.insightWindowStyles.top);
      event.preventDefault();
      document.addEventListener("mousemove", this.onDrag);
    },
    onDrag(event) {
      if (this.dragging) {
        this.insightWindowStyles.left = `${event.clientX - this.startX}px`;
        this.insightWindowStyles.top = `${event.clientY - this.startY}px`;
      }
      event.preventDefault();
    },
    stopDragging() {
      this.dragging = false;
      this.startX = this.startY = "";
      document.removeEventListener("mousemove", this.onDrag);
    },
    closeWindow() {
      this.$emit("closeWindow");
    }
  },
  mounted() {
    let params = {
      selfSignedTranscript: this.selfSignedTranscript,
      call_id: this.callId
    };
    if (this.selfSignedTranscript) {
      params["userid"] = this.$route.params.userid;
      params["reckey"] = this.$route.params.reckey;
    }

    this.retrieveInsightsCallData(params);
    this.tableHeight =
      "calc(" + this.$refs.vCardHeight.$el.offsetHeight + "px - 165px)";
  }
};
</script>
<style scoped>
.draggable-handle {
  cursor: move;
}

.draggable-card {
  position: fixed;
}

.data-table-font {
  font-size: 14px;
}

.sticky-dialog {
  z-index: 500;
}
</style>