<template>
  <v-tooltip :open-on-hover="false" open-on-click bottom z-index="9999">
    <template v-slot:activator="{ on }">
      <v-btn @click="on.click" color="normal" v-on="on" icon small>
        <v-icon color="blue darken-2">mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <span v-html="tipMessage"> </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    section: String
  },
  computed: {
    tipMessage() {
      switch (this.section) {
        case "subcategory": //Scorecards setup
          return SUBCATEGORY_INFO;
        case "category":
          return CATEGORY_INFO;
        case "settings":
          return SETTINGS_INFO;
        case "callscore":
          return CALLSCORE_INFO;
        case "talktime":
          return TALKTIME_INFO;
        case "accessroles":
          return ACCESSROLE_INFO;
        case "dynalabels":
          return DYNALABEL_INFO;
        case "category_dynalabels":
          return CATEGORYDYNALABEL_INFO;
        case "definition":
          return DEFINITION_INFO; //Dynalabels setup
        case "selections":
          return SELECTIONS_INFO;
        case "access_selections": //Access roles setup
          return ACCESS_SELECTIONS_INFO;
        case "insight_dynalabel": //Insight setup
          return INSIGHT_DYNALABEL_INFO;
        case "insight_accessrole":
          return INSIGHT_ACCESSROLE_INFO;
        case "insight_talktime":
          return INSIGHT_TALKTIME_INFO;
        case "srd_info":
          return SRD_INFO;
        case "coaching_scorecards":
          return COACHING_SCORECARD_INFO;
        default:
          return "";
      }
    }
  }
};
//scorecard info
const SUBCATEGORY_INFO = `<p>
                    Scoring Type – Automated requires a minimum of one search to<br>
                    be selected for each subcategory before a scorecard can be<br />
                    published. Manual allows for a default score for each<br />
                    subcategory (either checked or unchecked), but each<br />
                    subcategory can subsequently be manually adjusted.
                </p>
                <p>
                    Scoring Rule Weight - If there are two subcategories within<br />
                    a category and each has a scoring rule weight of 1, each<br />
                    subcategory is weighted as 1/2. If the first subcategory is<br />
                    changed to a scoring rule weight of 2, each subcategory<br />
                    weight will be adjusted to 2/3 and 1/3
                </p>`;

const CATEGORY_INFO = `<p>
                      Category Weight – Select a weight from 0-10. The
                      overall<br />
                      call score will be calculated based on weighted average
                      of<br />
                      each category. Example: A scorecard has three
                      categories<br />
                      each with a weight of one and all categories apply to a<br />
                      call. Each category score is weighted as 1/3. If the
                      first<br />
                      category is changed to a category weight of 2, each
                      category<br />
                      score is weighted as 2/4, 1/4, and 1/4.<br />
                    </p>
                    <p>
                      Scoring Rule – Weight allows for a scoring rule weight
                      entry<br />
                      at each subcategory level. If there are two
                      subcategories<br />
                      within a category and each has a scoring rule weight of
                      1,<br />
                      each subcategory is weighted as 1/2. If the first<br />
                      subcategory is changed to a scoring rule weight of 2,
                      each<br />
                      subcategory weight will be adjusted to 2/3 and 1/3.<br />
                      Threshold requires a specific count or all subcategories
                      to<br />
                      be checked in order for an overall category to pass or
                      fail.<br /></p
                  >`;

const SETTINGS_INFO = ` <p>
                    Display talk time or handle time – Talk time is the actual
                    call recording length.<br />
                    Handle time is the sum of talk time and hold time. If your
                    customized integration<br />
                    does not provide hold time metadata, there will be no
                    differences in the figures shown.
                  </p>
                  <p>
                    Non-applicable category scoring – Directs calculations for
                    not applicable categories. <br />Example: A scorecard has
                    three categories each with a category weight of one and one<br />
                    category does not apply to a specific call. If
                    redistribute weight is selected, the two <br />remaining
                    categories will be weighted at ½ or 50% each. If score
                    100% is selected,<br /> all three categories will be factored into
                    the overall score at a weight of 1/3 <br />and the one not
                    applicable category will automatically receive a score of
                    100%.
                  </p>
                  <p>
                    Include agent scorecards - Determines whether agent scorecards<br/>
                    are included in the "All Agents" printable scorecard and<br/>
                    scheduled report deliveries.
                  </p>`;

const CALLSCORE_INFO = `  Any calls with a fully automated call score less than the<br />
                    minimum or greater than the maximum will be automatically<br />
                    excluded from the scorecard, including team/dynamic<br />
                    label/agent calculations.
                  `;
const TALKTIME_INFO = `  Any calls with talk times less than the minimum or
                    greater<br />
                    than the maximum will be automatically excluded from the<br />
                    scorecard, including team/dynamic label/agent
                    calculations.`;
const ACCESSROLE_INFO = `Specify the access roles to which the scorecard should be
                  shared.`;
const DYNALABEL_INFO = ` Specify the dynamic labels you wish to include within your<br />
                  scorecard. If multiple dynamic labels are chosen, your<br />
                  interface will automatically include both a dynamic label<br />
                  and agent summary. Scorecards with single dynamic label<br />
                  selections will include an agent summary exclusively.`;

const CATEGORYDYNALABEL_INFO = ` Only make a dynamic label selection if the category applies to a portion of the calls being scored. <br />
                   If all calls should be scored under a category, a dynamic label selection should not be made. <br />
                   For new all manual scoring type categories, we recommend assigning a dynamic label which produces <br />
                   no call results making the category non-applicable by default. This will allow new all manual <br />
                   scoring type categories to be introduced without automatically adjusting historical scores.`;

// Dynalabel Info
const DEFINITION_INFO = `Select the desired dynamic label field options to <br />
                          define your desired call grouping/category.  <br />
                          If needed, use and/or between dynamic label options. <br />
                          You can also tie (via AND) dynamic label metadata fields <br />
                          with one or more searches`;

const SELECTIONS_INFO = ` Select the desired search option(s) to define <br />
                          your desired call group/category.`;

//Access Roles Info
const ACCESS_SELECTIONS_INFO = `Select the users you wish to include within your access role.`;

//Insight setup Info
const INSIGHT_ACCESSROLE_INFO = `One or more access role(s) must be included within the insight definition.<br />
                                As the insight definition creator, you will automatically have access <br />
                                to each created insight definition. If you are working on a draft insight definition, <br />
                                we recommend temporarily tying to an access role defined to the advanced user exclusively.`;
const INSIGHT_DYNALABEL_INFO = `One or more dynamic label(s) must be included within each insight definition. <br />
                                If more than one dynamic label is selected, a dynamic label and agent summary <br />
                                will automatically be included within the insight definition.  <br />
                                An insight definition tied to only one dynamic label will include <br />
                                an agent summary exclusively.`;
const INSIGHT_TALKTIME_INFO = ` Any calls less with talk times less than the minimum or greater than <br />
                                the maximum will be automatically excluded from the insight, <br />
                                including team/dynamic label/agent calculations.`;

//Coaching Info
const COACHING_SCORECARD_INFO = `Maximum of 5 scorecards are allowed per coaching definition`;

//Other setup tips
const SRD_INFO = `You should consider your customized integration handling <br />
                  when scheduling report deliveries. Only fully processed calls <br />
                  will be included in your scheduled delivery. Calls processed <br />
                  post-delivery will be accessible in the UI, but will not be <br />
                  included in your delivery. Please consider a later delivery time <br />
                  if you continually run into misalignment.`;
</script>

<style scoped></style>
