<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-col cols="11" class="blue--text text--darken-4 py-0 ml-1 mr-3" align-self="end">
        {{ description }}
      </v-col>
      <v-col class="pa-0" style="text-align: right;">
        <v-btn fab small dark color="primary" href="/setup-faq/0" target="_blank" rel="noopener" title="Click for Setup Help and FAQs">
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <span v-if="isExpanded">
        <v-col cols="9" class="hintText py-0 ml-1 mr-3">
            {{expandMessage}}
        </v-col>
      </span>
    </v-row>
    <v-row>
      <v-col class="pt-0 ml-1 mr-3">
        <v-btn text x-small color="primary" @click="toggleHints">
          <span v-if="isExpanded">
            hide example
            <v-icon small right>mdi-chevron-up</v-icon>
          </span>
          <span v-else>
            show example
            <v-icon small right>mdi-chevron-down</v-icon>
          </span>
        </v-btn>
    </v-col>
    </v-row>

    <!-- alerts row -->
    <v-row v-if="okToRender && (showAlert || isDraft)">
      <v-col :cols="getCols" class="d-flex flex-column" v-if="showAlert">
        <v-alert dense border="left" type="warning" transition="scroll-y-reverse-transition" class="small-alert">
          Dynamic label is being processed. You may make edits but may not publish them until processing is complete.
        </v-alert>
      </v-col>
      <v-col :cols="getCols" class="d-flex flex-column" v-if="isDraft">
        <v-alert dense text border="left" type="info" class="small-alert">
          Unpublished Draft (Will be stored for 24 hours from the last update)
        </v-alert>
      </v-col>
    </v-row>


    <v-row v-if="okToRender">

      <!-- dyna label name -->
      <v-col :cols="this.userData.features.chat ? 4 : 6">

          <v-text-field
            id="dynamicLabelNameTxt"
            ref="titleField"
            outlined
            v-model="dynaLabelInfo.title"
            counter
            :maxlength="maxNameLength"
            label="Dynamic Label Name"
            hint="Your dynamic label name will be visible by others if selected as part of an insight or scorecard definition."
            placeholder="Enter your dynamic label name"
            :rules="titleRules"
          >
        </v-text-field>

      </v-col>

    <!-- dyna label description -->
      <v-col :cols="this.userData.features.chat ? 5 : 6">
        <v-text-field
          outlined
          v-model="dynaLabelInfo.description"
          counter
          :maxlength="maxDescriptionLength"
          hint=" (Optional) Enter a brief description to represent your dynamic label. <br />
                  The description is only visible to you, as the creator, within the definition or dynamic label tile view."
          label="Description"
          placeholder="Describe your dynamic label"
        ></v-text-field>
      </v-col>

      <v-col cols="3" class="d-flex justify-center" v-if="this.userData.features.chat">
        <v-btn-toggle
          v-model="dynaLabelInfo.media_type"
          mandatory
          class="mx-4"
          :disabled="this.isPublished"
        >
          <v-btn value="Call Recording" :disabled="this.isPublished"
            >Call Recording</v-btn
          >
          <v-btn value="Chat" :disabled="this.isPublished"
            >Chat</v-btn
          >
        </v-btn-toggle>
      </v-col>

    </v-row>

    <!-- dyna label and search selections -->
    <v-row v-if="okToRender">
      <!-- dyna label component -->
      <v-col cols="6" class="d-flex flex-column">
        <dyna-label-definition v-if="dynaLabelDataLoadingStatus=='loaded'&&dynaLabelsSelectorsLoadingStatus=='loaded'" :dynaLabelId="Number(dynaLabelId)" :isNew="isNew"  @data-changed="dataChanged"></dyna-label-definition>
        <v-progress-circular
          v-else
          class="align-self-center mb-4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>

      <!-- search component -->
      <v-col cols="6" class="d-flex flex-column">
        <search-definition v-if="dynaLabelDataLoadingStatus=='loaded'&&dynaLabelsSelectorsLoadingStatus=='loaded'" :dynaLabelId="Number(dynaLabelId)" :isNew="isNew"></search-definition>
        <v-progress-circular
          v-else
          class="align-self-center mb-4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <!-- buttons for saving, deleting, etc. - the visual layout is guided by UI/UX eye-tracking research -->
    <v-row v-if="okToRender">
      <v-col cols="4" class="d-flex">
        <v-btn id="publishBtn" color="primary" class="ma-2" @click="publishMe" :disabled="!canPublish" title="Click to publish this dynamic label definition">Publish</v-btn>
        <v-btn color="blue-grey" class="ma-2 white--text" @click="cloneMe" :disabled="isDraft" title="Click to clone this dynamic label definition">Clone</v-btn>
        <v-btn color="normal" class="ma-2" @click="cancelEdits" :disabled="!isDraft" title="Click to discard edits to this dynamic label definition">Cancel Edits</v-btn>
      </v-col>
      <v-col cols="6" class="d-flex pl-5">
        <div class="d-inline">
            <v-btn color="blue-grey" class="ma-2 white--text" @click="validate" :loading="validating" title="Click to validate this dynamic label definition">Check
              <v-icon right dark>
              {{ validationIcon }}
              </v-icon>
            </v-btn>
            <div class="d-inline caption mt-4 ml-4">
              Created: {{ formatDate(dynaLabelInfo.created_at)}} &mdash; Updated:
              {{ formatDate(dynaLabelInfo.updated_at)}}
            </div>
        </div>
      </v-col>
      <v-col cols="2" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn color="error" class="ma-2" @click="deleteMe" :disabled="deleteDisabled" title="Click to delete this dynamic label definition">Delete</v-btn>
      </v-col>
    </v-row>

    <!-- dynamic label stats -->
    <v-row v-if="okToRender">
      <v-col cols="4" class="pl-5 pt-0">
      </v-col>
      <v-col cols="4" class="pl-5 pt-0">
        <transition name="slide-fade">
          <div class="validation-message small-caption error--text" v-if="(this.validationMessage!='')" v-html="this.validationMessage"></div>
        </transition>
      </v-col>
      <v-spacer></v-spacer>
      <div class="mr-4" v-if="deleteDisabled && isPublished">
        <delete-disabled :title="'Dynamic Label'" :problemOneTitle="'Scorecard Definitions'" :problemTwoTitle="'Insight Definitions'" :problemOneList="dynalabelAssociatedScorecards" :problemTwoList="dynalabelAssociatedInsights"></delete-disabled>
      </div>
    </v-row>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>


<script>

  import axios from "@/utils/AxiosInstance.js";
  import {mapActions, mapGetters} from "vuex"
  import dynaLabelDefinition from "@/components/DynaLabels/DynaLabelDefinition.vue"
  import searchDefinition from "@/components/DynaLabels/SearchDefinition.vue"
  import confirm from "@/components/Widgets/Confirm.vue"
  import DeleteDisabled from '../../components/Widgets/DeleteDisabled.vue';
  import utils from "@/mixins/utils";
  import SetupTip from "@/components/SetupTip.vue";
  import moment from "moment";

  export default {
    name: 'DynaLabelsPage',
    mixins: [utils],
    props: {
      dynaLabelId: String,
      description: String,
      expandMessage: String,
    },
    data() {
      return {
        isExpanded: false,
        dynaLabelInfo: {},
        dynaLabelInfoOrig: {},
        maxNameLength: 120,
        maxDescriptionLength: 255,
        oldListLength: 0,
        validated: false,
        validating: false,
        validationMessage: '',
        validationStatus: true,
        polling: null,
        isTitleValid: false,
        titleRules: [
          value => !!value || 'A unique name is required.',
          value => {
            if(!value) {
              return true
            } else {
              return !this.dynaLabelsList.some(item => { return item.id!=this.dynaLabelId&&item.title.trim().toUpperCase()==value.trim().toUpperCase() }) || 'Names must be unique.'
            }
          },
        ],
      }
    },
    computed: {
      ...mapGetters('dynaLabels', [
          'autosavePaused',
          'dynaLabelsList',
          'dynaLabelsListLoadingStatus',
          'dynaLabelsListLoadingError',
          'dynaLabelsListLoadingErrorStatus',
          'dynaLabelsSelectors',
          'dynaLabelsSelectorsLoadingStatus',
          'dynaLabelsSelectorsLoadingError',
          'dynaLabelsSelectorsLoadingErrorStatus',
          'dynaLabelData',
          'dynaLabelDataLoadingStatus',
          'dynaLabelDataLoadingError',
          'dynaLabelDataLoadingErrorStatus',
          'dynaLabelProcessingStatus',
          'dynalabelAssociatedInsights',
          'dynalabelAssociatedScorecards'
      ]),
      ...mapGetters('users', [
        'userData'
      ]),
      titleFieldIsValid() {
        return !this.$refs.titleField.hasError
      },
      deleteDisabled() {
        return (this.dynalabelAssociatedInsights.length > 0 ||
        this.dynalabelAssociatedScorecards.length > 0) || !this.isPublished
      },
      isPublished() {
        return Boolean(typeof this.dynaLabelInfo === 'object' && this.dynaLabelInfo.hasOwnProperty('published') && this.dynaLabelInfo.published) || false
      },
      isDraft() {
        return Boolean(typeof this.dynaLabelInfo === 'object' && this.dynaLabelInfo.hasOwnProperty('draft') && this.dynaLabelInfo.draft) || false
      },
      isNew() {
        return Boolean(typeof this.dynaLabelInfo === 'object' && this.dynaLabelInfo.hasOwnProperty('new') && this.dynaLabelInfo.new) || false
      },
      isProcessing() {
        return Boolean(this.dynaLabelProcessingStatus)
      },
      canPublish() {
        return Boolean(this.isDraft && !this.isProcessing && this.titleFieldIsValid)
      },
      showAlert() {
        return Boolean(this.okToRender && this.isProcessing)
      },
      okToRender() {
        return Boolean(typeof this.dynaLabelInfo === 'object' && this.dynaLabelInfo !== null)
      },
      validationIcon() {
        return !this.validated ? 'mdi-progress-upload' : this.validationStatus ? 'mdi-checkbox-marked-circle-outline' : 'mdi-circle-off-outline'
      },
      getCols() {
        return (this.showAlert && this.isDraft) ? 6 : 12
      },
    },
    components: {
      dynaLabelDefinition,
      searchDefinition,
      confirm,
      DeleteDisabled,
      SetupTip,
    },
    methods: {
      ...mapActions({
        retrieveDynaLabelsSelectors: 'dynaLabels/retrieveDynaLabelsSelectors',
        retrieveDynaLabelsList: 'dynaLabels/retrieveDynaLabelsList',
        retrieveDynaLabelData: 'dynaLabels/retrieveDynaLabelData',
        publishDynaLabel: 'dynaLabels/publishDynaLabel',
        cloneDynaLabel: 'dynaLabels/cloneDynaLabel',
        deleteDynaLabel: 'dynaLabels/deleteDynaLabel',
        updateDynaLabel: 'dynaLabels/updateDynaLabel',
        cancelDynaLabelEdits: 'dynaLabels/cancelDynaLabelEdits',
        checkDynaLabelProcessing: 'dynaLabels/checkDynaLabelProcessing',
        setDynaLabelProcessingStatus: 'dynaLabels/setDynaLabelProcessingStatus',
        updateDynaLabelProcessingFlags: 'dynaLabels/updateDynaLabelProcessingFlags',
      }),
      toggleHints() { this.isExpanded = !this.isExpanded },
      formatDate(time){
        return moment(time).format('l LT');
      },
      dataChanged() {
        if(!this.autosavePaused) {
          this.dynaLabelInfo = Object.assign({}, this.dynaLabelData)
        }
      },
      setInfo() {
        this.dynaLabelInfo = this.dynaLabelsList.find(dynalabel => {return String(dynalabel.id) == String(this.dynaLabelId)})
        if(typeof this.dynaLabelInfo === "object") {
          this.dynaLabelInfoOrig = Object.assign({}, this.dynaLabelInfo)
          this.setDynaLabelProcessingStatus(this.dynaLabelInfo.processing)
        }
      },
      publishMe() {
        this.publishDynaLabel(this.dynaLabelInfo.id)
      },
      cloneMe() {
        let id = Date.now()
        this.cloneDynaLabel({
          id: id,
          definition: this.dynaLabelInfo
        })
        this.selectTarget({ type: "dynaLabels", id: id });
      },
      selectTarget(obj) {
        let route = {
          name: "setup-" + this.kebabCase(obj.type),
          query: this.$route.query
        };
        if (obj.id !== "undefined") {
          route.params = { dynaLabelId: Number(obj.id) }
        }
        this.$router.push(route).catch(err => {});
        setTimeout(function() {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      },
      async cancelEdits() {
        if (await this.$refs.confirm.open('Cancel Edits?', 'About to cancel edits and/or remove draft. This action cannot be undone. Are you sure you want to proceed?', { color: 'red' })) {
          // yes
          let published = this.dynaLabelInfo.published
          let id = this.dynaLabelInfo.id
          this.cancelDynaLabelEdits(this.dynaLabelId)
          if(!published) {
            this.$router.push({path:'/setup/dyna-labels/'}).catch(err => {})
            setTimeout(function () {
              if (this.$vuetify) this.$vuetify.goTo(0)
            }, 1500)
          } else {
            this.retrieveDynaLabelsList({})
            this.retrieveDynaLabelData(id)
            this.$emit('openMenu', 'dynaLabels')
            this.$emit('selectTarget', { 'type':'dynaLabels', 'id':id, 'preventProp': true })
          }
        }
        else {
          // cancel
        }
      },
      async deleteMe() {
        if (await this.$refs.confirm.open('Delete Dynamic Label?', 'About to delete this dynamic label. This action cannot be undone. Are you sure you want to delete it?', { color: 'red' })) {
          // yes
          this.deleteDynaLabel(this.dynaLabelId)
          this.$router.push({path:'/setup/dyna-labels/'}).catch(err => {})
          setTimeout(function () {
            if (this.$vuetify) this.$vuetify.goTo(0)
          }, 3000)
        }
        else {
          // cancel
        }
      },
      async validate() {

        // flip flag
        this.validating = true;
        let params = {
          definition: this.dynaLabelData.definition,
          media_type: this.dynaLabelInfo.media_type,
        }

        await axios
          .post(process.env.VUE_APP_SETUP_DYNA_LABELS + '?action=count', params)
          .then(response => {
            this.validating = false
            if (response !== undefined) {
              let count = Number(response.data.data)
              this.validated = true
              this.validationStatus = true
              this.validationMessage =  'In the past 30 days,<br/>' + count + ' calls match this dynamic label definition.'
            } else {
              this.validated = false
              this.validating = false
              this.validationStatus = false
              this.validationMessage = "Invalid Definition: " + JSON.stringify(response)
            }
          })
          .catch(error => {
            // Error
            this.validated = false
            this.validating = false
            this.validationStatus = false
            this.validationMessage = 'Unable to validate definition. ' + error.response.data.error.message + ' (Code ' + error.response.data.error.status + ')'
          }).finally(() => {
            setTimeout( () => {
              this.validationMessage = ''
              this.validated = false
              this.validating = false
              this.validationStatus = false
            }, 10000)
          })

      },
      refreshInfo() {
        // if the list of dynalabels is refreshed we need to also refresh the current view,
        // but not until the list gets loaded - this hack makes it so
        if(this.oldListLength==0 && this.dynaLabelsList.length>1) {
          this.setInfo()
        }
        this.oldListLength = this.dynaLabelsList.length
      },
      initCheckDynaLabelProcessing() {
        this.polling = setInterval(() => {
          this.updateDynaLabelProcessingFlags()
        }, 10000)
      }
    },
    beforeDestroy() {
      if(this.polling!==null) clearInterval(this.polling)
    },
    updated() {
      if(this.dynaLabelInfo && this.dynaLabelInfo.hasOwnProperty('title') && this.dynaLabelInfo.hasOwnProperty('description') && this.dynaLabelInfo.hasOwnProperty('media_type')) {
        if (this.dynaLabelInfo.title != this.dynaLabelData.title || this.dynaLabelInfo.description != this.dynaLabelData.description || this.dynaLabelInfo.media_type != this.dynaLabelData.media_type) {
          this.updateDynaLabel(this.dynaLabelInfo)
        }
      }
    },
    mounted() {
      if(this.dynaLabelsSelectorsLoadingStatus!='loaded') {
        this.retrieveDynaLabelsSelectors()
      }

      this.setInfo()
      this.initCheckDynaLabelProcessing()
      this.$emit('openMenu', 'dynaLabels')
      this.$emit('selectTarget', { 'type':'dynaLabels', 'id':this.dynaLabelId, 'preventProp':true })
    },
    watch: {
      dynaLabelsList: 'refreshInfo',
      dynaLabelData: 'dataChanged'
    },
  }

</script>

<style scoped>

  .small-alert {
    font-size: small;
  }

  span.small-caption {
    color: #999 !important;
    font-size: .9rem;
  }

  .validation-message {
    color: #999 !important;
    font-size: .9rem;
    border-left: 1px solid #ccc;
    padding-left: 1rem;
    margin-left: .5rem;
  }

  .slide-fade-enter-active {
    transition: all 1.5s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
  .hintText {
	color: rgb(25, 118, 210);
  font-size: .875rem;
	line-height: 1.5rem !important;
  padding-left: 1.3rem !important;
  }
</style>
