<template>
  <v-app-bar elevation="1" color="primary lighten-2">
    <v-toolbar-title class="white--text">
      Task Review
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="white--text call-details">
      <span>Agent: {{ agentName }}</span>
    </div>

    <v-spacer></v-spacer>

    <!-- TODO: implement header controls once available... -->
    <!-- header controls -->
    <!-- <unified-call-header-controls
        :callId="callId"
        :callData="callData"
        :selfSigned="selfSigned"
      ></unified-call-header-controls> -->
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    taskId: {
      type: Number,
      required: true
    },
    taskData: {
      type: Object,
      required: false
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("resources", ["agentData"]),
    agentName() {
      if (this.agentData.length > 0 && this.taskData) {
        return this.agentData.find(s => s.id == this.taskData.task_agent).name;
      }
      return "";
    }
  },
  methods: {},
  mounted() {}
};
</script>
