<template>
  <v-timeline dense right class="t-content">
    <v-timeline-item
      v-for="(phrase, pIndex) in phrases"
      :id="'phrase-' + pIndex"
      :key="pIndex"
      class="custom-timeline-item"
      :class="hasInsightAccess ? 'transcript-timeline-item' : ''"
      color="white"
      small
    >
      <!-- timeline icon -->
      <template v-if="hasInsightAccess" v-slot:icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <icon-base
                :showTitle="false"
                width="20"
                height="20"
                icon-name="sentiment"
                class="svg-sentiment-info"
                :class="makeClassName(transformSentiment(phrase.sentiment))"
                ><icon-sentiment
              /></icon-base>
            </div>
          </template>

          <span>{{ transformSentiment(phrase.sentiment) }} Sentiment</span>
        </v-tooltip>
      </template>

      <!-- transcript phrases -->
      <v-card
        :color="isPhraseHighlighted(pIndex, phrase) ? '#ffff66' : phrase.color"
      >
        <call-transcript-body-phrase
          :phrase="phrase"
          :channelCount="callData.channel_count"
          :direction="callData.direction"
          :isChat="isChat"
          :showTask="showTask"
          :selfSigned="selfSigned"
          :callId="callId"
          @update-play-from-position="updatePlayFromPosition"
        ></call-transcript-body-phrase>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { mapGetters } from "vuex";
import IconBase from "@/components/IconBase.vue";
import IconSentiment from "@/components/Icons/IconSentiment.vue";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import CallTranscriptBodyPhrase from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptBodyPhrase.vue";

export default {
  components: {
    IconBase,
    IconSentiment,
    LoadingStatusBox,
    CallTranscriptBodyPhrase
  },
  data() {
    return {
      phrasesHighlighted: [],
      phrasesSelected: []
    };
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    transcriptView: {
      required: true,
      type: String
    },
    phraseChosen: {
      default: null,
      type: String
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    },
    selfSigned: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("transcript", [
      "callTranscript",
      "callTranscriptLoadingStatus",
      "callTranscriptLoadingError",
      "callTranscriptLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userData"]),
    isChat() {
      return this.callData.media_type == "Chat";
    },
    transcript() {
      return this.callTranscript ? this.callTranscript : [];
    },
    hasInsightAccess() {
      return this.userData.features.insights;
    },
    phrases() {
      // Phrases from Voci Analysis
      let transcript = this.callTranscript;
      if (transcript) {
        if (transcript.analyses) {
          return transcript.analyses[0].phrases.filter(phrase => {
            if (phrase.words) {
              phrase.color = this.getCardColor(phrase);
              return phrase;
            }
          });
        }
      }
      return [];
    },
    channelCount() {
      return this.callData.channel_count;
    }
  },
  methods: {
    getCardColor(phrase) {
      return this.getPerson(phrase).toLowerCase() === "agent" ||
        this.singleChannelSpeakerAgent(phrase)
        ? "rgba(119, 184, 0, .1)"
        : "rgba(0, 152, 207, .1)";
    },
    getPerson(phrase) {
      if (this.channelCount > 1) {
        return phrase.speaker;
      } else {
        return phrase.channel == 1 ? "Speaker 1" : "Speaker 2";
      }
    },
    singleChannelSpeakerAgent(phrase) {
      // if direction is "inbound" speaker 1 is agent if "outbound" speaker 1 is customer
      if (this.channelCount < 2) {
        return this.callData.direction == "in"
          ? phrase.channel == 0
          : phrase.channel == 1;
      }
      return false;
    },
    transformSentiment(sentiment) {
      switch (sentiment) {
        case "Positive":
          return "Strong Positive";
        case "Mostly Positive":
          return "Positive";
        case "Mostly Negative":
          return "Negative";
        case "Negative":
          return "Strong Negative";
        case "Mixed":
          return "Mixed";
        default:
          return "Neutral";
      }
    },
    makeClassName(sentiment) {
      if (sentiment !== null && typeof sentiment !== "undefined") {
        return "svg-sentiment-" + sentiment.replace(/\s+/g, "-").toLowerCase();
      } else {
        return "svg-sentiment-neutral";
      }
    },
    reformatSentiment(sentiment) {
      if (sentiment) {
        // Replace dashes with spaces
        let formattedSentiment = sentiment.replace(/-/g, " ");
        // Convert to proper case (capitalize first letter of each word)
        return formattedSentiment.replace(/\b\w/g, char => char.toUpperCase());
      }
    },
    isPhraseHighlighted(pIndex, phrase) {
      let seconds = this.playHeadPosition.toFixed(2);
      if (seconds > 0) {
        if (seconds >= phrase.starts_at && seconds < phrase.ends_at) {
          // a phrase has been highlighted
          if (!this.phrasesHighlighted.includes("#phrase-" + pIndex)) {
            this.phrasesHighlighted.push("#phrase-" + pIndex);
          }

          if (
            this.phrasesHighlighted.length > 0 &&
            !this.phrasesSelected.includes(this.phrasesHighlighted[0])
          ) {
            let highlightedPhrase = this.phrasesHighlighted[0];

            //always scroll to first phrase if multiple phrases are highlighted
            this.phrasesSelected.push(highlightedPhrase);
            this.selectScrollPosition(highlightedPhrase);

            // tell root that a phrase has been selected so that it knows which phrase to return to if switching between transcript tabs
            if (this.transcriptView != "transcript") {
              this.$emit("triggerPhraseSelected", highlightedPhrase);
            }
          }
        } else {
          //remove phrase no longed in selected/highlighted region
          this.removePhrase(pIndex);
        }
        return seconds >= phrase.starts_at && seconds < phrase.ends_at;
      } else {
        //remove phrase no longed in selected/highlighted region
        this.removePhrase(pIndex);
        return false;
      }
    },
    removePhrase(pIndex) {
      const phraseIndex = this.phrasesHighlighted.indexOf("#phrase-" + pIndex);
      if (phraseIndex > -1) {
        this.phrasesSelected.splice(phraseIndex, 1);
        this.phrasesHighlighted.splice(phraseIndex, 1);
      }
    },
    checkIfPhraseChosen() {
      // we need to check if a phrase was chosen when switching to the transcript tab in order to pinpoint it in the transcript
      if (this.transcriptView == "transcript" && this.phraseChosen) {
        this.selectScrollPosition(this.phraseChosen);
        //tell the root we caught the phrase and scrolled to the selected phrase so that we can clear the phrase at the root
        this.$emit("triggerPhraseCaught");
      }
    },
    selectScrollPosition(phraseKey) {
      //scroll to phrase key if transcript
      if (this.transcriptView == "transcript") {
        this.$vuetify.goTo(phraseKey, {
          offset: 75,
          easing: "linear",
          container: ".unified-card-text"
        });
      }
    },
    updatePlayFromPosition(e) {
      this.$emit("update-play-from-position", e);
    },
    resetData() {
      this.phrasesHighlighted = [];
      this.phrasesSelected = [];
    }
  },
  destroyed() {
    this.resetData();
  },
  mounted() {},
  watch: {
    transcriptView: "checkIfPhraseChosen"
  }
};
</script>

<style scoped>
/* Ensure the circle element is hidden */
.custom-timeline-item .v-timeline-item__dot {
  box-shadow: none !important;
  border: none !important;
}

.highlight {
  background-color: yellow !important;
  font-weight: bold !important;
}

.highlighted {
  background-color: #ffff66;
}

.person-label {
  position: absolute;
  top: -0.75rem;
  width: 100%;
}

.coaching-label {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dr-flex {
  display: flex;
  flex-direction: row;
}

.svg-sentiment-info {
  cursor: help;
  border: none !important;
}

.unified-card-text > .t-content,
.svg-sentiment-strong-positive {
  --color-chevron-north-upper: green;
  --color-chevron-north-middle: lightgreen;
  --color-chevron-south-middle: lightgrey;
  --color-chevron-south-lower: lightgrey;
}

.svg-sentiment-positive {
  --color-chevron-north-upper: lightgrey;
  --color-chevron-north-middle: green;
  --color-chevron-south-middle: lightgrey;
  --color-chevron-south-lower: lightgrey;
}

.svg-sentiment-neutral {
  --color-chevron-north-upper: lightgrey;
  --color-chevron-north-middle: lightgrey;
  --color-chevron-south-middle: lightgrey;
  --color-chevron-south-lower: lightgrey;
}

.svg-sentiment-negative {
  --color-chevron-north-upper: lightgrey;
  --color-chevron-north-middle: lightgrey;
  --color-chevron-south-middle: orangered;
  --color-chevron-south-lower: lightgrey;
}

.svg-sentiment-strong-negative {
  --color-chevron-north-upper: lightgrey;
  --color-chevron-north-middle: lightgrey;
  --color-chevron-south-middle: orange;
  --color-chevron-south-lower: orangered;
}

.svg-sentiment-mixed {
  --color-chevron-north-upper: lightgrey;
  --color-chevron-north-middle: lightgreen;
  --color-chevron-south-middle: orange;
  --color-chevron-south-lower: lightgrey;
}

.transcript-timeline-item ::v-deep .v-timeline-item__dot {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  box-shadow: none !important;
}

/* handles the v-card caret... */
.theme--light.v-timeline .v-timeline-item .v-card::before {
  opacity: 0.1;
}
</style>
