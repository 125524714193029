<template>
  <unified-call-framework-modal
    v-if="userDataLoadingStatus == 'loaded'"
    :callId="Number(this.$route.params.callId)"
    :showModal="true"
    :pageQueryData="{ size: 1, num: 10, search: '' }"
    :selfSigned="true"
  ></unified-call-framework-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UnifiedCallFrameworkModal from "@/components/UnifiedCall/UnifiedCallFrameworkModal.vue";

export default {
  name: "UnifiedCallSigned",
  components: { UnifiedCallFrameworkModal },
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters("authentication", ["loginReqPB"]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingErrorStatus",
      "userDataListLoadingStatus"
    ]),
    selfSigned() {
      return (
        this.$route.params.reckey != null && this.$route.params.userid != null
      );
    },
    bearerSet() {
      return (
        localStorage.getItem("bearer") && localStorage.getItem("bearer") != ""
      );
    }
  },
  methods: {
    ...mapActions({
      checkLoginReqPB: "authentication/checkLoginReqPB",
      setIsAuthenticated: "authentication/setIsAuthenticated",
      retrieveUserData: "users/retrieveUserData"
    }),
    handleLoginReq() {
      // if loginreqpb then route to the page that will make them log in. otherwise load data
      if (this.loginReqPB == "log_in" && !this.bearerSet) {
        this.unSignedReroute();
      } else {
        let params = {
          selfSignedTranscript: this.selfSigned,
          userid: this.$route.params.userid,
          reckey: this.$route.params.reckey,
          call_id: this.$route.params.callId
        };

        this.retrieveUserData(params);
      }
    },
    unSignedReroute() {
      // send them to the login screen through call scorecard
      let to_route =
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.$route.params.agentId +
        "/calls";
      this.$router
        .push({
          path: to_route,
          query: this.$route.query,
          hash: "call-scorecard-" + this.$route.params.callId
        })
        .catch(err => {});
      this.$router.go();
    }
  },
  beforeUnmount() {},
  mounted() {
    // if the user is not logged in and is trying to use a self signed link
    if (!this.bearerSet && this.selfSigned) {
      this.checkLoginReqPB({
        userid: this.$route.params.userid,
        reckey: this.$route.params.reckey,
        call_id: this.$route.params.callId
      });
    } else if (this.bearerSet && this.selfSigned) {
      // signed link while logged in, go to normal scorecard
      this.unSignedReroute();
    }
  },
  watch: {
    loginReqPB: "handleLoginReq"
  }
};
</script>
<style scoped></style>
