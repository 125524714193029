import moment from "moment";

export default {
  methods: {
    formatScore(score) {
      score = score ? score : 0; // prevents NaN
      return Math.round(score * 100) + "%";
    },
    ampersandReplacement(replacement) {
      return replacement.replace(/&amp;/g, "&");
    },
    pendingCatOverride(row) {
      return row.catUpdated ? "pending-override" : "";
    },
    pendingSubcatOverride(row) {
      return row.subcatUpdated ? "pending-override" : "";
    },
    categoryIsManualApp(category) {
      return (
        category.catManualApplicable != undefined &&
        category.catManualApplicable != null
      );
    },
    categoryAppHandling(category) {
      if (this.categoryIsManualApp(category)) {
        return category.catManualApplicable == 1;
      } else {
        return category.catApplicable == 1;
      }
    },
    catApplicable(category) {
      if (category.catUpdated) {
        if (this.categoryIsManualApp(category)) {
          return category.catApplicable == 1 ? true : false;
        } else {
          return category.catApplicable != 1;
        }
      } else {
        if (this.categoryIsManualApp(category)) {
          return category.catManualApplicable == 1;
        } else {
          return category.catApplicable == 1;
        }
      }
    },
    categoryCollapsedIcon(row) {
      return row.catCollapsed ? "mdi-chevron-right" : "mdi-chevron-down";
    },
    callRowHover(hoveredRow, index) {
      return hoveredRow == index
        ? "caption grey--text text--darken-4"
        : "caption grey--text text--lighten-2";
    },
    callRowNumber(index) {
      return index + 1;
    }
  }
};
