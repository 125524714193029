<template>
  <v-card flat>
    <v-card-text class="mt-4 mr-4 ml-4 ">
      <v-row justify="center">
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              <audio-waveform v-if="hasRecording"
                ref="waveform"
                :callId="String(callId)"
                :displayedRegions="displayedRegions"
                :overlay-present="overlay"
              ></audio-waveform>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              <insights-widget :callId="String(callId)" :isChat="isChat"></insights-widget>
              <call-scorecard-layout
                v-if="isDataReady"
                :callData="callData"
                :callId="callId"
                :scorecardId="scorecardId"
                :dynalabelId="dynalabelId"
                :agentId="agentId"
                :displayedRegions="displayedRegions"
                :pageNum="pageNum"
                :pageSize="pageSize"
                :callSearch="callSearch"
                :menuLocation="'callScorecard'"
                :format="'callScorecard'"
                :singleDynaLabel="singleDynaLabel"
                @updateDataLoading="updateLoadingStatus"
                @callDataChanged="updateCallData"
                @spliceDisplayedRegions="spliceDisplayedRegions"
                @setDisplayedRegions="setDisplayedRegions"
                @overlayPresent="overlayPresent"
              ></call-scorecard-layout>
              <scorecard-loading-status
                v-else
                :message="scorecardMessageType"
                :type="scorecardLoadingType"
              ></scorecard-loading-status>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CallScorecardLayout from "./CallScorecardLayout.vue";
import ScorecardLoadingStatus from "./ScorecardLoadingStatus.vue";
import AudioWaveform from "../AudioWaveform.vue";
import InsightsWidget from "@/components/Widgets/InsightsWidget.vue";

export default {
  components: {
    CallScorecardLayout,
    AudioWaveform,
    InsightsWidget,
    ScorecardLoadingStatus
  },
  props: {
    callId: String,
    scorecardId: String,
    dynalabelId: String,
    agentId: String,
    location: String,
    pageSize: Number,
    pageNum: Number,
    callSearch: String,
    singleDynaLabel: Boolean
  },
  data() {
    return {
      has_tags: false,
      note_id: 0,
      note_is_blank: 1,
      transcriptions: false,
      checkedRegions: [],
      displayedRegions: [],
      offset: 0,
      showModal: false,
      buttonsDisabled: false,
      callData: {
        loading: true
      },
      overlay: false
    };
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus",
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingErrorStatus",
      "userDataListLoadingStatus",
      "userDataList",
      "cfOnlyUser",
      "administratorUser",
      "standardUser",
    ]),
    ...mapGetters("dateRange", [
      "start_date",
      "end_date"
    ]),
    scorecardLoadingType() {
      if (this.callScorecardDataV2LoadingError) return "error";
      else return "loading";
    },
    scorecardMessageType() {
      if (this.callScorecardDataV2LoadingError)
        return "There was an error loading this call scorecard...";
      else return "Call scorecard loading, please wait...";
    },
    isDataReady() {
      return !this.callData.loading;
    },
    isChat() {
      return this.scorecardV2Data && this.scorecardV2Data.mediaType == 'Chat';
    },
    hasRecording() {
      return this.scorecardV2Data && this.scorecardV2Data.mediaType != 'Chat';
    },
  },
  methods: {
    ...mapActions("scorecardsV2Calls", [
      "retrieveScorecard",
      "retrieveCallScorecardDataV2",
      "clearOverrides",
      "updateCallOverrides",
      "retrieveSearchHits"
    ]),
    updateLoadingStatus(status) {
      this.callData.loading = status;
    },
    updateCallData() {
      //this method is what causes the call scorecard data to be updated in this component
      this.callData = Object.assign({}, this.callScorecardDataV2[this.callId]);
    },
    overlayPresent(e) {
      this.overlay = e;
    },
    setDisplayedRegions(regions) {
      this.displayedRegions.push({ id: regions.id, offsets: regions.offsets, isCategory: regions.isCategory });
    },
    spliceDisplayedRegions(index) {
      this.displayedRegions.splice(index, 1);
    },
    retrieveData() {
      if (this.$route.name == "scorecards-v2-calls") {
        this.retrieveCallScorecardDataV2({
          scorecard_id: this.scorecardId,
          dynalabel_id: this.dynalabelId,
          agent_id: this.agentId,
          call_id: this.callId,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          users: this.userDataList,
          call_type:
            this.$store.getters["filters/scorecardAdjustments"] || "all"
        })
        this.retrieveScorecard(this.getParams())
      }
    },
    getParams() {
      return {
        scorecard_id: this.$route.params.scorecardId,
        dynalabel_id: this.$route.params.dynalabelId,
        agent_id: this.$route.params.agentId,
        grouping: this.getUnit,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        distributions: this.$route.params.agentId == 0 ? "team" : "agent",
        page: this.pageNum,
        page_size: this.pageSize,
        search_calls: this.search ? this.search.trim() : this.search,
        order_by: this.orderBy,
        order_direction: this.orderDirection,
        cat_sort_id: this.categorySortId,
        users: this.userDataList,
        call_type: this.$store.getters["filters/scorecardAdjustments"] || "all"
      };
    },
  },
  mounted() {
    if (this.userDataLoadingStatus == "loaded") {
      this.transcriptions = this.userData.features.transcriptions;
    }
    this.callData.loading = true;
    this.retrieveData();
  },
  watch: {
    userDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.transcriptions = this.userData.features.transcriptions;
      }
    },
    callScorecardDataV2LoadingStatus: function(status) {
      if (status == "loaded") {
        if (this.callScorecardDataV2[this.callId] != undefined) {
          this.callData = Object.assign(
            {},
            this.callScorecardDataV2[this.callId]
          );

          this.callData.loading = false;
          this.note_id = this.callData.note_id;
          this.note_is_blank = this.callData.note_is_blank;
          this.has_tags = this.callData.has_tags;
        }
      }
    },
    location: function(location) {
      // if location is changed, update call data in the case that it was not updated
      let localId = Number(location.substring(location.lastIndexOf("-") + 1));
      if (localId == this.callId) {
        if (
          !this.callData.loading &&
          this.callScorecardDataV2[this.callId] != undefined
        ) {
          this.callData = Object.assign(
            {},
            this.callScorecardDataV2[this.callId]
          );
          this.callData.loading = false;
        }
      }
    },
  }
};
</script>

<style>
/* call scorecard styling */
.mdi-open-in-new {
  cursor: pointer;
}
.mdi-open-in-app {
  cursor: pointer;
}

span.white--text {
  font-size: 0.8em;
}

thead tr.cols th {
  font-size: 1rem;
  padding: 8px 16px;
  border-bottom: 1px solid rgb(64, 64, 64) !important;
  background-color: #eee !important;
}

thead tr.hedr th h2 {
  color: #1976d2;
}

thead tr th span {
  font-weight: 900;
}

thead tr.header-row th {
  vertical-align: top;
  padding: 0.5rem 0.5rem;
}

thead tr.header-row th h2 {
  font-size: 1.2rem;
  color: #1976d2;
}

thead tr.header-row.top th {
  padding-bottom: 0.1rem;
}

thead tr.header-row.bottom th {
  vertical-align: middle;
  padding-bottom: 0.1rem;
}

thead tr.header-row th h2.header-score {
  text-align: right;
}

tbody tr td.icon-col {
  width: 0.1rem;
}

tbody tr td.icon-col button.v-btn {
  min-width: 2rem !important;
  padding: 0 0.25rem !important;
}

tfoot tr td {
  border-top: 1px solid rgb(64, 64, 64) !important;
  background-color: #eee;
}

th.align-right,
td.align-right {
  text-align: right;
}

.pending-override {
  /* padding: 0.25rem; */
  border: 1px rgb(255, 191, 0) solid;
  border-radius: 0.5rem;
}

.audio-waveform {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 10px;
  height: 120px;
  width: 100vw;
}

.v-tab {
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

h5.footnote {
  margin-bottom: 3rem;
}
sup.footnote {
  vertical-align: baseline;
  position: relative;
  top: 0rem;
  font-size: 1rem;
  color: rgba(179, 63, 38, 1);
}
h5.footnote sup.footnote {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

div.center-control {
  display: flex;
  justify-content: center;
}

.switch-center label {
  font-size: 0.75rem !important;
}
</style>
