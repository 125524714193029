<template>
  <v-card-actions class="justify-space-between px-4 sticky-card-actions">
    <template>
      <v-checkbox
          v-if="selfSigned && task.is_open"
          class="mr-2"
          small
          v-model="disputed"
          color="warning"
      >
        <template v-slot:label>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                    v-on="on"
                    class="caption"
                >
                  Dispute Task
                </span>
              </template>
              Check this if you want to dispute the task.<br />Disputed tasks
              require a reply.
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>

      <template v-if="!selfSigned">
        <v-checkbox
            v-if="agentInfo.email!=='' && task.is_open"
            small
            v-model="computedEmailAgent"
        >
          <template v-slot:label>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                      v-on="on"
                      class="caption text-caption black--text text--lighten-3"
                  >Email the agent</span
                  >
                </template>
                Check this if you want to send an email to the agent<br />when
                creating a task or responding to a message.
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
        <span v-if="agentInfo.email=='' && task.is_open">No email set up for Agent. Please contact your administrator.</span>
      </template>

      <div v-if="selfSigned && task.is_open">
        <v-btn
            v-if="disputed"
            class="mr-2"
            color="warning"
            small
            :dark="isDark('sendDispute')"
            :disabled="loadingButton && loadingButton !== 'sendDispute'"
            :loading="loadingButton === 'sendDispute'"
            @click="handleButtonClick('sendDispute')"
        >
          Send Dispute
        </v-btn>
        <v-btn
            v-else
            class="mr-2"
            color="primary"
            small
            :dark="isDark('sendReply')"
            :disabled="loadingButton && loadingButton !== 'sendReply'"
            :loading="loadingButton === 'sendReply'"
            @click="handleButtonClick('sendReply')"
        >
          Send Reply
        </v-btn>
        <v-btn
            class="mr-2"
            color="grey darken-0"
            small
            :dark="isDark('cancelEdit')"
            :disabled="loadingButton && loadingButton !== 'cancelEdit'"
            :loading="loadingButton === 'cancelEdit'"
            @click="handleTaskEventWithConfirm('cancelEdit')"
        >
          Cancel/Reset
        </v-btn>
        <v-btn
            class="mr-2"
            color="primary"
            small
            :dark="isDark('acknowledge')"
            :disabled="loadingButton && loadingButton !== 'acknowledge'"
            :loading="loadingButton === 'acknowledge'"
            @click="handleTaskEventWithConfirm('acknowledge')"
        >
          Acknowledge
        </v-btn>
      </div>

      <div v-else-if="selfSigned && !task.is_open">
        <h3>Task is closed.</h3>
      </div>

      <template v-else-if="!task.is_open">
        <v-spacer />
        <div>
          <v-btn
            class="mr-2"
            color="error"
            small
            :dark="isDark('deleteTask')"
            :disabled="loadingButton && loadingButton !== 'deleteTask'"
            :loading="loadingButton === 'deleteTask'"
            @click="handleTaskEventWithConfirm('deleteTask')"
          >
            Delete
          </v-btn>
        </div>
      </template>

      <div v-else-if="task.new_task">
        <v-btn
            v-if="emailAgent"
            class="mr-2"
            color="primary"
            small
            :dark="isDark('saveTask')"
            :disabled="loadingButton && loadingButton !== 'saveTask'"
            :loading="loadingButton === 'saveTask'"
            @click="handleButtonClick('saveTask')"
        >
          Save and Send
        </v-btn>
        <v-btn
            v-else
            class="mr-2"
            color="primary"
            small
            :dark="isDark('saveTask')"
            :disabled="loadingButton && loadingButton !== 'saveTask'"
            :loading="loadingButton === 'saveTask'"
            @click="handleButtonClick('saveTask')"
        >
          Save
        </v-btn>
        <v-btn
            class="mr-2"
            color="grey darken-0"
            small
            :dark="isDark('cancelEdit')"
            :disabled="loadingButton && loadingButton !== 'cancelEdit'"
            :loading="loadingButton === 'cancelEdit'"
            @click="handleTaskEventWithConfirm('cancelEdit')"
        >
          Cancel/Reset
        </v-btn>
      </div>

      <div v-else-if="task.is_open">
        <v-btn
            v-if="emailAgent"
            class="mr-2"
            color="primary"
            small
            :dark="isDark('saveTask')"
            :disabled="loadingButton && loadingButton !== 'saveTask'"
            :loading="loadingButton === 'saveTask'"
            @click="handleButtonClick('saveTask')"
        >
          Save and Send
        </v-btn>
        <v-btn
            v-else
            class="mr-2"
            color="primary"
            small
            :dark="isDark('saveTask')"
            :disabled="loadingButton && loadingButton !== 'saveTask'"
            :loading="loadingButton === 'saveTask'"
            @click="handleButtonClick('saveTask')"
        >
          Save
        </v-btn>
        <v-btn
            class="mr-2"
            color="grey darken-0"
            small
            :dark="isDark('cancelEdit')"
            :disabled="loadingButton && loadingButton !== 'cancelEdit'"
            :loading="loadingButton === 'cancelEdit'"
            @click="handleTaskEventWithConfirm('cancelEdit')"
        >
          Cancel/Reset
        </v-btn>
        <v-btn
            class="mr-2"
            color="primary"
            small
            :dark="isDark('closeTask')"
            :disabled="loadingButton && loadingButton !== 'closeTask'"
            :loading="loadingButton === 'closeTask'"
            @click="handleTaskEventWithConfirm('closeTask')"
        >
          Close Task
        </v-btn>
        <v-btn
            class="mr-2"
            color="error"
            small
            :dark="isDark('deleteTask')"
            :disabled="loadingButton && loadingButton !== 'deleteTask'"
            :loading="loadingButton === 'deleteTask'"
            @click="handleTaskEventWithConfirm('deleteTask')"
        >
          Delete
        </v-btn>
      </div>

    </template>
    <confirm ref="confirm"></confirm>
  </v-card-actions>
</template>

<script>

import CacheHandler from "@/utils/CacheHandler"
import Confirm from "@/components/Widgets/Confirm.vue";

export default {
  components: {
    Confirm
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    agentInfo: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    notified_agent: {
      type: Number,
      required: true,
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      emailAgent: 1,
      disputed: 0,
      showReset: false,
      loadingButton: null,
    }
  },
  methods: {
    async handleTaskEventWithConfirm(action) {
      const confirmed = await this.$refs.confirm.open(
          this.getConfirmationTitle(action),
          this.getConfirmationMessage(action),
          { color: "red" }
      );
      if (!confirmed) return;

      this.loadingButton = action;
      this.emitTaskEvent(action);
    },
    handleButtonClick(action) {
      this.loadingButton = action;
      this.emitTaskEvent(action);
    },
    emitTaskEvent(action) {
      this.$emit(action);
    },
    isDark(action) {
      return !this.loadingButton || this.loadingButton === action;
    },
    getConfirmationTitle(action) {
      const titles = {
        cancelEdit: "Cancel/Reset Edits?",
        closeTask: "Close Task?",
        deleteTask: "Delete Task?",
        acknowledge: "Acknowledge & Close Task?",
      };
      return titles[action] || "Confirm Action";
    },
    getConfirmationMessage(action) {
      const messages = {
        cancelEdit:
            "About to cancel all edits to this task. This action cannot be undone. Are you sure?",
        closeTask:
            "About to close this task. Unsaved changes will be discarded. Are you sure?",
        deleteTask:
            "About to delete this task entirely, including all attachments. This action cannot be undone. Are you sure?",
        acknowledge:
          "Acknowledging will also Close this task. This action cannot be undone. Are you sure?",
      };
      return messages[action] || "Are you sure you want to proceed?";
    },
    emitEmailAgentStatus() {
      this.$emit('setEmailAgent', this.emailAgent) // Emit the 'emailAgent' property
    },

  },
  computed: {
    computedEmailAgent: {
      get() {
        return this.emailAgent
      },
      set(value) {
        // override value if no email address has been set up
        value = (this.agentInfo.email !== "") ? value : 0
        this.emailAgent = value ? 1 : 0 // Force the value to be 1 or 0
        CacheHandler.setItem("email_agent", this.emailAgent)
      },
    },
  },
  mounted() {
    this.emailAgent = this.notified_agent
  },
  watch: {
    computedEmailAgent: "emitEmailAgentStatus",
    saving: {
      immediate: true,
      deep: true,
      handler(state) {
        if(!state) this.loadingButton = null;
      }
    },
  },
}

</script>

<style scoped>

.sticky-card-actions {
  height: 60px;
  position: sticky;
  bottom: 0;
  background-color: white; /* Ensures the footer has a background */
  box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Keep it above scrolling content */
}

.dr-flex {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

</style>
