import axios from "@/utils/AxiosInstance.js";

export const reckeys = {
  namespaced: true,
  state: {
    callReckeyLoadingErrorStatus: false,
    callReckeyLoadingError: "",
    callReckeyLoadingStatus: "notLoading",
    callReckey: [],

    agentReckeyLoadingErrorStatus: false,
    agentReckeyLoadingError: "",
    agentReckeyLoadingStatus: "notLoading",
    agentReckey: []
  },

  getters: {
    callReckeyLoadingErrorStatus: state => state.callReckeyLoadingErrorStatus,
    callReckeyLoadingError: state => state.callReckeyLoadingError,
    callReckeyLoadingStatus: state => state.callReckeyLoadingStatus,
    callReckey: state => state.callReckey,

    agentReckeyLoadingErrorStatus: state => state.agentReckeyLoadingErrorStatus,
    agentReckeyLoadingError: state => state.agentReckeyLoadingError,
    agentReckeyLoadingStatus: state => state.agentReckeyLoadingStatus,
    agentReckey: state => state.agentReckey
  },
  mutations: {
    SET_CALL_RECKEY_LOADING_ERROR_STATUS(state, status) {
      state.callReckeyLoadingErrorStatus = status;
    },
    SET_CALL_RECKEY_LOADING_ERROR(state, error) {
      state.callReckeyLoadingError = error;
    },
    SET_CALL_RECKEY_LOADING_STATUS(state, status) {
      state.callReckeyLoadingStatus = status;
    },
    SET_CALL_RECKEY(state, callReckey) {
      state.callReckey = callReckey;
    },

    SET_AGENT_RECKEY_LOADING_ERROR_STATUS(state, status) {
      state.agentReckeyLoadingErrorStatus = status;
    },
    SET_AGENT_RECKEY_LOADING_ERROR(state, error) {
      state.agentReckeyLoadingError = error;
    },
    SET_AGENT_RECKEY_LOADING_STATUS(state, status) {
      state.agentReckeyLoadingStatus = status;
    },
    SET_AGENT_RECKEY(state, agentReckey) {
      state.agentReckey = agentReckey;
    }
  },
  actions: {
    generateCallReckey(context, params) {
      context.commit("SET_CALL_RECKEY_LOADING_STATUS", "loading");
      axios
        .post(process.env.VUE_APP_RECKEYS, {
          call_id: params.call_id,
          expire: true
        })
        .then(response => {
          context.commit("SET_CALL_RECKEY", response.data.reckey);
          context.commit("SET_CALL_RECKEY_LOADING_STATUS", "loaded");
          context.commit("SET_CALL_RECKEY_LOADING_ERROR_STATUS", false);
          context.commit("SET_CALL_RECKEY_LOADING_ERROR", "");
        })
        .catch(function(error) {
          context.commit("SET_CALL_RECKEY", []);
          context.commit("SET_CALL_RECKEY_LOADING_STATUS", "failed");
          context.commit("SET_CALL_RECKEY_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error);
          if (
            error.hasOwnProperty(error) &&
            error.error.hasOwnProperty("message")
          )
            error_message = error.error.message;
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty("code"))
            error_message += ` (${error.error.code})`;
          context.commit(
            "SET_CALL_RECKEY_LOADING_ERROR",
            "generateCallReckey(): " + error_message
          );
        });
    },
    generateAgentReckey(context, params) {
      context.commit("SET_AGENT_RECKEY_LOADING_STATUS", "loading");
      axios
        .post(process.env.VUE_APP_RECKEYS, {
          agent_id_for_reckey: params.agent_id_for_reckey,
          expire: true
        })
        .then(response => {
          context.commit("SET_AGENT_RECKEY", response.data.reckey);
          context.commit("SET_AGENT_RECKEY_LOADING_STATUS", "loaded");
          context.commit("SET_AGENT_RECKEY_LOADING_ERROR_STATUS", false);
          context.commit("SET_AGENT_RECKEY_LOADING_ERROR", "");
        })
        .catch(function(error) {
          context.commit("SET_AGENT_RECKEY", []);
          context.commit("SET_AGENT_RECKEY_LOADING_STATUS", "failed");
          context.commit("SET_AGENT_RECKEY_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error);
          if (
            error.hasOwnProperty(error) &&
            error.error.hasOwnProperty("message")
          )
            error_message = error.error.message;
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty("code"))
            error_message += ` (${error.error.code})`;
          context.commit(
            "SET_AGENT_RECKEY_LOADING_ERROR",
            "generateAgentReckey(): " + error_message
          );
        });
    }
  }
};
