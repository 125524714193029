import axios from "@/utils/AxiosInstance.js"
export const observations = {
  namespaced: true,
  state: {

    savedTranscriptObservationData: [],
    pendingTranscriptObservationData: [],
    savedSubcategoryObservationData: [],
    pendingSubcategoryObservationData: [],

    scorecardSubcategoryXrefData: []

  },
  getters: {

    savedTranscriptObservationData: state => state.savedTranscriptObservationData,
    pendingTranscriptObservationData: state => state.pendingTranscriptObservationData,
    savedSubcategoryObservationData: state => state.savedSubcategoryObservationData,
    pendingSubcategoryObservationData: state => state.pendingSubcategoryObservationData,

    scorecardSubcategoryXrefData: state => state.scorecardSubcategoryXrefData,

  },
  mutations: {

    // initial load - these can be edited or deleted
    STORE_SAVED_TRANSCRIPT_OBSERVATION_DATA(state, transcriptObservationData) {
      state.savedTranscriptObservationData = transcriptObservationData
    },
    STORE_SAVED_SUBCATEGORY_OBSERVATION_DATA(state, subcategoryObservationData) {
      state.savedSubcategoryObservationData = subcategoryObservationData
    },

    STORE_PENDING_TRANSCRIPT_OBSERVATION_DATA(state, transcriptObservationData) {
      state.pendingTranscriptObservationData = transcriptObservationData
    },
    STORE_PENDING_SUBCATEGORY_OBSERVATION_DATA(state, subcategoryObservationData) {
      state.pendingSubcategoryObservationData = subcategoryObservationData
    },

    STORE_SCORECARD_SUBCATEGORY_XREF_DATA(state, scorecardSubcategoryXrefData) {
      state.scorecardSubcategoryXrefData = scorecardSubcategoryXrefData
    },

    // when adding new pending ones
    APPEND_TRANSCRIPT_OBSERVATION_DATA(state, transcriptObservationData) {
      const pendingObservation = state.pendingTranscriptObservationData.find(
        pendingObservation => pendingObservation.transcript_seg_id === transcriptObservationData[0].transcript_seg_id
      );
      const savedObservation = state.savedTranscriptObservationData.find(
        savedObservation => savedObservation.transcript_seg_id === transcriptObservationData[0].transcript_seg_id
      );
      if(!pendingObservation && !savedObservation) {
        state.pendingTranscriptObservationData = [
          ...state.pendingTranscriptObservationData,
          ...transcriptObservationData,
        ]
      }
    },
    APPEND_SUBCATEGORY_OBSERVATION_DATA(state, subcategoryObservationData) {
      const pendingObservation = state.pendingSubcategoryObservationData.find(
        pendingObservation => pendingObservation.subcategory_id === subcategoryObservationData[0].subcategory_id
      );
      const savedObservation = state.savedSubcategoryObservationData.find(
        savedObservation => savedObservation.subcategory_id === subcategoryObservationData[0].subcategory_id
      );
      if(!pendingObservation && !savedObservation) {
        state.pendingSubcategoryObservationData = [
          ...state.pendingSubcategoryObservationData,
          ...subcategoryObservationData,
        ]
      }
    },

    // when editing comments of pending ones
    UPDATE_PENDING_TRANSCRIPT_OBSERVATION_COMMENT(state, { transcript_seg_id, comment }) {
      const observation = state.pendingTranscriptObservationData.find(
        observation => observation.transcript_seg_id === transcript_seg_id
      );
      if (observation) {
        observation.comment = comment; // Update the comment
      }
    },
    UPDATE_PENDING_SUBCATEGORY_OBSERVATION_COMMENT(state, { subcategory_id, comment }) {
      const observation = state.pendingSubcategoryObservationData.find(
        observation => observation.subcategory_id === subcategory_id
      );
      if (observation) {
        observation.comment = comment; // Update the comment
      }
    },

    // removing pending ones - no consequence, they just go
    REMOVE_PENDING_TRANSCRIPT_OBSERVATION(state, transcript_seg_id) {
      state.pendingTranscriptObservationData = state.pendingTranscriptObservationData.filter(
        observation => observation.transcript_seg_id !== transcript_seg_id
      )
    },
    REMOVE_PENDING_SUBCATEGORY_OBSERVATION(state, subcategory_id) {
      state.pendingSubcategoryObservationData = state.pendingSubcategoryObservationData.filter(
        observation => observation.subcategory_id !== subcategory_id
      )
    },

    // when editing comments of saved ones
    UPDATE_SAVED_TRANSCRIPT_OBSERVATION_COMMENT(state, { transcript_seg_id, comment }) {

      const observation = state.savedTranscriptObservationData.find(
        observation => observation.transcript_seg_id === transcript_seg_id
      );
      if (observation) {
        observation.comment = comment; // Update the comment
      }
    },
    UPDATE_SAVED_SUBCATEGORY_OBSERVATION_COMMENT(state, { subcategory_id, comment }) {
      const observation = state.savedSubcategoryObservationData.find(
        observation => observation.subcategory_id === subcategory_id
      );
      if (observation) {
        observation.comment = comment; // Update the comment
      }
    },

    // removing saved ones - we need to remember what we deleted
    REMOVE_SAVED_TRANSCRIPT_OBSERVATION(state, transcript_seg_id) {
      state.savedTranscriptObservationData = state.savedTranscriptObservationData.filter(
        observation => observation.transcript_seg_id !== transcript_seg_id
      )
    },
    REMOVE_SAVED_SUBCATEGORY_OBSERVATION(state, subcategory_id) {
      state.savedSubcategoryObservationData = state.savedSubcategoryObservationData.filter(
        observation => observation.subcategory_id !== subcategory_id
      )
    },

    // reset the whole data store - ready to receive next task
    RESET_PENDING_STATE(state) {
      state.pendingTranscriptObservationData = []
      state.pendingSubcategoryObservationData = []
    },

  },
  actions: {

    // used when first opening a previously saved task
    storeSavedTranscriptObservationData(context, payload) {
      context.commit("STORE_SAVED_TRANSCRIPT_OBSERVATION_DATA", payload);
    },
    storeSavedSubcategoryObservationData(context, payload) {
      context.commit("STORE_SAVED_SUBCATEGORY_OBSERVATION_DATA", payload);
    },

    storePendingTranscriptObservationData(context, payload) {
      context.commit("STORE_PENDING_TRANSCRIPT_OBSERVATION_DATA", payload);
    },
    storePendingSubcategoryObservationData(context, payload) {
      context.commit("STORE_PENDING_SUBCATEGORY_OBSERVATION_DATA", payload);
    },

    storeScorecardSubcategoryXrefData(context, payload) {
      context.commit("STORE_SCORECARD_SUBCATEGORY_XREF_DATA", payload);
    },

    // transcript pending observation operations
    appendTranscriptObservation(context, payload) {
      context.commit("APPEND_TRANSCRIPT_OBSERVATION_DATA", payload);
    },
    updatePendingTranscriptObservationComment(context, payload) {
      context.commit("UPDATE_PENDING_TRANSCRIPT_OBSERVATION_COMMENT", payload);
    },
    removePendingTranscriptObservation(context, transcript_seg_id) {
      context.commit("REMOVE_PENDING_TRANSCRIPT_OBSERVATION", transcript_seg_id)
    },

    // subcategory pending observation operations
    appendSubcategoryObservation(context, payload) {
      context.commit("APPEND_SUBCATEGORY_OBSERVATION_DATA", payload);
    },
    updatePendingSubcategoryObservationComment(context, payload) {
      context.commit("UPDATE_PENDING_SUBCATEGORY_OBSERVATION_COMMENT", payload);
    },
    removePendingSubcategoryObservation(context, id) {
      context.commit("REMOVE_PENDING_SUBCATEGORY_OBSERVATION", id)
    },

    // transcript saved observation operations
    updateSavedTranscriptObservationComment(context, payload) {
      context.commit("UPDATE_SAVED_TRANSCRIPT_OBSERVATION_COMMENT", payload);
    },
    removeSavedTranscriptObservation(context, transcript_seg_id) {
      context.commit("REMOVE_SAVED_TRANSCRIPT_OBSERVATION", transcript_seg_id)
    },

    // subcategory saved observation operations
    updateSavedSubcategoryObservationComment(context, payload) {
      context.commit("UPDATE_SAVED_SUBCATEGORY_OBSERVATION_COMMENT", payload);
    },
    removeSavedSubcategoryObservation(context, subcategory_id) {
      context.commit("REMOVE_SAVED_SUBCATEGORY_OBSERVATION", subcategory_id)
    },

    // do the deeds
    deleteTranscriptObservation(context, payload) {
      let params = { transcript_seg_id: payload.transcript_seg_id }
      let task_url = process.env.VUE_APP_TASK_TRANSCRIPT_SEGMENTS + payload.task_id
      axios.delete(task_url, {data: params })
    },
    deleteSubcategoryObservation(context, payload) {
      let params = { subcategory_id: payload.subcategory_id }
      let task_url = process.env.VUE_APP_TASK_SUBCATEGORIES + payload.task_id
      axios.delete(task_url, {data: params })
    },

    // reset the store
    resetPendingObservations(context) {
      context.commit("RESET_PENDING_STATE");
    },

  }
};
