<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead style="background-color:#999;">
          <tr class="cols">
            <th>&nbsp;</th>
            <th>
              <call-scorecard-body-table-collapse
                :scCollapseOptions="scCollapseOptions"
                @expandCollapse="expandCollapse"
              ></call-scorecard-body-table-collapse>
            </th>
            <th
              v-for="(item, index) in scorecardHeaders"
              :colspan="item.colspan"
              :class="item.class"
              :key="index"
            >
              {{ item.label }}
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in scorecard"
            :key="index"
            @mouseover="hoveredRow = index"
            @mouseleave="hoveredRow = null"
            v-if="!row.catCollapsed || row.primary"
          >
            <td class="text-center pl-0 pr-0 icon-col">
              <span :class="callRowHover(hoveredRow, index)">{{
                callRowNumber(index)
              }}</span>
            </td>
            <td class="text-center pl-0 pr-0 icon-col">
              <span v-if="row.primary">
                <v-btn icon @click="toggleCategoryRow(row.catId)">
                  <v-icon small v-text="categoryCollapsedIcon(row)"></v-icon>
                </v-btn>
              </span>
            </td>

            <td class="text-center pl-0 pr-0 icon-col no-print">
              <v-progress-circular
                v-if="searchDataLoading"
                indeterminate
                color="primary"
                size="15"
              ></v-progress-circular>
              <call-scorecard-show-hit
                v-else-if="!isChat && !searchDataHasError"
                :hitEnabled="categoryShowHitEnabled(row)"
                :hitDisabled="categoryShowHitDisabled(row)"
                :checkedRegions="checkedRegions"
                :rowId="row.catId"
                @updateDisplayedRegions="updateDisplayedRegions"
              ></call-scorecard-show-hit>
              <span v-else>&nbsp;</span>
            </td>

            <td class="text-center icon-col pl-0 pr-0">
              <call-scorecard-icon
                v-if="row.primary"
                :format="format"
                :callId="String(callId)"
                :type="'category'"
                :key="row.catId"
                :classType="pendingCatOverride(row)"
                :iconColor="getCatColor(row, excludeCall)"
                :iconType="getCatIcon(row)"
                :titleText="getCatText(row)"
                :row="row"
                :applicable="categoryIsApplicable(row)"
                :actionsDisabled="iconsDisabled"
                :iconSize="'medium'"
                @updateCat="updateCat"
              ></call-scorecard-icon>
            </td>
            <td class="pl-1 pr-0">
              <span v-if="row.primary">{{ row.catTitle }}</span>
            </td>

            <td class="text-center pl-0 pr-0 icon-col no-print">
              <v-progress-circular
                v-if="searchDataLoading"
                indeterminate
                color="primary"
                size="15"
              ></v-progress-circular>
              <call-scorecard-show-hit
                v-else-if="!isChat && !searchDataHasError && !row.catCollapsed"
                :hitEnabled="subcategoryShowHitEnabled(row)"
                :hitDisabled="subcategoryShowHitDisabled(row)"
                :checkedRegions="checkedRegions"
                :rowId="row.subcatId"
                @updateDisplayedRegions="updateDisplayedRegions"
              ></call-scorecard-show-hit>
              <span v-else>&nbsp;</span>
            </td>

            <td class="icon-col pl-0 pr-0">
              <call-scorecard-icon
                v-if="!row.catCollapsed"
                :callId="String(callId)"
                :format="format"
                :type="'subcategory'"
                :classType="pendingSubcatOverride(row)"
                :iconColor="getSubCatColor(row, excludeCall)"
                :iconType="getSubCatIcon(row)"
                :titleText="getSubCatText(row)"
                :row="row"
                :applicable="categoryIsApplicable(row)"
                :actionsDisabled="iconsDisabled"
                :iconSize="'medium'"
                @updateSubcat="updateSubcat"
              ></call-scorecard-icon>
            </td>
            <td class="pl-1">
              <span v-if="!row.catCollapsed">
                {{ ampersandReplacement(row.subcatTitle) }}</span
              >
              <span
                v-else
                class="caption grey--text"
                style="text-wrap: nowrap"
                >{{ collapsedSubcatgories(row) }}</span
              >
            </td>

            <td
              v-if="row.primary && row.showScore"
              class="pl-0 pr-0 text-right"
            >
              {{ formatScore(Number(row.catScore)) }}
            </td>
            <td v-else>&nbsp;</td>

            <td class="text-center pl-0 pr-0 icon-col">
              <call-scorecard-coachable
                :subcatId="row.subcatId"
                :index="index"
                :hoveredRow="hoveredRow"
                :showTask="showTask"
                :selfSigned="selfSigned"
                :callId="callId"
              ></call-scorecard-coachable>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row>
      <v-col>
        <call-scorecard-control-info
          :headerStyle="'font-size:14px;'"
          :iconSize="'medium'"
          :firstColSpan="'3'"
          :secondColSpan="'3'"
        ></call-scorecard-control-info>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import CallScorecardIcon from "@/components/Scorecards/CallScorecardIcon.vue";
import callSubcategoryIndex from "@/mixins/callSubcategoryIndex";
import callCategoryIndex from "@/mixins/callCategoryIndex";
import callScorecardHelpers from "@/mixins/callScorecardHelpers";
import DemoHandler from "@/utils/DemoHandler";
import CallScorecardShowHit from "@/components/Scorecards/CallScorecardShowHit.vue";
import CallScorecardBodyTableCollapse from "./CallScorecardBodyTableCollapse.vue";
import CallScorecardCoachable from "./CallScorecardCoachable.vue";
import CallScorecardControlInfo from "@/components/Scorecards/CallScorecardControlInfo.vue";

export default {
  mixins: [callSubcategoryIndex, callCategoryIndex, callScorecardHelpers],
  components: {
    CallScorecardIcon,
    CallScorecardShowHit,
    CallScorecardBodyTableCollapse,
    CallScorecardCoachable,
    CallScorecardControlInfo
  },
  data() {
    return {
      hoveredRow: null,
      scCollapseOptions: [
        { target: "collapse_na", text: "Applicable Category Only" },
        { target: "collapse_subcats", text: "Category Only" }
      ],
      scorecardHeaders: [
        { colspan: 3, label: "Category", class: "pl-2" },
        { colspan: 3, label: "Subcategory", class: "pl-0" },
        { colspan: 1, label: "Score", class: "text-right align-text-print" }
      ],
      searchIds: [],
      checkedRegions: [],
      scorecard: [],
      payload: {},
      callDataId: DemoHandler.determineDemoCall(this.callId)
    };
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    callData: {
      required: true,
      type: Object
    },
    format: {
      required: false,
      type: String,
      default: "callScorecard"
    },
    excludeCall: {
      required: true,
      type: Boolean
    },
    typeOfExclusion: {
      required: false,
      type: Number,
      default: null
    },
    changesApplied: {
      required: true,
      type: Boolean
    },
    changesRemoved: {
      required: true,
      type: Boolean
    },
    clearAppliedChanges: {
      required: true,
      type: Boolean
    },
    displayedRegions: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    pageQueryData: {
      type: Object,
      required: false,
      default: null
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus",

      "callSearchHits",
      "callSearchHitsLoadingStatus",
      "callSearchHitsLoadingError",
      "callSearchHitsLoadingErrorStatus"
    ]),
    ...mapGetters("dynaLabels", [
      "dynalabelHitsForCall",
      "dynalabelHitsForCallLoadingStatus",
      "dynalabelHitsForCallLoadingError",
      "dynalabelHitsForCallLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userDataList", "userData"]),
    categorySearches() {
      // get all of the searches from the category dynalabels
      let rtn = {};
      rtn[this.callDataId] = [];

      if (this.dynalabelHitsForCallLoadingStatus == "loaded") {
        // get the search ids from categories that have a dynalabel hit
        this.callData.categories.forEach(category => {
          category.dynalabels.forEach(dynalabel => {
            dynalabel.searches.forEach(search => {
              rtn[this.callDataId].push(search);
            });
          });
        });
        return rtn;
      }
      return rtn;
    },
    callScorecardRegions: function() {
      let regions = { category: {}, subCategory: {} };
      regions["subCategory"] = this.callData.categories.flatMap(category => {
        return category.sub_cats.flatMap(subcategory => {
          return { id: subcategory.id, search_ids: subcategory.searches };
        });
      });

      regions["category"] = this.callData.categories.flatMap(category => {
        return {
          id: category.id,
          search_ids: category.dynalabels
            .map(dynalabel => dynalabel.searches)
            .flat()
        };
      });

      return regions;
    },
    searchDataLoading() {
      if (this.searchIds.length < 1) {
        // no searches to load data for
        return false;
      } else if (
        this.callSearchHitsLoadingStatus != "failed" &&
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("loading")
      ) {
        return this.callSearchHits[this.callDataId].loading;
      }
      return true;
    },
    iconsDisabled() {
      return (this.excludeCall && this.typeOfExclusion != 1) || this.selfSigned;
    },
    isChat() {
      return this.callData.media_type == "Chat";
    },
    searchDataHasError() {
      if (this.callSearchHitsLoadingStatus == "failed") {
        return true;
      } else {
        return this.callSearchHits[this.callDataId] != undefined &&
          this.callSearchHits[this.callDataId].hasOwnProperty("error")
          ? this.callSearchHits[this.callDataId].error
          : false;
      }
    },
    subcatSearchPhrases() {
      let subcatsWithPhrase = [];
      //push all of the subcategory ids that have a search phrase that exists
      if (
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("offsets") &&
        this.callData.hasOwnProperty("categories")
      ) {
        this.callData.categories.forEach(category => {
          category.sub_cats.forEach(subcat => {
            subcat.searches.forEach(search => {
              //subcategory has a search phrase when search ids match and the phrase_group_id and phrase id are set
              this.callSearchHits[this.callDataId].offsets.forEach(
                searchHit => {
                  if (
                    searchHit.fkey_phrase_group_id &&
                    searchHit.fkey_phrase_id &&
                    searchHit.fkey_search_id == search &&
                    !subcatsWithPhrase.includes(subcat.id)
                  ) {
                    subcatsWithPhrase.push(subcat.id);
                  }
                }
              );
            });
          });
        });
      }
      return subcatsWithPhrase;
    },
    categorySearchPhrases() {
      let categoriesWithPhrase = [];
      // push all of the category ids that have a search phrase that exists
      if (
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("offsets") &&
        this.callData.hasOwnProperty("categories")
      ) {
        this.callData.categories.forEach(category => {
          category.dynalabels.forEach(dynalabel => {
            dynalabel.searches.forEach(search => {
              // category has a search phrase when search ids match and the phrase_group_id and phrase id are set
              this.callSearchHits[this.callDataId].offsets.forEach(
                searchHit => {
                  if (
                    searchHit.fkey_phrase_group_id &&
                    searchHit.fkey_phrase_id &&
                    searchHit.fkey_search_id == search &&
                    !categoriesWithPhrase.includes(category.id)
                  ) {
                    categoriesWithPhrase.push(category.id);
                  }
                }
              );
            });
          });
        });
      }
      return categoriesWithPhrase;
    },
    allCategoriesCollapsed() {
      return this.scorecard.every(row => row.catCollapsed);
    }
  },
  methods: {
    ...mapActions({
      clearOverrides: "scorecardsV2Calls/clearOverrides",
      removeAppliedOverrides: "scorecardsV2Calls/removeAppliedOverrides",
      updateCallOverrides: "scorecardsV2Calls/updateCallOverrides",
      createExcludedCall: "scorecardsV2Calls/createExcludedCall",
      retrieveSearchHits: "scorecardsV2Calls/retrieveSearchHits",
      getDynalabelCallHit: "dynaLabels/getDynalabelCallHit",
      setDynalabelHitsForCallLoadingStatus:
        "dynaLabels/setDynalabelHitsForCallLoadingStatus",
      storeScorecardSubcategoryXrefData:
        "observations/storeScorecardSubcategoryXrefData"
    }),
    collapsedSubcatgories(row) {
      let subcatCount = this.subcatCount(row.catId);
      let subcatString = subcatCount + " collapsed subcategor";
      let subcatSubStr = subcatCount > 1 ? "ies" : "y";
      return subcatString + subcatSubStr;
    },
    getSearchIds() {
      let searches = [];
      if (this.callData.hasOwnProperty("categories")) {
        this.callData.categories.forEach(category => {
          if (category.manual_scoring == 0) {
            category.dynalabels.forEach(dynalabel => {
              dynalabel.searches.forEach(search => {
                if (!searches.includes(search)) searches.push(search);
              });
            });
            category.sub_cats.forEach(sub_cat => {
              sub_cat.searches.forEach(search => {
                if (!searches.includes(search)) searches.push(search);
              });
            });
          }
        });
      }
      return searches;
    },
    setCheckedRegions(id) {
      if (!this.checkedRegions.includes(id)) {
        this.checkedRegions.push(id);
      } else {
        const index = this.checkedRegions.indexOf(id);
        if (index > -1) this.checkedRegions.splice(index, 1);
      }
    },
    updateDisplayedRegions(id) {
      this.setCheckedRegions(id);
      let region_id = this.checkedRegions.find(entry => entry == id);

      if (region_id) {
        // ok, this just got checked, so add the region to the display
        // check both the subcategories and categories now
        let record = this.callScorecardRegions.subCategory.find(
          entry => entry.id == region_id
        );
        let isCategory = false;
        if (record == undefined) {
          record = this.callScorecardRegions.category.find(
            entry => entry.id == region_id
          );
          isCategory = true;
        }
        let offsets = this.getCallOffsets(isCategory, record);
        if (Array.isArray(offsets))
          this.$emit("setDisplayedRegions", {
            id: id,
            offsets: offsets,
            isCategory: isCategory
          });
      } else {
        // ok, this just got unchecked, so remove the region from the display
        let indx = this.displayedRegions.findIndex(o => o.id === id);
        if (indx >= 0) this.$emit("spliceDisplayedRegions", indx);
      }
    },
    getCallOffsets(isCategory, record) {
      let offsets = [];
      if (
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("offsets")
      ) {
        this.callSearchHits[this.callDataId].offsets.forEach(offset => {
          if (record.search_ids.includes(offset.fkey_search_id)) {
            if (
              !isCategory ||
              this.categorySearches[this.callDataId].includes(
                offset.fkey_search_id
              )
            ) {
              offsets.push(offset);
            }
          }
        });
      }
      return offsets;
    },
    categoryShowHitEnabled(row) {
      return (
        this.categoryHasSearchHits(row) &&
        row.primary &&
        this.categoryIsApplicable(row)
      );
    },
    categoryShowHitDisabled(row) {
      return (
        this.categoryHasSearchHits(row) &&
        row.primary &&
        !this.categoryIsApplicable(row)
      );
    },
    subcategoryShowHitEnabled(row) {
      return this.subcatHasSearchHits(row) && this.categoryIsApplicable(row);
    },
    subcategoryShowHitDisabled(row) {
      return this.subcatHasSearchHits(row) && !this.categoryIsApplicable(row);
    },
    subcatHasSearchHits(row) {
      // show "Search Hit"/enable transcript button if category is automatic && subcategory has a search phrase hit
      return (
        row.catManualScoring == 0 &&
        this.subcatSearchPhrases.includes(row.subcatId)
      );
    },
    categoryHasSearchHits(row) {
      // show hit for category
      return this.dynalabelHitsForCallLoadingStatus == "loaded"
        ? this.categorySearchPhrases.includes(row.catId)
        : false;
    },
    toggleCategoryRow(id) {
      this.scorecard = this.scorecard.map(item =>
        item.catId == id ? { ...item, catCollapsed: !item.catCollapsed } : item
      );

      this.scCollapseOptions = this.allCategoriesCollapsed
        ? [
            { target: "collapse_na", text: "Applicable Category Only" },
            { target: "expand_all", text: "Expand All" }
          ]
        : [
            { target: "collapse_na", text: "Applicable Category Only" },
            { target: "collapse_subcats", text: "Category Only" }
          ];
    },
    updateSubcat(subcategoryId) {
      this.scorecard.forEach(row => {
        if (row.subcatId == subcategoryId) {
          row.subcatUpdated = !row.subcatUpdated;
        }
      });
    },
    updateCat(categoryId) {
      this.scorecard.forEach(row => {
        if (row.catId == categoryId) {
          row.catUpdated = !row.catUpdated;
        }
      });
    },
    subcatCount(id) {
      return this.scorecard.filter(item => item.catId === id).length;
    },
    expandCollapse(type) {
      switch (type) {
        case "expand_all":
          this.scorecard = this.scorecard.map(item => ({
            ...item,
            catCollapsed: false
          }));
          this.scCollapseOptions = [
            { target: "collapse_na", text: "Applicable Category Only" },
            { target: "collapse_subcats", text: "Category Only" }
          ];
          break;
        case "collapse_subcats":
          this.scorecard = this.scorecard.map(item => ({
            ...item,
            catCollapsed: true
          }));
          this.scCollapseOptions = [
            { target: "collapse_na", text: "Applicable Category Only" },
            { target: "expand_all", text: "Expand All" }
          ];
          break;
        case "collapse_na":
          this.scorecard = this.scorecard.map(item =>
            !this.catApplicable(item)
              ? { ...item, catCollapsed: true }
              : { ...item, catCollapsed: false }
          );

          this.scCollapseOptions = [
            { target: "collapse_subcats", text: "Category Only" },
            { target: "expand_all", text: "Expand All" }
          ];
          break;
      }
      if (this.allCategoriesCollapsed) {
        this.scCollapseOptions = [
          { target: "collapse_na", text: "Applicable Category Only" },
          { target: "expand_all", text: "Expand All" }
        ];
      }
    },
    showCategoryScore(category) {
      if (!this.isCategoryApplicable(category)) {
        return this.callData.nonApplicableCategoryHandling == 1;
      } else return true;
    },
    isCategoryApplicable(category) {
      if (
        category.manual_applicable != undefined &&
        category.manual_applicable != null
      ) {
        return category.manual_applicable == 1;
      } else {
        return category.applicable == 1;
      }
    },
    callGetDynalabelCallHit() {
      let dynalabel_ids = [];
      this.callData.categories.forEach(category => {
        category.dynalabels.forEach(dynalabel => {
          dynalabel_ids.push(dynalabel.id);
        });
      });
      let params = {
        call_id: this.callDataId,
        dynalabel_ids: dynalabel_ids,
        selfSigned: this.selfSigned
      };
      if (this.selfSigned) {
        params["userid"] = this.$route.params.userid;
        params["reckey"] = this.$route.params.reckey;
      }
      this.getDynalabelCallHit(params);
    },
    createCallScorecardRows() {
      let lastCategoryName = "";
      let primary = false;
      let rowNumber = 0;
      let xrefData = [];
      if (this.callData.hasOwnProperty("categories")) {
        this.callData.categories.forEach(category => {
          category.sub_cats.forEach(subcategory => {
            if (lastCategoryName != category.title) {
              lastCategoryName = category.title;
              primary = true;
            } else {
              primary = false;
            }

            xrefData.push({
              subcategory_id: subcategory.id,
              rowNumber: ++rowNumber
            });

            this.scorecard.push({
              catId: category.id,
              primary: primary,
              catScore: category.score,
              catTitle: category.title,
              catManualApplicable: category.manual_applicable,
              categoryOverriddenBy: category.username,
              catApplicable: category.applicable,
              catScoringRule: category.scoring_rule,
              catManualScoring: category.manual_scoring,
              catOvverrideDate: category.modified_on,
              threshold_passfail: category.threshold_passfail,
              threshold_count: category.threshold_count,
              showScore: this.showCategoryScore(category),
              catWeight: category.weight,
              catContribution: category.contribution,
              catCollapsed: false,
              catUpdated: false,
              subcatUpdated: false,
              subcatId: subcategory.id,
              subcatTitle: subcategory.title,
              subcatHit: subcategory.hit,
              subcatFail: subcategory.fail,
              subcatManualPass: subcategory.manual_pass,
              subcatManualFail: subcategory.manual_fail,
              subcatOverriddenBy: subcategory.username,
              subcatOverrideDate: subcategory.override_datetime,
              subcatSearches: subcategory.searches,
              totalSubcatWeight: category.totalSubcatWeight,
              subcatWeight: subcategory.weight
            });
          });
        });

        this.storeScorecardSubcategoryXrefData(xrefData);
      }
    },
    removeCatUpdates() {
      this.scorecard.forEach(row => {
        row.catUpdated = false;
        row.subcatUpdated = false;
      });
    },
    overridesCleared() {
      if (this.clearAppliedChanges) {
        this.removeCatUpdates();
        this.clearOverrides({ call_id: this.callId });
        this.$emit("clearAppliedChanges", false);
      }
    },
    overridesApplied() {
      if (this.changesApplied) {
        this.payload.excluded_call = this.excludeCall;
        this.payload.exclusion_type = this.typeOfExclusion;
        if (
          this.callData.exclusionType != this.typeOfExclusion &&
          this.typeOfExclusion != null
        ) {
          if (this.typeOfExclusion == 1) {
            this.payload.exclusion_type = 1;
            //just need to update overrides because call will become exluded automatically when reprocessed with the new category applicable-ness
            this.updateCallOverrides(this.payload);
          } else {
            //create an excluded call - no need to reprocess the call when it is not excluded by selecting categories
            this.payload.exclusion_type = 0;
            this.createExcludedCall(this.payload);
          }
        } else {
          // just update the overrides
          this.updateCallOverrides(this.payload);
        }
        this.payload.excluded_call = null;
        this.payload.exclusion_type = null;
        this.$emit("changeCallOverrides", false);
      }
    },
    overridesRemoved() {
      if (this.changesRemoved) {
        this.removeCatUpdates();
        this.removeAppliedOverrides(this.payload);
        this.$emit("deleteAppliedOverrides", false);
      }
    },
    getSearchHits() {
      let axios_params = {};
      if (this.selfSigned) {
        axios_params["userid"] = this.$route.params.userid;
        axios_params["reckey"] = this.$route.params.reckey;
      }

      if (this.searchIds.length > 0) {
        this.retrieveSearchHits({
          call_id: this.callDataId,
          search_ids: this.searchIds,
          axios_params: axios_params,
          selfSigned: this.selfSigned
        });
      }
    }
  },
  mounted() {
    this.setDynalabelHitsForCallLoadingStatus("notLoading");
    this.createCallScorecardRows();

    this.payload = {
      scorecard_id: this.$route.params.scorecardId,
      dynalabel_id: this.$route.params.dynalabelId,
      agent_id: this.$route.params.agentId,
      call_id: this.callId,
      start_date: moment(this.$route.query.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.$route.query.end_date).format("YYYY-MM-DD"),
      call_type: this.$store.getters["filters/scorecardAdjustments"] || "all",
      users: this.userDataList,
      page: this.pageQueryData ? this.pageQueryData.num : 1,
      page_size: this.pageQueryData ? this.pageQueryData.size : 10,
      search_calls: this.pageQueryData.search
        ? this.pageQueryData.search.trim()
        : ""
    };

    if (
      this.userData.id != this.userData.csrep_userid &&
      this.userData.privileges.callFinderManageSearches
    ) {
      this.payload.get_audit_info = true;
    }
    this.searchIds = this.getSearchIds();
    this.getSearchHits();
    this.callGetDynalabelCallHit();
  },
  watch: {
    clearAppliedChanges: "overridesCleared",
    changesApplied: "overridesApplied",
    changesRemoved: "overridesRemoved"
  }
};
</script>

<style scoped>
thead tr.cols th {
  font-size: 1rem;
  padding: 8px 16px;
  border-bottom: 1px solid rgb(64, 64, 64) !important;
  background-color: #eee !important;
}

tbody tr td.icon-col {
  width: 0.075rem;
}
</style>
