<template>
  <div :class="containerClass">
    <v-row class="ma-0 pa-0" v-if="summaryData && this.summaryData.hasOwnProperty('summary') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title :class="headerTextClass">Overall Summary<v-spacer></v-spacer><custom-icon name='powered-by-open-ai'></custom-icon></v-card-title>
          <v-card-text :class="bodyTextClass" class="text--primary">{{this.summaryData.summary}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="summaryData && this.summaryData.hasOwnProperty('agent') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title :class="headerTextClass">Agent Summary</v-card-title>
          <v-card-text :class="bodyTextClass" class="text--primary">{{this.summaryData.agent}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="summaryData && this.summaryData.hasOwnProperty('customer') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title :class="headerTextClass">Customer Summary</v-card-title>
          <v-card-text :class="bodyTextClass" class="text--primary">{{this.summaryData.customer}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "Transcript",
  props: {
    summaryIsLoading: {
      type: Boolean,
    },
    summaryIsReady: {
      type: Boolean,
    },
    summaryData: {
      type: Object,
    },
    containerClass: {
      type: String,
      default: 'border-light',
      required: false,
    },
    headerTextClass: {
      type: String,
      default: '',
      required: false,
    },
    bodyTextClass: {
      type: String,
      default: 'body-1',
      required: false,
    }
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

  .border-light {
    border: 1px solid rgba(0,0,0,.3);
  }

</style>
