import { mapGetters } from "vuex";

const DISPUTED = 4;
const ACKNOWLEDGED = 3;
const IN_PROGRESS = 2;
const NEW = 1;

export default {
  methods: {
    getTaskTitle(task) {
      return this.taskIconHelper(task).title;
    },
    getTaskIconColor(task) {
      return this.taskIconHelper(task).color;
    },
    getTaskIcon(task) {
      return this.taskIconHelper(task).icon;
    },
    taskIsNew(task) {
      return task && task.is_open && task.last_task_status == NEW && !task.overdue;
    },
    getTaskCoachRead(task) {
      return task && task.last_reply_read_by_coach == 0;
    },
    taskIconHelper(task, isOpen) {
      const defaultTask = {
        icon: "mdi-clipboard-outline",
        color: "grey lighten-2",
        title: "No Task Assigned",
      };

      if (!task||task.new_task) return defaultTask;

      const statusMap = {
        closed: {
          condition: !task.is_open || (task.is_open && task.last_task_status == ACKNOWLEDGED),
          icon: "mdi-clipboard-text-off-outline",
          color: "grey darken-2",
          title: "Closed",
        },
        disputed: {
          condition: task.last_task_status === DISPUTED,
          icon: "mdi-clipboard-alert-outline",
          color: "orange darken-2",
          title: "Disputed",
        },
        overdue: {
          condition: task.overdue,
          icon: "mdi-clipboard-text-clock-outline",
          color: "red darken-2",
          title: "Overdue",
        },
        new: {
          condition: task.last_task_status === NEW,
          color: "blue darken-2",
          title: "New", // No icon for "New" status
        },
        inProgress: {
          condition: task.last_task_status === IN_PROGRESS,
          icon: "mdi-clipboard-text-outline",
          color: "green darken-2",
          title: "In Progress",
        },
      };

      for (const status of Object.values(statusMap)) {
        if (status.condition) {
          const { condition, ...result } = status; // Exclude `condition` from the result
          return result;
        }
      }

      return defaultTask;
    },

  }
};
