<template>
  <div>
    <h2>Custom Metadata</h2>
    <div v-if="dynaLabelLoaded">
      <h3>Dynamic Label</h3>
      <v-data-table
        :headers="dynaHeaders"
        :items="dynaLabelInfo"
        :items-per-page="dynaLabelInfo.length"
        :hide-default-footer="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
      <template v-slot:[`item.value`]="{ item }">
          <a v-if="regexUrlCheck(item.value)" target="_blank" rel="noopener" :href="checkValueProtocol(item.value)">{{ item.value }}</a>
          <span v-else>{{ item.value }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/AxiosInstance.js";
import moment from "moment";
import regexChecks from "@/mixins/regexChecks";

const EXCLUDED_DYNA_FIELDS = [
  "Customer",
  "Acctnmbr",
  "Agent Id",
  "Adjusteddatetime",
  "Cust Id"
];

const DESIGNATED_TIME_FIELDS = [
  "Average Talk Time",
  "Hold Time",
  "Handle Time External"
];

export default {
  mixins: [regexChecks],
  props: {
    callId: {
      type: String,
      required: false,
      default: null
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    dataLoaded: false,
    dynaLabelLoaded: false,
    dynaLabelInfo: [],
    sortBy: "var_text",
    sortDesc: false,
    dynaHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "key"
      },
      {
        text: "Value",
        align: "start",
        sortable: false,
        value: "value"
      }
    ]
  }),
  methods: {
    formatDynalabelInfo(fields) {
      let itemsFormatted = [];
      fields.forEach((item, index) => {
        item.key = this.formatDynaField(item.key);
        let notNull = (item.value != null);

        if (DESIGNATED_TIME_FIELDS.includes(item.key) && notNull) {
          if (item.key == DESIGNATED_TIME_FIELDS[2]) {
            item.key = "Handle Time (External)";
          }
          item.value = this.formatHMS(item.value);
        }
        if (notNull && !EXCLUDED_DYNA_FIELDS.includes(item.key)) {
          itemsFormatted.push(item);
        }

        this.dynaLabelInfo = itemsFormatted;
      });
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    formatDynaField(key) {
      if (!key.includes("_")) {
        return key[0].toUpperCase() + key.substr(1);
      } else {
        let keySplit = key.split("_");
        for (var i = 0; i < keySplit.length; i++) {
          keySplit[i] =
            keySplit[i].charAt(0).toUpperCase() + keySplit[i].slice(1);
        }
        let capitalized = keySplit.join("_");
        capitalized = capitalized.replaceAll("_", " ");
        return capitalized;
      }
    },
    getDynaFields(val) {
      let dynalabel_url = process.env.VUE_APP_SETUP_DYNA_LABELS_CALLS + val;
      let axios_params = {
        headers: {},
        params: {},
      }
      if(this.selfSigned) {
        dynalabel_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + val
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
        axios_params['params']['route'] = 'dynalabels-calls'
      }
      axios
        .get(dynalabel_url,axios_params)
        .then(response => {
          if (response && response.data) {
            let dynalabelFields = Object.entries(response.data.data).map(
              ([key, value]) => ({
                key,
                value
              })
            );
            dynalabelFields.sort((a, b) => a.key.localeCompare(b.key));
            this.formatDynalabelInfo(dynalabelFields);
            this.dynaLabelLoaded = true;
          }
        })
        .finally(() => {
          // loader.hide();
        });
    }
  },
  watch: {
  },
  mounted() {
    this.getDynaFields(this.callId);
  }
};
</script>

<style scoped>
h2 {
  color: #1976d2;
}
</style>
