<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <div class="pt-0 mt-0">
        <v-fade-transition>
          <div
            v-show="hover"
            class="person-label text-caption text-uppercase font-weight-bold grey--text dr-flex justify-space-between align-center px-2"
          >
            {{ speakerText }}

            <v-btn v-if="!isChat" icon x-small elevation-0>
              <v-icon @click="playFromPosition(phrase.starts_at)"
                >mdi-play-circle-outline</v-icon
              >
            </v-btn>
          </div>
        </v-fade-transition>

        <v-card-text
          class="grey--text text--darken-2 body-1"
          style="word-break: break-word;"
        >
          {{ phrase.text }}
        </v-card-text>

        <div
          v-show="hover || hasTranscriptSegmentObservation"
          class="coaching-label text-caption text-uppercase font-weight-bold grey--text dr-flex justify-end px-2"
        >
          <v-btn
            v-if="showTask && !selfSigned"
            icon
            :color="getCoachable(phrase._id)"
            style="margin-right:-.5rem;"
            @click="makeObservation(phrase)"
          >
            <v-icon small>mdi-creation</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-hover>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    phrase: {
      type: Object
    },
    channelCount: {
      type: Number
    },
    direction: {
      type: String
    },
    isChat: {
      type: Boolean,
      default: false
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    callId: {
      required: true,
      type: Number
    },
  },
  computed: {
    ...mapGetters('observations', [
      "pendingTranscriptObservationData",
      "savedTranscriptObservationData"
    ]),
    ...mapGetters("scorecardTasks", ["scorecardTaskList"]),
    ...mapGetters("users", [
      "userData",
    ]),
    speakerText() {
      if (this.channelCount > 1) {
        return this.phrase.speaker;
      } else {
        return this.phrase.channel == 1 ? "Speaker 1" : "Speaker 2";
      }
    },
    scTask() {
      return this.scorecardTaskList.find(task => task.call_id == this.callId)
    },
    hasTranscriptSegmentObservation() {
      let hasPendingSeg = this.pendingTranscriptObservationData.some((item) => item.transcript_seg_id === this.phrase._id && item.call_id == this.callId)

      return (this.scTask) 
        ? (this.savedTranscriptObservationData.some((item) => item.transcript_seg_id === this.phrase._id && item.task_id == this.scTask.task_id) || hasPendingSeg)
        : hasPendingSeg

    },
  },
  methods: {
    ...mapActions({
      appendTranscriptObservation: "observations/appendTranscriptObservation",
    }),
    makeObservation(phrase) {
      const transcript_seg_id = phrase._id
      const comment = ""
      const user_id = this.userData.id
      this.appendTranscriptObservation([{
        "transcript_seg_id": transcript_seg_id,
        "comment": comment,
        "user_id": user_id,
        "call_id": this.callId
      }] )
    },
    getCoachable(phrase_id) {

      if (this.scTask && this.savedTranscriptObservationData.some(item => item.transcript_seg_id === phrase_id && item.task_id == this.scTask.task_id)) {
        return "orange darken-1"
      }
      return (this.pendingTranscriptObservationData.find(item => item.transcript_seg_id === phrase_id && item.call_id == this.callId)) 
        ? "orange darken-1" 
        : "blue-grey darken-1"
    },
    playFromPosition(e) {
      this.$emit("update-play-from-position", {
        position: e,
        buttonPress: true
      });
    },
    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.highlight {
  background-color: yellow !important;
  font-weight: bold !important;
}

.person-label {
  position: absolute;
  top: -0.75rem;
  width: 100%;
}

.coaching-label {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dr-flex {
  display: flex;
  flex-direction: row;
}
</style>
