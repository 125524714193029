<template>
  <div>
    <v-dialog
      v-if="metaDataWindow"
      v-model="windowOpen"
      hide-overlay
      content-class="elevation-0"
    >
      <v-card
        ref="vCardHeight"
        color="white"
        v-show="metaDataWindow"
        class="draggable-card"
        elevation="4"
        :style="metaDataWindowStyles"
      >
        <v-card-title
          draggable="true"
          @mousedown="startDragging"
          @mouseup="stopDragging"
          class="draggable-handle primary lighten-2 white--text"
        >
          <div class="row">
            <div class="col">Custom Metadata</div>
            <div align="right" class="col">
              <span class="dim">(Call ID: {{ callId }})</span>
            </div>
          </div>
          <v-btn dark icon>
            <v-icon class="pr-0 mr-0" @click="closeWindow">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <v-flex style="overflow: auto">
              <v-data-table
                :headers="dynaLabelHeaders"
                :items="dynalabelInfo"
                :search="search"
                :hide-default-footer="true"
                disable-pagination
                class="dl-dt"
                :height="tableHeight"
                :loading="loadingDynalabelData"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.value`]="{ item }">
                  <a
                    v-if="regexUrlCheck(item.value)"
                    target="_blank"
                    :href="checkValueProtocol(item.value)"
                    >{{ item.value }}</a
                  >

                  <span v-else>{{ item.value }} </span>
                </template>

                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                    single-line
                    hide-details
                    clearable
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="theme--dark primary" small @click="closeWindow"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/utils/AxiosInstance.js";
import moment from "moment";
import regexChecks from "@/mixins/regexChecks";
import customMetadataHelpers from "@/mixins/customMetadataHelpers";

export default {
  mixins: [regexChecks, customMetadataHelpers],
  data() {
    return {
      search: "",
      metaDataWindowStyles: {
        top: "100px",
        left: "100px",
        width: "600px",
        "max-height": "70%"
      },
      dragging: false,
      startX: "",
      startY: "",
      windowOpen: true,
      dynaLabelHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "key"
        },
        {
          text: "Value",
          align: "start",
          sortable: false,
          value: "value"
        }
      ],
      dynalabelInfo: [],
      sortBy: "var_text",
      sortDesc: false,
      loadingDynalabelData: true,
      tableHeight: "40vh",
      zIndex: 500,
      isImportant: ""
    };
  },
  props: {
    metaDataWindow: {
      type: Boolean,
      required: true
    },
    callId: {
      type: Number,
      required: true
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {},
  methods: {
    ...mapActions({}),
    startDragging(event) {
      this.dragging = true;

      this.startX = event.clientX - parseInt(this.metaDataWindowStyles.left);
      this.startY = event.clientY - parseInt(this.metaDataWindowStyles.top);
      event.preventDefault();
      document.addEventListener("mousemove", this.onDrag);

      this.zIndex += 500;
      this.isImportant = " !important ";
    },
    onDrag(event) {
      if (this.dragging) {
        this.metaDataWindowStyles.left = `${event.clientX - this.startX}px`;
        this.metaDataWindowStyles.top = `${event.clientY - this.startY}px`;
      }
      event.preventDefault();
    },
    stopDragging() {
      this.dragging = false;
      this.startX = this.startY = "";
      document.removeEventListener("mousemove", this.onDrag);
    },
    closeWindow() {
      this.$emit("closeWindow");
    },
    getDynaFields() {
      let dynalabel_url =
        process.env.VUE_APP_SETUP_DYNA_LABELS_CALLS + this.callId;

      let axios_params = {
        params: {}
      };
      if (this.selfSignedTranscript) {
        dynalabel_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId;
        axios_params["params"]["route"] = "dynalabels-calls";
        axios_params["headers"] = {
          Authorization:
            this.$route.params.userid + " " + this.$route.params.reckey
        };
      }
      axios
        .get(dynalabel_url, axios_params)
        .then(response => {
          if (response && response.data) {
            let dynalabelFields = Object.entries(response.data.data).map(
              ([key, value]) => ({
                key,
                value
              })
            );
            dynalabelFields.sort((a, b) => a.key.localeCompare(b.key));
            this.dynalabelInfo = this.formatDynalabelInfo(dynalabelFields);
            this.loadingDynalabelData = false;
          }
        })
        .finally(() => {
          // loader.hide();
        });
    }
  },

  mounted() {
    this.getDynaFields();

    this.tableHeight =
      "calc(" + this.$refs.vCardHeight.$el.offsetHeight + "px - 200px)";
  }
};
</script>
<style scoped>
.draggable-handle {
  cursor: move;
}

.draggable-card {
  position: fixed;
}

.sticky-dialog {
  z-index: 500;
}
</style>
