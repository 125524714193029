<template>
    <v-content style="height: 100vh; max-height: calc(100%);">
        <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">

          <v-row>
            <v-col style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center">
              <Filters ref="myInsightsFilters" :channels="this.channelCount" feature="insights" @filters-loaded="filtersLoaded" @agents-filter-updated-reload="filterUpdated" @subjectiveSet-filter-updated-reload="filterUpdated" @accounts-filter-updated-reload="filterUpdated"></Filters>
              <v-row v-show="(this.myRouteName=='insights-details')">
                <v-col class="pl-12 pb-0">
                <template v-if="insightsAggregatedDynaLabelDataLoadingStatus==='loaded' && insightsAggregatedDynaLabelDataLoadingErrorStatus===false">
                  <insight-breadcrumbs :linkEnabled="true" :insightHeader="insightHeader" :level="'dynalabel'" @go-to-page="goBack" @re-apply-search-phrase="reApplySearchPhrase" :searchFieldIsEmpty="searchFieldIsEmpty" ></insight-breadcrumbs>
                  </template>
                </v-col>
                <v-col class="text-right pr-10">
                  <export-menu @exportClick="exportClick"
                    :disableDeliveries="insightsAggregatedDynaLabelDataLoadingStatus!='loaded'"
                    :disableExports="insightsAggregatedDynaLabelDataLoadingStatus!='loaded'"
                  ></export-menu>
                  <insights-download
                    ref="InsightsDownload"
                    v-if="
                      insightsAggregatedDynaLabelDataLoadingStatus==='loaded'
                    "
                    class="float-right"
                    :insightsId="Number(uriInsightId)"
                    :insightsUrl="insightDynalabelUrl"
                    :insightsTitle="insightHeader + ' Grouping Dynamic Label'"
                    :dateRange="getDateRangeString()"
                  ></insights-download>
                  <v-btn fab small dark class="ml-2" color="primary" href="/faq/1" target="_blank" rel="noopener" title="Click for Insights Help and FAQs">
                    <v-icon dark>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <insight-processing-status :type="'dynalabel'"></insight-processing-status>
              <v-row>
                <v-col cols="8" offset="3">
                  <ad-hoc-search ref="adHocSearch" :channels="this.channelCount" @searchChanged="searchChanged" />
                </v-col>
              </v-row>

              <v-container v-show="(this.myRouteName=='insights-details')" pl-5 pr-5 fluid style="height: 100vh; max-height: calc(100%);">
                <v-row>
                  <v-col class="pl-6">
                    <v-card flat v-if="insightsAggregatedDynaLabelDataLoadingStatus==='loaded' && insightsAggregatedDynaLabelDataLoadingErrorStatus===false" class="mb-12 pb-12">
                      <v-data-table

                        :headers="filteredAggregateHeaders"
                        :items="dynaLabelTeamData"
                        :hide-default-footer="true"
                        class="pl-4 pr-8 pb-2"
                      >

                        <template v-slot:no-data>
                          <v-alert :value="true" color="warning" icon="mdi-message-alert-outline" class="ma-2" dark>
                            {{ noDataMessage }}
                          </v-alert>
                        </template>

                        <template v-slot:item.name="{ item }">

                          <v-tooltip top :class="getCallCountStyle(item)">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="loadDynaLabelTeam()"
                                :disabled="item.callCount==0"
                                text
                                small
                                color="primary"
                                class="agent-btn"
                                >{{ item.name }}
                              </v-btn>
                            </template>
                            <span v-if="item.callCount>0">{{item.name}} ({{item.id}}) call log&hellip;</span>
                          </v-tooltip>

                        </template>

                        <template v-slot:item.team_sentiment="{ item }" >
                          <template v-if="item.callCount>0">
                          <template v-if="item.channelCount==2">
                              <subjective-distribution-widget
                                :scale="50"
                                :info="item.sentimentCounts"
                                :display="'Customer'"
                                :type="'sentiment'"
                              ></subjective-distribution-widget>

                              </template>
                              <template v-else-if="item.channelCount==1">
                                <subjective-distribution-widget
                                  :scale="50"
                                  :info="item.sentimentCounts"
                                  :display="'Speaker 1/Speaker 2'"
                                  :type="'sentiment'"
                              ></subjective-distribution-widget>
                              </template>
                              <template v-else>
                                <subjective-distribution-widget

                                  :scale="50"
                                  :info="item.sentimentCounts"
                                  :display="'Both Channels'"
                                  :type="'sentiment'"
                              ></subjective-distribution-widget>
                              </template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.team_emotion="{ item }" >
                            <template v-if="item.callCount>0">
                              <template v-if="!isChat">
                                <template v-if="item.channelCount==2">
                                  <subjective-distribution-widget
                                    :scale="50"
                                    :info="item.emotionCounts"
                                    :display="'Customer'"
                                    :type="'emotion'"
                                  ></subjective-distribution-widget>
                                </template>
                              <template v-else-if="item.channelCount==1">
                                <subjective-distribution-widget
                                :scale="50"
                                :info="item.emotionCounts"
                                :display="'Speaker 1/Speaker 2'"
                                :type="'emotion'"
                                ></subjective-distribution-widget>
                              </template>
                              <template v-else>
                                <subjective-distribution-widget
                                  :scale="50"
                                  :info="item.emotionCounts"
                                  :display="'Both Channels'"
                                  :type="'emotion'"
                                ></subjective-distribution-widget>
                              </template>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.averageDuration_formatted="{ item }">
                          <template v-if="item.callCount>0">
                            <span class="tData" title="Talk Time (the average length of all the audio recordings)">{{ item.averageDuration_formatted }}</span>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.silenceAveragePercent="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span class="tData" :title="item.silenceAverageSeconds + ' (Average measured silence per call)'">{{ item.silenceAveragePercent }}</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>
                        <template v-slot:item.silenceExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer">{{ item.silenceRatio }}</span><span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.talkTimeAverageSeconds="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span class="tData" :title="item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'">{{ item.talkTimeAveragePercent }}</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>
                        <template v-slot:item.talkTimeExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' + item.talkTimePercentCustomer">{{item.talktimeRatio}}</span>
                              <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.overtalkAveragePercent="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="tData" :title="item.overtalkAverageSeconds + ' (Average measured overtalk per call)'">{{ item.overtalkAveragePercent }}</span>
                              <span v-else class="tData" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.overtalkExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer">{{item.overtalkRatio}}</span>
                              <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.callCount="{ item }"><span class="tData">{{ getCallCountDisplay(item) }}</span>
                          <template>
                            <v-icon v-if="item.channelCount==3" color="warning" class="pl-10 mixed-channels">mdi-alert-box</v-icon>
                          </template>
                        </template>

                      </v-data-table>

                      <v-card-title>
                        <h1 class="main">Dynamic Labels</h1>
                        <v-text-field
                          dense
                          v-model="dynaLabelSearch"
                          append-icon="mdi-magnify"
                          placeholder="Find Dynamic Labels"
                          single-line
                          clearable
                          @click:clear="clearDynaLabelSearch()"
                          class="pl-0 pr-4"
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :sort-by.sync="dynaLabelSortBy"
                        :sort-desc.sync="dynaLabelSortDesc"
                        :search="dynaLabelSearch"
                        :headers="filteredGroupedHeaders"
                        :items="dynaLabelRowData"
                        :items-per-page="5"
                        :footer-props="{
                          itemsPerPageOptions: [10, 25, 50, 100]
                        }"
                        class="pl-4 pr-8 pb-2"
                        :custom-sort="customSort"
                      >

                        <template v-slot:no-data>
                          <v-alert :value="true" color="warning" icon="mdi-message-alert-outline" class="ma-2" dark>
                            {{ noDataMessage }}
                          </v-alert>
                        </template>

                        <template v-slot:item.name="{ item }" :class="getCallCountStyle(item)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="loadDynaLabelAgents(item.id)"
                                :disabled="item.callCount==0"
                                text
                                small
                                color="primary"
                                class="agent-btn"
                              >{{ item.name }}
                              </v-btn>
                            </template>
                            <span v-if="item.callCount>0">{{item.name}} ({{item.id}}) call log&hellip;</span>
                          </v-tooltip>
                        </template>

                        <template v-slot:item.averageDuration_formatted="{ item }">
                          <template v-if="item.callCount>0"><span class="tData" :class="getCallCountStyle(item)" title="Talk Time (the average length of all the audio recordings)">{{ item.averageDuration_formatted }}</span></template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.agent_sentiment="{ item }" >
                          <template v-if="item.callCount>0">
                          <template v-if="item.channelCount==2">
                              <subjective-distribution-widget
                                :scale="50"
                                :info="item.sentimentCounts"
                                :display="'Customer'"
                                :type="'sentiment'"
                              ></subjective-distribution-widget>

                              </template>
                              <template v-else-if="item.channelCount==1">
                              <subjective-distribution-widget
                                :scale="50"
                                :info="item.sentimentCounts"
                                :display="'Speaker 1/Speaker 2'"
                                :type="'sentiment'"
                            ></subjective-distribution-widget>
                            </template>
                              <template v-else>
                                <subjective-distribution-widget

                                  :scale="50"
                                  :info="item.sentimentCounts"
                                  :display="'Both Channels'"
                                  :type="'sentiment'"
                              ></subjective-distribution-widget>
                              </template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.agent_emotion="{ item }" >
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <template v-if="item.channelCount==2">
                                <subjective-distribution-widget
                                  :scale="50"
                                  :info="item.emotionCounts"
                                  :display="'Customer'"
                                  :type="'emotion'"
                                ></subjective-distribution-widget>
                              </template>
                              <template v-else-if="item.channelCount==1">
                                  <subjective-distribution-widget
                                    :scale="50"
                                    :info="item.emotionCounts"
                                    :display="'Speaker 1/Speaker 2'"
                                    :type="'emotion'"
                                ></subjective-distribution-widget>
                                </template>
                              <template v-else>
                                <subjective-distribution-widget
                                  :scale="50"
                                  :info="item.emotionCounts"
                                  :display="'Both Channels'"
                                  :type="'emotion'"
                                ></subjective-distribution-widget>
                              </template>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.silenceAveragePercent="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span class="tData" title="Average measured silence per call">{{ item.silenceAveragePercent }}</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>
                        <template v-slot:item.silenceExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer">{{ item.silenceRatio }}</span>
                              <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.talkTimeAveragePercent="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span class="tData" :title="item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'">{{ item.talkTimeAveragePercent }}</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>
                        <template v-slot:item.talkTimeExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' +item.talkTimePercentCustomer">{{ item.talktimeRatio }}</span>
                              <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.overtalkAveragePercent="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="tData" :title="item.overtalkAverageSeconds + ' (Average measured overtalk per call)'">{{ item.overtalkAveragePercent }}</span>
                              <span v-else class="tData" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.overtalkExtra="{ item }">
                          <template v-if="item.callCount>0">
                            <template v-if="!isChat">
                              <span v-if="item.channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer">{{ item.overtalkRatio }}</span>
                              <span v-else class="dim" :class="getCallCountStyle(item)" title="Not available from single-channel audio">&mdash;</span>
                            </template>
                            <template v-else><span>&mdash;</span></template>
                          </template>
                          <template v-else><span class="no-data">&mdash;</span></template>
                        </template>

                        <template v-slot:item.callCount="{ item }"><span class="tData">{{ getCallCountDisplay(item) }}</span>
                          <template>
                            <v-icon v-if="item.channelCount==3" color="warning" class="pl-10 mixed-channels">mdi-alert-box</v-icon>
                          </template>
                        </template>
                      </v-data-table>

                      <h5 class="footnote"><sup class="footnote">*</sup> A call can belong to more than one dynamic label. The sum of the dynamic labels may add up to more than the "All Dynamic Labels" total.</h5>

                      <h5 class="footnote" v-if="showMixAlert"><v-icon color="warning" class="mixed-channels">mdi-alert-box</v-icon> Reported data contain a mix of single and dual-channel call recordings &mdash; agent/cust details apply only to the dual-channel recordings.</h5>

                    </v-card>

                    <v-card flat v-if="insightsAggregatedDynaLabelDataLoadingErrorStatus===true">
                      <v-card-text>{{ insightsAggregatedDynaLabelDataLoadingError }}</v-card-text>
                    </v-card>
                    <v-card flat v-if="insightsAggregatedDynaLabelDataLoadingStatus !=='loaded' ">
                      <v-row align="center" justify="center">
                        <v-col style="text-align: center;">
                          <v-progress-circular
                            class="align-self-center mb-4"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <div class="progress">retrieving list loading</div>
                        </v-col>
                      </v-row>
                    </v-card>

                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <ScheduledExportsModal
            :showScheduledExportsModal="showScheduledExportsModal"
            @HIDE_SCHEDULED_EXPORTS_MODAL="hideScheduledExportsModal"
            :isNew="true"
            :insightOrScorecard="'insights'"
            :pdfDelivery="false"
            :exportPath="1"
          ></ScheduledExportsModal>
        </v-container>
    </v-content>
</template>

<script>
import Filters from "@/components/Filters.vue";
import SubjectiveDistributionWidget from "@/components/Widgets/SubjectiveDistributionWidget.vue"
import { mapGetters, mapActions } from "vuex";
import emotion from "@/mixins/emotion";
import subjective from "@/mixins/subjective";
import sort from "@/mixins/sort";
import moment from "moment";
import AdHocSearch from "@/components/Controls/AdHocSearch.vue";
import presentation from "@/mixins/presentation";
import selectPage from "@/mixins/selectPage";
import InsightProcessingStatus from '../../components/Insights/InsightProcessingStatus.vue';
import InsightBreadcrumbs from '../../components/Breadcrumbs/InsightBreadcrumbs.vue';
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";
import ExportMenu from "@/components/Widgets/ExportMenu.vue";
import InsightsDownload from "@/components/Controls/InsightsDownload.vue";

export default {
  mixins: [emotion, subjective, sort, presentation, selectPage],
  components: {
    SubjectiveDistributionWidget,
    Filters,
    AdHocSearch,
    InsightProcessingStatus,
    InsightBreadcrumbs,
    ExportMenu,
    ScheduledExportsModal,
    InsightsDownload,
  },
  data() {
    return {
      dynaLabelSortBy: 'name',
      dynaLabelSortDesc: false,
      showModal: false,
      listType: "",
      dynaLabelSearch: '',
      callSearch: '',
      emotionType: '',
      callId: "0",
      showFootnote: false,
      showMixAlert: false,
      singleChannelCallCount: 0,
      dualChannelCallCount: 0,
      agentsInScope: [],
      accountsInScope: [],
      channelCount: 0,
      noDataMessage: process.env.VUE_APP_NO_CALLS_FOUND,
      showScheduledExportsModal: false,
    }
  },
  computed: {
    ...mapGetters("insights", [
      "insightsAdHocSearchPath",
      "insightsAggregatedDynaLabelDataLoadingErrorStatus",
      "insightsAggregatedDynaLabelDataLoadingError",
      "insightsAggregatedDynaLabelDataLoadingStatus",
      "insightsAggregatedDynaLabelData",

      "insightsGroupedDynaLabelDataLoadingErrorStatus",
      "insightsGroupedDynaLabelDataLoadingError",
      "insightsGroupedDynaLabelDataLoadingStatus",
      "insightsGroupedDynaLabelData",

      "insightsListData",
      "insightsListDataLoadingStatus",

      "displayFormats"
    ]),
    ...mapGetters("presentation", [
      "presentationInsightsData",
      "presentationInsightsDataLoadingStatus",
      "presentationInsightsDataLoadingError",
      "presentationInsightsDataLoadingErrorStatus",
    ]),
    ...mapGetters('dateRange', [
      'start_date',
      'end_date',
      'date_range_english',
    ]),
    searchFieldIsEmpty() {
      return this.$refs.hasOwnProperty('adHocSearch')
          && this.$refs.adHocSearch.hasOwnProperty('search')
          && (typeof this.$refs.adHocSearch.search == 'undefined'
              || this.$refs.adHocSearch.search == null
              || (typeof this.$refs.adHocSearch.search == 'string'
                  && this.$refs.adHocSearch.search == '')) || false
    },
    filteredAggregateHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationInsightsData.map(p => p.source_key);
      return this.aggregateHeaders
        .filter(function(o) {
          return sourceKeys.includes(o.key);
        })
        .sort(
          ({ key: r }, { key: t }) =>
            sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
        );
    },
    filteredGroupedHeaders() {
      // check against presentation service source keys
      var sourceKeys = this.presentationInsightsData.map(p => p.source_key);
      return this.groupedHeaders
        .filter(function(o) {
          return sourceKeys.includes(o.key);
        })
        .sort(
          ({ key: r }, { key: t }) =>
            sourceKeys.indexOf(r) - sourceKeys.indexOf(t)
        );
    },
    getUnit() {
      return (moment(this.end_date).diff(moment(this.start_date),'days')>1) ? 'day' : 'hour'
    },
    uriInsightId() {
      return this.$route.params.id;
    },
    filteredAgentList() {
      if(!this.$store.getters["filters/agents"]) return []
        else return this.$store.getters["filters/agents"].agents || []
    },
    filteredAccountList() {
      if(!this.$store.getters["filters/accounts"]) return []
      else return this.$store.getters["filters/accounts"].accounts || []
    },
    insightHeader() {
        return this.insightsListData.find(o => o.id==this.uriInsightId).title
    },
    dynaLabelRowData() {
      let myThis = this
      let rtnList = [...this.insightsGroupedDynaLabelData].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
      return rtnList.map((obj) => {
        let channelCount = 0;
        if(obj.single_channel_call_count>0&&obj.dual_channel_call_count>0) {
          myThis.showMixAlert = true;
          channelCount = 3;
        } else {
          channelCount = (obj.dual_channel_call_count>0) ? 2 : 1;
        }
        myThis.channelCount = Math.max(this.channelCount,channelCount)
        if ((obj.talktime.percent_customer + obj.talktime.percent_agent) > 1) myThis.showFootnote = true
        return myThis.insightsPresentationFormattedData(obj, true, channelCount, false)
      })
    },
    dynaLabelTeamData() {
      if (this.insightsAggregatedDynaLabelData.length>0) {
        if (!this.insightsAggregatedDynaLabelData[0].hasOwnProperty("call_count") ||  (this.insightsAggregatedDynaLabelData[0].hasOwnProperty("call_count") && this.insightsAggregatedDynaLabelData[0].call_count<1)) {
          return []
        } else {
          let myThis = this
          return this.insightsAggregatedDynaLabelData.map(
            (obj) => {
              let channelCount = 0;
              if(obj.single_channel_call_count>0&&obj.dual_channel_call_count>0) {
                myThis.showMixAlert = true;
                channelCount = 3;
              } else {
                channelCount = (obj.dual_channel_call_count>0) ? 2 : 1;
              }
              myThis.channelCount = Math.max(this.channelCount,channelCount)
              if ((obj.talktime.percent_customer + obj.talktime.percent_agent) > 1) myThis.showFootnote = true
              return myThis.insightsPresentationFormattedData(obj, false, channelCount, false)
            }
          )
        }
      }
      return []
    },
    aggregateHeaders() {
      return [
        {text: "Dynamic Label", value: "name", sortable: false, width: "1%", key: 'dynalabel_insights[].title'},
        {text: "Talk Time", value: "averageDuration_formatted", align: "center", sortable: false, width: "1%", key: 'dynalabel_insights[].average_duration'},
        {text: this.headerPrefix + "Sentiment", value: "team_sentiment", align: "center", sortable: false, width: "1%", key: 'dynalabel_insights[].sentiment_counts.agent_sentiment_counts||dynalabel_insights[].sentiment_counts.customer_sentiment_counts||dynalabel_insights[].sentiment_counts.overall_sentiment_counts'},
        {text: this.headerPrefix + "Emotion", value: "team_emotion", align: "center", sortable: false, width: "1%", key: 'dynalabel_insights[].emotion_counts.agent_emotion_counts||dynalabel_insights[].emotion_counts.customer_emotion_counts||dynalabel_insights[].emotion_counts.overall_emotion_counts'} ,
        {text: "Silence", value: "silenceAveragePercent", align: "center", sortable: false, width: "1%", key: 'dynalabel_insights[].silence.average_percent'},
        {text: "agent/cust", value: "silenceExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'dynalabel_insights[].silence.percent_agent/dynalabel_insights[].silence.percent_customer'},
        {text: "Overtalk", value: "overtalkAveragePercent", align: "center", sortable: false, width: "1%", key:'dynalabel_insights[].overtalk.average_percent'},
        {text: "agent/cust", value: "overtalkExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'dynalabel_insights[].overtalk.percent_agent/dynalabel_insights[].overtalk.percent_customer'},
        {text: "Speaking Time", value: "talkTimeAverageSeconds", align: "center", sortable: false, width: "1%", key:'dynalabel_insights[].talktime.average_percent'},
        {text: "agent/cust", value: "talkTimeExtra", align: "center", sortable: false, class: "dim", width: "1%", key: 'dynalabel_insights[].talktime.percent_agent/dynalabel_insights[].talktime.percent_customer'},
        {text: "Calls", value: "callCount", align: "center", sortable: false, width: "1%", key: 'dynalabel_insights[].call_count'},

      ]
    },
    groupedHeaders() {
      return [
        {text: "Dynamic Label", value: "name", width: "1%", key: 'dynalabel_insights[].title'},
        {text: "Talk Time", value: "averageDuration_formatted", align: "center", sortable: true, width: "1%", key: 'dynalabel_insights[].average_duration'},
        {text: this.headerPrefix + "Sentiment", value: "agent_sentiment", align: "center", sortable: true , width: "1%", key: 'dynalabel_insights[].sentiment_counts.agent_sentiment_counts||dynalabel_insights[].sentiment_counts.customer_sentiment_counts||dynalabel_insights[].sentiment_counts.overall_sentiment_counts'},
        {text: this.headerPrefix + "Emotion", value: "agent_emotion", align: "center", sortable: true, width: "1%", key: 'dynalabel_insights[].emotion_counts.agent_emotion_counts||dynalabel_insights[].emotion_counts.customer_emotion_counts||dynalabel_insights[].emotion_counts.overall_emotion_counts'},
        {text: "Silence", value: "silenceAveragePercent", align: "center", width: "1%", key: 'dynalabel_insights[].silence.average_percent'},
        {text: "agent/cust", value: "silenceExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'dynalabel_insights[].silence.percent_agent/dynalabel_insights[].silence.percent_customer'},
        {text: "Overtalk", value: "overtalkAveragePercent", align: "center", width: "1%", key:'dynalabel_insights[].overtalk.average_percent'},
        {text: "agent/cust", value: "overtalkExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'dynalabel_insights[].overtalk.percent_agent/dynalabel_insights[].overtalk.percent_customer'},
        {text: "Speaking Time", value: "talkTimeAveragePercent", align: "center", width: "1%", key:'dynalabel_insights[].talktime.average_percent'},
        {text: "agent/cust", value: "talkTimeExtra", align: "center", sortable: true, class: "dim", width: "1%", key: 'dynalabel_insights[].talktime.percent_agent/dynalabel_insights[].talktime.percent_customer'},
        {text: "Calls", value: "callCount", align: "center", width: "1%", key: 'dynalabel_insights[].call_count'},
      ]
    },
    headerPrefix() { return (this.channelCount==2) ? "Customer " : "Overall " },
    myRouteName() { return this.$route.name },
    isChat() {
      return this.insightsGroupedDynaLabelDataLoadingStatus == 'loaded' && this.insightsGroupedDynaLabelData[0].insight_media_type == 'Chat';
    },
    insightDynalabelUrl() {
      // make the scorecards URL insightDynalabelUrl
      let exportUrl = new URL(process.env.VUE_APP_INSIGHTS_INTERNAL + this.$route.params.id + '/dynalabels/')
      let subjectiveFilterObject = this.$store.getters["filters/subjectiveSet"] || {subjectiveSet:[]}
      exportUrl.searchParams.append('start_date',moment(this.start_date).format("YYYY-MM-DD"))
      exportUrl.searchParams.append('end_date',moment(this.end_date).format("YYYY-MM-DD"))

      if(JSON.stringify(subjectiveFilterObject.subjectiveSet) !== '[]') {
        subjectiveFilterObject.subjectiveSet.forEach(value => {
          exportUrl.searchParams.append('subjective',JSON.stringify(value))
        })
      }

      if(this.$route.query.speaker !== undefined) exportUrl.searchParams.append('speaker',this.$route.query.speaker)
      if(this.$route.query.phrase !== undefined) exportUrl.searchParams.append('search_phrase',this.$route.query.phrase)

      exportUrl.searchParams.append('grouping',this.getUnit)

      return exportUrl.toString()
    }
  },
  methods: {
    ...mapActions({
      retrievePresentationInsightsData:
        "presentation/retrievePresentationInsightsData",
        setAggregatedDynalabelLoadingStatus: "insights/setAggregatedDynalabelLoadingStatus"
    }),
    ...mapActions("insights", [
      "setInsightsAdHocSearchPath",
    ]),
    searchChanged(status) {
        switch(status) {
            case 'on':
              this.setInsightsAdHocSearchPath(this.$route.path)
              break;
            case 'off':
            default:
                this.setInsightsAdHocSearchPath('')
                break;
        }
    },
    reApplySearchPhrase() {
      if(this.$route.query.hasOwnProperty('phrase')&&this.$route.query.phrase!='') {
        this.$refs.adHocSearch.search = this.$route.query.phrase
      }
    },
    resetSearch() {
      let resetQuery = Object.assign({},this.$route.query)
      delete resetQuery.phrase
      delete resetQuery.speaker
      this.$refs.adHocSearch.search = ''
      this.$router.replace({ path: this.$route.path, query: resetQuery})
    },
    goBack(location) {
      this.selectInsightPage(location, false, this.$refs)
    },
    numberGrammar(num) {
      return (num>10) ? num : this.numberToWord(num)
    },
    numberToWord(num) {
      return ['zero','one','two','three','four','five','six','seven','eight','nine','ten'][num]
    },
    filtersLoaded(f) {
      this.agentsInScope = (f.find(x=> x.value=='agents')) ? f.find(x=> x.value=='agents').options : []
      this.accountsInScope = (f.find(x=> x.value=='accounts')) ? f.find(x=> x.value=='accounts').options : []
    },
    setListType() {
      this.listType = "dyna label"
    },
    loadDynaLabelAgents(dynalabel_id) {
      let insight_id = this.$route.params.id
      this.$router.push({ path: '/insights/' + insight_id + '/dynalabels/' + dynalabel_id + '/agents/', query: this.$route.query }).catch(err => {})
      setTimeout(function() {
        if(this.$vuetify) this.$vuetify.goTo(0)
      }, 1500)
    },
    loadDynaLabelTeam() {
      let insight_id = this.$route.params.id
      this.$router.push({ path: '/insights/' + insight_id + '/dynalabels/0/agents/', query: this.$route.query }).catch(err => {})
      setTimeout(function() {
        if(this.$vuetify) this.$vuetify.goTo(0)
      }, 1500)
    },
    formatPercent(val, limit) {
      let percent = String(Math.round(val * 100)) ;
      if (limit) {
        if (Number(percent) > 100) {
            return "100%"
        } else {
          return percent + "%"
        }
      } else {
        return percent + "%"
      }
    },
    formatHMS(seconds) {

      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    clearDynaLabelSearch() {
      this.dynaLabelSearch = ''
    },
    filterUpdated() {
      this.loadPresentationData()
    },
    loadDynaLabelData() {
      if (this.myRouteName=='insights-details') {
        let insight_id = this.$route.params.id
        let dynalabel_id = this.$route.params.dynalabelId
        let accountsFilterObject = this.$store.getters["filters/accounts"] || {accounts:[]}
        let agentFilterObject = this.$store.getters["filters/agents"] || {agents:[]}
        let subjectiveFilterObject = this.$store.getters["filters/subjectiveSet"] || {subjectiveSet:[]}
        let parameters = {
          starts_at: moment(this.start_date).format("YYYY-MM-DD"),
          ends_at: moment(this.end_date).format("YYYY-MM-DD"),
          insight_id: insight_id,
          dynalabel_id: dynalabel_id,
          agent_ids: agentFilterObject.agents,
          account_ids: accountsFilterObject.accounts,
          subjective: subjectiveFilterObject.subjectiveSet,
          grouping: this.getUnit,
          speaker: this.$route.query.speaker,
          phrase: this.$route.query.phrase,
          presentation: this.presentationInsightsData
        }
        this.$store.dispatch("insights/retrieveInsightsList", { starts_at: parameters.starts_at, ends_at: parameters.ends_at});
        this.$store.dispatch("insights/retrieveInsightsAggregatedDynaLabelData",parameters);
        this.$store.dispatch("insights/retrieveInsightsGroupedDynaLabelData",parameters);
      }
    },
    routeChanged() {
      this.loadPresentationData()
    },
    getCallCountDisplay(item) {
      return (this.$route.query.phrase) ? (item.callCount + '/' + item.overallCount) : item.callCount;
    },
    getCallCountStyle(item) {
      return (item.callCount == 0) ? 'no-data' : '';
    },
    loadPresentationData() {
      let insight_id = this.$route.params.id
      if (this.myRouteName=='insights-details') {
        this.setAggregatedDynalabelLoadingStatus("loading")
        this.retrievePresentationInsightsData({
            insight_id: insight_id,
            aggregate_type: "dynalabel",
            display_type: 'D',
        });
      }
    },
    getDateRangeString() {
      return moment(this.start_date).format("M/D/YYYY") + ' - ' +
        moment(this.end_date).format("M/D/YYYY");
    },
    exportClick(item) {
      switch (item) {
        case "downloadExport":
          // do immediate export stuff
          this.$refs.InsightsDownload.exportInsights(null,null);
          break;

        case "scheduleExport":
          // pop schedule export modal to do schedule stuff
          this.showScheduledExportsModal = true;
          break;
      }
    },
    hideScheduledExportsModal(hideScheduledExportsModal) {
      this.showScheduledExportsModal = hideScheduledExportsModal;
    },
  },
  mounted() {
    this.routeChanged()
    this.$root.$on('DATE_RANGE_UPDATED', () => {
      this.$router.push(
        { query:
            Object.assign(
              {},
              this.$route.query,
              { start_date: String(moment(this.$store.getters["dateRange/start_date"]).format("YYYY-MM-DD")) },
              { end_date: String(moment(this.$store.getters["dateRange/end_date"]).format("YYYY-MM-DD")) }
            )
        }
      ).catch(err => {})
      this.loadPresentationData()
    })
  },
  watch: {
    $route: "routeChanged",
    presentationInsightsDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.loadDynaLabelData()
      }
    }
  },
};
</script>

<style scoped>
  h1, h2, h3, h4 { width: 100%; }
  th span {
    font-size: .8rem;
    color: rgba(0,0,0,.75);
  }
  th.dim span {
    font-size: .7rem;
  }
  td > span.name {
    font-size: 1.1rem;
    cursor: pointer;
  }
  td > span.tData {
    font-size: 1rem;
    cursor: help;
    text-align: center;
  }
  td span.dim {
    font-size: .9rem;
    cursor: help;
  }
  h1 {
    line-height: 1.2rem !important;
    font-size: 1.2rem !important;
  }

  h1.main {
    line-height: 1.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  h1.main > span.dim {
    font-size: 1.2rem !important;
    color: rgba(0,0,0,.4);
  }
  h2 {
    margin-top: .75rem;
    line-height: 1.2rem !important;
    font-size: 1.1rem !important;
    color: rgba(0,0,0,.9) !important;
  }
  h3 {
    margin-top: 1rem;
    position: relative;
    line-height: 1.2rem !important;
    font-size: .9rem !important;
    color: rgba(0,0,0,.9) !important;
  }
  h3 > span.dim {
    font-size: .75rem;
    color:black;
    display: inline-block;
    position: absolute;
    left: 7rem;
  }
  h4 {
    position: relative;
    margin-top: .75rem;
  }
  h4 > span.large {
    font-size: 2rem;
    color: rgba(0,0,0,.8) !important;
    cursor: help;
  }
  h4 > span.medium {
    font-size: 1.5rem;
    color: rgba(0,0,0,.8) !important;
    cursor: help;
  }
  h4 span.dim {
    padding-left: .75rem;
    font-size: .9rem;
    color: black;
    display: inline-block;
    position: absolute;
    left: 6.25rem;
    cursor: help;
  }
  .progress {
    font-size: .9rem;
    color: rgba(0, 0, 0, .5);
  }
  .dont-wrap { white-space: nowrap; }
  .bubble { width: 100%; display: inline-block; padding: 4px; border-radius: 4px; color: #fff; white-space: nowrap; }
  h5.footnote {
    margin-bottom: .5rem;
  }
  sup.footnote {
    vertical-align: baseline;
    position: relative;
    top: 0rem;
    font-size: 1rem;
    color: rgba(179, 63, 38, 1);
  }
  h5.footnote sup.footnote {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  div.mix-alert {
    margin-top: 2rem;
    padding: 0 2rem 0 0;
  }
  div.mix-alert p {
    font-size: .9rem;
  }
  .float-subjective {
      width: 50%;
      float: left;
  }
  .float-child {
    width: 50%;
    float: left;
  }
  ::v-deep button.agent-btn span.v-btn__content {
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
  .no-data {
    opacity: 50%;
    color: #C0C0C0;
  }

</style>
