<template>
  <v-card flat>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="12">
          <scorecard-breadcrumbs
            v-if="isDataReady"
            :scorecardHeader="scorecardTitle"
            :dynaLabelHeader="callData.dynalabelTitle"
            :singleDynaCount="callData.dynalabelCount < 2"
            :agentHeader="callData.agentName"
            :callId="$route.params.callId"
            :level="'call-scorecard'"
            :linkEnabled="!selfSigned"
            @go-to-page="selectPage"
          ></scorecard-breadcrumbs>
          <audio-waveform
            class="no-print"
            v-if="userDataLoadingStatus == 'loaded'"
            ref="waveform"
            :callId="String($route.params.callId)"
            :displayedRegions="displayedRegions"
            :selfSignedTranscript="selfSigned"
          ></audio-waveform>
          <h2 class="header-color pb-4" v-html="getHeader"></h2>
          <div class="pr-2 pl-2">
            <insights-widget
              :callId="String($route.params.callId)"
              :outlined="true"
              :selfSignedTranscript="selfSigned"
            ></insights-widget>
          </div>
          <call-scorecard-layout
            v-if="isDataReady"
            :callData="callData"
            :callId="$route.params.callId"
            :scorecardId="$route.params.scorecardId"
            :dynalabelId="$route.params.dynalabelId"
            :agentId="$route.params.agentId"
            :format="callScorecardFormat"
            :displayedRegions="displayedRegions"
            :selfSigned="selfSigned"
            :menuLocation="'printableCallScorecard'"
            @spliceDisplayedRegions="spliceDisplayedRegions"
            @setDisplayedRegions="setDisplayedRegions"
          ></call-scorecard-layout>
          <scorecard-loading-status
            v-else
            :message="scorecardMessageType"
            :type="scorecardLoadingType"
          ></scorecard-loading-status>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <custom-dyna-metadata-grid
        :callId="$route.params.callId"
        :selfSigned="selfSigned"
      ></custom-dyna-metadata-grid>
    </v-card-text>
  </v-card>
</template>

<script>
import CallScorecardLayout from "../../components/Scorecards/CallScorecardLayout.vue";
import ScorecardLoadingStatus from "../../components/Scorecards/ScorecardLoadingStatus.vue";
import InsightsWidget from "../../components/Widgets/InsightsWidget.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CustomDynaMetadataGrid from "../../components/Widgets/CustomDynaMetadataGrid.vue";
import AudioWaveform from "@/components/AudioWaveform.vue";
import ScorecardBreadcrumbs from "../../components/Breadcrumbs/ScorecardBreadcrumbs.vue";
import selectPage from "@/mixins/selectPage";

export default {
  mixins: [selectPage],
  components: {
    InsightsWidget,
    CallScorecardLayout,
    ScorecardLoadingStatus,
    CustomDynaMetadataGrid,
    AudioWaveform,
    ScorecardBreadcrumbs
  },
  data() {
    return {
      callData: {
        loading: true
      },
      isPrinting: false,
      displayedRegions: []
    };
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus"
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingErrorStatus",
      "userDataListLoadingStatus",
      "userDataList",
      "cfOnlyUser",
      "administratorUser",
      "standardUser",
    ]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("authentication", ["loginReqPB"]),
    callAdjustmentsFilter() {
      return this.$route.query.hasOwnProperty("call_type")
        ? this.$route.query.call_type
        : "all";
    },
    scorecardLoadingType() {
      if (this.callScorecardDataV2LoadingError) return "error";
      else return "loading";
    },
    scorecardMessageType() {
      if (this.callScorecardDataV2LoadingError)
        return "There was an error loading this call scorecard...";
      else return "Call scorecard loading, please wait...";
    },
    scorecardTitle() {
      return this.callData.scorecardTitle;
    },
    agentName() {
      return this.callData.agentName;
    },
    isDataReady() {
      return !this.callData.loading;
    },
    getHeader() {
      return this.isDataReady
        ? "<span>" +
            this.scorecardTitle +
            " Call Scorecard for " +
            this.agentName +
            "</span>"
        : "";
    },
    selfSigned() {
      return (
        this.$route.params.reckey != null && this.$route.params.userid != null
      );
    },
    bearerSet() {
      return (
        localStorage.getItem("bearer") && localStorage.getItem("bearer") != ""
      );
    },
    callScorecardFormat() {
      return "printingCallScorecard";
    }
  },
  methods: {
    ...mapActions({
      retrieveCallScorecardDataV2:
        "scorecardsV2Calls/retrieveCallScorecardDataV2",
      checkLoginReqPB: "authentication/checkLoginReqPB",
      setIsAuthenticated: "authentication/setIsAuthenticated",
      retrieveUserData: "users/retrieveUserData"
    }),
    retrieveData() {
      if (
        this.$route.name == "printable-call-scorecard-v2" ||
        this.$route.name == "printable-call-scorecard-v2-signed"
      ) {
        let payload = {
          scorecard_id: this.$route.params.scorecardId,
          dynalabel_id: this.$route.params.dynalabelId,
          agent_id: this.$route.params.agentId,
          call_id: this.$route.params.callId,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          users: this.userDataList,
          call_type: this.callAdjustmentsFilter
            ? this.callAdjustmentsFilter
            : "all"
        };
        if (this.selfSigned) {
          payload["userid"] = this.$route.params.userid;
          payload["reckey"] = this.$route.params.reckey;
          payload["selfSigned"] = this.selfSigned;
        }
        this.retrieveCallScorecardDataV2(payload);
      }
    },
    handleLoginReq() {
      // if loginreqpb then route to the page that will make them log in. otherwise load data
      if (this.loginReqPB == "log_in" && !this.bearerSet) {
        this.unSignedReroute();
      } else {
        let params = {
          selfSignedTranscript: this.selfSigned,
          userid: this.$route.params.userid,
          reckey: this.$route.params.reckey,
          call_id: this.$route.params.callId
        };

        this.retrieveUserData(params);
        this.retrieveData();
      }
    },
    unSignedReroute() {
      // send them to the login screen through call scorecard
      let to_route =
        "/scorecards-v2/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.$route.params.agentId +
        "/calls";
      this.$router
        .push({
          path: to_route,
          query: this.$route.query,
          hash: "call-scorecard-" + this.$route.params.callId
        })
        .catch(err => {});
      this.$router.go();
    },
    signedReroute() {
      // send them to the signed call scorecard
      let to_route =
        "/scorecards-v2-signed/" +
        this.$route.params.scorecardId +
        "/dynalabels/" +
        this.$route.params.dynalabelId +
        "/agents/" +
        this.$route.params.agentId +
        "/calls/" +
        this.$route.params.reckey +
        "/" +
        this.$route.params.userid;
      this.$router
        .push({
          path: to_route,
          query: this.$route.query,
          hash: "call-scorecard-" + this.$route.params.callId
        })
        .catch(err => {});
      this.$router.go();
    },
    setDisplayedRegions(regions) {
      this.displayedRegions.push({
        id: regions.id,
        offsets: regions.offsets,
        isCategory: regions.isCategory
      });
    },
    spliceDisplayedRegions(index) {
      this.displayedRegions.splice(index, 1);
    },
    selectPage(page) {
      this.selectScorecardPage(page, "scorecard");
    }
  },
  mounted() {
    // if the user is not logged in and is trying to use a self signed link
    if (!this.bearerSet && this.selfSigned) {
      this.checkLoginReqPB({
        userid: this.$route.params.userid,
        reckey: this.$route.params.reckey,
        call_id: this.$route.params.callId
      });
    } else if (this.bearerSet && this.selfSigned) {
      // signed link while logged in, go to normal scorecard
      this.unSignedReroute();
    } else {
      this.retrieveData();
    }
    this.callData.loading = true;
  },
  watch: {
    callScorecardDataV2LoadingStatus: function(status) {
      if (status == "loaded") {
        if (this.callScorecardDataV2[this.$route.params.callId] != undefined) {
          this.callData = Object.assign(
            {},
            this.callScorecardDataV2[this.$route.params.callId]
          );
          this.callData.loading = false;
        }
      }
    },
    loginReqPB: "handleLoginReq"
  }
};
</script>

<style scoped>
.header-color {
  font-size: 1.3rem;
  color: #1976d2;
}

@media print {
  * {
    zoom: 96%;
  }

  @page {
    margin: 0;
  }

  .no-print {
    display: none !important;
  }
}
</style>
