import Vue from "vue";
import axios from "axios";
import App from "../App";
import router from "../router/index"
import { store } from "@/store/index.js";

// add session token to all requests except self sign ones

const requestInterceptor = request => {
      let pathname = window.location.pathname.split("/")[1]
      if ((localStorage.getItem("bearer") == "" || !localStorage.getItem("bearer")) && (pathname != 'transcripts-signed' && pathname != 'scorecards-v2-signed' && pathname != 'tasks-signed')) {
        router.push("/signin").catch(() => {});
      } else {
        // Token will be dynamic so we can use any app-specific way to always
        // fetch the new token before making the call

        // if we are using a bearer to authorize include it
        if(pathname != 'transcripts-signed' && pathname != 'scorecards-v2-signed' && pathname != 'tasks-signed') {
          request.headers.Authorization = "bearer " + localStorage.getItem("bearer");
        }
        resetTimer();
        return request;
      }
};

const handleResponse = response => {
  return response;
};

function resetTimer() {
  clearTimeout(Vue.prototype.$logoutTimer);
  Vue.prototype.$logoutTimer = setTimeout(() => router.push("/signin").catch(() => {}), Vue.prototype.$timeBeforeLogout);
}

async function sendHtmlEmail(username,siteurl,serviceCall,requestHeaders,responseHeaders,currentCookies,currentLocalStorage,errorJsonString,errorCode) {
  // get correct html template for the error
  const emailService = process.env.VUE_APP_EMAIL_SERVICE;
  var errorEmail = ""
  var url = ""
  var replaceArray = []
  var infoArray = []

  if(errorCode === 403) {
    url = "/src/assets/error_email_403.html"
  } else {
    url = "/src/assets/error_email.html"
  }

  try {
    const response = await fetch(url);
    errorEmail = await response.text();
    // need to string replace vars in the html to be what we want
    // lists didnt work for this
    replaceArray = ["{{process.env.NODE_ENV}}","{{process.env.VUE_APP_ENV}}","{{username}}","{{siteurl}}","{{serviceCall}}","{{requestHeaders}}","{{responseHeaders}}","{{currentCookies}}","{{currentLocalStorage}}","{{errorJsonString}}","{{errorCode}}"]
    infoArray = [process.env.NODE_ENV,process.env.VUE_APP_ENV,username,siteurl,serviceCall,requestHeaders,responseHeaders,currentCookies,currentLocalStorage,errorJsonString,errorCode]

    // loop through the two arrays and replace the values in the template with the error information
    replaceArray.forEach((r, index) => {
      const i = infoArray[index];
      errorEmail = errorEmail.replace(r,i)
    });
    const config = {
      headers: { "Authorization": "Bearer " + localStorage.getItem("bearer") }
    };

    // 2024-09-11 BM: exclude call stream not found, for chats
    if (errorCode == '404' && serviceCall.includes('call-streams')) {
      return;
    }

    axios
    .post(emailService, {
      to: "customerservice@mycallfinder.com",
      from: "no-replies@mycallfinder.com",
      subject: "CallFinder UI Error Notification",
      bearer: localStorage.getItem("bearer"),
      text: encodeURI(await errorEmail)
    },config)
  } catch (err) {
    return "";
  }

}


function sendEmail(error) {
  var currentUser = store.getters["users/userData"];

  var username = '';
  var siteurl = window.location.href;                 // the current url of the user
  var serviceCall = error.response.config.url;        // the service call that errored out
  var requestHeaders = error.response.config.headers; // headers in the request
  var responseHeaders = error.response.headers;       // headers in the response
  var currentCookies = document.cookie;               // the cookies in the site
  var currentLocalStorage = { ...localStorage};       // the current localstorage
  var errorCode = error.response.status;              // the error number that was returned

  // grab username. if proxying let them know


  // need to test for each of these
  if(currentUser.id == currentUser.csrep_userid) {
    username = String(currentUser.username)
  } else {
    username = String(currentUser.username) + " ( " + String(currentUser.csrep_userid) + " is proxying )"
  }
  // make the service call
  // send the email
  sendHtmlEmail(username,siteurl,serviceCall,JSON.stringify(requestHeaders, null, 2),JSON.stringify(responseHeaders, null, 2),currentCookies,JSON.stringify(currentLocalStorage,null, 2),JSON.stringify(error.response, null, 2),errorCode)

}

function isProxying(userData) {
  return userData && userData.id != userData.csrep_userid || false;
}

const handleError = error => {

  let isProxy = isProxying(store.getters["users/userData"])

  // very important - if we don't "handle" an error we must return a rejected Promise for everything to "flow"
   if (error.hasOwnProperty('response') && typeof error.response != "undefined") {

      let promotedEnv = (process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'staging')
      // list of error codes we send an email for.
      // if the error number is in this list and the error is not from email service
      if(process.env.VUE_APP_EMAIL_ERROR_CODES.includes(error.response.status) && !String(error.response.config.url).includes(process.env.VUE_APP_EMAIL_SERVICE) && promotedEnv) {
        sendEmail(error);
      }

     // If 401 -> send user to signin
     if (error.response.status === 401) {
       store.commit("setIsAuthenticated", false);
       window.location.href = "/signin";
       // only redirect the following errors when not in development
     } else if (error.response.status === 403 && promotedEnv && !isProxy) {
       window.location.href = "/forbidden";
     } else if (error.response.status === 404 && promotedEnv && !isProxy) {
       window.location.href = "/notfound";
     } else if (error.response.status === 500 && promotedEnv) {
       window.location.href = "/servererror";
     } else if (error.response.status === 502 && promotedEnv) {
       window.location.href = "/badgateway";
     } else if (error.response.status === 503 && promotedEnv) {
       window.location.href = "/serviceunavailable";
     } else {
       return Promise.reject({...error})
     }
   } else if (error.hasOwnProperty('message')) {
     return Promise.reject(new Error(error.message))
   } else {
     return Promise.reject({ ...error })
   }
};

const AxiosInstance = () => {
  const instance = axios.create();
  // set post data format
  instance.defaults.headers.post["Content-Type"] = "application/json";
  // Include the same config and headers in all api calls
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(handleResponse, handleError);
  return instance;
};



export default AxiosInstance();
