export default {
  methods: {

    getSetupMenuItems() {
      return [
        {
          title: "Dynamic Labels",
          type: "dynaLabels",
          description: "A dynamic label allows you to group or categorize a set of calls by specific call criteria. Dynamic labels are then associated with insights and scorecards.",
          expandMessage: "A dynamic label can be created, named “New Agents” and defined with the appropriate agent picklist selection. A separate dynamic label can be created, named “Existing Agents” and defined with the balance of the agent picklist selection. Refer to FAQs related to Insight and Scorecard Definitions to learn more about how to link them to existing dynamic labels.",
          emptyMessage: "There are no Dynamic Labels defined.",
        },
        {
          title: "Access Roles",
          type: "accessRoles",
          description: "An access role allows you to group or categorize your internal or third-party users.",
          expandMessage: "If each QA team lead should have call visibility restricted to a separate set of calls, create an access role identifying each unique access group.",
          emptyMessage: "There are no Access Roles defined.",
        },
        {
          title: "Insight Definitions",
          type: "insightDefinitions",
          description: "Insight Definitions allow you to easily pair dynamic labels with access roles.",
          expandMessage: "If one QA team lead is responsible for coaching new agents, you can select an existing dynamic label which identifies “New Agents” and share with the access role tied to the user or users which should have access to the new agent calls.",
          emptyMessage: "There are no Insight Definitions defined.",
        },
        {
          title: "Scorecard Definitions",
          type: "scorecardDefinitions",
          description: "Scorecards allow for the manual/automated scoring of calls. You may define a scorecard using variety of Dynamic Labels, Searches, Call Score limits, Time Limits, etc.",
          emptyMessage: "There are no Scorecard Definitions defined.",
        },
        {
          title: "Coaching Definitions",
          type: "coachingDefinitions",
          description: "Coaching definitions link coaching processes to scorecards, enabling efficient management of agent performance. Definitions are created by selecting specific scorecards, which activate coaching and guidance features for the relevant teams. Team management is handled under the Profiles tab.",
          emptyMessage: "There are no Coaching Definitions defined.",
        },
      ]
    },

  }
};
