<template>
  <div>
    <task-container
      v-if="showTaskContainer"
      ref="theTaskContainer"
      :TDSHStyle="trimmedScrollHeightStyle"
      :task="selectedTask"
      :agentInfo="agentInfo"
      :canCreate="true"
      :key="0"
      :selfSigned="selfSigned"
      @resetParent="resetUserInterface"
    >
    </task-container>
    <v-card-text v-else class="scrollable-content">
      <div
        class="d-flex justify-content-center align-items-center"
        style="width: 100%; height: 100%;"
      >
        <v-row class="justify-center align-center">
          <v-col cols="auto" class="d-flex align-center">
            <v-btn
              small
              @click="createNewTask"
              style="color: #016aaf;background-color: rgba(1, 106, 175, .25)"
              text
              >+ new task</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import CallTranscriptBodyPhrase from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptBodyPhrase.vue";
import TranscriptSummary from "@/components/Transcripts/TranscriptSummary.vue";
import CallTranscriptTimeline from "@/components/UnifiedCall/Component/CallTranscript/CallTranscriptTimeline.vue";
import TaskContainer from "@/components/Tasks/TaskContainer.vue";

export default {
  components: {
    LoadingStatusBox,
    CallTranscriptBodyPhrase,
    TranscriptSummary,
    CallTranscriptTimeline,
    TaskContainer
  },
  data() {
    return {
      taskHasBeenCreated: false
    };
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    selectedTask: {
      type: Object,
      required: false
    },
    selfSigned: {
      required: false,
      type: Boolean,
      default: false
    },
    scrollHeightStyle: {
      required: true,
      type: String,
      default: ""
    },
    callData: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters("resources", [
      "agentData",
      "agentDataLoadingStatus",
      "agentDataLoadingError",
      "agentDataLoadingErrorStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "scorecardTaskList",
      "scorecardTaskListLoadingStatus",
    ]),
    trimmedScrollHeightStyle() {
      let styleString = this.scrollHeightStyle;
      const match = styleString.match(/height:\s*(\d+)px/)
      if (match) {
        const originalValue = parseInt(match[1], 10)
        const newValue = originalValue - 184 // subtract 184
        // Replace the old value with the new value
        styleString = styleString.replace(
          /height:\s*\d+px/,
          `height: ${newValue}px`
        )
      }
      return styleString
    },
    showTaskContainer() {
      return (
        this.selectedTask &&
        (!this.componentIsNewTaskFrom("call_id") ||
          this.taskHasBeenCreated ||
          this.scorecardTaskList.find(task => task.call_id == this.callId))
      );
    },
    agentInfo() {
      if (this.agentDataLoadingStatus == "loaded") {
        return this.agentData.find(o =>
          this.selfSigned
            ? o.id == this.$route.params.agentid
            : o.id == this.callData.agentId
        );
      } else {
        return { name: "", id: "" };
      }
    }
  },
  methods: {
    ...mapActions("scorecardTasks", [
      "pushNewScorecardTask",
      "updateScorecardTask",
      "updateScorecardTaskIsOpen",
      "deleteTaskFromScorecardList",
      "updateTaskMessageInStore",
      "updateTaskInTaskLists",
    ]),
    resetUserInterface(params) {
      this.$emit("resetParent", params);
    },
    createNewTask() {
      this.taskHasBeenCreated = true;
    },
    componentIsNewTaskFrom(where) {
      return (
        this.selectedTask &&
        this.selectedTask.hasOwnProperty("new_task") &&
        this.selectedTask.new_task &&
        this.selectedTask.hasOwnProperty("new_task_from") &&
        this.selectedTask.new_task_from == where
      );
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped></style>
